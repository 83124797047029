import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import $ from "jquery";
import axios from "axios";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";



const citymanagement = () => {
  const [loc, setLoc] = useState(window.location.pathname);
   const token = localStorage.getItem("Token");

  const [cityList, setCityList] = useState([]);
  const [stateData , setStateData]=useState([]);
  const [cityName, setCityName]=useState([]);
  const [cityId, setCityId]=useState([]);
  const [stateName, setStateName]=useState([]);
  const [stateId, setStateId]=useState();
  const [errorMsg, seterrorMsg] =useState();
 
 

  //async function to fetc market data
  async function getCityList() {
    try {
    
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_city_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
        }
      );
      console.log("city response---------------------", fetchResponse);

      const city_List = fetchResponse.data.data;
      const error_code = fetchResponse.data.error_code;

      if (error_code == 200) {
        setCityList(city_List);
       
        
      }
    } catch (err) {
      console.log("Failed to get offer list ", err);
    }
  }

  async function fetchStateName(name) {
    //console.log("value................", id);
    try {
      const formData = new FormData();
      formData.append("state_name", name);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "state_list",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      //console.log("State Response........", fetchResponse);

      const ErrorCode = fetchResponse.data.error_code;
     // console.log("course Error Code ", ErrorCode);

      if (ErrorCode == 200) {
        const stateData = fetchResponse.data.data;
        //console.log("statename Array.............", stateData);
        setStateData(stateData);
        
      } else {
        setStateData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }
   async function createCity() {
    
    try {
      const formData = new FormData();
      formData.append("state_id", stateId);
      formData.append("city_name", cityName);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "create_city",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

       
     
      if (fetchResponse.data.error_code == 200) {
        $(".delete_container").removeClass("showModal");
        seterrorMsg(fetchResponse.data.message);
        const stateData = fetchResponse.data.data;
        
        $(".formSuccess").show()
         setTimeout(() => {
           $(".formSuccess").hide()

         }, 5000);
             window.location.reload();

      } else {
        setCityName([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function edit_city_data() {
    try{
      const token = localStorage.getItem("Token");
        const formData = new FormData();
        formData.append("state_id", stateId);
        formData.append("state_name", stateName);
        formData.append("city_id", cityId);
        formData.append("city_name",cityName);
      
        
        const StatusResponse =  await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_edit_city",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              
              Authorization: token,
            },
          }
     
        
        );
        // console.log(" Response........", StatusResponse);
  
        if (StatusResponse.data.error_code == 200) {
         
          $(".edit_Modal").removeClass("showModal");
          seterrorMsg(StatusResponse.data.message);
         $(".formSuccess").show()
         setTimeout(() => {
           $(".formSuccess").hide()
   
          
         }, 5000);
             window.location.reload();
            
        
         }
         if (StatusResponse.data.error_code == 409) {
          seterrorMsg(StatusResponse.data.message);
          $(".error_msg").show();
          setTimeout(() => {
            $(".error_msg").hide();
          }, 5000);
        }
       } catch (err) {
         console.log("Log in Fail", err);
       }
   
  }
   
  const getStateName = (e) => {
    setStateName(e);
    //console.log("state name.................", e);
    {
      stateData.map((id) => {
        if (e == id.state_id) {
          setStateId(id.state_id);
        }
      });
    }
  };

  useEffect(() => {
     getCityList();
    fetchStateName();
   
  }, []);

  const columns = [
    {
      name: <b>State Name </b>,
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: <b>City Name</b>,
      selector: (row) => row.city_name,
      sortable: true,
    },
    {
     
      name: <b>Action</b>,
      selector: (row) => {
        return (
          <div className="d-flex">
              
              <a
              className="cta"
              onClick={() => edit_city(row.state_id,row.state_name,row.city_id,row.city_name)}
              style={{ backgroundColor: "transparent" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginLeft: "2px" }}
                src={require("../images/Pencil.png")}
              />
              &nbsp;
            </a>

            <a
              className="cta"
              onClick={() => delete_city(row.city_id)}
              style={{ backgroundColor: "transparent" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginLeft: "2px" }}
                src={require("../images/delete.png")}
              />
              &nbsp;
            </a>
            
          </div>
        );
      },
      sortable: true,

    
      
    },
  ];

  function addCity() {
    //console.log("button clicked................")
   
   $(".add_city").addClass("showModal");
   
  }
  function closeModal() {
    
    $(".delete_container").removeClass("showModal");
    $(".edit_Modal").removeClass("showModal");
    $(".delete_city_modal").removeClass("showModal");
    $(".add_city").removeClass("showModal")
   
  }

  function edit_city(state_id,state_name,city_id,city_name) {
    
    setStateId(state_id);
    setStateName(state_name);
    setCityId(city_id);
    setCityName(city_name);
    
    $(".edit_Modal").addClass("showModal");
  }

  function delete_city(city_id){
    setStateId(city_id);
    $(".delete_city_modal").addClass("showModal");
  }

  function delete_city(city_id){
    setCityId(city_id);
    $(".delete_city_modal").addClass("showModal");
  }
  async function deleteCity() {
    try{
      const token = localStorage.getItem("Token");
        const formData = new FormData();
        formData.append("city_id", cityId);
        const StatusResponse =  await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_delete_city",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              
              Authorization: token,
            },
          }
     
        
        );
      
          console.log("delete city response..............",StatusResponse);
        if (StatusResponse.data.error_code == 200) {
          
          $(".delete_city_modal").removeClass("showModal");
          seterrorMsg(StatusResponse.data.message);
         $(".formSuccess").show()
         setTimeout(() => {
           $(".formSuccess").hide()
   
          
         }, 5000);
             window.location.reload();
            
        
         }
       } catch (err) {
         console.log("Log in Fail", err);
         $(".delete_city_modal").removeClass("showModal");
       }
  }

  return (
    <div>
      <Header />
      <div className="d-flex">
      <Menu pageTitle={loc} />

      <div className="content-wrapper">
      <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
        </div>

        <div className="d-flex border_class">
          <div>
            {" "}
            <h5><b> City List</b></h5>
          </div>
          <button className="create_button" style={{marginLeft:"auto"}}
             onClick={addCity}>Add City</button>
        </div>

        <div className="border_class">
        <DataTable
          // title="Campus List"
          fixedHeader
          fixedHeaderScrollHeight="400px"
          pagination
          columns={columns}
          data={cityList}
        />
        </div>
      </div>
      <div id="delete" className="modaloverlay delete_container add_city">
        <div className="modalContainer" style={{ width:"30%" }}>
        <div  className="close_modal">
           <button style={{color:"red" ,border:"none",position:"right"}} onClick={closeModal}>
           <img
                style={{ width: "30px", height: "30px", marginLeft: "2px" }}
                src={require("../images/wrong.jpg")}
              />
           </button>
           </div>
          <div className="card-body" style={{ marginTop: "0px",  }}>

          <div >
                
            
                <p
                  style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
                >
                  Select State:
                </p>
                
                
                <div className="d-flex mt-1 mb-4" >
                   <select  onChange={(e) => getStateName(e.target.value)}
                    style={{
                      borderRadius: "5px",
                      width:"100%",
                      fontSize: "13PX",
                      padding: "8px 12px",
                      fontWeight:"700",
                      border: "1px solid #2d5dd0",
                      height: "40px",
                      marginLeft: "6px", 
                      marginRight:"6px"
                    }}>
                    <option>Select State</option>

                    {stateData.map((name) => {
                    return (
                      <option value={name.state_id} >
                        {name.state_name}
                      </option>
                    );
                  })}
                   </select>
  
  
                </div>
              </div>
            <div>
                
            
              <p
                style={{ fontWeight: "600",  fontSize: "13px",marginTop: "4px" }}
              >
                Add City:
              </p>
              <div className=" mt-2">
              
              <input
                type="text"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                style={{ marginRight:"6px", width: "100%", borderRadius: "5px", background: "white", height: "40px", fontSize: "13PX", padding: "8px 12px", border: "1px solid #2d5dd0" }}
              />
               </div>

              <div className="d-flex mt-3 " >
                <a
                 
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Add"
                    onClick={ createCity}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: " rgb(41, 48, 67)",
                      fontSize: "13PX",
                      padding: "8px 12px",
                      fontWeight:"700"
                    }}
                  />
                </a>


              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
        </div>

        <div id="delete" className="modaloverlay  edit_Modal ">
      
      <div className="modalContainer" style={{ width:"30%" }}>
         <div className="close_modal">
         <button className="close_modal" style={{color:"red" ,border:"none",position:"right", }} onClick={closeModal}>
         <img
                style={{ width: "30px", height: "30px", marginLeft: "2px" }}
                src={require("../images/wrong.jpg")}
              />
         </button>
         </div>
        <div className="card-body" style={{ marginTop: "0px",  }}>
          
          <div>
            <p
              style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
            >
               State Name:
            </p>
            <div className="d-flex mt-1 mb-4" >
                   <select  onChange={(e) => getStateName(e.target.value)}
                    style={{
                      borderRadius: "5px",
                      width:"100%",
                      fontSize: "13PX",
                      padding: "8px 12px",
                      fontWeight:"700",
                      border: "1px solid #2d5dd0",
                      height: "40px",
                      marginLeft: "6px", 
                      marginRight:"6px"
                    }}>
                    <option>Select State</option>

                    {stateData.map((name) => {
                    return (
                      <option value={name.state_id} >
                        {name.state_name}
                      </option>
                    );
                  })}
                   </select>
  
  
                </div>

             <div className="mt-4">
                
            
                <p
                  style={{ fontWeight: "600",  color: "black", fontSize: "13px",marginTop: "4px" }}
                >
                  City Name:
                </p>
                <div  className="d-flex mt-2">
                
                <input
                  type="text"
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                  style={{ marginRight:"6px", width: "100%", borderRadius: "5px", background: "white", height: "40px", fontSize: "13PX", padding: "8px 12px", border: "1px solid #2d5dd0" }}
                />
                 </div>
  
                
              </div>

             <p className="error_msg" style={{color:"red"}}> {errorMsg} </p>

            <div className="d-flex mt-3 " >
              <a
                href="#"
                style={{ marginLeft: "auto" }}
              >
                <input
                  type="button"
                  className="create_btn"
                  value="Submit"
                  onClick={()=>edit_city_data()}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: " rgb(41, 48, 67)",
                    fontSize: "13PX",
                    padding: "8px 12px",
                    fontWeight:"700"
                  }}
                />
              </a>


            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
      </div>
      {/* Modal end */}

      <div id="delete" className="modaloverlay delete_container delete_city_modal">
        <div className="modalContainer">
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Delete City
              </p>
              <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>
                Are You Sure, You Want To Delete this City?
              </h2>

              <div className="d-flex mt-3">
                <a
                  //   onClick={close_delete_modal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"
                    onClick={()=>closeModal()}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#c4c4c4",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>

                <a
                  href="#delete_with_protection"
                  style={{ color: "grey", fontSize: "15PX" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_city"
                    value="Delete"
                    onClick={() => deleteCity()}
                    style={{
                      borderRadius: "5px",
                      marginRight: "7px",
                      backgroundColor: "#d21f3c",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      {/* Modal end */}

    </div>
    </div>
  );
};

export default citymanagement ;
