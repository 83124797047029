import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export function AddAdmin() {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("Token");
  const [error_message, updateError_message] = useState("");

  const [campusId, updateCampusId] = useState("");
  const [adminName, updateAdminName] = useState("");
  const [email, updateEmail] = useState("");
  const [mobile, updateMobile] = useState("");
  const [Password, updatePassword] = useState("");
  const [campusName, updateCampusName] = useState("");

  const location = useLocation();
  const history = useHistory();
  const { camp_data } = location.state || { id: "none" };
 

  async function createAdmins(emailField) {
    try {
      const campus_name = document.getElementById("campus_name");
      const admin_name = document.getElementById("admin_name");
      const admin_email = document.getElementById("email");
      const admin_contact = document.getElementById("contact");
      const password = document.getElementById("password");

      var filter =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (
        campus_name.value == "" &&
        admin_name.value == "" &&
        admin_email.value == "" &&
        (admin_contact.value == "") & (password.value == "")
      ) {
        $(".Campus_name").show();

        setTimeout(function () {
          $(".Campus_name").hide();
        }, 3000);
      } else if (campus_name.value == "") {
        $(".Campus_name").show();

        setTimeout(function () {
          $(".Campus_name").hide();
        }, 3000);
      } else if (admin_name.value == "") {
        $(".AdminName").show();

        setTimeout(function () {
          $(".AdminName").hide();
        }, 3000);
      } else if (admin_email.value == "") {
        $(".EmailAddress").show();

        setTimeout(function () {
          $(".EmailAddress").hide();
        }, 3000);
      } else if (!filter.test(admin_email.value)) {
        $(".validEmailAddress").show();

        setTimeout(function () {
          $(".validEmailAddress").hide();
        }, 3000);
      } else if (admin_contact.value == "") {
        $(".contact").show();

        setTimeout(function () {
          $(".contact").hide();
        }, 3000);
      } else if (mobile.length != 10) {
        $(".validMobileNumber").show();

        setTimeout(function() {
          $(".validMobileNumber").hide();
        }, 3000);
      }  
      else if (password.value == "") {
        $(".password").show();

        setTimeout(function () {
          $(".password").hide();
        }, 3000);
      } else {
        const formData = new FormData();
        formData.append("campus_id", campusId);
        formData.append("admin_name", adminName);
        formData.append("email", email);
        formData.append("mobile", mobile);
        formData.append("password", Password);
        // console.log(formData);

        // for (const pair of formData.entries()) {
        //   console.log(pair[0], pair[1]);
        // }

        const response = await axios.post(
          process.env.REACT_APP_API_KEY + "create_admin",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );

        console.log("Create Admin response--------------------", response);

        updateError_message(response.data.message);
        if(response.data.error_code == 200){
        $(".formSuccess").show();
        setTimeout(function () {
          $(".formSuccess").hide();
          history.push("/universities"); 
        }, 3000);

        updateCampusId("");
        updateAdminName("");
        updateEmail("");
        updateMobile("");
        updatePassword("");
      }
    }
    } catch (err) {
      console.log("Log in Fail", err);
    }

    // GET CAMPUS LIST
  }
  async function fetchList() {
   
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "get_campus_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      // console.log("Get Campus List", fetchResponse);
      const campusListArry = fetchResponse.data.data;
      // console.log("Campus listArry", campusListArry);

      setData(campusListArry);
      // console.log("Data list", data);
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }
  useEffect(() => {
    fetchList();
    setValue();
  }, []);

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/[^0-9]/g, "");
    updateMobile(onlyDigits);
  };

  const resetValues = () => {
    
    updateCampusId("");
    updateAdminName("");
    updateEmail("")
    updateMobile("");
    updatePassword("");
    
    $("#campus_name").val("disabled")
    // $("#paid_status").val(1);

  };


  function setValue(){
   $("#campus_name").val(camp_data);
   updateCampusId(camp_data);
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkandhandle = () => {
    if (adminName.length == 0 || email.length == 0) {
      alert("please preview after filling in all data");
    } else {
      if (mobile > 11111111 && mobile <= 99999999) {
        handleShow();
      } else {
        alert("invalid mobile number");
      }
    }
  };

  function preview() {
    $(".preview_polls").show();
  }
  function closePreview() {
    $(".preview_polls").hide();
  }

  return (
    <div>
      <div>
      
      <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {error_message}
          </Alert>
        </Stack>
        </div>
        <div>
          <div className="border_class">
            <div class="row">
              <div class="col-md-9">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>                 
                  <div className="d-flex">
                    <label className="all_labels">
                      Campus Name
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <select
                    className="form-select form-select-sm input_box"
                    aria-label=".form-select-sm example"
                    id="campus_name"
                    onChange={(e) => updateCampusId(e.target.value)}
                    required
                    value={campusId}
                  >
                    <option value="disabled">
                      Select Campus
                    </option>
                    {data.map((item, index) => {
                      
                      return (
                        <option value={item.campus_id} key={index}>
                          {item.campus_name}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    class="Campus_name"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Select Campus Name
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-9">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">
                        Campus ID
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="name"
                      disabled
                      className="stud-prefered-name input_box"
                      id="camp_id_app"
                      autoComplete="off"
                      placeholder="Campus ID"
                      value={campusId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border_class">
            <div class="row">
              <div class="col-md-9">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  {/* <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "bold" }}>Admin Name<span className='text-danger'>*</span></label> */}
                  <div className="d-flex">
                    <labe className="all_labels">
                      Admin Name
                    </labe>

                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <input
                    type="name"
                    className="stud-prefered-name input_box"
                    id="admin_name"
                    autoComplete="off"
                    placeholder="Enter Admin Name..."
                    value={adminName}
                    onChange={(e) => updateAdminName(e.target.value)}
                    // style={{ width: "100%", height: "35px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "10px", paddingLeft: "5PX" }}
                  />
                  <div
                    class="AdminName"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Admin Name
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-9">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    {/* <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "bold" }}>Email<span className='text-danger'>*</span></label> */}
                    <div className="d-flex">
                      <label className="all_labels">
                        Email
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="email"
                      className="stud-prefered-name all_inputs"
                      id="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                      autoComplete="off"
                      placeholder="Email goes here..."
                      value={email}
                      onChange={(e) => updateEmail(e.target.value)}
                      
                      required
                    />
                    <div
                      class="EmailAddress"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Email Address
                      </h4>
                    </div>

                    <div
                      class="validEmailAddress"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter valid Email Address with @
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-9">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    {/* <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "bold" }}>Contact<span className='text-danger'>*</span></label> */}
                    <div className="d-flex">
                      <label className="all_labels">
                        Contact
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="text"
                      className="stud-prefered-name input_box"
                      minLength="10"
                      maxLength="10"
                      pattern="/^-?\d+\.?\d*$/"
                      autoComplete="off"
                      id="contact"
                      placeholder="Enter Contact Number..."
                      value={mobile}
                      onChange={(e) => {
                        checkInput(e);
                      }}
                     
                      required
                    />
                    <div
                      class="contact"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Contact Number
                      </h4>
                    </div>
                    <div class="validMobileNumber"
                     style={{ margin: "0", display: "none" }}
                  >
                    <h4 class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}>
                      Please Enter 10 digit mobile number
                    </h4>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-9">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    
                    <div className="d-flex">
                      <label className="all_labels">
                        Password
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      className="stud-prefered-name all_inputs"
                      autoComplete="off"
                      type="password"
                      id="password"
                      placeholder="Enter Password..."
                      value={Password}
                      onChange={(e) => updatePassword(e.target.value)}
                      
                    />
                    <div
                      class="password"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Password
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  buttons */}

          <div className="d-flex   border_class box_padding buttons_div">                 
          
          <div
            class="ValueMsg"
            style={{ margin: "8px", width: "57%", display: "none" }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="error">
                Error! You Must Fill In All The Fields
              </Alert>
            </Stack>
          </div>

          <img  className="delete_image"
            src="dist/img/delete.png"
            alt="dropdown"
            onClick={() => resetValues()}
            
          />
          <span
            className="news_bar">
            |
          </span>
          <button className="preview_button"
           onClick={preview}
           >
            <span className="preview_font">
              Preview
            </span>
              <div className="preview_img_div">
              <img className="preview_image"
              src="dist/img/view.png"
              alt="dropdown"
              
             
            />
              </div>
          </button>

          <input
            type="button"
            className="publish_button"
            defaultValue="Sign Up"
            onClick={() => createAdmins()}
            value="Publish"
           
          />
        </div>

          {/* <div className="d-flex border_class">
            <input
              type="button"
              className="publish_button"
              defaultValue="Sign Up"
              value="Preview"
              onClick={handleShow}
            />
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Admin Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <b>Campus Name&nbsp;</b>
                {campusName}
                <br />
                <b>Campus Id&nbsp;</b>
                {campusId}
                <br />
                <b>Admin Name&nbsp;</b>
                {adminName}
                <br />
                <b>Email&nbsp;</b>
                {email}
                <br />
                <b>Contact&nbsp;</b>
                {mobile}
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <div
              class="EnterValue"
              style={{ margin: "10px auto", display: "none" }}
            >
              <h4
                class="login-text"
                style={{ color: "red", fontSize: "12PX", marginLeft: "0" }}
              >
                You Must Fill In All The Fields
              </h4>
            </div>
            <input
              type="button"
              className="save_button"
              defaultValue="Sign Up"
              value="Save"
              onClick={() => createAdmins()}
              style={{ marginLeft: "auto" }}
            />
          </div> */}


           {/* Preview */}
           <div
            className="preview_polls">
            <div className="preview_polls_inner_div1">
              <div className="preview_polls_inner_label">
                <label>
                  Preview
                </label>

                <img
                  src="dist/img/Cancel.png"
                  alt="dropdown"
                  className="cancel_img ml-auto"
                  onClick={closePreview}
                />
              </div>

              {/* {
            eventName == "" || description == "" || venue == "" || eventDate == "" || startTime == "" || endTime == "" || ticketCapacity == "" || sendNotificationTo == "" ?

              (
                <div>Please fill the form to preview it</div>
              ) : ( */}
              <div className="preview_polls_inner_div2">
                <div className="d-flex" style={{ padding: "10px 0px" }}>
                  <h4 className="preview_h4">
                    Campus 
                  </h4>
                  
                </div>

                {
                  <div>
                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                      Organization Name
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        :{" "}
                        {/* <span style={{ marginLeft: "10px" }}>{eventName}</span> */}
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                      College Name
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {/* {description}{" "} */}
                        </span>
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                         College Logo
                      </p>  
                      <p className="col-md-9 preview_row_inner">
                        :
                        <span style={{ marginLeft: "10px" }}>
                          {/* {picture == null ? ( */}
                            <div>
                              <img
                                src={require("../images/no_image.png")}
                                alt="no image"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          {/* ) : (
                            <div>
                              <img
                                // src={imgData}
                                alt="preview"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          )} */}
                        </span>
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                        Address
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        :
                        {/* : <span style={{ marginLeft: "10px" }}>{venue}</span> */}
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                        Email Id
                      </p>
                      <p className="col-md-9 preview_row_inner">:
                        {/* : <span style={{ marginLeft: "10px" }}>{venue}</span> */}
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                        Phone Number
                      </p>
                      <p className="col-md-9 preview_row_inner">:
                        {/* : <span style={{ marginLeft: "10px" }}>{venue}</span> */}
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                        Website
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        :{" "}
                        {/* <span style={{ marginLeft: "10px" }}>{eventDate} </span> */}
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                        Admin Name
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        : <span style={{ marginLeft: "10px" }}>
                          {/* {endDate}  */}
                          </span>
                      </p>
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                      Admin Email
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {/* {startTime} */}
                          </span>
                      </p>
                     
                      
                    </div>

                    <div className="row preview_row">
                      <p className="col-md-3 preview_row_inner">
                      Admin Contact
                      </p>
                      <p className="col-md-9 preview_row_inner">
                        : <span style={{ marginLeft: "10px" }}></span>{" "}
                      </p>
                    </div>

                  </div>
                }
              </div>
              {/* )
          } */}
            </div>
          </div>

        </div>
      </div>
      
      
    </div>
  );
}
