import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
// import DonutChart from "react-donut-chart";
import "@patternfly/react-core/dist/styles/base.css";
import { Header } from "../Header";
import { Menu } from "../Menu";
import { useHistory, Link } from "react-router-dom";

const reactDonutChartdata = [
  {
    label: "Present",
    value: 21,
    color: "#FF4560",
  },

  {
    label: "Absent",
    value: 4,
    color: "#FEB019",
  },
  {
    label: "Leave",
    value: 2,
    color: "#00E396",
  },
];
const reactDonutChartBackgroundColor = ["green", "red", "purple"];
const reactDonutChartInnerRadius = 0.7;
const reactDonutChartSelectedOffset = 0.04;
const reactDonutChartHandleClick = (item, toggled) => {
  if (toggled) {
    console.log("item", item);
  }
};
let reactDonutChartStrokeColor = "#FFFFFF";
const reactDonutChartOnMouseEnter = (item) => {
  let color = reactDonutChartdata.find((q) => q.label === item.label).color;
  reactDonutChartStrokeColor = color;
};

export function StudentsProfile(props) {
  const token = localStorage.getItem("Token");
  const student_id = useLocation().state.sid;
  console.log("location", useLocation().state.sid);
  const [studentProfile, updateStudentProfile] = useState([]);

  console.log("Student ID", student_id);

  async function viewStudentProfile() {
    const formData = new FormData();
    formData.append("student_id", student_id);
    const studentProfileResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_get_student_profile",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }
    );
    const profile = studentProfileResponse.data.data;
    console.log("tudent profile-------------------------", profile);
    updateStudentProfile(profile);
  }
  useEffect(() => {
    viewStudentProfile();
  }, []);

  return (
    <div>
      <div className="d-flex">
        <Menu />
        <div className="content-wrapper p-0">
          <Link to="/student">
            <div className="d-flex" style={{ padding: "0", margin: "0" }}>
              <i
                class="fas fa-arrow-alt-circle-left"
                style={{
                  color: "black",
                  margin: "0px 0px 0px 10px",
                  fontSize: "24px",
                  padding: "0",
                  fontWeight: "bold",
                }}
              />
            </div>
          </Link>

          <div className="border_class2">
            <div style={{ height: "80px", background: "#1F3977" }}>
              <div className=""></div>
            </div>

            {studentProfile.map((studentItem) => {
             
              return (
                <>
                  <div
                    style={{
                      height: "60px",
                      fontWeight: "500",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div></div>
                      <div>
                        <div>
                          <b>{studentItem.personal_info.student_name}</b>
                        </div>
                        <div>
                          <span>{studentItem.personal_info.department}</span>
                        </div>
                      </div>

                      <div>
                        <div>
                          <b>Academy of Art</b>
                        </div>
                        <div>
                          <span>NUC Mumbai</span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div
                          style={{
                            border: "1px solid #ced4da",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ alignContent: "center" }}
                          >
                            <div>
                              <img
                                src="dist/img/IdentificationImg.png"
                                style={{ height: "20px", width: "20px" }}
                              />
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                alignContent: "center",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  color: "black",
                                  marginLeft: "2px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Enrolled
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "grey",
                                  marginLeft: "2px",
                                  fontWeight: "500",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                - {studentItem.personal_info.created_at}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ marginLeft: "10px" }}>
                          <div>
                            <button
                              type="button"
                              className="d-flex  create_button"
                              defaultValue="Sign Up"
                              style={{
                                marginRight: "0px",
                                padding: "7px 10px",
                              }}
                            >
                              <div className="create_button_inner">
                                Send Message
                              </div>
                              <img
                                className="create_button_img"
                                src="dist/img/Progress.png"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {studentItem.personal_info.profile == "" ? (
                      <div>
                        <img
                          src="dist/img/boy.jpg"
                          className="text-center"
                          alt="User Profile"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "10%",
                            top: "-60px",
                            left: "55px",
                            position: "absolute",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={studentItem.personal_info.student_profile}
                          className="text-center"
                          alt="User Profile"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "10%",
                            top: "-60px",
                            left: "55px",
                            position: "absolute",
                            color:"#ffffff"
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>

          {studentProfile.map((studentItem) => {
            return (
              <div className="d-flex mt-2" style={{ padding: "5px 25px 80px 25px" }}>
                <div style={{ width: "100%" }}>
                  {/* <div>
                  student profile
                  <div
                    style={{ background: "#1F3977", width: "100%" }}
                    className="d-flex"
                  >
                    <div style={{ width: "10%" }}>
                      <img
                        src="dist/img/boy.jpg"
                        className="text-center"
                        alt="User Image"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginLeft: "20px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <div style={{ width: "65%", marginLeft: "7px" }}>
                      <div style={{ color: "white", marginTop: "20px" }}>
                        <p>
                          <b style={{ fontSize: "15px" }}>{studentItem.personal_info.student_name}</b>
                          <br />
                          <p style={{ color: "#a8afba", fontSize: "12px" }}>
                            {studentItem.personal_info.department}
                          </p>
                        </p>
                      </div>
                    </div>

                    <div style={{ width: "25%", marginLeft: "7px" }}>
                      <div style={{ color: "white", marginTop: "10px" }}>
                        <div style={{ fontWeight: "bold", fontSize: "10px", color: "white", textAlign: "right" }}>
                          <div style={{ marginRight: "10PX" }}>STATUS</div>
                          <div
                            className="d-flex"
                            style={{ background: "white", margin: "5px 7px", paddingTop: "5px", paddingRight:"10px" }}
                          >

                            <i
                              class="far fa-check-circle fa-2x"
                              style={{ color: "#46d262", padding: "0", margin: "0px 5px" }}
                            ></i>

                            <p
                              style={{ fontSize: "12px", color: "black", marginLeft: "2px" }}
                            >
                              Enrolled
                            </p>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "grey",
                                marginLeft: "2px",
                                fontWeight: "500",
                                padding: 0
                              }}
                            >
                              - {studentItem.personal_info.created_at}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div> */}

                  <div className="border_class">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        style={{
                          color: "#1F3977",
                          fontSize: "13px",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span> Personal Details</span>
                      </div>

                      <div className="ml-auto">
                        <span
                          style={{
                            background: "#4779F0",
                            color: "white",
                            padding: "5px 30px",
                            borderRadius: "5px",
                          }}
                        >
                          Actions
                        </span>
                      </div>
                      <div></div>
                    </div>

                    <hr style={{ marginTop: "9px" }} />

                    <div style={{ paddingBottom: "70px" }}>
                      <table style={{ width: "100%", fontFamily: "Poppins" }}>
                        <tr style={{ width: "100%" }}>
                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              First Name
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              {studentItem.personal_info.first_name}
                            </div>
                          </td>
                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              Gender
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              {studentItem.personal_info.gender}
                            </div>
                          </td>
                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              First Nationality
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              {studentItem.personal_info.first_nationality}
                            </div>
                          </td>
                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              Persona
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            ></div>
                          </td>
                        </tr>

                        <tr style={{ width: "100%" }}>
                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              Last Name
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              {studentItem.personal_info.last_name}
                            </div>
                          </td>

                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              Date of Birth
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              {studentItem.personal_info.dob}
                            </div>
                          </td>

                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              Student #
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                              }}
                            >
                              {studentItem.personal_info.student_id}
                            </div>
                          </td>

                          <td style={{ padding: "8px", lineHeight: "14px" }}>
                            <div
                              style={{
                                color: "grey",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              Email Address
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                fontWeight: "700",
                                fontSize: "13px",
                                color: "#4779F0",
                              }}
                            >
                              {studentItem.personal_info.email}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div
                      className="border_class"
                      style={{
                        color: "#4779F0",
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      <span>Recent Activity</span>
                    </div>

                    <div
                      className=""
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      <ul style={{ lineHeight: "30px", padding: "5px 20px" }}>
                        <li className="d-flex">
                          Created a new group Backbenchers
                          <span
                            style={{
                              background: "#4779F0",
                              color: "white",
                              padding: "0px 30px",
                              borderRadius: "5px",
                              marginLeft: "auto",
                            }}
                          >
                            Actions
                          </span>
                        </li>
                      </ul>

                      <ul
                        style={{
                          lineHeight: "30px",
                          padding: "5px 20px",
                          background: "#ffffff",
                        }}
                      >
                        <li className="d-flex">
                          Became friends with Akash Punshi
                          <span
                            style={{
                              background: "#4779F0",
                              color: "white",
                              padding: "0px 30px",
                              borderRadius: "5px",
                              marginLeft: "auto",
                            }}
                          >Actions</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
