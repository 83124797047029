import React, { useState, useEffect } from "react";
import { AiFillGift } from "react-icons/ai";
import { FaUserGraduate } from "react-icons/fa";
import "react-calendar/dist/Calendar.css";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import styled from "styled-components";
// import { EventCalendar } from "./EventCalendar";
import { EventCalendar } from "./View Homepage Calendar/EventCalendar"
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { useHistory, Link } from "react-router-dom";
import toast,{Toaster} from "react-hot-toast";

export function Dashboard() {

  const customStyles = {
    rows: {
      style: {
        background: "rgba(228, 233, 243, 0.6)",
        marginTop: "6PX",
        border: "none",
        height:"30px",
        fontSize:"10px",
        fontWeight:"500"

      },
    },
    headCells: {
      style: {
        color: "#1F3977",
      },
    },

    head: {
      style: {
        fontWeight:"400",
        fontSize:"9px",
        boxShadow: "0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)",
      },
    },
    table: {
      style: {
        marginTop: "0PX",
        height: "auto",
        display:"flex",
        height:"165px"
      },
    },
  };

  const token = localStorage.getItem("Token");
  const [data, setData] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [birthday, setBirthday] = useState([]);
  const [bdyMessage, setBdyMessage] = useState([]);
  const [allCountData,setAllCountData] = useState([]);
  const [bounceRateData, setBounceRateData] = useState([]);
  const [flaggedData, setFlaggedData] = useState([]);


  const [msg, updateMsg] = useState("");
  // get ticket
  async function fetchTicketList() {

    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_get_all_ticket",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );


      const ticketList = fetchResponse.data.data;

      setTicket(ticketList);
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function getBirthdayList() {
    try {
      const fetchBirthdayResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_get_birthday",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );


      const BirthdayList = fetchBirthdayResponse.data.data;
      const BirthdayErrorCode = fetchBirthdayResponse.data;

      setBdyMessage(BirthdayErrorCode);
      if (BirthdayErrorCode.error_code == 200) {

        $(".modaloverlay").show();
      } else {

        $(".modaloverlay").hide();
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }


  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e) => {
    setDateState(e);
  };

  const mark = ["04-11-2021", "18-11-2021", "29-11-2021"];

  function postMessage() {
    {
      if (bdyMessage.error_code === 200) {
        bdyMessage.data.map((sIdItem) => {
          //alert("inside post message" + sIdItem.id);
          const sid = sIdItem.id;
          //alert("Student ID"+ sid);
          const formData = new FormData();
          formData.append("sid", sid);
          formData.append("message", msg);
          const birthdayResponse = axios.post(
            process.env.REACT_APP_API_KEY + "super_post_birthday_wish",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                // "X-Requested-With": "XMLHttpRequest",
                Authorization: token,
              },
            }
          );

          //alert("Birthday response", birthdayResponse);
          alert("birthday message posted: "+msg);

        })
      }
      else {
        console.log("No data");
      }

    }
  }

  async function getAllCountData() {
    try {
      const fetchResponse = await axios.get(process.env.REACT_APP_API_KEY + "super_admin_get_homepage_cnts",
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',

            "Authorization": token,
          }
        }
      );
      console.log("super_admin_get_homepage_cnts-----------------",fetchResponse);
      const dd = fetchResponse.data.data;
      if(fetchResponse.data.error_code==200){
        setAllCountData(dd);
      }
    }
    catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function getBounceRate() {
    try {
      const fetchResponse = await axios.get(process.env.REACT_APP_API_KEY + "calculate_student_metrics",
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',

            "Authorization": token,
          }
        }
      );
      const dd = fetchResponse.data.data;
      // console.log("dd---------------------",dd);
      if(fetchResponse.data.error_code==200){
        setBounceRateData(dd);
      }
    }
    catch (err) {
      console.log("bounce rate api error-------", err);
    }
  }

  async function getFlaggedContentData() {
    try {
      const flaggedResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_reported_post_details_2_limit",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      // console.log("flaggedResponse...............", flaggedResponse);

      const ErrorCode = flaggedResponse.data.error_code;
      if (ErrorCode == 200) {
        const _data = flaggedResponse.data.data;
        setFlaggedData(_data);
        console.log("flaggedResponse...............", _data);

      } else {

      }
    } catch (err) {
      console.log("get flagged data error-----------", err);
    }
  }

  useEffect(() => {
    fetchTicketList();
    getAllCountData();
    getBounceRate();
    getFlaggedContentData();
  }, []);


  const columns = [
    {
      name: "Reported by ",
      selector: (row) => {
                 return(<>
                  <div className="">{row.reported_by_full_name}</div>
                  <div className="nine_font_class">{row.reported_at}</div>
                  </>)
      },
      sortable: true,
      with: "25%",
    },
    {
      name: "Category",
      selector: (row) =>{
        return(
                <div className=""> {row.category}</div>
     ) },
        width:"10%",
      sortable: true,
    },

    {
      name: "Post",
      width:"35%",
      selector: (row) => {
             return(
              <div className="" style={{width:"315px"}}>{row.f_description}</div>
             )
      },
      sortable: true,
    },
    {
      width:"10%",
      selector: (row) =>{
        const array = [];
        row.feed_images.map((itemimage) => {
        array.push(itemimage.feed_img);

        });
            return(<>
              {array.length  == 0 ?(
                  <div>
                  <img style={{height:"30px",width:"60px",margin:"2px",objectFit:"contain"}}
                   src="dist/img/event_photo.png"
                   />
                </div>
              ):(
             <div>
               <img style={{height:"30px",width:"60px",margin:"2px",objectFit:"contain"}}
                src={array[0]}
                />
             </div>
             )}
             </> )
      },
      sortable: true,
    },
    {
      name: "Posted by",
      selector: (row) =>{return(
        <div className=""> { row.posted_by_full_name}</div>
      )},
      sortable: true,
      width:"10%",
    },
    {
      name: "Status",
      // selector: (row) => row.Status,
      width:"10%",
      selector: (row) => {
        return(<>
        {row.feed_status == 0 ? (
            <div className="">Hidden</div>
        ):(
          <div className="">Keep Post</div>
        )}

         </>)
},
      sortable: true,
    },

    {
      name: "",
      width: "auto",
      cell: (row) => {
        return (
          <div className="d-flex" style={{width:"100%"}}>
            <div className="action_buttons_end_css"
              onClick={() => openActionsModal(row.report_id)}
            >
              <button className="all_action_buttons">
                Actions
              </button>
            </div>
            <div
              className={`edit_campus_modal actions_modal${row.report_id}`}
              style={{
                display: "none",
                position: "absolute",
                top: "30px",
                right: "15px",
                width:"335px"
              }}
            >
              <div>
                <div className="  ">
                  <div className=" d-flex ml-auto">
                    <img
                      className="campus_img ml-auto"
                      src="dist/img/Cancel.png"
                      onClick={() => closeActionsModal(row.report_id)}
                    />
                  </div>
                </div>
                {row.feed_status == 0 ? (
                <div className=" hover_class p-2"
                        onClick={()=> hidePostFunc(row.f_id)}>
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/ThumbsUp.png"
                      />
                    </div>

                      <div className="flag_inner_div">
                      <div style={{fontSize:"10px",fontWeight:"600"}}>Keep Post</div>
                      <div style={{fontSize:"9px",fontWeight:"500"}}>Disagree with flag and keep the post unchanged</div>

                    </div>


                  </div>
                </div>
                ):(
                <div className=" d-flex flex-row hover_class p-2"
                      onClick={()=> hidePostFunc(row.f_id)}>
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/PostHide.png"
                      />
                    </div>
                    <div className="flag_inner_div">
                      <div style={{fontSize:"10px",fontWeight:"600"}}>Hide Post</div>
                      <div style={{fontSize:"9px",fontWeight:"500"}}>Hide this post and send a warning message</div>
                    </div>
                  </div>
                </div>
                 )}

                   {row.acc_freeze == 0 ?(
                <div className=" d-flex flex-row hover_class p-2"
                     onClick={()=> silentStudentFunc(row.posted_by_id)}
                >
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/PostSilence.png"
                      />
                    </div>
                      <div className="flag_inner_div">
                      <div style={{fontSize:"10px",fontWeight:"600"}}>Silence User</div>
                      <div style={{fontSize:"9px",fontWeight:"500"}}>A silenced user has all posting disabled</div>
                    </div>

                  </div>
                </div>
                 ):(
                  <div className=" d-flex flex-row hover_class p-2"
                    onClick={()=> silentStudentFunc(row.posted_by_id)}
                  >
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/PostSilence.png"
                      />
                    </div>
                      <div className="flag_inner_div">
                      <div style={{fontSize:"10px",fontWeight:"600"}}>Unfreeze User</div>
                      <div style={{fontSize:"9px",fontWeight:"500"}}>Unfreezed user has all posting enables</div>
                    </div>

                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

//   const columns = [
//     {
//       name: "Reported by ",
//       selector: (row) => {
//                  return(<>
//                   <div>{row.reported_by_full_name}</div>
//                   <div style={{fontSize:"9px"}}>{row.reported_at}</div>
//                   </>)
//       },
//       sortable: true,
//       with: "25%",
//     },
//     {
//       name: "Category",
//       selector: (row) => row.category,

//       sortable: true,
//     },

//     {
//       name: "Post",
//       selector: (row) => {
//              return(
//               <div style={{width:"130px"}}>{row.f_description}</div>
//              )
//       },
//       sortable: true,
//     },
//     {

//       selector: (row) =>{
//         const array = [];
//         row.feed_images.map((itemimage) => {
//         array.push(itemimage.feed_img);

//         });
//             return(<>
//               {array.length  == 0 ?(
//                   <div>
//                   <img style={{height:"70px",width:"100px",margin:"2px",objectFit:"contain"}}
//                    src="dist/img/event_photo.png"
//                    />
//                 </div>
//               ):(
//              <div>
//                <img style={{height:"70px",width:"100px",margin:"2px",objectFit:"contain"}}
//                 src={array[0]}
//                 />
//              </div>
//              )}
//              </> )
//       },
//       sortable: true,
//     },
//     {
//       name: "Posted by",
//       selector: (row) => row.posted_by_full_name,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       // selector: (row) => row.Status,
//       selector: (row) => {
//         return(<>
//         {row.feed_status == 0 ? (
//             <div>Hidden</div>
//         ):(
//           <div>Keep Post</div>
//         )}

//          </>)
// },
//       sortable: true,
//     },

//     {
//       name: "Action",
//       width: "auto",
//       cell: (row) => {
//         return (
//           <div className="d-flex">
//             <div
//               style={{ position: "relative", cursor: "pointer" }}
//               onClick={() => openActionsModal(row.report_id)}
//             >
//               <span
//                 style={{
//                   background: "#4779F0",
//                   color: "white",
//                   padding: "5px 13px",
//                   borderRadius: "5px",
//                 }}
//               >
//                 Actions
//               </span>
//             </div>
//             <div
//               className={`edit_campus_modal actions_modal${row.report_id}`}
//               style={{
//                 display: "none",
//                 position: "absolute",
//                 top: "30px",
//                 right: "73px",
//                 width:"335px"
//               }}
//             >
//               <div>
//                 <div className="  ">
//                   <div className=" d-flex ml-auto">
//                     <img
//                       className="campus_img ml-auto"
//                       src="dist/img/Cancel.png"
//                       onClick={() => closeActionsModal(row.report_id)}
//                     />
//                   </div>
//                 </div>
//                 {row.feed_status == 0 ? (
//                 <div className=" hover_class p-2"
//                         onClick={()=> hidePostFunc(row.f_id)}>
//                   <div className=" d-flex flex-row">
//                     <div style={{display:"flex",alignItems:"center"}}>
//                       <img
//                         className="campus_img"
//                         src="dist/img/ThumbsUp.png"
//                       />
//                     </div>

//                       <div className="campus_inner_div">
//                       <div style={{fontSize:"14px",fontWeight:"600"}}>Keep Post</div>
//                       <div style={{fontSize:"10px",fontWeight:"500"}}>Disagree with flag and keep the post unchanged</div>

//                     </div>


//                   </div>
//                 </div>
//                 ):(
//                 <div className=" d-flex flex-row hover_class p-2"
//                       onClick={()=> hidePostFunc(row.f_id)}>
//                   <div className=" d-flex flex-row">
//                     <div style={{display:"flex",alignItems:"center"}}>
//                       <img
//                         className="campus_img"
//                         src="dist/img/PostHide.png"
//                       />
//                     </div>
//                     <div className="campus_inner_div">
//                       <div style={{fontSize:"14px",fontWeight:"600"}}>Hide Post</div>
//                       <div style={{fontSize:"10px",fontWeight:"500"}}>Hide this post and send a warning message</div>
//                     </div>
//                   </div>
//                 </div>
//                  )}

//                    {row.acc_freeze == 0 ?(
//                 <div className=" d-flex flex-row hover_class p-2"
//                      onClick={()=> silentStudentFunc(row.posted_by_id)}
//                 >
//                   <div className=" d-flex flex-row">
//                     <div style={{display:"flex",alignItems:"center"}}>
//                       <img
//                         className="campus_img"
//                         src="dist/img/PostSilence.png"
//                       />
//                     </div>
//                       <div className="campus_inner_div">
//                       <div style={{fontSize:"14px",fontWeight:"600"}}>Silence User</div>
//                       <div style={{fontSize:"10px",fontWeight:"500"}}>A silenced user has all posting disabled</div>
//                     </div>

//                   </div>
//                 </div>
//                  ):(
//                   <div className=" d-flex flex-row hover_class p-2"
//                     onClick={()=> silentStudentFunc(row.posted_by_id)}
//                   >
//                   <div className=" d-flex flex-row">
//                     <div style={{display:"flex",alignItems:"center"}}>
//                       <img
//                         className="campus_img"
//                         src="dist/img/PostSilence.png"
//                       />
//                     </div>
//                       <div className="campus_inner_div">
//                       <div style={{fontSize:"14px",fontWeight:"600"}}>Unfreeze User</div>
//                       <div style={{fontSize:"10px",fontWeight:"500"}}>Unfreezed user has all posting enables</div>
//                     </div>

//                   </div>
//                 </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         );
//       },
//     },
//   ];

  const silentStudentFunc = async (s_id) => {
    try {
        const formData = new FormData();
        formData.append("stud_id",s_id);
      const silentStudentResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_change_student_freez_acc_status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      // console.log("silentStudentResponse...............", silentStudentResponse);

      const ErrorCode = silentStudentResponse.data.error_code;
      if (ErrorCode == 200) {
        getFlaggedContentData();
        $(".edit_campus_modal").hide();
        toast.success(silentStudentResponse.data.message);
      } else {

      }
    } catch (err) {
      console.log("silence user error-----------", err);
    }
  }

  const hidePostFunc = async (f_id) => {
    try {
        const formData = new FormData();
        formData.append("f_id",f_id);
      const hidePostResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_flagged_content_hide_post",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      console.log("hidePostResponse...............", hidePostResponse);

      const ErrorCode = hidePostResponse.data.error_code;
      if (ErrorCode == 200) {
        getFlaggedContentData();
        $(".edit_campus_modal").hide();
        toast.success(hidePostResponse.data.message);
      } else {

      }
    } catch (err) {
      console.log("hide post error-----------", err);
    }
  }

  const [tId, setTId] = useState("");
  const openActionsModal = (e) => {
    setTId(e);
    $('.edit_campus_modal').hide();
    $('.actions_modal'+e).toggle();
  }
  const closeActionsModal = (e) => {
    $('.edit_campus_modal').hide();
  }


  return (
    <div className="content-wrapper">
       <Toaster
           position="top-right"
          reverseOrder={false}
     />

      <section className="">
        <div className=" table-cards">

          <div className="table-cards">

            <div className="row">

              <div style={{display:"flex",gap:"4px"}}>
             <div className="" >
              <section>
                <div className="container-fluid table-cards">
                  <div className="table-cards">
                    <div className="row">
                      <div
                        className=""
                        style={{
                          width:"250px",
                          padding:"0px"
                        }}
                      >
                        <div
                          className="small-box"
                          style={{
                            height: "80px",
                            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                            borderRadius: "3PX",
                          }}
                        >
                          <div
                            className="inner flagged_inner d-flex" style={{justifyContent:"space-between"}}
                            >
                            <div>
                              <div>
                                <h5
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "16px",
                                    color: "#1F3977",

                                    fontFamily: "Poppins",
                                  }}
                                >
                                {allCountData.total_university_cnt}
                                </h5>
                              </div>

                              <div
                                className="d-flex"
                                style={{
                                  flexWrap: "wrap",
                                  marginTop: "5px",
                                  fontFamily: "Poppins",
                                  color:"#848484",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                }}
                              >
                                <div> Total Universities</div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Link to="/universities">
                                <button
                                  type="button"
                                  style={{
                                    background: "#1F3977",
                                    color: "#ffffff",
                                    height: "30px",
                                    width: "70px",
                                    fontSize: "9px",
                                    fontWeight: "500",
                                    border: "none",
                                    borderRadius: "3px",
                                  }}
                                >
                                  View All
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div style={{width:"175px"}}>
                          <div
                            className="small-box"
                            style={{
                              height: "80px",
                              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                              borderRadius: "3PX",
                            }}
                          >
                            <div className="inner d-flex flagged_inner" style={{justifyContent:"space-between"}}>

                              <div className="">
                              <div
                                style={{
                                  fontWeight: "500",
                                  color:"#848484",
                                  fontSize: "11px",
                                  fontFamily: "Poppins",
                                }}
                              >

                                Total Users
                              </div>

                              <div>
                              <h5
                                style={{
                                  fontWeight: "600",

                                  fontSize: "16px",
                                  color: "black",

                                  fontFamily: "Poppins",
                                }}
                              >
                                {allCountData.total_users_cnt}
                              </h5>
                            </div>
                            </div>

                            <div className=" d-flex">
                                <img
                                  src="dist/img/Teacher.png"
                                  className="ml-auto all_icon_imgs"
                                />
                              </div>
                            </div>
                          </div>
               </div>

               <div style={{width:"100px"}}>
                          <div
                            className="small-box"
                            style={{
                              height: "80px",
                              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                              borderRadius: "3PX",
                            }}
                          >
                            <div className="inner flagged_inner">

                              <div
                                style={{
                                  fontWeight: "500",
                                  display:"flex",
                                  justifyContent:"center",
                                  fontSize: "11px",
                                  fontFamily: "Poppins",
                                  color:"#4AA081"
                                }}
                              >

                                Paid Users
                              </div>

                              <h5
                                style={{
                                  fontWeight: "600",
                                  display:"flex",
                                  justifyContent:"center",
                                  fontSize: "16px",
                                  color: "black",

                                  fontFamily: "Poppins",
                                }}
                              >
                                {allCountData.paid_users}
                              </h5>
                            </div>
                          </div>
               </div>

               <div style={{width:"100px"}}>
                          <div
                            className="small-box"
                            style={{
                              height: "80px",
                              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                              borderRadius: "3PX",
                            }}
                          >
                            <div className="inner flagged_inner">

                              <div
                                style={{
                                  fontWeight: "500",
                                  display:"flex",
                                  justifyContent:"center",
                                  fontSize: "11px",
                                  fontFamily: "Poppins",
                                  color:"#EB3F3F"
                                }}
                              >

                                Free Users
                              </div>

                              <h5
                                style={{
                                  fontWeight: "600",
                                  display:"flex",
                                  justifyContent:"center",
                                  fontSize: "16px",
                                  color: "black",

                                  fontFamily: "Poppins",
                                }}
                              >
                                {allCountData.unpaid_users}
                              </h5>
                            </div>
                          </div>
               </div>
               </div>

            </div>

            <div className="mt-1">
            <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    height: "100%",
                    marginTop: "10PX",
                  }}
                >
                  <div
                    className="small-box "
                    style={{
                      padding: "0px",

                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0px 0px 5px 5px",
                    }}
                  >
                    <div className="">
                      <div>
                        <div
                          style={{
                            background: "rgb(108, 122, 153)",
                            color: "#fff",
                            padding: "3px 7px 5px 25px"
                          }}
                        >

                            <span  className="ten_font_class">Analytics Dashboard</span>

                        </div>
                      </div>

                      <div className="event_inner_div " style={{minHeight:"120px"}}>
                        <div className="row m-0">


                        <div className="col-md-4"
                          style={{
                            height: "100%",

                            margin: "0",
                          }}
                        >
                          <div
                            className="small-box box_border_class"
                            style={{
                              height: "85px",
                              marginTop:"10px"
                            }}
                          >
                            <div className="inner ">

                              <div className="d-flex" style={{justifyContent:"space-between"}}>
                              <div className="">
                              <div className="eleven_font_class"
                                style={{color:"#1F3977"}}
                              >

                                Users
                              </div>

                              <div></div>
                              <div style={{display:"flex",gap:"5px"}}>
                              <span className="sixteen_font_class"
                                style={{color: "black"}}
                              >
                                {bounceRateData.total_students}
                              </span>
                              <span className="ten_font_class" style={{color:"#4AA081",display:"flex",alignItems:"center"}}>
                                  (+40%)
                                  </span>
                            </div>


                            </div>

                            <div className=" d-flex p-0"
                             style={{justifyContent:"center",alignItems:"center",
                              background: "#E1EBFF",width:"40px",borderRadius:"3px"}}>
                            <img
                                        src="dist/img/UsersIcon.png"
                                        className="all_icon_imgs"
                                      />

                              </div>
                              </div>

                              <div className="">
                                <div className="">
                                  <div>
                                    <span className="nine_font_class"style={{color:"#293043"}}>
                                      Analytics for last week
                                      </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                           </div>

                           <div className="col-md-4"
                          style={{
                            height: "100%",

                            margin: "0",
                          }}
                        >
                          <div
                            className="small-box box_border_class"
                            style={{
                              height: "85px",
                              marginTop:"10px"

                            }}
                          >
                            <div className="inner ">

                              <div  className="d-flex" style={{justifyContent:"space-between"}}>
                              <div className="">
                              <div className="eleven_font_class"
                                style={{color:"#1F3977"}}
                              >

                               Bounce Rate
                              </div>

                              <div></div>
                              <div style={{display:"flex",gap:"5px"}}>
                              <span className="sixteen_font_class"
                                style={{color: "black"}}
                              >
                                {bounceRateData.bounce_rate}
                              </span>
                              <span className="ten_font_class" style={{color:"#4AA081",display:"flex",alignItems:"center",
                                 }}>
                                  (+40%)
                                  </span>
                            </div>


                            </div>

                            <div className=" d-flex p-0" style={{justifyContent:"center",alignItems:"center",
                              background: "#FBE1FF",width:"40px",borderRadius:"3px"}}>

                                      <img
                                        src="dist/img/ComboChart.png"
                                        className="all_icon_imgs"
                                      />
                                    </div>

                              </div>

                              <div className="">
                                <div className="col-md-12 p-0">
                                  <div>
                                    <span  className="nine_font_class" style={{color:"#293043"}}>
                                      Analytics for last week
                                      </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                           </div>




                           <div className="col-md-4"
                          style={{
                            height: "100%",

                            margin: "0",
                          }}
                        >
                          <div
                            className="small-box box_border_class"
                            style={{
                              height: "85px",
                              marginTop:"10px"
                            }}
                          >
                            <div className="inner flagged_inner">

                              <div  className="d-flex" style={{justifyContent:"space-between"}}>
                              <div className="">
                              <div className="eleven_font_class"
                                style={{color:"#1F3977"}}
                              >

                               Time on App
                              </div>

                              <div></div>
                              <div style={{display:"flex",gap:"5px"}}>
                              <span className="sixteen_font_class"
                                style={{color: "black"}}
                              >
                                {bounceRateData.average_time}m
                              </span>
                              <span className="ten_font_class" style={{color:"#EB3F3F",display:"flex",alignItems:"center",
                                 }}>
                                  (-16%)
                                  </span>
                            </div>


                            </div>

                            <div className=" d-flex p-0" style={{justifyContent:"center",alignItems:"center",
                              background: "#BEF5C3",width:"40px",borderRadius:"3px"}}>

                                      <img
                                        src="dist/img/Timer.png"
                                        className="all_icon_imgs"
                                      />
                                    </div>

                              </div>

                              <div className="">
                                <div className="col-md-12 p-0">
                                  <div>
                                    <span className="nine_font_class" style={{color:"#293043"}}>
                                      Analytics for last week
                                      </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                           </div>




                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <div className="mt-2">
            <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    height: "100%",
                    marginTop: "10PX",
                  }}
                >
                  <div
                    className="small-box "
                    style={{
                      padding: "0px",

                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                      borderRadius: "0px 0px 5px 5px",
                    }}
                  >
                    <div className="">
                      <div>
                        <div
                          style={{
                            background: "rgb(108, 122, 153)",
                            color: "#fff",
                            padding: "3px 7px 5px 25px",
                          }}
                        >

                            <span className="ten_font_class">Flagged Content</span>

                        </div>
                      </div>

                      <div className="event_inner_div " style={{minHeight:"140px"}}>
                        <div className="row m-0">
                        <div className="d-flex">
                        <DataTable
                        columns={columns}
                        data={flaggedData}
                        striped

                        defaultSortFieldId={1}
                        customStyles={customStyles}

                      />

                    </div>

                        </div>
                        <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
                          <Link to="/flaggedcontents"
                             style={{border:"none",color:"rgb(108, 122, 153)",padding:"6px 40px",
                             borderRadius:"5px",fontSize:"9px",fontWeight:"600"}}
                          >View All</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>









            {/* <div className="row mt-4">
                           {data.map((item, index) => {
                return (
                  <div
                    className="col-md-3"
                    style={{ height: "100%", padding: "0px 5px" }}
                  >

                    <a href="/student">
                      <div
                        className="small-box"
                        style={{
                          height: "120px",
                          padding: "5px",
                          borderRadius: "2.5PX",
                        }}
                      >
                        <div className="inner">
                          <div
                            style={{
                              fontWeight: "bold",
                              marginTop: "0px",
                              fontSize: "22px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {item.std_count}
                          </div>

                          <h5
                            style={{
                              fontWeight: "500",
                              fontSize: "10px",
                              color: "black",
                              marginTop: "5px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Total Students
                          </h5>

                          <div
                            className="d-flex"
                            style={{
                              flexWrap: "wrap",
                              marginTop: "5px",
                              fontFamily: "Poppins",
                            }}
                          >
                            <div
                              style={{
                                color: "rgb(21, 163, 18)",
                                fontWeight: "bold",
                                marginRight: "4px",
                                fontSize: "11px",
                              }}
                            >

                              {item.percentage_student >= 1 ?
                                <div style={{ color: "green", fontWeight: "bold", marginRight: "4px", fontSize: "11px" }}>
                                  +{item.percentage_student.toFixed(2)}%
                                </div> :
                                <div style={{ color: "red", fontWeight: "bold", marginRight: "4px", fontSize: "11px" }}>
                                  -{item.percentage_student.toFixed(2)}%
                                </div>
                              }
                            </div>
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "500",
                                color: "grey",
                              }}
                            >
                              {" "}
                              than last month
                            </div>
                          </div>
                        </div>
                        <div className="icon mt-0">
                          <FaUserGraduate
                            style={{ color: "#1F3977", fontSize: "30px" }}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}

              {data.map((item, index) => {
                return (
                  <div
                    className="col-md-3"
                    style={{ height: "100%", padding: "0px 5px" }}
                  >
                    <a href="/teacher">
                      <div
                        className="small-box"
                        style={{
                          height: "120px",
                          padding: "5px",
                          borderRadius: "2.5PX",
                        }}
                      >
                        <div className="inner">
                          <div
                            style={{
                              fontWeight: "bold",
                              marginTop: "0px",
                              fontSize: "22px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {item.teacher_count.toFixed(2)}
                          </div>

                          <h5
                            style={{
                              fontWeight: "500",
                              fontSize: "10px",
                              color: "black",
                              marginTop: "5px",
                              fontFamily: "Poppins",
                            }}
                          >
                            Total Teachers
                          </h5>

                          <div
                            className="d-flex"
                            style={{
                              flexWrap: "wrap",
                              marginTop: "5px",
                              fontFamily: "Poppins",
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                marginRight: "4px",
                                fontSize: "11px",
                              }}
                            >

                              {item.percentage_student >= 1 ?
                                <div style={{ color: "green", fontWeight: "bold", marginRight: "4px", fontSize: "11px" }}>
                                  +{item.percentage_student.toFixed(2)}%
                                </div> :
                                <div style={{ color: "red", fontWeight: "bold", marginRight: "4px", fontSize: "11px" }}>
                                  -{item.percentage_student.toFixed(2)}%
                                </div>
                              }
                            </div>
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "500",
                                color: "grey",
                              }}
                            >
                              {" "}
                              than last month
                            </div>
                          </div>
                        </div>
                        <div className="icon mt-0">
                          <i
                            class="fas fa-network-wired"
                            style={{ color: "#1F3977", fontSize: "30px" }}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}

              {data.map((item, index) => {
                return (
                  <div
                    className="col-md-3"
                    style={{ height: "100%", padding: "0px 5px" }}
                  >
                    <div
                      className="small-box"
                      style={{
                        height: "120px",
                        padding: "5px",
                        borderRadius: "2.5PX",
                      }}
                    >
                      <a href="/viewCampusListDetails">

                      <div className="inner">
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            fontSize: "22px",
                            fontFamily: "Poppins",
                          }}
                        >
                          {item.college_count}
                        </div>

                        <h5
                          style={{
                            fontWeight: "500",
                            fontSize: "10px",
                            color: "black",
                            marginTop: "5px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Total Colleges
                        </h5>

                        <div
                          className="d-flex"
                          style={{
                            flexWrap: "wrap",
                            marginTop: "5px",
                            fontFamily: "Poppins",
                          }}
                        >
                          <div
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              marginRight: "4px",
                              fontSize: "11px",
                            }}
                          >

                            {item.percentage_student >= 1 ?
                              <div style={{ color: "green", fontWeight: "bold", marginRight: "4px", fontSize: "11px" }}>
                                +{item.percentage_student.toFixed(2)}%
                              </div> :
                              <div style={{ color: "red", fontWeight: "bold", marginRight: "4px", fontSize: "11px" }}>
                                -{item.percentage_student.toFixed(2)}%
                              </div>
                            }
                          </div>
                          <div
                            style={{
                              fontSize: "11px",
                              fontWeight: "500",
                              color: "grey",
                            }}
                          >
                            {" "}
                            than last month
                          </div>
                        </div>
                      </div>
                      </a>
                      <div className="icon mt-0">
                        <i
                          class="fas fa-network-wired"
                          style={{ color: "#1F3977", fontSize: "30px" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div
                className="col-md-3"
                style={{ height: "100%", padding: "0px 5px" }}
              >
                <div
                  className="small-box"
                  style={{
                    height: "120px",
                    padding: "3px",
                    borderRadius: "2.5PX",
                    overflowY: "auto",
                  }}
                >
                  <div className="inner" style={{ paddingBottom: "0" }}>
                    <div
                      style={{
                        fontWeight: "600",
                        marginTop: "0px",
                        fontSize: "16px",
                        padding: "0px",
                      }}
                    >
                      Birthdays
                    </div>
                    <div style={{ marginTop: "0px" }}>
                      <p>
                        {" "}
                        <a className="cta" href="#google">
                          <div
                            className="inner d-flex"
                            style={{ marginTop: "0px" }}
                          >
                            <div>
                              <AiFillGift style={{ fontSize: "40px" }} />
                            </div>
                            <div
                              style={{
                                marginLeft: "2px",
                                fontWeight: "400",
                                fontSize: "12px",
                                color: "black",
                                marginTop: "5px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "12px",
                                  marginLeft: "5px",
                                  overflowY: "auto",

                                }}
                              >
                                {bdyMessage.error_code == 200
                                  ? bdyMessage.data.map((item) => {
                                    console.log("a sn bjkab", item.id);

                                    return (
                                      <div>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            marginTop: "3PX",
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                        <div style={{ fontSize: "12PX" }}>
                                          {" "}
                                          have birthday today.{" "}
                                        </div>
                                      </div>
                                    );
                                  })
                                  : bdyMessage.error_code == 404
                                    ? "No Birthday Today"
                                    : null}
                              </p>
                            </div>
                          </div>
                        </a>
                      </p>

                      <div id="google" className="modaloverlay">
                        <div className="modalContainer">
                          <div className="birthday">Birthdays</div>
                          <a href="#close" className="closeContainer">
                            ×
                          </a>


                          <div
                            style={{
                              background: "white",
                              borderRadius: "0px 0px 10px 10px",
                              marginBottom: "50px",
                              borderTop: "1px solid #E4E6EB",
                              borderBottom: "1px solid #E4E6EB",
                            }}
                          >
                            {bdyMessage.error_code == 200
                              ? bdyMessage.data.map((item) => {


                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      borderBottom: "1px solid #e6e7e9",
                                      marginTop: "10px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <div style={{ width: "10%" }}>
                                      <img
                                        src={item.profile}
                                        alt="User Image"
                                        style={{
                                          padding: "5px",
                                          height: "50px",
                                          width: "50px",
                                        }}
                                      />
                                    </div>
                                    <div>{item.name}</div>
                                    <div
                                      style={{
                                        fontSize: "10PX",
                                        color: "darkgrey",
                                        marginLeft: "5PX",
                                        marginTop: "2PX",
                                      }}
                                    >
                                      ({item.flag})
                                    </div>
                                  </div>
                                );
                              })
                              : bdyMessage.error_code == 404
                                ? "No Birthday Today"
                                : null}
                            <div style={{ width: "90%", padding: "5px" }}>
                              <div style={{ marginTop: "0" }}>
                                <p class="lead emoji-picker-container">
                                  <input
                                    type="text"
                                    className="birthday_popUp"
                                    data-emoji-input="unicode"
                                    placeholder="Write on their timeline"

                                    id="exampleInputEmail1"
                                    value={msg}
                                    onChange={(e) =>
                                      updateMsg(e.target.value)
                                    }

                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      paddingTop: "8px",
                                      paddingLeft: "10px",
                                      border: "none",
                                      borderRadius: "30px",
                                      background: "#f0f2f5",
                                    }}
                                  />
                                </p>
                                <div
                                  className=" d-flex"
                                  STYLE={{
                                    marginLeft: "10PX",
                                    marginTop: "0PX",
                                  }}
                                >
                                  <div
                                    className="birthday_msg"
                                    style={{
                                      fontSize: "15PX",
                                      marginLeft: "5PX",
                                      display: "none",
                                      color: "#339dd8",
                                    }}
                                  >
                                    Message sent sucessfully !!
                                  </div>
                                  <button
                                    type="button"
                                    className="d-flex buttonContainer"
                                    defaultValue="Sign Up"
                                    onClick={() => postMessage()}
                                    style={{
                                      padding: "8px",
                                      marginTop: "0PX",
                                      background: "#1F3977",
                                      flexWrap: "wrap",
                                      borderRadius: "5px",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "12PX",
                                      }}
                                    >
                                      POST
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div id="emoji-picker">
                              <input type="search" placeholder="Search" />
                              <div id="container" />
                              <ol id="categories" />
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}



            {/* <div className="row" style={{ marginTop: "10px" }}>
              <div
                className="col-md-6 "
                style={{ height: "100%", padding: "0px 5px" }}
              >
                <div
                  className="small-box displayTicket"
                  style={{
                    height: "370px",
                    margin: "0",
                    paddingBottom: "30px",
                    borderRadius: "5.3PX",
                    fontWeight: "900",
                  }}
                >
                  <div className="inner" style={{ lineHeight: "18px" }}>
                    <div
                      style={{
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        marginTop: "0px",
                        fontSize: "15px",
                        lineHeight: "25px",
                        padding: "0px",
                      }}
                    >
                      Incoming Tickets
                    </div>
                    <div
                      style={{
                        padding: "0px",
                        fontWeight: "600",
                        marginLeft: "0px",
                        fontSize: "11px",
                        marginBottom: "0",
                        color: "rgba(0, 0, 0, 0.5)",
                        marginTop: "0",
                      }}
                    >
                      Support: 73{" "}
                    </div>
                  </div>


                  {ticket.slice(0,3).map((ticketitem, index) => {
                    console.log("status", ticketitem.status);

                    return (
                      <div>
                        <div
                          className=" d-flex"
                          style={{
                            height: "auto",
                            fontWeight: "bold",
                            padding: "10px",
                            background: "#eff6ff",
                            fontSize: "12px",
                            flexWrap: "wrap",
                            marginBottom: "3px",
                          }}
                        >
                          <div style={{ borderRadius: "50%" }}>
                            <img
                              alt="Anne Hathaway picture"
                              src="dist/img/boy.jpg"
                              style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginLeft: "10px",
                              fontWeight: "bold",
                              fontFamily: "Poppins",
                            }}
                          >
                            <div
                              style={{
                                color: "#197df3",
                                fontWeight: "600",
                                fontSize: "12PX",
                                fontFamily: "Poppins",
                              }}
                            >
                              {ticketitem.subject}{" "}
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "12PX",
                                  fontWeight: "600",
                                }}
                              >
                                #{ticketitem.ticket_id}
                              </span>
                            </div>
                            <p
                              style={{ fontWeight: "600", fontSize: "11PX" }}
                            >
                              <span
                                style={{
                                  color: "grey",
                                  fontSize: "11PX",
                                  marginRight: "3px",
                                }}
                              >
                                Status:
                              </span>
                              {ticketitem.status == 1 ? "Open" : "Closed"},
                              &nbsp;
                              <span
                                style={{
                                  color: "grey",
                                  fontSize: "11PX",
                                  marginRight: "3px",
                                }}
                              >
                                Priority:
                              </span>
                              {ticketitem.priority == "Unread"
                                ? "Low"
                                : ticketitem.priority == "open"
                                  ? "Closed"
                                  : ticketitem.priority == "close"
                                    ? "high"
                                    : null}
                              , &nbsp;
                              <span
                                style={{
                                  color: "grey",
                                  fontSize: "11PX",
                                  marginRight: "3px",
                                }}
                              >
                                From:
                              </span>
                              {ticketitem.uid}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <a href="/ticketsDashboard" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <input
                        type="button"
                        className="create_btn form-buttons1"
                        defaultValue="Sign Up"
                        value="View All"
                        style={{
                          borderRadius: "6px",

                          marginTop: "12px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          backgroundColor: "#1F3977",
                         padding: "7px 15px",
                          fontSize: "12PX",

                        }}
                      />
                    </a>
                </div>
              </div>

              <div
                className="col-md-6"
                style={{ height: "100%", padding: "0px 5px" }}
              >
                <div
                  className="small-box"
                  style={{ height: "370px", borderRadius: "5.3PX" }}
                >
                  <div
                    className="inner calendar_inner"
                    style={{ lineHeight: "18px" }}
                  >
                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                      <div>
                        <div
                          style={{
                            fontWeight: "600",
                            marginLeft: "5px",
                            marginTop: "0px",
                            fontSize: "13px",
                            padding: "0px",
                          }}
                        >
                          Event Calendar
                        </div>
                        <div
                          style={{
                            padding: "0px",
                            fontWeight: "400",
                            marginLeft: "0px",
                            fontSize: "12px",
                            marginBottom: "0",
                            color: "grey",
                            marginTop: "0",
                          }}
                        >
                          You have 3 Events in {moment(dateState).format("MMMM")}
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginLeft: "auto",
                          fontWeight: "500",
                          fontSize: "9PX",
                        }}
                      >
                        <p>{moment(dateState).format("DD MMMM YYYY")}</p>
                      </div>
                    </div>
                  </div>

                  <EventCalendar style={{ width: "auto", height: "100%" } } />



                </div>
              </div>
            </div> */}


          </div>
        </div>
      </section>

      {/* end teacher info */}
    </div>
  );
}
