import React, { useState, useEffect } from "react";
import axios from "axios";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import $ from "jquery";
import { useHistory } from "react-router-dom";
import { Button } from "bootstrap";
import { left } from "@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core";
import SummerNote from "../Summer Note/SummerNote";


export function JobForm() {
  var todayy = "";
  const [data, setData] = useState([]);
  const token = localStorage.getItem("Token");
  const [jobCategory, updateJobCategory] = useState("");
  const [jobTitle, updateJobTitle] = useState("");
  const [companyName, updateCompanyName] = useState("");
  const [jobDiscription, updateJobDiscription] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobBudget, updateJobBudget] = useState("");
  const [picture, setPicture] = useState(null);

  const [error_message, seterror_message] = useState();

  const [stateData, setStateData] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [city, updateCity] = useState("");
  const [state, updateState] = useState("");
  const [imgData, setImgData] = useState([]);

  const history = useHistory();

  async function addJob(emailField) {
    try {
      const formData = new FormData();

      // formData.append("job_title", jobTitle);
      // formData.append("company_name", companyName);
      // formData.append("validity", jobDeadlineDate);
      // formData.append("category", jobCategory);
      // formData.append("send_to", jobSendTo);
      // formData.append("users", childId);
      // formData.append("description", jobDescription);
      // formData.append("budget", jobBudget);
      // formData.append("image", companyLogo)

      const response = await axios.post(
        process.env.REACT_APP_API_KEY + "super_add_job",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      console.log("Add Job", response);
      // updateJobTitle("");
      // updateCompany("");
      // updateLocation("");
      // updateExp("");
      // updateQualification("");
      // updateSalary("");
      // updatePostedOn("");
      // updateOpening("");
      // updateJobType("");
      // updateDepartment("");
      // updateInterviewType("");
      // updateEmployment("");
      // updateCompanyProfile("");
      // updateResponsibility("");
      // updateEligibility("");
      // updateKeySkills("");
    } catch (err) {
      console.log("Log in Fail", err);
    }

    // GET CAMPUS LIST
  }
  async function fetchList() {
    console.log("Access Token-", token);
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "get_campus_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      console.log("Get Campus List", fetchResponse);
      const campusListArry = fetchResponse.data.data;
      console.log("Campus listArry", campusListArry);

      setData(campusListArry);
      console.log("Data list", data);
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function fetchStateName(name) {
    //console.log("value................", id);
    try {
      const formData = new FormData();
      formData.append("state_name", name);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "state_list",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      console.log("State Response........", fetchResponse);

      const ErrorCode = fetchResponse.data.error_code;
      // console.log("course Error Code ", ErrorCode);

      if (ErrorCode == 200) {
        const stateData = fetchResponse.data.data;
        //console.log("statename Array.............", stateData);
        setStateData(stateData);
      } else {
        setStateData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const getStateName = async (e) => {
    try {
      const formData = new FormData();
      formData.append("state_id", e);
      // updateState(e);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "city_list",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      if (fetchResponse.data.error_code == 200) {
        setCityList(fetchResponse.data.data);
      } else {
        setCityList([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  async function nextStep() {
    try {

      const job_category = document.getElementById("job_category");
      const job_title = document.getElementById("job_title");
      const company_name = document.getElementById("comp_name");
      const j_location = document.getElementById("job_location");
      const budget = document.getElementById("event_name");

      //var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (
        job_category.value == "" &&
        job_title.value == "" &&
        company_name.value == "" &&
        jobDiscription == "" &&
        j_location.value == "" &&
        budget.value == "" &&
        picture == null
      ) {
        $(".jobCat").show();

        setTimeout(function () {
          $(".jobCat").hide();
        }, 3000);
      } else if (job_category.value == "") {
        $(".jobCat").show();

        setTimeout(function () {
          $(".jobCat").hide();
        }, 3000);
      } else if (company_name.value == "") {
        $(".CompanyName").show();

        setTimeout(function () {
          $(".CompanyName").hide();
        }, 3000);
      } else if (job_title.value == "") {
        $(".JobTitle").show();

        setTimeout(function () {
          $(".JobTitle").hide();
        }, 3000);
      } else if (picture == null) {
        $(".companyLogo").show();

        setTimeout(function () {
          $(".companyLogo").hide();
        }, 3000);
      }
       else if (jobDiscription == "") {
        $(".JobDesc").show();

        setTimeout(function () {
          $(".JobDesc").hide();
        }, 3000);
      } else if (j_location.value == "") {
        $(".job_location").show();

        setTimeout(function () {
          $(".job_location").hide();
        }, 3000);
      } else if (budget.value == "") {
        $(".JobBudget").show();

        setTimeout(function () {
          $(".JobBudget").hide();
        }, 3000);
      }
       else {

        setTimeout(function() {
          history.push("/jobformstep2",{jobCategory,companyName,jobTitle,picture,jobDiscription,
          jobLocation,jobBudget});
        }, 1000);
      }
    } catch(err) {
      console.log("next step error------------",err);
    }
  }
  var eventImage = [];
  const getImage = (e) => {

    $(".default_image").hide();

    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setPicture(eventImage);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";

        imgData.push(src);
        console.log("srccccccccccc--------",src);
      }
    }
  };

  const resetValues = () => {
     updateJobCategory("");
     updateCompanyName("");
     updateJobTitle("");
     setPicture("");
     updateJobDiscription("");
     setJobLocation("");
     updateJobBudget("");
     $(".default_image").show();
     var preview = document.getElementById("file-ip-1-preview");
     preview.style.display = "none";
  };

  todayy = new Date().toISOString().slice(0, 10);


  const handelSummenrnote = (e) => {
    updateJobDiscription(e);
  };

  useEffect(() => {
    fetchList();
    fetchStateName();

  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showPreview = () => {
    // alert("in the show preview");
    handleShow();
    // if (jobTitle.length == 0 || jobCategory.length == 0 || companyName.length == 0 || state.length == 0 || city <= 0 || jobBudget <= 0) {
    //   alert("Cannot preview empty fields! Please enter some information")
    // }
    // else {

    // }
  };

  function preview() {
    $(".preview_polls").show();
  }

  function closePreview() {
    $(".preview_polls").hide();
  }

  // function nextStep() {
  //   setTimeout(function() {
  //     history.push("/jobformstep2");
  //   }, 1000);
  // }

  return (
    <div className="content-wrapper">
      {/* <div>
        <a href="/jobDetails">
          <br />
          <div
            className="d-flex mt-1 h-100"
            style={{ padding: "0", margin: "0" }}
          >
            <i
              class="fas fa-arrow-alt-circle-left"
              style={{
                color: "black",
                margin: "0px 0px 0px 5px",
                fontSize: "24px",
                padding: "0",
                fontWeight: "bold",
              }}
            />
          </div>
        </a>
      </div> */}
      {/* <div className="border_class p-0 m-0">
        <h5 className="main_heading_h1" >
          CREATE JOB
        </h5>
      </div> */}

      <div className="border_class2">
            <div style={{ padding: "6px" }}>
              <div className="polls_heading_h1">CREATE JOB</div>
            </div>

            <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 1 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                  Job Details
                  </p>
                </div>
              </div>
          </div>

      <div class="formSuccess"
        style={{
          marginTop: "5px",
          width: "97%",
          marginLeft: "18px",
          marginRight: "198px",
          display: "none",
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert variant="filled" severity="success">
            {error_message}
          </Alert>
        </Stack>
      </div>

      <div>

        <div className=" border_class">

            <div className="row">
              <div className="col-md-8">
                 <div className="row">
                 <div class="col-md-6">
                <div style={{ width: "100%", marginTop: "0px", paddingRight: "0" }}>

                  <div className="d-flex">
                    <label className="all_labels">
                      Job Category
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <select
                    className="form-select form-select-sm input_box"
                    id="job_category"
                    aria-label=".form-select-sm example"

                    onChange={(e) => updateJobCategory(e.target.value)}

                  >
                    <option
                      selected="selected"
                      value={jobCategory}
                      style={{ padding: "6px" }}
                    >
                      Select Category
                    </option>
                    <option value="1">Part Time</option>
                    <option value="2">Full Time</option>
                    <option value="3">Remote Friendly</option>
                    <option value="4">Freelance</option>
                    <option value="5">Volunteer</option>
                  </select>
                  <div
                    class="jobCat"
                    id="category"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Select Job Category
                    </h4>
                  </div>
                </div>

              </div>
                 </div>

              <div className="row mt-2">
              <div class="col-md-6">
                <div className="">

                  <div className="d-flex">
                    <label className="all_labels">
                      Company Name
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input className="input_box"
                    type="name"
                    id="comp_name"
                    value={companyName}
                    onChange={(e) => updateCompanyName(e.target.value)}
                    placeholder="Who's  hiring?..."
                    autoComplete="true"

                  />
                  <div
                    class="CompanyName"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Company Name
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-md-6" >
                  <div  className="">


                    <div className="d-flex">
                      <label className="all_labels">
                        Job Title
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                     <input className="input_box"
                        type="name"
                        id="job_title"
                        value={jobTitle}
                        onChange={(e) => updateJobTitle(e.target.value)}
                        placeholder="Job title goes here..."
                        autoComplete="true"

                  />
                  <div
                    class="JobTitle"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Job Title
                    </h4>
                  </div>

                    {/* <input
                      type="name"
                      id="job_title"
                      //value={jobTitle}
                     // onChange={(e) => updateJobTitle(e.target.value)}
                      placeholder="Job title goes here..."
                      autoComplete="true"
                      style={{ width: "100%", height: "34px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "10px", paddingLeft: "5PX" }}
                    /> */}


                  </div>
              </div>
              </div>

              </div>
              <div class="col-md-4">
                <div class="">
                  <div  className="">
                    <div className="d-flex">
                      <label className="all_labels">
                        Add Company Logo
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>

                    <label for="file-ip-1" class="file-ip-1 x mb-0">
                      <img class="default_image"
                        src="dist/img/event_photo.png"
                        id="comp_logo"
                      />

                      <img
                        id="file-ip-1-preview"
                        style={{display:"none"}}
                      />
                    </label>

                    <input
                      type="file"
                      name="photo"
                      style={{ visibility: "hidden",display:"none" }}
                      accept="image/png, image/gif, image/jpeg"
                      onChange={getImage}
                      multiple
                      id="file-ip-1"
                    />

                    <div
                      class="companyLogo"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Select Company Logo
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              </div>

            </div>



          {/* <div className="border_class">
           <div className="row">
              <div class="col-md-3" >
                <div
                  style={{ width: "100%", marginTop: "0px", paddingRight: "0" }}
                >
                  <div className=" d-flex ">
                    <label
                      style={{
                        color: "#1F3977",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      State Name
                    </label>

                    <p
                      style={{
                        color: "#EB2424",
                        fontWeight: "600",
                        marginLeft: "3PX",
                        fontSize: "10px",
                      }}
                    >
                      *
                    </p>
                  </div>
                  <select className="input_box"
                      onChange={(e) => getStateName(e.target.value)}
                      id="state_name"

                    >
                      <option value={"disabled"}>Select State</option>

                      {stateData.map((name) => {
                        return (
                          <option value={name.state_id}>
                            {name.state_name}
                          </option>
                        );
                      })}
                    </select>

                    <div
                      class="JobState"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Select State Name
                      </h4>
                    </div>

                </div>
              </div>

              <div class="col-md-3" >
                <div className="">

                  <div className="d-flex">
                    <label
                      style={{
                        color: "#1F3977",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      City Name
                    </label>

                    <p
                      style={{
                        color: "#EB2424",
                        fontWeight: "600",
                        marginLeft: "3PX",
                        fontSize: "10px",
                      }}
                    >
                      *
                    </p>
                  </div>


                  <select className="input_box"
                    onChange={(e) => updateCity(e.target.value)}
                    id="city_name"

                  >
                    <option value={"disabled"}>Select City</option>

                    {cityList.map((name) => {
                      return (
                        <option value={name.city_name}>{name.city_name}</option>
                      );
                    })}
                  </select>

                  <div
                    class="JobCity"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter city Name
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
              <div  className="">
                <div className="d-flex date">
                  <label
                    style={{
                      color: "#1F3977",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Job Deadline date/time
                  </label>

                  <p
                    style={{
                      color: "#EB2424",
                      fontWeight: "600",
                      marginLeft: "3PX",
                      fontSize: "10px",
                    }}
                  >
                    *
                  </p>
                </div>

                <input
                  type="datetime-local"
                  className="form_control input_box"
                  min={todayy + "T00:00"}
                  placeholder="dd-mm-yyyy hh-mm"
                  id="publish_date"
                  value={jobDate}
                  onChange={(e) => updateJobDate(e.target.value)}
                  name="birthdaytime"

                />
                <div
                  class="EventDate"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10px", marginLeft: "0" }}
                  >
                    Please Enter Event Date
                  </h4>
                </div>
              </div>
              </div>

              <div class="col-md-3 p-0">
                <div className="left_padding">
                <div  className="d-flex ">
                  <label
                    style={{
                      color: "#1F3977",
                      fontSize: "12px",
                      fontWeight: "bold",

                    }}
                  >
                    Budget
                  </label>

                  <p
                    style={{
                      color: "#EB2424",
                      fontWeight: "600",
                      marginLeft: "3PX",
                      fontSize: "10px",
                    }}
                  >
                    *
                  </p>
                </div>
                <input className="input_box"
                  type="number"
                  id="event_name"
                  min={11111111}
                  max={9999999999}
                  pattern="/^-?\d+\.?\d*$/"
                  value={jobBudget}
                  onChange={(e) => updateJobBudget(e.target.value)}
                  placeholder="0.00"
                  autoComplete="true"

                />
                <div
                  class="JobBudget"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10px", marginLeft: "0" }}
                  >
                    Please Enter Job Budget
                  </h4>
                </div>
              </div>
              </div>

            </div>
          </div> */}

          <div className="border_class ">
            <div class="row">
              <div class="col-md-8" >
                <div
                  style={{
                    width: "100%",

                    paddingRight: "0",
                  }}
                >
                  <div className="d-flex">
                    <label className="all_labels">
                      Job Description
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <div>
                      <SummerNote
                          value={jobDiscription}
                          _onChange={handelSummenrnote}
                      />
                 </div>


                  {/* <textarea  className="input_box "
                      id="job_description"

                      value={jobDiscription}
                      onChange={(e) => updateJobDiscription(e.target.value)}
                     style={{height:"90px"}}

                   ></textarea>  */}

                  <div
                    class="JobDesc"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Job Description
                    </h4>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="border_class">
           <div className="row">

              <div class="col-md-4">
              <div  className="">
                <div className="d-flex date">
                  <label className="all_labels">
                    Job Location
                  </label>

                  <p className="all_stars">
                    *
                  </p>
                </div>

                <input
                  type="text"
                  className=" input_box"

                  placeholder="Location"
                  id="job_location"
                  value={jobLocation}
                  onChange={(e) => setJobLocation(e.target.value)}
                  name="birthdaytime"

                />
                <div
                  class="job_location"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10px", marginLeft: "0" }}
                  >
                    Please Enter Job Location
                  </h4>
                </div>
              </div>
              </div>

              <div class="col-md-4">
                <div className="">
                <div  className="d-flex ">
                  <label className="all_labels">
                    Budget
                  </label>

                  <p className="all_stars">
                    *
                  </p>
                </div>
                <input className="input_box"
                  type="number"
                  id="event_name"
                  min={11111111}
                  max={9999999999}
                  pattern="/^-?\d+\.?\d*$/"
                  value={jobBudget}
                  onChange={(e) => updateJobBudget(e.target.value)}
                  placeholder="0.00"
                  autoComplete="true"

                />
                <div
                  class="JobBudget"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10px", marginLeft: "0" }}
                  >
                    Please Enter Job Budget
                  </h4>
                </div>
              </div>
              </div>

            </div>
          </div>


        <div className="mt-0 p-0">
          <div class="row">

          </div>
        </div>

        {/* buttons */}
        {/* <div className="d-flex border_class" style={{alignItems:"center"}}>
          <div
            class="ValueMsg"
            style={{ margin: "8px", width: "57%", display: "none" }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="error">
                Error! You Must Fill In All The Fields
              </Alert>
            </Stack>
          </div>
          <img className="ml-auto delete_image" onClick={() => resetValues()}
             src="dist/img/delete.png" alt="dropdown" />
              <div style={{margin:"0px 15px"}}>
                 <p className="news_bar" >|</p>
              </div>

             <button className="preview_button" >
              <p className="preview_button_p_tag">Preview</p>
             <img className="preview_image" onClick={() => preview()}
              src="dist/img/view.png" alt="dropdown"  />
             </button>

            <input
              type="button"
              className="publish_button"
              defaultValue="Sign Up"
              onClick={() => CreateJob()}
              value="Publish"

            />


        </div> */}

        <div className="d-flex border_class" style={{alignItems:"center"}}>
            <div
              class="ValueMsg"
              style={{ width: "57%",display: "none" }}
            >
              <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="error">
              Error! You Must Fill In All The Fields
              </Alert>
              </Stack>
            </div>


           <img className="ml-auto delete_image" onClick={() => resetValues()}
             src="dist/img/delete.png" alt="dropdown" />
              <div>
                 <div className="news_bar" >|</div>
              </div>

            <button
              type="button"
              className="next_step_btn"

              onClick={nextStep}


            >
             <div className="next_step_btn_div">
              Next Step
            </div>
            <img className="next_step_btn_img"
              src="dist/img/next.png"

            />
            </button>
          </div>

      </div>

      {/* PREVIEW */}
      <div
        className="preview_polls"
        style={{
          position: "fixed",
          top: "0",
          left: "0px",
          background: "rgba(0,0,0,0.5)",
          padding: "10px",
          width: "100%",
          height: "100%",
          zIndex: "10",
          display: "none",
        }}
      >
        <div
          style={{
            padding: "15px",
            background: "#f5f5f5",
            boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)",
            position: "absolute",
            bottom: "0px",
            top: "0",
            right: "5px",
            width: "420px",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div
            className="d-flex"
            style={{
              borderBottom: "2px solid #15a312",
              transform: "rotate(0.13deg)",
            }}
          >
            <label style={{ fontSize: "11px", fontWeight: "600" }}>
              Preview
            </label>

            <button
              style={{ border: "none", marginLeft:"330px",marginBottom:"2px" }}
              onClick={() => closePreview()}
            >
              <img
                src="dist/img/Cancel.png"
                alt="dropdown"
                width="18px"
                height="16px"
                className="close_event ml-auto"
                style={{ cursor: "pointer" }}
              />
            </button>
          </div>

          {/* jobCategory == "" ?

               (
                 <div>Please fill the form to preview it</div>
              ) : ( */}
          <div
            style={{
              background: "white",
              marginTop: "10PX",
              padding: "5px 10PX",
              border: "0.4px solid #C4C4C4",
            }}
          >
            <div className="d-flex" style={{ padding: "10px 0px" }}>
              <h4
                style={{
                  color: "rgba(0, 0, 0, 0.7)",
                  fontSize: "12PX",
                  fontWeight: "600",
                }}
              >
                Job
              </h4>
              <img
                src="dist/img/Pencil.png"
                onClick={() => closePreview()}
                alt="dropdown"
                width="18px"
                height="18px"
                className=" ml-auto"
              ></img>
            </div>

            {
              <div>
                <div
                  className="row"
                  style={{
                    background: "#e4e9f3",
                    padding: "7px",
                    margin: "7px 3px",
                  }}
                >
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Job Category
                  </p>
                  <p
                    className="col-md-10"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    : {jobCategory}
                  </p>
                </div>

                <div
                  className="row"
                  style={{
                    background: "#e4e9f3",
                    padding: "7px",
                    margin: "7px 3px 0px 3px",
                  }}
                >
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Job Title
                  </p>
                  <p
                    className="col-md-10"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    : {jobTitle}{" "}
                  </p>
                </div>

                <div
                  className="row"
                  style={{
                    background: "#e4e9f3",
                    padding: "7px",
                    margin: "7px 3px 0px 3px",
                  }}
                >
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Company Name
                  </p>
                  <p
                    className="col-md-10"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    : {companyName}
                  </p>
                </div>

                <div
                  className="row"
                  style={{
                    background: "#e4e9f3",
                    padding: "7px",
                    margin: "7px 3px 0px 3px",
                  }}
                >
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Description
                  </p>
                  <p
                    className="col-md-10"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    : <p dangerouslySetInnerHTML={{ __html: jobDiscription }}/>
                  </p>
                </div>

                <div
                  className="row"
                  style={{
                    background: "#e4e9f3",
                    padding: "7px",
                    margin: "7px 3px 0px 3px",
                  }}
                >
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Company Logo
                  </p>
                  <p
                    className="col-md-10"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    : {imgData}{" "}
                    <img src={imgData.src} style={{ width: "70px" }} />
                  </p>
                </div>

                <div
                  className="row"
                  style={{
                    background: "#e4e9f3",
                    padding: "7px 0 0 0",
                    margin: "7px 3px 0px 3px",
                  }}
                >
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Deadline date
                  </p>
                  <p
                    className="col-md-4"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    :
                  </p>
                  <p
                    className="col-md-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    Budget
                  </p>
                  <p
                    className="col-md-4"
                    style={{
                      color: "#1f3977",
                      fontWeight: "600",
                      fontSize: "10PX",
                    }}
                  >
                    : {jobBudget}
                  </p>
                </div>
              </div>
            }
          </div>
          {/* ) */}
          {/* } */}

          <div
            class="EnterValue"
            style={{ margin: "10px auto", display: "none" }}
          >
            <h4
              class="login-text"
              style={{ color: "red", fontSize: "12PX", marginLeft: "0" }}
            >
              You Must Fill In All The Fields
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
