import React, { useState, useEffect } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import $ from "jquery";
import axios from "axios";
import { useLocation } from "react-router-dom";

export function MarketPlaceStep2() {
  const token = localStorage.getItem("Token");
  const location = useLocation();
  const { title } = location.state || { id: "none" };
  const { description } = location.state || { id: "none" };
  const { imgData } = location.state;
  const { price } = location.state || { id: "none" };
  const { send_to } = location.state || { id: "none" };
  const { childId } = location.state || { id: "none" };
const {photos} = location.state;
  console.log("title", title);
  console.log("photos imgData", photos);

  const [inputList, setInputList] = useState([{ title: "", value: "" }]);
  inputList.map((item) =>
  {
    console.log("inputList",item.title)
  })

  // handle input change
  const handleInputChange = (e, index) => {
    console.log("get unknown number",e.target.name)
      const { name, value } = e.target;
      console.log("get unknown number",...inputList)
      const list = [...inputList];
      console.log("get unknown number",list)
      list[index][name] = value;
      console.log("get unknown number",value)
      setInputList(list);
      console.log("inputList",list)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { title: "", value: "" }]);
  };
  function resetValues()
  {
    updateSku("")
    updateManageSku("")
    updateOptionName("")
    updateOptionValue("")
    var ele = document.getElementById("manage_sku");
    for(var i=0;i<ele.length;i++)
       ele[i].checked = false;
  }
  const [sku, updateSku] = useState("");
  const [manageSku, updateManageSku] = useState("");
  const [optionName, updateOptionName] = useState("");
  const [optionValue, updateOptionValue] = useState("");
  const [error_message, updateError_message] = useState("");

  console.log("print Option Name", optionName);

function closePreview()
{
  $(".preview_polls").hide();
}
  const obj = [
    {
      title: optionName,
      value: optionValue,
    },
  ];

  $("#myFunction").click(function(){
    alert("The paragraph was clicked.");
  });

  var letter =
    " <div class='next-referral'><label style=' color: #1F3977; font-size: 10px; fontWeight: 600; '>Option Name</label><br />   <select style='width: 100%;height: 35px;,padding: 5px;font-size:10px;color: black;border: 1px solid #c4c4c4;border-radius: 0px,padding: 6px;'><option  style=' padding: 6px; '>Select Option Name</option><option style=' padding: 6px; '>Size</option><option style=' padding: 6px; '>Color</option><option style=' padding: 6px; ' >Material</option></select><br /> <label style=' color:#1F3977; font-size: 10px; fontWeight: 600; '>Option Value</label><br /><input type='text' id='newInputBox' style='fontFamily: Poppins;background: #FFFFFF; width: 100%; height: 35px; font-size: 12px !important; margin-left: 0px; border: 1px solid #c4c4c4; margin-top:5px'></input></div><br /><input type='button' class='inputRemove' value='Remove' id='myFunction'/></div>";
  function createNewElement() {
    var txtNewInputBox = document.createElement("div");
    txtNewInputBox.innerHTML = letter;
    document.getElementById("newElementId").appendChild(txtNewInputBox);
    console.log("letter",letter)
$(".delete_button").show();

    // if(letter == "null")
    // {
    //   $(".delete_button").hide();
    // }
  }
  function myFunction()
  {
    alert("ongoin")
  }
  function removeTextBox()
  {
    
    $(".next-referral").last().remove();
    // var txtNewInputBox = document.remove("div");
    // txtNewInputBox.innerHTML = letter;
    // document.getElementById("newElementId").removeChild(txtNewInputBox);
    // console.log("letter",letter)
    
  }
  async function submitMarketplace() {
    console.log("hello")
    try {
    //   const m_sku = document.getElementById("sku_unit");
     
    //   const m_option_name = document.getElementById("marketplaceOptionName");
    //   const m_option_value = document.getElementById("optionValue");
    
    // if (m_sku.value == "") {

    //     $(".skuUnit").show();

    //     setTimeout(function () {
    //       $(".skuUnit").hide();
    //     }, 3000);

    //   }

    //   else if (manageSku == "") {

    //     $(".manage_sku").show();

    //     setTimeout(function () {
    //       $(".manage_sku").hide();
    //     }, 3000);

    //   }
    //   else if (optionName == "") {

    //     $(".option_name").show();

    //     setTimeout(function () {
    //       $(".option_name").hide();
    //     }, 3000);

    //   }
    //   else if (m_option_value.value == "") {

    //     $(".option_value").show();

    //     setTimeout(function () {
    //       $(".option_value").hide();
    //     }, 3000);

    //   }
    //   else{
      const formData = new FormData();

      formData.append("title", title);
      formData.append("send_to", send_to);
      formData.append("sku", sku);
      formData.append("price", price);
      formData.append("description", description);
      for (let i = 0; i < photos.length; i++) {
        formData.append("image[]", photos[i]);
      }
      // formData.append("image[]", imgData)
      formData.append("charge_tax", 1);
      formData.append("manage_sku", manageSku);
      formData.append("users", childId);
      formData.append("info", JSON.stringify(inputList));
      const secondResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "admin_add_marketplace",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      console.log("Marketplace add response", secondResponse.data.data);
      updateError_message(secondResponse.data.message);
      $(".formSuccess").show();
      setTimeout(function () {
        $(".formSuccess").hide();
      }, 3000);

      // window.location.href = "/marketplaceDetails";
    // }
    } catch (err) {
      console.log("Log in Fail", err);
      //   setIsLoading(false)
    }
  }

  function preview() {
    $(".preview_polls").show();

  }
  function edit_category() {
    $(".preview_polls").hide();
    $(".preview_category").show();
  }
  return (
    <div>
      <Header />
      <div className="d-flex">
        <Menu />
        <div className="content-wrapper mx-auto">
          <div style={{ padding: "8px", margin: "0" }}>
            <h1
              style={{
                color: "black",
                fontWeight: "600",
                fontFamily: "Poppins",
                fontSize: "13PX",
                lineHeight: "24PX",
                marginLeft: "42PX",
              }}
            >
              ADD PRODUCT
            </h1>
          </div>

          <div
            class="formSuccess"
            style={{
              marginTop: "5px",
              marginLeft: "18px",
              width: "97%",
              marginRight: "198px",
              display: "none",
            }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="success">
                {error_message}
              </Alert>
            </Stack>
          </div>

          <div
            style={{
              background: "#FFFFFF",
              width: "100%",
              height: "520px",
              overflowY: "auto",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              paddingRight: "10px",
              paddingLeft: "15px",
            }}
          >
            {/* inventory */}
            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-5">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Inventory
                      </label>

                      <p
                        style={{
                          color: "#EB2424",
                          fontWeight: "600",
                          marginLeft: "3PX",
                          fontSize: "10px",
                        }}
                      >
                        *
                      </p>
                    </div>
                    <label
                      style={{
                        color: "#1F3977",
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                    >
                      SKU(Stock Keeping Unit)
                    </label>
                    <input
                      type="number"
                      id="sku_unit"
                      className="input_fields"
                      value={sku}
                      onChange={(e) => updateSku(e.target.value)}
                      // placeholder="Your title goes here..."
                      autoComplete="true"
                      style={{
                        width: "100%",
                        height: "35px",
                        border: "1px solid #c4c4c4",
                        boxSizing: "border-box",
                        fontSize: "10px",
                        paddingLeft: "5PX",
                      }}
                    />
                    <div
                      class="skuUnit"
                      style={{ marginTop: "0px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Specify SKU unit
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* track quantity */}
            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-12">
                  <div
                    className=""
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <div
                    id="manageSku"
                      className="d-flex"
                      value={manageSku}
                      onChange={(e) => updateManageSku(e.target.value)}
                    >
                      <input
                        type="radio"
                        name="userType"
                        value="1"
                        id="manage_sku"
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />
                      <label
                        for="manage_sku"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          marginLeft: "0PX",
                          marginTop: "4px",
                          fontWeight: "600",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "5px",
                            fontSize: "10PX",
                            fontWeight: "600",
                          }}
                        >
                          Track Quantity
                        </p>
                      </label>

                      <input
                        type="radio"
                        name="userType"
                        value="2"
                        id="specific class"
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />

                      <label
                        for="specific class"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          marginLeft: "10PX",
                          marginTop: "4px",
                          fontWeight: "600",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "5px",
                            fontSize: "10PX",
                            fontWeight: "600",
                          }}
                        >
                          Continue selling when out of stock
                        </p>
                      </label>
                    </div>

                    <div
                      class="manage_sku"
                      style={{ marginTop: "0px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Select 1 of the option
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*OPTIONS  */}
            <div className="mt-2 p-0">
              <div class="row">
                <div class="col-md-12">
                  <div
                    className=""
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <div className="d-flex">
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "10px",
                          fontWeight: "600",
                        }}
                      >
                        Options
                      </label>

                      <p
                        style={{
                          color: "#EB2424",
                          fontWeight: "600",
                          marginLeft: "3PX",
                          fontSize: "10px",
                        }}
                      >
                        *
                      </p>
                    </div>

                    <div
                      className="d-flex"
                      value={manageSku}
                      onChange={(e) => updateManageSku(e.target.value)}
                    >
                      <input
                        type="radio"
                        name="manage_sku"
                        value=""
                        id="now"
                        checked="checked"
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />
                      <label
                        for="now"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          marginLeft: "0PX",
                          marginTop: "4px",
                          fontWeight: "600",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "5px",
                            fontSize: "10PX",
                            fontWeight: "600",
                          }}
                        >
                          This Product has Options, like size or color
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form class="form-horizontal">
<fieldset>



{/* <!-- Text input--> */}
<div id="items" class="form-group">
{inputList.map((x, i) => {
        return (
         
          <div className="box" style={{ width: "100%", margin: "0px 30px" }}>
            <div className="d-flex">
<label
                        style={{
                          color: "#1F3977",
                          fontSize: "10px",
                          fontWeight: "600",
                        }}
                      >
                        Option Name
                      </label>
                      <p
                        style={{
                          color: "#EB2424",
                          fontWeight: "600",
                          marginLeft: "3PX",
                          fontSize: "10px",
                        }}
                      >
                        *
                      </p>
                      </div>
                      <select
                      className="form-select form-select-sm "
                      name="title"
                      id="marketplaceOptionName"
                      aria-label=".form-select-sm example"
                      class="input_fields"
                      // value={optionName}
                      // onChange={(e) => updateOptionName(e.target.value)}
                      value={x.title}
                      onChange={e => handleInputChange(e, i)}
                      style={{
                        width: "35%",
                        height: "35px",
                        padding: "5px",
                        fontSize: "10px",
                        color: "black",
                        border: "1px solid #c4c4c4",
                        borderRadius: "0px",
                        boxSizing: "border-box",
                        padding: "6px",
                      }}
                    >
                      <option selected="selected" style={{ padding: "6px" }}>
                        Select Option Name
                      </option>
                      <option style={{ padding: "6px" }}>Size</option>
                      <option style={{ padding: "6px" }}>Color</option>
                      <option style={{ padding: "6px" }}>Material</option>
                    </select>

          
            <br />

{/* option value */}
            <div className="d-flex">
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "10px",
                          fontWeight: "600",
                        }}
                      >
                        Option Value
                      </label>

                      <p
                        style={{
                          color: "#EB2424",
                          fontWeight: "600",
                          marginLeft: "3PX",
                          fontSize: "10px",
                        }}
                      >
                        *
                      </p>
            </div>

<div className="d-flex" >
                    <input
                      type="text"
                      name="value"
                      id="optionValue"
                      // onChange={(e) => updateOptionValue(e.target.value)}
                      // value={optionValue}
                      value={x.value}
                      onChange={e => handleInputChange(e, i)}
                      className="input_fields ml10"
                    
                      style={{
                        width: "35%",
                        height: "35px",
                        border: "1px solid #c4c4c4",
                        boxSizing: "border-box",
                        fontSize: "10px",
                        paddingLeft: "5PX",
                        
                      }}
                    />
                      <div className="btn-box d-flex" >
                      {inputList.length !== 1 && 
                 <button class="delete mr10"  onClick={() => handleRemoveClick(i)} style={{background:"transparent",marginTop:"0",padding:"0",border:"none"}}>
                 <span
                                     className="d-flex"
                                     style={{ marginLeft: "5px" }}
                                   
                                   >
                                     <img
                                       src={require("../images/Cancel.png")}
                                       alt="dropdown"
                                       style={{ width: "18px", height: "18px",marginLeft:"auto" }}
                                     />
                                   
                                   </span>
                 </button>
                }
                        </div>
                    </div>

        <div className="row btn-box d-flex" >
            {inputList.length - 1 === i &&
                <button className="col-md-6" id="add"  type="button" onClick={handleAddClick} style={{background:"transparent",marginTop:"0",padding:"0",border:"none"}}>
                <span
                                      className="d-flex"
                                      style={{ marginLeft: "0px" }}
                                     
                                    >
                                      <img
                                        src="dist/img/add.png"
                                        alt="dropdown"
                                        style={{ width: "20px", height: "20px" }}
                                      />
                                      <p
                                        className="add"
                                        style={{
                                          color: "#1f3977",
                                          fontWeight: "600",
                                          fontSize: "10PX",
                                        }}
                                      >
                                        Add another option
                                      </p>
                                    </span>
                  </button> 
               }
              {/* {inputList.length !== 1 && 
                 <button class="delete col-md-6 mr10"  onClick={() => handleRemoveClick(i)} style={{background:"transparent",marginTop:"0",padding:"0",border:"none"}}>
                 <span
                                     className="d-flex"
                                     style={{ marginLeft: "0px" }}
                                   
                                   >
                                     <img
                                       src={require("../images/close-icon.png")}
                                       alt="dropdown"
                                       style={{ width: "20px", height: "20px",marginLeft:"auto" }}
                                     />
                                     <p
                                       className="add"
                                       style={{
                                         color: "#1f3977",
                                         fontWeight: "600",
                                         fontSize: "10PX",
                                       }}
                                     >
                                       Remove option
                                     </p>
                                   </span>
                 </button>
                } */}
                
           
            </div>
          </div>
          
        );
      })}

</div>
</fieldset>
</form>

       
           


            <div className="d-flex">
              <img
                src="dist/img/delete.png"
                onClick={() => resetValues()}
                alt="dropdown"
                style={{ width: "33px", height: "33px", marginTop: "5px" }}
                className="ml-auto"
              />
              <img
                src="dist/img/view.png"
                onClick={() => preview()}
                alt="dropdown"
                style={{
                  width: "33px",
                  height: "33px",
                  marginLeft: "8PX",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              />
              <input
                type="button"
                className=" form-buttons3 "
                onClick={() => submitMarketplace()}
                value="Publish"
                style={{
                  fontWeight: "500",
                  border: "none",
                  color: "white",
                  borderRadius: "6px",
                  marginLeft: "8px",
                  backgroundColor: "#1F3977",
                  padding: "10px 40px",
                  fontSize: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  marginBottom: "5px",
                }}
              />
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      
      {/* PREVIEW */}

      <div className="preview_polls" style={{ position: "fixed", top: "0", left: "0px", background: "rgba(0,0,0,0.5)", padding: "10px", width: "100%", height: "100%", zIndex: "10", display: "none" }}>
        <div style={{ padding: "15px", background: "#f5f5f5", boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)", position: "absolute", bottom: "0px", top: "0", right: "5px", width: "420px", height: "100%" }}>
          <div className="d-flex" style={{ borderBottom: "2px solid #15a312",marginTop:"28px", transform: "rotate(0.13deg)" }}>
            <label style={{ fontSize: "11px", fontWeight: "600" }}>Preview</label>


            <img src="dist/img/Cancel.png" onClick={() =>closePreview()} alt="dropdown" width="18px" height="16px" className="close_event ml-auto" style={{ cursor: "pointer" }} />

          </div>

          {/* {
            title  == ""  ?

              (
                <div>Please fill the form to preview it</div>
              ) : ( */}
                <div style={{ background: "white",height:"600px",overflowY:"AUTO", marginTop: "10PX", padding: "5px 10PX", border: "0.4px solid #C4C4C4" }}>

                  <div className="d-flex" style={{ padding: "10px 0px" }}>
                    <h4 style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: "12PX", fontWeight: "600" }}>Martketplace</h4>
                    <img src="dist/img/Pencil.png" alt="dropdown" width="18px" height="18px" className=" ml-auto"
                      onClick={() => edit_category()} />
                  </div>

                  {


                    <div>
                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Title</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>{title }</span></p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Product Description</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>{description }</span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Product Image</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX",display:"flex" }}>: <span style={{marginLeft:"10px",display:"flex"}}>
                        {imgData.map((item) =>
      {
        return(
            <div className style={{width:"30px",height:"30px",margin:"0 3px"}}>
                <img src ={item} style={{height:"100%",width:"100%"}}/>
            </div>
        )
      })}
                          {/* <img src ={imgData} alt="photos" style={{width:"50px",height:"50px"}} /> */}
                          </span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Price</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>{price }</span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>User Type</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>
                          {send_to == 1 ? "All Students":"Specific Recipient" }
                          </span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>SKU</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>{sku}</span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Manage SKU</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>
                          {manageSku == 1 ? "Track Quantity":"Continue selling when out of stock"}
                          </span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Option Name</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>
                        {inputList.map((item) =>
  {
    return(
      <div>
{item.title}
      </div>)
  })}

                          </span> </p>
                      </div>

                      <div className="row" style={{ background: "#e4e9f3", padding: "7px", margin: "7px 3px 0px 3px" }}>
                        <p className="col-md-3" style={{ color: "rgba(0, 0, 0, 0.5)", fontWeight: "600", fontSize: "10PX" }}>Option Value</p>
                        <p className="col-md-9" style={{ color: "#1f3977", fontWeight: "600", fontSize: "10PX" }}>: <span style={{marginLeft:"10px"}}>
                        {inputList.map((item) =>
  {
    return(
      <div>{item.value}</div>

    )
  })}
                          </span> </p>
                      </div>

                    </div>

                  }



                </div>
              {/* )
          } */}

        </div>
      </div>
    
    </div>
  );
}

