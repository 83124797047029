import React, { useState } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import $ from "jquery";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory, Link } from "react-router-dom";

export const FacultyForm = () => {
  return (
    <div>
      <Header />

      <div className="d-flex">
        <Menu />

        <div className="content-wrapper">
        <div className="border_class2 box_padding">
        <h1 className="main_heading_h1 m-0">CREATE TEACHER</h1>
      </div>

      <div>
        <div className="border_class2 box_padding">
          <div class="row">
            <div class="col-md-8">
              <div
                style={{ width: "100%", marginTop: "0px", paddingRight: "0" }}
              >
                <div className="row d-flex">
                  {/* first name */}
                  <div className="col-md-6">
                    <div className="d-flex">
                      <label className="all_labels">First Name</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                      className="all_inputs"
                      type="name"
                      id="first_name"
                      // value={firstName}
                      // onChange={(e) => updateFirstName(e.target.value)}
                      placeholder="Your First Name..."
                      autoComplete="true"
                    />

                    <div class="FirstName" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Enter First Name
                      </h4>
                    </div>
                  </div>

                  {/* last name */}
                  <div className="col-md-6">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">Last Name</label>

                        <p className="all_stars">*</p>
                      </div>

                      <input
                        className="all_inputs"
                        type="name"
                        id="last_name"
                        // value={lastName}
                        // onChange={(e) => updateLastName(e.target.value)}
                        placeholder="Your Last Name..."
                        autoComplete="true"
                      />
                      <div class="LastName" style={{ display: "none" }}>
                        <h4 class="login-text all_validations_h4">
                          Please Enter Last Name
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* preffered name */}
                <div className="mt-2 row d-flex">
                  <div className="col-md-6">
                    <label className="all_labels">Preffered Name</label>

                    <input
                      className="all_inputs"
                      type="name"
                      id="preferred_name"
                      // value={preferredName}
                      // onChange={(e) => updatePreferredName(e.target.value)}
                      placeholder="Your Preferred Name..."
                      autoComplete="true"
                    />
                    <div class="PreferredName" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Enter Preferred Name
                      </h4>
                    </div>
                  </div>

                  {/* last name */}
                  <div className="col-md-6">
                    <div className="">
                      <label className="all_labels">Gender</label>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="gender_neww"
                        // onChange={fetchGender}
                      >
                        <option selected 
                        // value={gender}
                        >
                          Select Gender
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>

                      <div class="Gender" style={{ display: "none" }}>
                        <h4 class="login-text all_validations_h4">
                          Please Select Gender
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2 row d-flex">
                  {/* first name */}
                  <div className="col-md-6">
                    <label className="all_labels">Date Of Birth</label>

                    <input
                      type="date"
                      className="all_inputs"
                      placeholder="dd-mm-yyyy"
                      id="dob"
                      // value={dateOfBirth}
                      // onChange={(e) => updateDateOfBirth(e.target.value)}
                      // required
                      name="birthdaytime"
                    />


                    <div class="Dob" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Enter Date of Birth
                      </h4>
                    </div>
                  </div>

                  {/* last name */}
                  <div className="col-md-6">
                    <div className="">
                      <label className="all_labels">Designation</label>

                      <input
                        className="all_inputs"
                        type="name"
                        id="designation_neww"
                        // value={designation}
                        // onChange={(e) => updateDesignation(e.target.value)}
                        placeholder="Enter Designation..."
                        autoComplete="true"
                      />

                      <div class="Designation" style={{ display: "none" }}>
                        <h4 class="login-text all_validations_h4">
                          Please Select Designation
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="d-flex">
                  <label className="all_labels">Teacher's Profile</label>
                </div>

                <label
                  for="file-ip-1"
                  class="file-ip-1 x"
                  style={{ height: "165px" }}
                >
                  <img
                    class="default_image "
                    src="dist/img/event_photo.png"
                    id="comp_logo"
                    style={{height:"145px"}}
                  />

                  <img
                    id="file-ip-1-preview"
                    style={{ display: "none", height: "145px" }}
                  />
                </label>
                <input
                  type="file"
                  name="photo"
                  style={{ visibility: "hidden",display:"none" }}
                  accept="image/png, image/gif, image/jpeg"
                  // onChange={getImage}
                  multiple
                  id="file-ip-1"
                />

                <div class="Image" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Select Image
                  </h4>
                </div>

                {/* </img> */}
              </div>
            </div>
          </div>
        </div>

        <div className="border_class2 box_padding">
          <div class="row">
            <div class="col-md-4">
              <div className="" style={{ width: "100%" }}>
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label className="all_labels">Department</label>

                    <p className="all_stars">*</p>
                  </div>

                  <select
                    className="form-select form-select-sm all_inputs"
                    aria-label=".form-select-sm example"
                    id="department_neww"
                    // onChange={fetchDepartmentWiseCourseList}
                  >
                    <option selected="selected" 
                    // value={department}
                    >
                      Select Department
                    </option>

                    {/* {departmentdata.length > 0 ? (
                      departmentdata.map((dept, index) => {
                        return (
                          <option value={dept.department_id} key={index}>
                            {dept.department_name}
                          </option>
                        );
                      })
                    ) : (
                      <div>Data Not Found</div>
                    )} */}
                  </select>

                  <div class="Department" style={{ display: "none" }}>
                    <h4 class="login-text all_validations_h4">
                      Please Select Department
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label className="all_labels">Course</label>

                    <p className="all_stars">*</p>
                  </div>

                  <select
                    className="form-select form-select-sm all_inputs"
                    aria-label=".form-select-sm example"
                    id="course_neww"
                    // onChange={courseWiseSubjectList}
                  >
                    <option selected="selected"
                    //  value={course}
                     >
                      Select Course
                    </option>

                    {/* {courseData.length > 0 ? (
                      courseData.map((dept, index) => {
                        return (
                          <option value={dept.course_id} key={index}>
                            {dept.course_name}
                          </option>
                        );
                      })
                    ) : (
                      <div>Data Not Found</div>
                    )} */}
                  </select>

                  <div class="neww_class" style={{ display: "none" }}>
                    <h4 class="login-text all_validations_h4">
                      Please Select Course
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div className="">
                <div className="d-flex">
                  <label className="all_labels">Class</label>

                  <p className="all_stars">*</p>
                </div>

                <select
                  className="form-select form-select-sm all_inputs"
                  aria-label=".form-select-sm example"
                  id="student_class"
                  // onChange={fetchClass}
                >
                  <option selected="selected"
                  //  value={teacherClass}
                   >
                    Select Class
                  </option>

                  {/* {classdata.length > 0 ? (
                    classdata.map((item, index) => {
                      return (
                        <option
                          value={item.class_id}
                          key={index}
                          style={{
                            color: "#000000",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                        >
                          {item.class_name}
                        </option>
                      );
                    })
                  ) : (
                    <div>Data Not Found</div>
                  )} */}
                </select>

                <div class="StudentClass" style={{ display: "none" }}>
                  <h4 class="login-text" className="all_validations_h4">
                    Please Select Class
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border_class2 box_padding">
          <div class="row">
            <div class="col-md-4">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label className="all_labels">Subject</label>
                  <p className="all_stars">*</p>
                </div>
                <select
                  className="form-select form-select-sm all_inputs"
                  aria-label=".form-select-sm example"
                  id="student_subject"
                  // onChange={fetchSubject}
                >
                  <option selected="selected"
                  //  value={subject}
                   >
                    Select Subject
                  </option>

                  {/* {subjectData.map((item, index) => {
                   
                    return (
                      <option value={item.subject_id} key={index}>
                        {item.subject_name}
                      </option>
                    );
                  })} */}
                </select>

                <div class="std_subject" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Select Subject
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="d-flex">
                  <label className="all_labels">Email</label>

                  <p className="all_stars">*</p>
                </div>

                <input
                  type="email"
                  className="all_inputs"
                  placeholder="Enter Email"
                  id="email_neww"
                  // value={email}
                  // onChange={(e) => updateEmail(e.target.value)}
                  name="birthdaytime"
                />
                <div class="std_email" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Enter Email Id
                  </h4>
                </div>
                <div class="validEmail" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Enter valid Email Address with @
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="d-flex">
                  <label className="all_labels">Mobile Number</label>

                  <p className="all_stars">*</p>
                </div>

                <input
                  type="text"
                  className="all_inputs"
                  maxLength="10"
                  placeholder="Enter Mobile Number"
                  id="mobile_neww"
                  // value={mobile}
                  minLength="10"
                  // pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                  // onChange={(e) => checkInput(e)}
                  name="birthdaytime"
                />

                <div class="Mobile" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Enter Mobile Number
                  </h4>
                </div>
                <div class="tenDigitMobileNumber" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Enter 10 digit mobile number
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border_class2 box_padding">
          <div class="row">
            <div class="col-md-4">
              <div className="" style={{ width: "100%" }}>
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label className="all_labels">Spoken Language</label>
                  </div>

                  <select
                    className="form-select form-select-sm all_inputs"
                    aria-label=".form-select-sm example"
                    id="spokenn_language"
                    // onChange={fetchSpokenLangugae}
                  >
                    <option selected="selected"
                    //  value={spokenLanguage}
                     >
                      Select Spoken Language
                    </option>

                    {/* {languagedata.length > 0 ? (
                      languagedata.map((lang, index) => {
                        return (
                          <option value={lang.language_id} key={index}>
                            {lang.language}
                          </option>
                        );
                      })
                    ) : (
                      <div>Data Not Found</div>
                    )} */}
                  </select>

                  <div class="SpokenLanguage" style={{ display: "none" }}>
                    <h4 class="login-text all_validations_h4">
                      Please Select Spoken Language
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="d-flex">
                  <label className="all_labels">Race Ethnicity</label>
                </div>
                <select
                  className="form-select form-select-sm all_inputs"
                  aria-label=".form-select-sm example"
                  id="race_neww"
                  // onChange={fetchRace}
                >
                  <option selected
                  //  value={raceId}
                   >
                    Select Race/Ethnicity
                  </option>
                  <option value="1">White</option>
                  <option value="2">Black</option>
                  <option value="3">Asian</option>
                  <option value="4">American Indian</option>
                </select>

                <div class="Race" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Select Race/ Ethinicity
                  </h4>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="">
                <div className="d-flex">
                  <label className="all_labels">Password</label>

                  <p className="all_stars">*</p>
                </div>

                <input
                  type="password"
                  className="all_inputs"
                  placeholder="Enter Password"
                  id="password_neww"
                  // value={password}
                  // onChange={(e) => updatePassword(e.target.value)}
                  name="birthdaytime"
                />
                <div class="pass" style={{ display: "none" }}>
                  <h4 class="login-text all_validations_h4">
                    Please Enter Password
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* buttons */}
        <div
          className="d-flex border_class2 box_padding buttons_div">
          <div
            class="ValueMsg"
            style={{ margin: "8px", width: "57%", display: "none" }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="error">
                Error! You Must Fill In All The Fields
              </Alert>
            </Stack>
          </div>
          <div
            class="formSuccess success_msg">
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="success">
                {/* {error_message} */}
              </Alert>
            </Stack>
          </div>
          <img  className="delete_img"
            src="dist/img/delete.png"
            alt="dropdown"
            
            // onClick={() => resetValues()}
            
          />
          <span
            className="news_bar"
           
          >
            |
          </span>
          <button className="preview_button " 
          // onClick={() => preview()}
          >
            <span className="preview_font">
              Preview
            </span>
             <div className="preview_img_div">
             <img className="preview_img"
              src="dist/img/view.png"
              alt="dropdown"
              
            />
             </div>
          </button>

          
          <input
            type="button"
            className="publish_button"
            defaultValue="Sign Up"
            // onClick={() => createTeacher()}
            value="Publish"

           
          />
         
        </div>
      </div>
            
        </div>
      </div>
    </div>
  );
};
