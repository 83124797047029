import React, { useState, useEffect } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import $ from "jquery";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import axios from "axios";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export const flaggedContents = () => {
  const customStyles = {
    rows: {
      style: {
        background: "rgba(228, 233, 243, 0.6)",
        marginTop: "6PX",
        border: "none",
        // paddingLeft: "15px",
        // fontWeight:"600"
      },
    },
    headCells: {
      style: {
        // color: "#15a312",
        color: "#1F3977",
      },
    },

    head: {
      style: {
        // border: "0.5px solid #C4C4C4",
        boxShadow: "0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)",
      },
    },
    table: {
      style: {
        // padding: "0px 10px 0 10px",
        marginTop: "0PX",
        height: "auto",
      },
    },
  };

    const token = localStorage.getItem("Token");
    const [flaggedData, setFlaggedData] = useState([]);
    const [flaggedCount, setFlaggedCount] = useState("");

  async function getFlaggedContentData() {
    try {
      const flaggedResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_reported_post_details",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      console.log("flaggedResponse------------",flaggedResponse);
      const ErrorCode = flaggedResponse.data.error_code;
      if (ErrorCode == 200) {
        const _data = flaggedResponse.data.data;
        setFlaggedData(_data);
      } else {

      }
    } catch (err) {
      console.log("get flagged data error-----------", err);
    }
  }

  async function getFlaggedContentCount() {
    try {
      const flaggedCountResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_flagged_content_counts",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = flaggedCountResponse.data.error_code;
      if (ErrorCode == 200) {
        const _data = flaggedCountResponse.data.data;
        setFlaggedCount(_data);
      } else {

      }
    } catch (err) {
      console.log("get flagged data error-----------", err);
    }
  }
  useEffect(() =>{
    getFlaggedContentData();
    getFlaggedContentCount()
    localStorage.setItem('active_index', JSON.stringify(7));
  }, [])

  const offerData = [
    {
      id : 1,
      Reportedby: " Sachin",
      Category: "Hate Speech",
      Post: "I can’t stand South Indians especially malyalis, also their food is shit.",
      Image: "dist/img/GIRL.jpg",
      PostedBy: "Shruti Arora",
      Status: "Hidden",
      time:"1h ago"
    },
    {
           id : 2,
      Reportedby: " Mahesh",
      Category: "Hate Speech",
      Post: " I can’t stand South Indians especially malyalis, also their food is shit.",
      Image: "dist/img/user4.jpg",
      PostedBy: "Pallavi Lamba",
      Status: "Hidden",
      time:"2 days ago"
    },
    {
           id : 3,
      Reportedby: " Sachin",
      Category: "Hate Speech",
      Post: "I can’t stand South Indians especially malyalis, also their food is shit.",
      Image: "",
      PostedBy: "Shruti Arora",
      Status: "Hidden",
      time:"1h ago"
    },
    {
           id : 4,
      Reportedby: " Mahesh",
      Category: "Hate Speech",
      Post: " I can’t stand South Indians especially malyalis, also their food is shit.",
      Image: "dist/img/user3-128x128.jpg",
      PostedBy: "Pallavi Lamba",
      Status: "Hidden",
      time:"1h ago"
    },
    {
           id : 6,
      Reportedby: " Sachin",
      Category: "Hate Speech",
      Post: "I can’t stand South Indians especially malyalis, also their food is shit.",
      Image: "",
      PostedBy: "Shruti Arora",
      Status: "Hidden",
    },
    {
           id : 7,
      Reportedby: " Mahesh",
      Category: "Hate Speech",
      Post: " I can’t stand South Indians especially malyalis, also their food is shit.",
      Image: "",
      PostedBy: "Pallavi Lamba",
      Status: "Hidden",
    },
  ];

  const [tId, setTId] = useState("");
  const openActionsModal = (e) => {
    setTId(e);
    $(".edit_campus_modal").hide();
    $(".actions_modal" + e).toggle();
  };
  const closeActionsModal = (e) => {
    // $(".actions_modal" + tId).hide();
    $(".edit_campus_modal").hide();
  };

  const columns = [
    {
      name: "Reported by ",
      selector: (row) => {
                 return(<>
                  <div style={{fontWeight:"600"}}>{row.reported_by_full_name}</div>
                  <div style={{fontSize:"9px"}}>{row.reported_at}</div>
                  </>)
      },
      sortable: true,
      with: "25%",
    },
    {
      name: "Category",
      selector: (row) => row.category,

      sortable: true,
    },

    {
      name: "Post",
      selector: (row) => {
             return(
              <div style={{width:"130px"}}>{row.f_description}</div>
             )
      },
      sortable: true,
    },
    {

      selector: (row) =>{
        const array = [];
        row.feed_images.map((itemimage) => {
        array.push(itemimage.feed_img);

        });
            return(<>
              {array.length  == 0 ?(
                  <div>
                  <img style={{height:"70px",width:"100px",margin:"2px",objectFit:"contain"}}
                   src="dist/img/event_photo.png"
                   />
                </div>
              ):(
             <div>
               <img style={{height:"70px",width:"100px",margin:"2px",objectFit:"contain"}}
                src={array[0]}
                />
             </div>
             )}
             </> )
      },
      sortable: true,
    },
    {
      name: "Posted by",
      selector: (row) => row.posted_by_full_name,
      sortable: true,
    },
    {
      name: "Status",
      // selector: (row) => row.Status,
      selector: (row) => {
        return(<>
        {row.feed_status == 0 ? (
            <div>Hidden</div>
        ):(
          <div>Keep Post</div>
        )}

         </>)
},
      sortable: true,
    },

    {
      name: "Action",
      width: "auto",
      cell: (row) => {
        return (
          <div className="d-flex">
            <div
              style={{ position: "relative", cursor: "pointer" }}
              onClick={() => openActionsModal(row.report_id)}
            >
              <span
                style={{
                  background: "#4779F0",
                  color: "white",
                  padding: "5px 13px",
                  borderRadius: "5px",
                }}
              >
                Actions
              </span>
            </div>
            <div
              className={`edit_campus_modal actions_modal${row.report_id}`}
              style={{
                display: "none",
                position: "absolute",
                top: "30px",
                right: "73px",
                width:"335px"
              }}
            >
              <div>
                <div className="  ">
                  <div className=" d-flex ml-auto">
                    <img
                      className="campus_img ml-auto"
                      src="dist/img/Cancel.png"
                      onClick={() => closeActionsModal(row.report_id)}
                    />
                  </div>
                </div>
                {row.feed_status == 0 ? (
                <div className=" hover_class p-2"
                        onClick={()=> hidePostFunc(row.f_id)}>
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/ThumbsUp.png"
                      />
                    </div>

                      <div className="campus_inner_div">
                      <div style={{fontSize:"14px",fontWeight:"600"}}>Keep Post</div>
                      <div style={{fontSize:"10px",fontWeight:"500"}}>Disagree with flag and keep the post unchanged</div>

                    </div>


                  </div>
                </div>
                ):(
                <div className=" d-flex flex-row hover_class p-2"
                      onClick={()=> hidePostFunc(row.f_id)}>
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/PostHide.png"
                      />
                    </div>
                    <div className="campus_inner_div">
                      <div style={{fontSize:"14px",fontWeight:"600"}}>Hide Post</div>
                      <div style={{fontSize:"10px",fontWeight:"500"}}>Hide this post and send a warning message</div>
                    </div>
                  </div>
                </div>
                 )}

                   {row.acc_freeze == 0 ?(
                <div className=" d-flex flex-row hover_class p-2"
                     onClick={()=> silentStudentFunc(row.posted_by_id)}
                >
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/PostSilence.png"
                      />
                    </div>
                      <div className="campus_inner_div">
                      <div style={{fontSize:"14px",fontWeight:"600"}}>Silence User</div>
                      <div style={{fontSize:"10px",fontWeight:"500"}}>A silenced user has all posting disabled</div>
                    </div>

                  </div>
                </div>
                 ):(
                  <div className=" d-flex flex-row hover_class p-2"
                    onClick={()=> silentStudentFunc(row.posted_by_id)}
                  >
                  <div className=" d-flex flex-row">
                    <div style={{display:"flex",alignItems:"center"}}>
                      <img
                        className="campus_img"
                        src="dist/img/PostSilence.png"
                      />
                    </div>
                      <div className="campus_inner_div">
                      <div style={{fontSize:"14px",fontWeight:"600"}}>Unfreeze User</div>
                      <div style={{fontSize:"10px",fontWeight:"500"}}>Unfreezed user has all posting enables</div>
                    </div>

                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

    const silentStudentFunc = async (s_id) => {
      try {
          const formData = new FormData();
          formData.append("stud_id",s_id);
        const silentStudentResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_change_student_freez_acc_status",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
        const ErrorCode = silentStudentResponse.data.error_code;
        if (ErrorCode == 200) {
          getFlaggedContentData();
          getFlaggedContentCount();
          $(".edit_campus_modal").hide();
        } else {

        }
      } catch (err) {
        console.log("silence user error-----------", err);
      }
    }

    const hidePostFunc = async (f_id) => {
      try {
          const formData = new FormData();
          formData.append("f_id",f_id);
        const hidePostResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_flagged_content_hide_post",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
        const ErrorCode = hidePostResponse.data.error_code;
        if (ErrorCode == 200) {
          getFlaggedContentData();
          getFlaggedContentCount();
          $(".edit_campus_modal").hide();
        } else {

        }
      } catch (err) {
        console.log("hide post error-----------", err);
      }
    }

  return (
    <div>
      <Header />
      <div className="d-flex">
        <Menu />

        <div className="content-wrapper">
          <div className="border_class">
            <div className="row">
              <div className="col-md-3">
                <section style={{ margin: "10px 5px" }}>
                  <div className="container-fluid table-cards">
                    <div className="table-cards">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ height: "100%", padding: "0px 5px" }}
                        >
                          <div
                            className="small-box"
                            style={{
                              height: "90px",
                              padding: "5px",
                              borderRadius: "5px",
                              display: "flex",
                            }}
                          >
                            <div
                              className="inner"
                              // onClick={UniDetails}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <img
                                  src="dist/img/TwoTickets.png"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>

                              <div>
                                <div>
                                  <h5
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      fontWeight: "700",
                                      fontSize: "20px",
                                      color: "#1F3977",
                                      marginTop: "5px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {flaggedCount.total_flags}
                                  </h5>
                                </div>

                                <div
                                  className="d-flex"
                                  style={{
                                    flexWrap: "wrap",
                                    marginTop: "5px",
                                    fontFamily: "Poppins",

                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  Total Flags
                                  {/* <div>{jobDetails.job_applications}</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="col-md-3">
                <section style={{ margin: "10px 5px" }}>
                  <div className="container-fluid table-cards">
                    <div className="table-cards">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ height: "100%", padding: "0px 5px" }}
                        >
                          <div
                            className="small-box"
                            style={{
                              height: "90px",
                              padding: "5px",
                              borderRadius: "5px",
                              display: "flex",
                            }}
                          >
                            <div
                              className="inner"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <img
                                  src="dist/img/Hourglass.png"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>

                              <div>
                                <div>
                                  <h5
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      fontWeight: "700",
                                      fontSize: "20px",
                                      color: "#1F3977",
                                      marginTop: "5px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {flaggedCount.un_hidden_posts}
                                  </h5>
                                </div>

                                <div
                                  className="d-flex"
                                  style={{
                                    flexWrap: "wrap",
                                    marginTop: "5px",
                                    fontFamily: "Poppins",

                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  False Flags
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="col-md-3">
                <section style={{ margin: "10px 5px" }}>
                  <div className="container-fluid table-cards">
                    <div className="table-cards">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ height: "100%", padding: "0px 5px" }}
                        >
                          <div
                            className="small-box"
                            style={{
                              height: "90px",
                              padding: "5px",
                              borderRadius: "5px",
                              display: "flex",
                            }}
                          >
                            <div
                              className="inner"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <img
                                  src="dist/img/ClosedSign.png"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>

                              <div>
                                <div>
                                  <h5
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      fontWeight: "700",
                                      fontSize: "20px",
                                      color: "#1F3977",
                                      marginTop: "5px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {flaggedCount.hidden_posts}
                                  </h5>
                                </div>

                                <div
                                  className="d-flex"
                                  style={{
                                    flexWrap: "wrap",
                                    marginTop: "5px",
                                    fontFamily: "Poppins",

                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  Hidden Posts
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="col-md-3">
                <section style={{ margin: "10px 5px" }}>
                  <div className="container-fluid table-cards">
                    <div className="table-cards">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ height: "100%", padding: "0px 5px" }}
                        >
                          <div
                            className="small-box"
                            style={{
                              height: "90px",
                              padding: "5px",
                              borderRadius: "5px",
                              display: "flex",
                            }}
                          >
                            <div
                              className="inner"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <img
                                  src="dist/img/Remove.png"
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                />
                              </div>

                              <div>
                                <div>
                                  <h5
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      fontWeight: "700",
                                      fontSize: "20px",
                                      color: "#1F3977",
                                      marginTop: "5px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {flaggedCount.silenced_users}
                                  </h5>
                                </div>

                                <div
                                  className="d-flex"
                                  style={{
                                    flexWrap: "wrap",
                                    marginTop: "5px",
                                    fontFamily: "Poppins",

                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  Restricted Users
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="border_class">
            <DataTable
              // title="Campus List"
              fixedHeader
              fixedHeaderScrollHeight="600px"
              pagination
              columns={columns}
              data={flaggedData}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
