import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiPlusMedical, BiSearchAlt2 } from "react-icons/bi";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JobFilterComponent = ({ filterText, onFilter, onClear }) => (


  <div className="row" style={{ width: "100%", marginLeft: "0", paddingRight: "0PX" }} >
       <div className="row border_class2 search_box_padding" >
       
        <div className="col-md-5 d-flex flex-row">
            <div className="search_box_div">

          <img className="search_box_img"
            src={require("../images/Search.png")}
           
          />
         
          <Input className="search_box"
            id="search"
            type="text"
            placeholder="Search by job title"
            value={filterText}
            onChange={onFilter}
           
           
            
          />
        </div>
        </div>

       
        <div className="col-md-7 d-flex flex-row">
          <div style={{ marginTop: "0px", padding: "0", marginLeft: "auto" }}>
            <Link to="/createJob">
              <button
                type="button"
                className="d-flex create_button"
                defaultValue="Sign Up"
                >
               
                <div className="create_button_inner">
                   Create Job
                </div>
                <img className="create_button_img"
                    src="dist/img/Progress.png"
                    
                  />
              </button>
            </Link>
          </div>
        </div>
      </div>

    {/* <div className="col-md-7 d-flex flex-row" style={{ height: "100%", padding: "0px 5px" }}>
      <h4 style={{ color: "black", fontWeight: "700", marginTop: "7px" }} className="ml-auto" >JOBS</h4>
      <i class="fa fa-bars" style={{ marginLeft: "10PX", marginTop: "7PX" }}></i>
    </div> */}

    {/* <div className="col-md-3 d-flex flex-row" style={{ height: "100%", background: "white", padding: "0", borderRadius: "30PX" }}>
      <BiSearchAlt2 style={{ fontSize: "28PX", verticalAlign: "middle", margin: "3px 0px 0px 3px" }} />
      <Input
        id="search"
        type="text"
        placeholder="Search By Title"
        value={filterText}
        onChange={onFilter}
        style={{ background: "white", height: "32px", width: "100%", border: "none", fontWeight: "600", borderRadius: "30PX" }}
      />
    </div>

    <div className="col-md-2"  >

      <Link to="/createJob" style={{ padding: "0" }}>


        <button
          type="button"
          className="d-flex flex-row buttonContainer"
          defaultValue="Sign Up"

          style={{ padding: "5px", paddingRight: "7px", marginTop: "0", background: "#1F3977", flexWrap: "wrap", borderRadius: "5px", marginLeft: "auto" }}
        >
          
          
          <div style={{ marginLeft: "5px", fontSize: "12.25PX", fontWeight: "400", fontFamily: "Poppins" }}>Create Job</div>
        </button>

      </Link>


    </div> */}


  </div>

);

export default JobFilterComponent;
