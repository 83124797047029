import React,{ useState } from 'react'
import {Header} from "../Header";
import {Menu} from "../Menu";
// import {HelpDesk} from "./HelpDesk";
import {Explore} from "./Explore";

export function HelpDeskDetails() {
    const [loc,setLoc] = useState(window.location.pathname);
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} />
            {/* <HelpDesk /> */}
            <Explore />
        </div>
        </div>
    )
}
