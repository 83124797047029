import React,{ useState } from 'react'
import { Header } from '../Header'
import { Menu } from '../Menu'
import { CampusDirectory } from './CampusDirectory'
export function CampusDirectoryDetails() {
    const [loc,setLoc] = useState(window.location.pathname);
    return (
        <div>
            <Header />
            <div className="d-flex">
            <Menu pageTitle={loc} />
            <CampusDirectory />
        </div>
        </div>
    )
}
