import React, { useState, useEffect } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import $ from "jquery";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const UniversityDetails = () => {
  const token = localStorage.getItem("Token");
  const [loc, setLoc] = useState(window.location.pathname);

  const location = useLocation();
  const history = useHistory();
  const { uni_id } = location.state || { id: "none" };

  const [uniData, setUniData] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [campusList, setCampusList] = useState([]);

  const [campusData, setCampusData] = useState([]);
  const [campStatusMsg, setCampStatusMsg] = useState("");
  const [uniName, setUniName] = useState("");
  const [uniState, setUniState] = useState("");

        // edit states
  const [editCampId,setEditCampId] = useState("");
  const [editCollegeName, setEditCollegeName] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editMobileNumber, setEditMobileNumber] = useState("");
  const [editEmailId, setEditEmailId] = useState("");
  const [editWebsite, setEditWebsite] = useState("");

  async function getCampusData() {
    try {
      const formData = new FormData();
      formData.append("uni_id", uni_id);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_data",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      // console.log("campus response...............", fetchResponse);

      const ErrorCode = fetchResponse.data.error_code;

      if (ErrorCode == 200) {
        const c_data = fetchResponse.data.data;

        setUniData(c_data);
        setUniName(c_data[0].uni_name)
        setUniState(c_data[0].state_name)
        setCampusList(c_data[0].camp_list);

        console.log("u_data........++.......", c_data);
      } else {
        // setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function getCampusDetails(id) {
    setCampusId(id);

    try {
      const formData = new FormData();
      formData.append("camp_id", id);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_campus_data",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );



      const ErrorCode = fetchResponse.data.error_code;

      if (ErrorCode == 200) {
        const c_data = fetchResponse.data.data;
        setCampusData(c_data);

        c_data.map((e)=>{
          setEditCampId(e.campus_id);
         setEditCollegeName(e.campus_name);
         setEditAddress(e.address);
         setEditEmailId(e.email_id);
         setEditMobileNumber(e.mobile_no);
         setEditWebsite(e.campus_website);
        })
      } else {
        // setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function changeCampusStatus(id) {
    try {
      const formData = new FormData();
      formData.append("camp_id", id);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_change_campus_status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const c_status = fetchResponse.data.message;

        setCampStatusMsg(c_status);

        getCampusDetails(id);
        closeEditModal();

        $(".success_msg").show();
        setTimeout(function () {
          $(".success_msg").hide();
        }, 2000);
      } else {
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function changeCampusPaidStatus(id) {
    try {
      const formData = new FormData();
      formData.append("camp_id", id);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_change_campus_paid_status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      // console.log("campus response...............", fetchResponse);

      const ErrorCode = fetchResponse.data.error_code;

      if (ErrorCode == 200) {
        const c_status = fetchResponse.data.message;

        setCampStatusMsg(c_status);

        getCampusDetails(id);
        closeEditModal();

        $(".success_msg").show();
        setTimeout(function () {
          $(".success_msg").hide();
        }, 2000);
      } else {
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const saveUpdate = async () =>{
    try{
    if (editCollegeName == "") {
      $(".edit_uni_name").show();

      setTimeout(function () {
        $(".edit_uni_name").hide();
      }, 3000);
    }else if(editAddress == "") {
      $(".edit_add").show();

      setTimeout(function () {
        $(".edit_add").hide();
      }, 3000);
    }
    else if(editMobileNumber == "" || editMobileNumber == null) {
      $(".edit_mobile").show();

      setTimeout(function () {
        $(".edit_mobile").hide();
      }, 3000);
    }else if(editEmailId == "" || editEmailId == null) {
      $(".edit_email").show();

      setTimeout(function () {
        $(".edit_email").hide();
      }, 3000);
    }else if(editWebsite == "") {
      $(".edit_website").show();

      setTimeout(function () {
        $(".edit_website").hide();
      }, 3000);
    }
    else{

        const formData = new FormData();
        formData.append("campus_id",editCampId);
        formData.append("campus_name",editCollegeName);
        formData.append("address",editAddress);
        formData.append("email_id",editEmailId);
        formData.append("mobile_no",editMobileNumber);
        formData.append("website",editWebsite);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_edit_campus",
       formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

         if(fetchResponse.data.error_code == 200){
            $(".preview_category").hide();
            $('.edit_campus_modal').hide();
            getCampusData();
            getCampusDetails(campusId);
            resetValues();
      }
    }
  }catch(err){}
  }

  const resetValues =() =>{
    // setEditCampId("");
    setEditCollegeName("");
    setEditAddress("");
    setEditEmailId("");
    setEditMobileNumber("");
    setEditWebsite("");
  }

  useEffect(() => {
    getCampusData();
  }, []);

  function viewAllAdmins() {
    history.push("/admindetails",{campusId});
  }
  function viewAllStudents() {
    history.push("/student",{campusId});
  }

  function sendCampId() {
    history.push("/createCampusDetails",{uni_id,uniName,uniState});
  }

  const openEditModal = () => {
    $(".edit_campus_modal").toggle();
  };
  const closeEditModal = () => {
    $(".edit_campus_modal").hide();
    resetValues();
  };
  function addStudents() {
    $(".add_std_modal").toggle();
  }

  function close_student_modal() {
    $(".preview_category").hide();
  }

  function addFacuty() {
    $(".add_faculty_modal").toggle();
  }

  function openEditForm(u_id) {
    $(".preview_category").show();
    $('.edit_campus_modal').hide();
  }

  function closeEditForm() {
    $(".preview_category").hide();
    resetValues();
  }

  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu pageTitle={loc} />

          <div className="content-wrapper">
            {/* <Link to="/universities">
              <i
                className="fas fa-arrow-alt-circle-left"
                style={{ fontSize: "21px", color: "black" }}
              ></i>
            </Link> */}
            <div className="card_div" style={{ width: "100%" }}>
              <div className="card-header p-0">
                <div
                  className="bg-white"
                  style={{ borderRadius: "5px", paddingBottom: "50px" }}
                >
                  <div className="">
                    <div>
                      <div style={{ width: "100%" }}>
                      {uniData.map((e) => (
                        <img
                          className="cover_image"
                          src={e.uni_cover_photo}
                          style={{height:"260px",width:"100%"}}
                        />
                        ))}
                      </div>

                      <div>
                        <div>
                          {uniData.map((e) => (
                          <img
                            style={{
                              width: "110px",
                              height: "120px",
                              marginTop: "-55px",
                              marginLeft: "40px",
                              background: "#F1F1F1",
                              borderRadius: "5px",
                            }}
                            src={e.uni_logo}
                          />
                          ))}

                        </div>

                        <div style={{ margin: "15px 25px 0px 40px" }}>
                          {uniData.map((e) => {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <p>{e.uni_name}</p>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        display: "inline-flex",
                                      }}
                                    >
                                      <p style={{ marginRight: "5px" }}>
                                        {e.total_user}
                                      </p>
                                      <p>Users</p>
                                    </div>
                                  </div>
                                </div>

                                <div class="row mt-3">
                                  <div class="col-md-6">
                                    <div
                                      className=""
                                      style={{
                                        width: "100%",
                                        marginTop: "0px",
                                      }}
                                    >
                                      {/* <label  style={{ color: "#1F3977", fontSize: "12px", fontWeight: "bold" }}>Campus Name <span className='text-danger'>*</span> </label> */}
                                      <div className="d-flex">
                                        <label className="all_labels">
                                          Select University/College
                                        </label>
                                      </div>
                                      <select
                                        className="form-select form-select-sm input_box"
                                        aria-label=".form-select-sm example"
                                        id="campus_name"
                                        onChange={(e) =>
                                          getCampusDetails(e.target.value)
                                        }
                                        required
                                      >
                                        <option
                                          selected="selected"
                                          disabled
                                          value={""}
                                          // value={campusName}
                                        >
                                          Select Campus
                                        </option>
                                        {campusList.map((item) => {
                                          return (
                                            <option
                                              key={item.camp_id}
                                              value={item.camp_id}
                                            >
                                              {item.camp_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <div
                                        class="Campus_name"
                                        style={{ margin: "0", display: "none" }}
                                      >
                                        <h4
                                          class="login-text"
                                          style={{
                                            color: "red",
                                            fontSize: "12PX",
                                            marginLeft: "0",
                                          }}
                                        >
                                          Please Select Campus Name
                                        </h4>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-2 p-0"
                                    style={{
                                      display: "flex",
                                      alignItems: "end",
                                    }}
                                  >
                                    <div>
                                      <button onClick={sendCampId} style={{border:"none",background:"white",padding:"0px"}}>
                                        <img
                                          src={require("../images/add.png")}
                                          style={{
                                            height: "25px",
                                            width: "25px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}

                          <div
                            class="success_msg"
                            style={{
                              marginTop: "5px",
                              display: "none",
                              width: "100%",
                              marginBottom: "5px",
                            }}
                          >
                            <Stack sx={{ width: "100%" }} spacing={2}>
                              <Alert variant="filled" severity="success">
                                {campStatusMsg}
                              </Alert>
                            </Stack>
                          </div>

                          <div className="mt-2">
                            <hr />
                          </div>

                          {/* {
                            campusData==""?(<> */}
                          {/* <div className="border_class" style={{height:"100px",display:"flex",
                            alignItems:"center",justifyContent:"center"}}>
                                <p style={{fontSize:"17px",fontWeight:"400"}}>No data to display</p>
                              </div> */}
                          {/* </>):
                            (<> */}

                          {campusData.map((e) => (
                            <div className="mt-3">

                              <div
                                className="d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <div>
                                  <div>
                                    <div>
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <p className="m-0">{e.campus_name}</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="">
                                    <span
                                      style={{
                                        fontSize: "11px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {e.address}
                                    </span>

                                    <div
                                      className="mt-2"
                                      style={{
                                        fontSize: "11px",

                                        fontWeight: "600",
                                        display: "flex",
                                      }}
                                    >
                                      <span>Mobile No :</span>
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          color: "#3A6BD2",
                                        }}
                                      >
                                        {e.mobile_no}
                                      </span>
                                      <span className="info_bar">|</span>
                                      <span>Email :</span>
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          color: "#3A6BD2",
                                        }}
                                      >
                                        {e.email_id}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="mt-2"
                                    style={{
                                      fontSize: "11px",

                                      fontWeight: "600",
                                      display: "flex",
                                    }}
                                  >
                                    <span>Website :</span>
                                    <span
                                      style={{
                                        color: "#3A6BD2",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {e.campus_website}
                                    </span>
                                  </div>

                                  {e.paid_status == 1 ? (
                                    <div style={{ marginTop: "20px" }}>
                                      <span
                                        style={{
                                          background: "green",
                                          color: "white",
                                          padding: "5px 30px",
                                          borderRadius: "5px",
                                          fontSize:"11px",
                                          fontWeight:"500"
                                        }}
                                      >
                                        Paid
                                      </span>
                                    </div>
                                  ) : (
                                    <div style={{ marginTop: "20px" }}>
                                      <span
                                        style={{
                                          background: "red",
                                          color: "white",
                                          padding: "5px 30px",
                                          borderRadius: "5px",
                                          fontSize:"11px",
                                          fontWeight:"500"
                                        }}
                                      >
                                        Unpaid
                                      </span>
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex">
                                  {e.status == 1 ? (
                                    <div>
                                      <button
                                        style={{
                                          background: "green",
                                          border: "none",
                                          color: "white",
                                          padding: "5px 30px",
                                          borderRadius: "5px",
                                          fontSize:"11px",
                                          fontWeight:"500"
                                        }}
                                      >
                                        Active
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      <button
                                        style={{
                                          background: "red",
                                          border: "none",
                                          color: "white",
                                          padding: "5px 30px",
                                          borderRadius: "5px",
                                          fontSize:"11px",
                                          fontWeight:"500"
                                        }}
                                      >
                                        Deactive
                                      </button>
                                    </div>
                                  )}
                                  <div style={{
                                      position: "relative",
                                      cursor: "pointer",
                                    }}>
                                  <div

                                    onClick={openEditModal}
                                  >
                                    <img
                                      src="dist/img/menu2-512.jpg"
                                      style={{ height: "25px", width: "25px" }}
                                    />
                                    </div>
                                    {/* Edit campus modal */}
                                    <div
                                      class="edit_campus_modal"
                                      id=""
                                      style={{
                                        display: "none",
                                        position: "absolute",
                                      }}
                                    >
                                      <div className="  ">
                                        <div className=" d-flex ml-auto">
                                          <img
                                            className="campus_img ml-auto"
                                            src="dist/img/Cancel.png"
                                            onClick={closeEditModal}
                                          />
                                        </div>
                                      </div>

                                      <div className="  hover_class">
                                        <div className=" d-flex flex-row" onClick={()=> openEditForm(e.campus_id)}>
                                          <div>
                                            <img
                                              className="campus_img"
                                              src="dist/img/campusEdit.png"
                                            />
                                          </div>
                                          <div className="campus_inner_div">
                                            <span>Edit</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className=" d-flex flex-row hover_class">
                                        <div className=" d-flex flex-row">
                                          <div>
                                            <img
                                              className="campus_img"
                                              src="dist/img/NoEntry.png"
                                            />
                                          </div>
                                          {e.status == 0 ? (
                                            <button
                                              className="campus_inner_div"
                                              onClick={() =>
                                                changeCampusStatus(e.campus_id)
                                              }
                                              style={{ border: "none" }}
                                            >
                                              <span>Active</span>
                                            </button>
                                          ) : (
                                            <div
                                              className="campus_inner_div"
                                              onClick={() =>
                                                changeCampusStatus(e.campus_id)
                                              }
                                              style={{ border: "none" }}
                                            >
                                              <span>Deactivate</span>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className=" d-flex flex-row hover_class">
                                        <div className=" d-flex flex-row">
                                          <div>
                                            <img
                                              className="campus_img"
                                              src="dist/img/NoEntry.png"
                                            />
                                          </div>
                                          {e.paid_status == 0 ? (
                                            <button
                                              className="campus_inner_div"
                                              onClick={() =>
                                                changeCampusPaidStatus(e.campus_id)
                                              }
                                              style={{ border: "none" }}
                                            >
                                              <span>Paid</span>
                                            </button>
                                          ) : (
                                            <div
                                              className="campus_inner_div"
                                              onClick={() =>
                                                changeCampusPaidStatus(e.campus_id)
                                              }
                                              style={{ border: "none" }}
                                            >
                                              <span>Unpaid</span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    </div>

                                </div>
                              </div>

                              <div className="mt-4">
                                <div className="row">
                                  <div className="col-md-4 p-0">
                                    <section style={{ margin: "10px 5px" }}>
                                      <div className="container-fluid table-cards">
                                        <div className="table-cards">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              style={{
                                                height: "100%",
                                                padding: "0px 5px",
                                              }}
                                            >
                                              <div
                                                className="small-box"
                                                style={{
                                                  height: "65px",
                                                  borderRadius: "2.5PX",
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  className="inner"
                                                  // onClick={UniDetails}
                                                  style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div>
                                                    <div>
                                                      <span  className="eleven_font_class"
                                                        style={{ color: "#1F3977"}}
                                                      >
                                                        Users
                                                      </span>
                                                    </div>

                                                    <div
                                                      className="d-flex sixteen_font_class"
                                                      style={{
                                                        flexWrap: "wrap",
                                                        marginTop: "5px"
                                                      }}
                                                    >
                                                      {campusData.map(
                                                        (e) => (
                                                          (
                                                            <div>
                                                              {" "}
                                                              {e.total_user}
                                                            </div>
                                                          )
                                                        )
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div>

                                                        <button
                                                         onClick={viewAllStudents}
                                                          type="button"
                                                          style={{
                                                            background:
                                                              "#3A6BD2",
                                                            color: "#ffffff",
                                                            height: "28px",
                                                            width: "100px",
                                                            fontSize: "11px",
                                                            fontWeight: "500",
                                                            border: "none",
                                                            borderRadius: "5px",
                                                          }}
                                                        >
                                                          View All
                                                        </button>

                                                    </div>

                                                    {/* <div className=" mt-1">
                                                    <button
                                                      type="button"
                                                      className="d-flex add_faq_button"
                                                      defaultValue="Sign Up"
                                                      style={{
                                                        justifyContent: "end",
                                                        position: "relative",
                                                        height: "30px",
                                                      }}
                                                      onClick={addStudents}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        Add students
                                                      </span>
                                                      <div
                                                        style={{
                                                          marginLeft: "5px",
                                                          fontSize: "12.25PX",
                                                          fontWeight: "400",
                                                          fontFamily: "Poppins",
                                                        }}
                                                      ></div>
                                                      <img
                                                        src="dist/img/AddNew.png"
                                                        style={{
                                                          width: "20px",
                                                          height: "20px",
                                                          marginLeft: "13px",
                                                        }}
                                                      />
                                                    </button>
                                                    <div
                                                      class="add_students_modal add_std_modal"
                                                      id="add_modal1"
                                                      style={{
                                                        display: "none",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <div className="  hover_class">
                                                        <Link
                                                          to="/studentform"
                                                          style={{
                                                            display: "flex",
                                                            padding: "5px 10px",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div className=" d-flex">
                                                            <img
                                                              src="dist/img/CollaboratorMale.png"
                                                              style={{
                                                                width: "15px",
                                                                height: "15px",
                                                              }}
                                                            />
                                                          </div>
                                                          <div
                                                            style={{
                                                              marginLeft: "5px",
                                                              color: "#000000",
                                                              fontSize: "11PX",
                                                              lineHeight:
                                                                "18px",
                                                              fontWeight: "500",
                                                              fontFamily:
                                                                "Poppins",
                                                              fontStyle:
                                                                "normal",
                                                            }}
                                                          >
                                                            Add a student
                                                          </div>
                                                        </Link>
                                                      </div>

                                                      <div className=" d-flex  hover_class">
                                                        <a
                                                          href="#addStudents"
                                                          style={{
                                                            display: "flex",
                                                            padding: "5px 10px",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div className=" d-flex flex-row">
                                                            <img
                                                              src="dist/img/UserAccount.png"
                                                              style={{
                                                                width: "15px",
                                                                height: "15px",
                                                              }}
                                                            />
                                                          </div>
                                                          <div
                                                            style={{
                                                              marginLeft: "5px",
                                                              color: "#000000",
                                                              fontSize: "11PX",
                                                              lineHeight:
                                                                "18px",
                                                              fontWeight: "500",
                                                              fontFamily:
                                                                "Poppins",
                                                              fontStyle:
                                                                "normal",
                                                            }}
                                                          >
                                                            Add multiple student
                                                          </div>
                                                        </a>
                                                      </div>
                                                    </div>

                                                  </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>

                                  <div className="col-md-4">
                                    <section style={{ margin: "10px 5px" }}>
                                      <div className="container-fluid table-cards">
                                        <div className="table-cards">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              style={{
                                                height: "100%",
                                                padding: "0px 5px",
                                              }}
                                            >
                                              <div
                                                className="small-box"
                                                style={{
                                                  height: "65px",
                                                  borderRadius: "2.5PX",
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  className="inner"
                                                  // onClick={UniDetails}
                                                  style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div>
                                                    <div>
                                                      <span className ="eleven_font_class"
                                                        style={{ color: "#1F3977"}}
                                                      >
                                                        Admins
                                                      </span>
                                                    </div>

                                                    <div
                                                      className="d-flex sixteen_font_class"
                                                      style={{
                                                        flexWrap: "wrap",
                                                        marginTop: "5px"
                                                      }}
                                                    >
                                                      {campusData.map((e) => (
                                                        <div>
                                                          {" "}
                                                          {e.total_admins}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >

                                                      <button
                                                        onClick={viewAllAdmins}
                                                        type="button"
                                                        style={{
                                                          background: "#3A6BD2",
                                                          color: "#ffffff",
                                                          height: "28px",
                                                          width: "100px",
                                                          fontSize: "11px",
                                                          fontWeight: "500",
                                                          border: "none",
                                                          borderRadius: "5px",
                                                        }}
                                                      >
                                                        View All
                                                      </button>

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>

                                  {/* <div className="col-md-4 p-0">
                                    <section style={{ margin: "10px 5px" }}>
                                      <div className="container-fluid table-cards">
                                        <div className="table-cards">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              style={{
                                                height: "100%",
                                                padding: "0px 5px",
                                              }}
                                            >
                                              <div
                                                className="small-box"
                                                style={{
                                                  height: "90px",
                                                  padding: "5px",
                                                  borderRadius: "2.5PX",
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  className="inner"

                                                  style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div>
                                                    <div>
                                                      <h5
                                                        style={{
                                                          fontWeight: "600",
                                                          fontSize: "14px",
                                                          color: "#1F3977",
                                                          marginTop: "5px",
                                                          fontFamily: "Poppins",
                                                        }}
                                                      >
                                                        Faculty
                                                      </h5>
                                                    </div>

                                                    <div
                                                      className="d-flex"
                                                      style={{
                                                        flexWrap: "wrap",
                                                        marginTop: "5px",
                                                        fontFamily: "Poppins",

                                                        fontWeight: "700",
                                                        fontSize: "20px",
                                                      }}
                                                    >
                                                      {campusData.map((e) => (
                                                        <div>
                                                          {" "}
                                                          {e.total_teachers}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div>
                                                      <Link to="/teacher">
                                                        <button
                                                          type="button"
                                                          style={{
                                                            background:
                                                              "#3A6BD2",
                                                            color: "#ffffff",
                                                            height: "35px",
                                                            width: "100px",
                                                            fontSize: "13px",
                                                            fontWeight: "400",
                                                            border: "none",
                                                            borderRadius: "5px",
                                                          }}
                                                        >
                                                          View All
                                                        </button>
                                                      </Link>
                                                    </div>


                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div> */}
                                </div>


                              </div>

                              {/* add student modal */}
                              <div
                                id="addStudents"
                                className="student_add_new_modal"
                              >
                                <div
                                  className="student_inner_div border_class"
                                  style={{ width: "380px" }}
                                >
                                  <div
                                    className="card-body"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          color: "black",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Import Students
                                      </p>
                                      <br></br>
                                      <div>
                                        <p
                                          style={{
                                            fontWeight: "300",
                                            color: "black",
                                            fontSize: "13px",
                                          }}
                                        >
                                          To import students, select a CSV or
                                          vCard file.
                                        </p>
                                      </div>
                                      <div class="row">
                                        <div class="" style={{ padding: "0" }}>
                                          <div
                                            style={{
                                              width: "100%",
                                              marginTop: "30px",
                                              paddingRight: "0",
                                              height: "85px",
                                            }}
                                          >
                                            <label for="file-ip-1">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  justifyContent:
                                                    "space-around",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    background: "#1F3977",
                                                    color: "#FFFFFF",
                                                    height: "50px",
                                                    width: "110px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "6px",
                                                    fontWeight: "300",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Select file
                                                </div>
                                                <div
                                                  id="file-ip-1-preview"
                                                  style={{
                                                    display: "block",
                                                    width: "200px",
                                                    overflow: "auto",
                                                    marginLeft: "13px",
                                                    paddingTop: "8px",
                                                  }}
                                                ></div>
                                              </div>
                                            </label>
                                            <input
                                              type="file"
                                              // onChange={(e)=>getImage(e)}
                                              id="file-ip-1"
                                              accept=".pdf,.xlsx,.xls,application/vnd.ms-excel"
                                              className="input_fields"
                                              name="birthdaytime"
                                              style={{
                                                visibility: "hidden",
                                                color: "black",
                                                fontSize: "11px",
                                                width: "100%",
                                                marginTop: "20px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            class="error_modal"
                                            style={{
                                              marginTop: "5px",
                                              display: "none",
                                              width: "97%",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <Stack
                                              sx={{ width: "100%" }}
                                              spacing={2}
                                            >
                                              <Alert
                                                variant="filled"
                                                severity="error"
                                              >
                                                Please select file
                                              </Alert>
                                            </Stack>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="d-flex mt-3">
                                        <a
                                          onClick={close_student_modal}
                                          href="#"
                                          style={{ marginLeft: "auto" }}
                                        >
                                          <input
                                            type="button"
                                            className=""
                                            value="Cancel"
                                            style={{
                                              background: "#ffffff",
                                              border: "none",
                                              color: "#4779F0",
                                            }}
                                          />
                                        </a>

                                        <input
                                          type="button"
                                          value="Import"
                                          // onClick={() => uploadExcel()}
                                          style={{
                                            background: "#ffffff",
                                            border: "none",
                                            color: "#4779F0",
                                            marginLeft: "35px",
                                          }}
                                        />

                                        <div
                                          class="formError"
                                          style={{
                                            marginTop: "-6px",
                                            display: "none",
                                          }}
                                        >
                                          <h4
                                            class="login-text"
                                            style={{
                                              color: "red",
                                              fontSize: "10PX",
                                              marginLeft: "0",
                                            }}
                                          >
                                            Please Select the File
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* add student modal end */}

                              {/* add faculty modal */}
                              {/* <div
                                id="addFaculty"
                                className="student_add_new_modal"
                              >
                                <div
                                  className="student_inner_div border_class"
                                  style={{ width: "380px" }}
                                >
                                  <div
                                    className="card-body"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          color: "black",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Import Faculty
                                      </p>
                                      <br></br>
                                      <div>
                                        <p
                                          style={{
                                            fontWeight: "300",
                                            color: "black",
                                            fontSize: "13px",
                                          }}
                                        >
                                          To import students, select a CSV or
                                          vCard file.
                                        </p>
                                      </div>
                                      <div class="row">
                                        <div class="" style={{ padding: "0" }}>
                                          <div
                                            style={{
                                              width: "100%",
                                              marginTop: "30px",
                                              paddingRight: "0",
                                              height: "85px",
                                            }}
                                          >
                                            <label for="file-ip-1">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  justifyContent:
                                                    "space-around",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    background: "#1F3977",
                                                    color: "#FFFFFF",
                                                    height: "50px",
                                                    width: "110px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "6px",
                                                    fontWeight: "300",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Select file
                                                </div>
                                                <div
                                                  id="file-ip-1-preview"
                                                  style={{
                                                    display: "block",
                                                    width: "200px",
                                                    overflow: "auto",
                                                    marginLeft: "13px",
                                                    paddingTop: "8px",
                                                  }}
                                                ></div>
                                              </div>
                                            </label>
                                            <input
                                              type="file"
                                              // onChange={(e)=>getImage(e)}
                                              id="file-ip-1"
                                              accept=".pdf,.xlsx,.xls,application/vnd.ms-excel"
                                              className="input_fields"
                                              name="birthdaytime"
                                              style={{
                                                visibility: "hidden",
                                                color: "black",
                                                fontSize: "11px",
                                                width: "100%",
                                                marginTop: "20px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            class="error_modal"
                                            style={{
                                              marginTop: "5px",
                                              display: "none",
                                              width: "97%",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <Stack
                                              sx={{ width: "100%" }}
                                              spacing={2}
                                            >
                                              <Alert
                                                variant="filled"
                                                severity="error"
                                              >
                                                Please select file
                                              </Alert>
                                            </Stack>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="d-flex mt-3">
                                        <a
                                          onClick={close_student_modal}
                                          href="#"
                                          style={{ marginLeft: "auto" }}
                                        >
                                          <input
                                            type="button"
                                            className=""
                                            value="Cancel"
                                            style={{
                                              background: "#ffffff",
                                              border: "none",
                                              color: "#4779F0",
                                            }}
                                          />
                                        </a>

                                        <input
                                          type="button"
                                          value="Import"
                                          // onClick={() => uploadExcel()}
                                          style={{
                                            background: "#ffffff",
                                            border: "none",
                                            color: "#4779F0",
                                            marginLeft: "35px",
                                          }}
                                        />

                                        <div
                                          class="formError"
                                          style={{
                                            marginTop: "-6px",
                                            display: "none",
                                          }}
                                        >
                                          <h4
                                            class="login-text"
                                            style={{
                                              color: "red",
                                              fontSize: "10PX",
                                              marginLeft: "0",
                                            }}
                                          >
                                            Please Select the File
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              {/* add faculty modal end */}
                            </div>
                          ))}
                          {/* </>)
                          } */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
        className="preview_category">
        <div className="edit_inner">
          <div className="d-flex edit_inner_div">
            <label className="main_labels">
              {editCollegeName}
            </label>

            <img
              src="dist/img/Cancel.png"
              alt="dropdown"
              className="close_event ml-auto cancel_img"
              onClick={closeEditForm}

            />
          </div>

          <div
            className="preview_form">
              <div className="border_class edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        University/College Name
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={editCollegeName}
                      onChange={(e) => setEditCollegeName(e.target.value)}
                    />

                    <div
                      class="edit_uni_name"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter college name
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Address
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={editAddress}
                      onChange={(e) => setEditAddress(e.target.value)}

                    />

                    <div
                      class="edit_add"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter address name
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Mobile Number
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="number"
                      autoComplete="true"
                      value={editMobileNumber}
                      onChange={(e) => setEditMobileNumber(e.target.value)}

                    />

                    <div
                      class="edit_mobile"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter mobile number
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Email Id
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="email"
                      autoComplete="true"
                      value={editEmailId}
                      onChange={(e) => setEditEmailId(e.target.value)}

                    />

                    <div
                      class="edit_email"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter email id
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Website
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={editWebsite}
                      onChange={(e) => setEditWebsite(e.target.value)}

                    />

                    <div
                      class="edit_website"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter Website
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>



            {/* ******************button********************** */}

            <div className="d-flex mt-3 edit_buttons_div border_class"
             style={{justifyContent:"end"}}>
              <input
                type="button"
                className="edit_cancel_button"
                onClick={() => closeEditForm()}
                value="Cancel"

              />

              <input
                type="button"
                className="edit_update_button"
                id="delete_single_student"
                value="Update"
                onClick={() => saveUpdate()}

              />
            </div>

            <div style={{ display: "none" }} className="saveMessage">
              Data Saved Successfully
            </div>
          </div>
        </div>
      </div>


          </div>
        </div>
      </div>
    </>
  );
};
