import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import FilterComponent from "./FilterComponent";
import {AiFillEdit} from "react-icons/ai"
import {MdDelete} from "react-icons/md"
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import $ from 'jquery';

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const customStyles = {
  rows: {
    style: {
      padding: "10px 0 10px 15px",
      border: "none",
    },
  },

  head: {
    style: {
      padding: "2px 0 10px 15px",
      fontWeight: "600",
      color: "black",
    },
  },
  table: {
    style: {
      padding: "0",
    },
  },
};

export function CampusDirectory(props) {
  const token = localStorage.getItem("Token");
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  // const[adminName,setAdminName] = useState([]);
  
  async function fetchList() {
    console.log("Access Token-", token);
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_get_directory_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      console.log("Get Campus Directory...........................", fetchResponse);
      const campusListArry = fetchResponse.data.data;
      console.log("Campus listArry", campusListArry);
      setData(campusListArry);
     
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  
  useEffect(() => {
    fetchList();
  }, []);

  const paginationComponentOptions = {
    selectAllRowsItem: true,

    selectAllRowsItemText: "ALL"
  };

  data.map((infoItem) =>
  {
    console.log("asbjkasbjkfjkf",infoItem.information);
    infoItem.information.map((item) =>
    {
      console.log("INFORMATION",item)
      // setInfo(item);
    })
    
  })


  async function changeStatus(id)
  {
   try{
      const formData = new FormData();
      formData.append("c_id", id);
    
      
      const StatusResponse =  await axios.post(
        process.env.REACT_APP_API_KEY + "super_change_status_campus",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            
            Authorization: token,
          },
        }
      );
        if(StatusResponse.data.error_code==200)
        {
          setErrorMsg(StatusResponse.data.message);
          $(".formSuccess").show();
          setTimeout(()=>{
            $(".formSuccess").hide();
          }, 5000);
            window.location.reload();
        }

      } catch (err) {
        console.log("Log in Fail", err);
      }
  }
  const columns = [
    {
      name: "Campus Id",
      selector: "information.profile",
      sortable: true,
      wrap: true,
      width: "auto",
      // cell: (row) =>{
      //   console.log("ROW",row);
      //   return(
      //     <div>
            
      //       <img src="dist/img/boy.jpg" alt="No Image To Display" style={{width:"60px", height:"50px"}} />
      //                     </div>

      //   )
      // } 

      cell: (row) =>  row.campus_id
      
      // {
        
      //   var newVariableProfile = "";
      //    console.log("ROW DATA",row.information)
      //    const array =row.information;
      //    array.forEach(Info => {
      //      console.log("name 1",Info.profile)
      //      newVariableProfile =Info.profile
      //    });
      //    console.log("Profile",newVariableProfile)
      // return(
      //   <div>
      //       { newVariableProfile === undefined ? (
      //     <img src="dist/img/boy.jpg" width="50px" height="50px"></img>
      //   ) : (
      //     <img src={newVariableProfile} alt="profile"  style={{width:"60px", height:"50px"}}/>
      //   )}
      //     </div>
      // )  
      
      //   }
    },
    {
      name: "Campus Name",
      selector: "campus_name",
      sortable: true,
      wrap: true,
      width: "auto",
    },
    
    {
      name: " Admin",
      selector: 'address',
      sortable: true,
      width: "auto",
      wrap: true,
     cell: (row) =>{
      var newVariable = [];
      // console.log("ROW DATA",row.information)
       const array =row.information;
       array.forEach(Info => {
        // console.log("name 1",Info.name)
         newVariable.push(Info.name);
       });
       //console.log("New Variable",newVariable)
    return(
      <div>
        {/* {newVariable.join(',')} */}
        {newVariable.map((name,index) => {
               return <li>{name}</li>
             }
                
              )} 
        </div>
    )  
      
      }
    },
    {
      name: "Contact",
      // selector: "city",
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) =>{
        var newContactVariable = [];
         console.log("ROW DATA",row.information)
         const array =row.information;
         array.forEach(Info => {
           console.log("name 1",Info.mobile)
           newContactVariable.push(Info.mobile);
         });
         console.log("New Variable",newContactVariable)
      return(
        <div>
          {/* {newContactVariable.join(',')} */}
          {newContactVariable.map((name,index) => {
               return <li>{name}</li>
             }
                
              )} 
          </div>
      )  
        
        }
    },
    // {
    //   name: "Email",
    //   // selector: "state",
    //   sortable: true,
    //   wrap: true,
    //   width: "auto",
    //   cell: (row) =>{
    //     var newEmailVariable = [];
    //      console.log("ROW DATA",row.information)
    //      const array =row.information;
    //      array.forEach(Info => {
    //        console.log("name 1",Info.email)
    //        newEmailVariable.push(Info.email);
    //      });
    //      console.log("New Variable",newEmailVariable)
    //   return(
    //     <div>
    //       {/* {newEmailVariable.join(',')} */}
    //        {newEmailVariable.map((name,index) => {
    //            return <li>{name}</li>
    //          }
                
    //           )}  
    //       </div>
    //   )  
        
    //     }
    // },
    {
      name: 'Status',
      selector: 'campus',
      sortable: true,
      wrap: true,
      width:"auto",
      cell: (row) =>{
      
        return(
         <div className="d-flex">
     
            {row.status == 0 ?
            (
              <button
              type="button"
              id="statusChangeButton"
              className="create_btn form-buttons3"
              defaultValue="Sign Up"
            
              onClick={() => changeStatus(row.campus_id)}
              style={{
                borderRadius: "5px",
                backgroundColor: "red",
                padding: "10px",
                
                fontSize: "12PX",
                fontWeight: "bold",
              }}
             >
               Deactive
               </button>
            ):
            (
              <button
              type="button"
              id="statusChangeButton"
              className="create_btn form-buttons3"
              defaultValue="Sign Up"
            
              onClick={() => changeStatus(row.campus_id)}
              style={{
                borderRadius: "5px",
                backgroundColor: "green",
                padding: "10px 17px",
                fontSize: "12PX",
                fontWeight: "bold",
              }}
             >
               Active
               </button>
            )
      }                  
            
  </div>

        )
      } 
    }
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      // <FilterComponent
      //   onFilter={(e) => setFilterText(e.target.value)}
      //   onClear={handleClear}
      //   filterText={filterText}
      // />
      <div></div>
    );
  }, [filterText, resetPaginationToggle]);
  return (
    // style={{height:"100%",maxHeight:"100%"}} //this style was not working but the bootstrap height is working
    <div className="content-wrapper" >

     <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
      </div>

       <div
        className="row m-0 border_class"
       
      >
        <div
          className="col-md-8 d-flex flex-row"
          style={{ height: "100%", padding: "0px 5px" }}
        >
          <h4 style={{ color: "black", fontWeight: "600", marginTop: "7px" }}>
            Campus Directory
          </h4>
        </div>

        <div
          className="col-md-3 d-flex flex-row"
          style={{
            height: "100%",
            background: "white",
            padding: "0",
            border: "1px solid lightgrey",
          }}
        >
          <img
            src={require("../images/Search.png")}
            style={{ width: "21px", height: "21px", margin: "5px 0px 0px 3px" }}
          />
         
          <Input
            id="search"
            type="text"
            placeholder="Search by campus name"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{
              border: "none",
              background: "white",
              height: "32px",
              width: "100%",
              fontWeight: "500",
              fontSize: "12PX",
              paddingLeft: "5px",
            }}
          />
        </div>

        <div className="col-md-1 d-flex flex-row">
          {/* <img
            src="dist/img/Sorting.png"
            alt="view"
            style={{ width: "28px", height: "28px" }}
            className="sort_table"
            onClick={fetchList}
          /> */}
        </div>
       
      </div>

 <div className="border_class">
<DataTable
          columns={columns}
          data={filteredItems}
          striped
          paginationPerPage={10}
          pagination
          // paginationRowsPerPageOptions={[10,20,30,40,50,60,70,80,90,100]}
          // paginationComponentOptions={paginationComponentOptions}
          // subHeader
          subHeaderComponent={subHeaderComponent}
          highlightOnHover
          defaultSortFieldId={1}
          customStyles={customStyles}
        />
        </div>
     
      {/* <DirectoryTable /> */}
    </div>
  );
}
