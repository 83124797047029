import React, { useState, useEffect } from "react";
import {BsFillSquareFill} from "react-icons/bs"
import axios from "axios";
import $ from "jquery"
import {IoAnalyticsOutline} from "react-icons/io5"
import {IoIosPersonAdd} from "react-icons/io"
import {FiMail} from "react-icons/fi"
// import {RightPart} from "./RightPart"
export function Explore() {
  const token = localStorage.getItem("Token");
  const [data, setData] = useState([]);
  async function fetchList()
  {
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_get_help_desk_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      console.log("Help desk tickets", fetchResponse);
      const HelpDeskList = fetchResponse.data.data;
      console.log("Help Desk listArry", HelpDeskList);
      setData(HelpDeskList);
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }
  useEffect(() => {
    fetchList();
    
  }, []);



    return (
        <div className="content-wrapper mx-auto" style={{ background: "#ebedef" }}>
          
          <div className="form-group" style={{marginLeft:"10px", marginRight:"10px"}}>
        <h1 style={{marginTop:"10PX",fontWeight:"bold",fontSize:"14PX"}}>HELP DESK</h1>
        <div style={{ background:"rgb(235, 237, 239)",width:"80%"}}>


        <div style={{width:"100%", marginTop:"0px"}}>
  <section className="mt-2" >
            <div className="container-fluid table-cards">
              <div class="table-cards">
              {data.map((item, index) => {
                console.log("helpdesk item",item.admin_name[0]);
                return(
                <div>
 {/* 1st row */}
 <div className="row mt-2" style={{width:"100%",borderLeft:"6PX SOLID GREY",background:"white"}}>

<div className='col-md-1'>
<div className="d-flex flex-row mt-3">
<input type="checkbox" className='mt-2'/>
<div style={{border:"1px solid #d3eac7",color: "#bfd0ae",padding:"12px", borderRadius:"5PX",background:"#d3eac7",height:"40px", width:"40px",marginLeft:"10PX"}}>
                     <p STYLE={{fontWeight:"bold", fontSize:"20PX"}}>{item.admin_name[0]}</p>
                   </div>
                   </div>
</div>



<div className='col-md-8'>
<div style={{width:"100%",marginLeft:"20px"}}>
                   
                   <div style={{fontWeight: "500",color: "black",marginTop: "15px"}} className="d-flex flex-row">
                     <p style={{fontSize:"14px",fontWeight:"bold"}}>{item.description}</p>
                     <p style={{color:"grey",marginLeft:"5px",fontSize:"14px"}}>#{item.id}</p>
                   </div>
                 
                 <div style={{fontWeight: "500",marginTop: "0px",fontSize: "13px",padding:"0"}} className="d-flex flex-row">
                
                   <FiMail style={{margin:"0"}}/>

                
                 <div style={{fontSize:"12PX",fontWeight:"bold",marginLeft:"3PX  "}}>{item.admin_name} </div> 
                   <div style={{color:"grey",fontSize:"12PX",marginLeft:"2PX"}}> * Agent responded 2 hours ago </div>
                    <div style={{color:"grey",fontSize:"12PX",marginLeft:"2px"}}> * Due in 3 days</div>
                 
                 
                 </div>
               </div>
  </div>

  <div className='col-md-3'>
  <div className="d-flex flex-column">
                   
                   <h5 className="d-flex flex-row" style={{fontWeight: "500",color: "black",marginTop: "5px",marginLeft:"3PX",fontSize:"12PX"}}>
                   <div style={{height: "10px", width: "10px",background: "green",marginTop:"8PX"}} class = "color-bg"></div>
                   <select name="input_13" id="input_5_13" class="large gfield_select" tabindex="1" style={{width: "100% !important",border:"none",marginTop:"0 !important"}}>
  {/* <option value="---" selected="selected">---</option> */}
  <option value="1" selected="selected"> Low</option>
  <option value="2"> Medium</option>
  <option value="3">High</option>


  
</select>
                   </h5>
                 
                 <h5 style={{fontWeight: "bold",marginTop: "5px",fontSize: "13px",color:"grey",fontSize:"12PX"}}>
                     <IoIosPersonAdd style={{fontSize:"15PX",marginRight:"5px"}}/>
                   -- / --
                 </h5>
                 <h5 className="d-flex flex-row" style={{fontWeight: "bold",marginTop: "5px",fontSize: "13px",color:"grey",fontSize:"12PX"}}>
                     <IoAnalyticsOutline style={{fontSize:"15PX",marginRight:"5px"}}/>
                     <select className="select_status" style={{}}>
                     <option>Open</option>
                     <option>Closed</option>
                     
                   </select>
                   
                 </h5>
               </div>
    </div>

              

              

              

             
              
            </div>
            {/* end 1st row */}
                </div>
                )})}
             
  
  

            </div>
            </div>
          </section>
  </div>


  </div>
      

       
        

    </div>
     
            </div>
    )
}
