import React, { useState } from 'react'
import axios from 'axios';
import $ from "jquery";
import { Header } from "./Header";
import { Menu } from "./Menu"
import * as FormData from "form-data";

export function ChangePassword() {

  $(' #confirm_Password').on('keyup', function () {
    if ($('#new_Password').val() == $('#confirm_Password').val()) {
      $('#message').html('Matching').css('color', 'green');
    } else
      $('#message').html('Not Matching').css('color', 'red');
  });
  const token = localStorage.getItem("Token");
  const [oldPassword, updateOldPassword] = useState("");
  const [newPassword, updateNewPassword] = useState("");
  const [confirmPassword, updateConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState();

  async function changePwd() {
    // old password
    const oldPass = document.getElementById("currentPassword");
    const newPass = document.getElementById("new_Password");
    const confirmPass = document.getElementById("confirm_Password");
    if(oldPassword == confirmPass.value)
    {
     
      console.log("Correct password.......");
    }
    else{
      console.log("current password doesnt match");
    }


    const formData = new FormData();

    formData.append("old", oldPassword);
    formData.append("new", confirmPassword);

    const response = await axios.post(
      process.env.REACT_APP_API_KEY + "super_change_password",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },
      }
    );

    console.log("Response................", response);
    // console.log("Code", response.data.error_code);
    // console.log("Message", response.data.message);
    setErrorMsg(response.data.message);
    updateOldPassword("");
    updateNewPassword("");
    updateConfirmPassword("");
    $('#message').html(errorMsg).css('color', 'green');
    setTimeout(() => {
      $('#message').html('').css('color', 'green');
    }, 5000);
  }

  $(function () {
    setTimeout(function () {
      $(".addNewsCatagory ").hide();
    }, 5000);

  })
  function LoginPopUp() {

    $(".updatePassword").show();

  }
  function Password() {
    return (
      <div className="content-wrapper" >
        <div className="form-group" style={{ marginLeft: "10px", marginRight: "10px"}}>
          <div className='mt-4'>
          <h1 style={{ marginTop: "20px", fontWeight: "bold", fontSize: "14PX" }}>Change Password</h1>
          </div>
          <div style={{ background: "white", marginTop: "20px", padding: "10px", width: "80%" }}>
            {/* current password */}
            <div className="form-group " >
               <div className='d-flex'>
               <p style={{ color: "#1F3977",fontWeight: "bold" }}>Current Password </p>
              <span style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</span>
               </div>
              <input
                type="password"
                className="border"
                id="currentPassword"
                autoComplete='off'
                value={oldPassword}
                onChange={(e) => updateOldPassword(e.target.value)}
                placeholder="Enter Current Password"
                style={{ width: "100%", height: "35px", border: "1px solid grey", paddingLeft: "4PX" }}

              />
            </div>

            {/* New Password */}
            <div className="form-group" style={{ marginTop: "10px" }}>
               <div className='d-flex'>
               <p style={{ color: "#1F3977",fontWeight: "bold" }}>New Password</p>
               <span style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</span>
               </div>
              <input
                type="password"
                className="border"
                autoComplete='off'
                id="new_Password"
                value={newPassword}
                onChange={(e) => updateNewPassword(e.target.value)}
                placeholder="Enter New Password"
                style={{ width: "100%", height: "35px", border: "1px solid grey", paddingLeft: "4PX" }}

              />
            </div>

            {/* confirm New Password */}
            <div className="form-group" style={{ marginTop: "10px" }}>
                <div className='d-flex'>
                <p style={{ color: "#1F3977",fontWeight: "bold" }}>Confirm New Password  </p>
                <span style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</span>
                </div>
              <input
                type="password"
                className="border"
                //  name="confirm_password"
                id="confirm_Password"
                autoComplete='off'
                value={confirmPassword}
                onChange={(e) => updateConfirmPassword(e.target.value)}
                placeholder="Re-enter New Password"
                style={{ width: "100%", height: "35px", border: "1px solid grey", paddingLeft: "4PX" }}
              />
              <span id='message'></span>
            </div>

            <div className="mt-3 d-flex" style={{ flexWrap: "wrap" }}>
              <div style={{ marginLeft: "1px", marginTop: "0px", padding: "0" }} className="d-flex">
                <button
                  type="button"
                  className="d-flex buttonContainer"
                  defaultValue="Sign Up"
                  onClick={() => changePwd()}
                  style={{ padding: "8px", marginTop: "0", background: "#1F3977", flexWrap: "wrap", borderRadius: "5px", marginLeft: "auto" }}
                >
                  {/* <BiPlusMedical style={{marginTop:"0px",fontSize:"12px"}}/> */}
                  <div style={{ marginLeft: "5px", fontSize: "12PX" }}>Update Password</div>
                </button>

                <div class="updatePassword" style={{ margin: "10px auto", display: "none" }}>
                  <div className="success_img ms-3"></div>
                  <h4 class="login-text" style={{ color: "#339dd8" }}>{errorMsg}</h4>
                </div>

              </div>
              <div>

                {/* end of view details */}
              </div>


            </div>

          </div>










        </div>

      </div>
    )
  }
  return (
    <div>
      <Header />
      <Menu />
      {Password()}
    </div>
  )
}
