import React, { useState,useEffect } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import axios from "axios";
import $ from "jquery";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

export const Universities = () => {
  const [loc, setLoc] = useState(window.location.pathname);

  const Input = styled.input.attrs((props) => ({
    type: "text",
    size: props.small ? 5 : undefined,
  }))`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
  `;

  const token = localStorage.getItem("Token");
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState();
  const [uniList, setUniList] = useState([]);
  const [uniId, setUniId] = useState("");

  const [uniName, setUniName] = useState("");
  const [uniAddress, setUniAddress] = useState("")
  const [uniLogo,setUniLogo] = useState(null);
  const [uniCoverPhoto,setUniCoverPhoto] = useState(null);
  const [editUniId, setEditUniId] = useState("");
  const [campStatusMsg, setCampStatusMsg] = useState("");

  const [filterText, setFilterText] = useState("");

  function UniDetails(uni_id) {
      // setUniId(uni_id)
    history.push("/universitydetails",{uni_id});
  };

  async function getUniList() {

    try {

      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_university_list",

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const u_data = fetchResponse.data.data;
        setUniList(u_data);
      } else {
        // setUniList([]);

      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function activeUniversity(id) {
    try {

      const formData = new FormData();
      formData.append("uni_id", id);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_update_university_status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const c_status = fetchResponse.data.message;

        setCampStatusMsg(c_status);
        getUniList();
        $('.edit_campus_modal').hide();

        $(".success_msg").show();
        setTimeout(function () {
          $(".success_msg").hide();
        }, 2000);
      } else {
      }
    } catch (err) {
      console.log("status error--------", err);
    }
  }

  useEffect(() => {
    getUniList();
    localStorage.setItem('active_index', JSON.stringify(3));
  }, []);

  const [tId, setTId] = useState("");
  const openActionsModal = (e) => {
    setTId(e);
    $('.edit_campus_modal').hide();
    $('.actions_modal'+e).toggle();
  }
  const closeActionsModal = (e) => {
    $('.edit_campus_modal').hide();
  }

  const saveUpdate =async () =>{
    try{
    if (uniName == "") {
      $(".uni_name").show();

      setTimeout(function () {
        $(".uni_name").hide();
      }, 3000);
    }else if(uniAddress == "") {
      $(".uni_add").show();

      setTimeout(function () {
        $(".uni_add").hide();
      }, 3000);
    }
    else if(uniLogo == "") {
      $(".uni_logo").show();

      setTimeout(function () {
        $(".uni_logo").hide();
      }, 3000);
    }else{

        const formData = new FormData();
        formData.append("uni_id",editUniId);
        formData.append("uni_name",uniName);
        formData.append("uni_add",uniAddress);
        for (var i = 0; i < uniLogo.length; i++) {
          formData.append("uni_photo", uniLogo[i]);
        }
        for (var i = 0; i < uniCoverPhoto.length; i++) {
          formData.append("uni_cover_photo", uniCoverPhoto[i]);
        }

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_edit_university",
       formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

         if(fetchResponse.data.error_code == 200){
          $(".preview_category").hide();
          $('.edit_campus_modal').hide();
          getUniList();
         }
    }
  }catch(err){}
  }

  function openEditForm(u_id) {
    setEditUniId(u_id);
    const u_data = uniList.filter((item)=> item.uni_id == u_id);
    u_data.map((e)=>{
      setUniName(e.uni_name);
      setUniAddress(e.address);
      setUniLogo(e.uni_photo);
      setUniCoverPhoto(e.uni_cover_photo);
    })
    $(".preview_category").show();
    $('.edit_campus_modal').hide();
    var preview = document.getElementById("file-ip-1-preview");
        preview.style.display = "none";
        $(".image_std").show();

        var preview1 = document.getElementById("uni_cover_img_preview");
        preview1.style.display = "none";
        $(".defalt_cover_img").show();
  }

  function closeEditForm() {
    $(".preview_category").hide();
  }

  var eventImage = [];
  const getImage = (e) => {
    $(".image_std").hide();

    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setUniLogo(eventImage);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";
      }
    }
  }

  var c_image = [];
  const getCoverImg = (e) => {
    $(".defalt_cover_img").hide();

    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        c_image.push(e.target.files[i]);

        setUniCoverPhoto(c_image);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("uni_cover_img_preview");
        preview.src = src;
        preview.style.display = "block";
        // $(".defalt_cover_img").css("display","none");
      }
    }
  };




  // const filteredItems = uniList.filter(
  //   (item) =>
  //     JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
  //     -1
  // );

  const filteredItems = uniList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  );
  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu pageTitle={loc} />

          <div className="content-wrapper">
          <div
                            class="success_msg"
                            style={{
                              marginTop: "5px",
                              display: "none",
                              width: "100%",
                              marginBottom: "5px",
                            }}
                          >
                            <Stack sx={{ width: "100%" }} spacing={2}>
                              <Alert variant="filled" severity="success">
                                {campStatusMsg}
                              </Alert>
                            </Stack>
                          </div>


            <div className="search_box_border_class">
              <div className="col-md-3 d-flex flex-row">
                <div className="search_box_div">
                  <img
                    className="search_box_img"
                    src={require("../images/Search.png")}
                  />
                  <input
                    className="search_box"
                    id="search"
                    type="text"
                    placeholder="Search by name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="col-md-9 d-flex flex-row"
                style={{ justifyContent: "end" }}
              >
                <div style={{ marginTop: "0px", padding: "0" }}>
                    <Link to="/adduniversity">
                    <button
                      type="button"
                      className="d-flex  create_button"
                      defaultValue="Sign Up"

                    >
                      <div className="create_button_inner">Add University</div>
                      <img
                        className="create_button_img"
                        src="dist/img/Progress.png"
                      />
                    </button>
                    </Link>

                </div>
              </div>
            </div>

            <div className="border_class" style={{minHeight:"77vh"}}>
              <section style={{ margin: "10px 5px" }}>
                <div className="container-fluid table-cards">
                  <div className="table-cards">
                    <div className="row">

                    {filteredItems.map((item) => {

                       return(<>


                      <div
                        className="col-md-4"
                        style={{ height: "100%", padding: "0px 5px" }}
                      >
                        <div
                          className="small-box"
                          style={{
                            height: "86px",
                            borderRadius: "2.5PX",
                            display: "flex",
                          }}
                        >

                          <div
                            className="inner"
                            onClick={() => UniDetails(item.uni_id)}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              style={{
                                border: "1px solid gray",
                                borderRadius: "3px",
                                padding: "2px",
                              }}
                            >
                              <img
                                src={item.uni_photo}
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "3px",
                                  // objectFit:"contain"
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="inner"
                            onClick={() => UniDetails(item.uni_id)}
                            style={{ cursor: "pointer" }}
                          >
                            <div>
                              <h5
                                style={{
                                  fontWeight: "600",
                                  fontSize: "12px",
                                  color: "black",
                                  marginTop: "5px",
                                  fontFamily: "Poppins",
                                  width:"180px",
                                  overflow:"hidden",
                                  whiteSpace:"nowrap",
                                  textOverflow:"ellipsis"
                                }}
                              >
                                {item.uni_name}
                              </h5>
                            </div>

                            <div
                              className="d-flex"
                              style={{
                                flexWrap: "wrap",
                                marginTop: "5px",
                                fontFamily: "Poppins",
                                color: "#1F3977",
                                fontWeight: "700",
                                fontSize: "14px",
                              }}
                            >
                              <div style={{ marginRight: "5px" }}>{item.total_user}</div>
                              <div> Users</div>
                            </div>

                            <div
                              className="d-flex"
                              style={{
                                flexWrap: "wrap",
                                fontFamily: "Poppins",
                                color: "#1F3977",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              <div style={{ marginRight: "5px" }}>{item.total_campus}</div>
                              <div> Campus</div>
                            </div>


                          </div>
                          <div style={{display:"contents"}}>
                          <div className="mt-0 ml-auto">
                            <img onClick={() => openActionsModal(item.uni_id)}
                              src="dist/img/menu2-512.jpg"
                              style={{ height: "15px", width: "15px",cursor:"pointer" }}
                            />
                          </div>

                          <div className={`edit_campus_modal actions_modal${item.uni_id}`}
                       style={{display: "none",position:"absolute",top:"5px",right:"4px"}}>
                      <div>
                    <div className="  ">
                      <div className=" d-flex ml-auto">
                        <img
                          className="campus_img ml-auto"
                          src="dist/img/Cancel.png"
                           onClick={()=>closeActionsModal(item.uni_id)}
                        />
                      </div>
                    </div>

                      {item.status == 0?(


                    <div className="  hover_class">
                      <div className=" d-flex flex-row"
                       onClick={() => activeUniversity(item.uni_id)}
                       >
                         <div>
                          <img
                            className="campus_img"
                            src="dist/img/NoEntry.png"
                          />
                        </div>

                        <div className="campus_inner_div">
                          <span>Active</span>
                        </div>
                      </div>
                    </div>
                      ):(
                      <div className="  hover_class">
                      <div className=" d-flex flex-row" onClick={() =>activeUniversity(item.uni_id)} >
                      <div>
                          <img
                            className="campus_img"
                            src="dist/img/NoEntry.png"
                          />
                        </div>
                        <div className="campus_inner_div">
                          <span>Deactive</span>
                        </div>
                      </div>
                    </div>
                     )}


                    <div className=" d-flex flex-row hover_class">
                      <div className=" d-flex flex-row"
                        onClick={() => openEditForm(item.uni_id)}
                        >
                          <div>
                          <img
                            className="campus_img"
                            src="dist/img/campusEdit.png"
                          />
                        </div>

                        <div className="campus_inner_div">
                          <span>Edit</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                          </div>
                        </div>
                      </div>
                      </>)
})}

                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
        className="preview_category">
        <div className="edit_inner">
          <div className="d-flex edit_inner_div">
            <label className="main_labels">
              {uniName}
            </label>

            <img
              src="dist/img/Cancel.png"
              alt="dropdown"
              className="close_event ml-auto cancel_img"
              onClick={closeEditForm}

            />
          </div>

          <div
            className="preview_form">
              <div className="border_class edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        University Name
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={uniName}
                      onChange={(e) => setUniName(e.target.value)}

                    />

                    <div
                      class="uni_name"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter university name
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                         University Address
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>

                    <textarea
                      // ref={summerNoteRef}
                      className=" edit_all_inputs"
                      id="news_description"
                      value={uniAddress}
                      onChange={(e) => setUniAddress(e.target.value)}
                      style={{height:"80px"}}
                    ></textarea>

                    <div
                      class="uni_add"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter University Address
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className="p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                           University Logo
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>

                    <div>
                      {uniLogo == null ? (
                        <div>
                          <label for="file-ip-1" style={{ height: "100%" }}>
                            <img
                              className="image_std"
                              src={require("../images/no_image.png")}
                              alt="no image"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="photo"
                            style={{ visibility: "hidden",display:"none" }}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={getImage}
                            id="file-ip-1"

                          />
                        </div>
                        ) : (
                         <div>
                          <label
                            for="file-ip-1">

                                <div style={{ margin:"2px" }}>
                                  <img className="image_std"
                                   style={{width: "100px", height: "100px"}}
                                  src={uniLogo} />
                                </div>


                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden" }}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={getImage}
                              id="file-ip-1"

                            />
                          </label>
                        </div>
                      )}
                    </div>

                    <div
                      class="uni_logo"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please select university logo
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                         University Cover Photo

                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>

                    <div>
                      {uniCoverPhoto == null ? (
                        <div>
                          <label for="uni_cover_img" style={{ height: "100%" }}>
                            <img
                              className="defalt_cover_img"
                              src={require("../images/no_image.png")}
                              alt="no image"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <img
                              id="uni_cover_img_preview"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="photo"
                            style={{ visibility: "hidden",display:"none" }}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={getCoverImg}
                            id="uni_cover_img"

                          />
                        </div>
                       ) : (
                         <div>
                          <label for="uni_cover_img">
                                  <img className="defalt_cover_img"
                                   style={{width: "100px", height: "100px"}}
                                  src={uniCoverPhoto}
                                  />


                            <img
                              id="uni_cover_img_preview"
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />

                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden",display:"none" }}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={getCoverImg}
                              id="uni_cover_img"
                            />
                            </label>

                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            {/* ******************button********************** */}

            <div className="d-flex mt-3 edit_buttons_div border_class"
             style={{justifyContent:"end"}}>
              <input
                type="button"
                className="edit_cancel_button"
                onClick={() => closeEditForm()}
                value="Cancel"

              />

              <input
                type="button"
                className="edit_update_button"
                id="delete_single_student"
                value="Update"
                onClick={() => saveUpdate()}

              />
            </div>

            <div style={{ display: "none" }} className="saveMessage">
              Data Saved Successfully
            </div>
          </div>
        </div>
      </div>


          </div>
        </div>
      </div>
    </>
  );
};
