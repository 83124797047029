import React,{ useState } from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu"
import Marketdummy from './Marketdummy';
import { Marketplace } from './Marketplace'

export function MarketplaceDetails() {
    const [loc,setLoc] = useState(window.location.pathname);
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} />
            {/* <Marketplace /> */}
            <Marketdummy/>
            {/* dummy market place to test and understand functioning of api's  */}
            </div>
           
          
        </div>
    )
}
