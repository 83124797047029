import React, { useState } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import $ from "jquery";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory, Link } from "react-router-dom";

export const StudentForm = () => {
  return (
    <div>
      <Header />

      <div className="d-flex">
        <Menu />

        <div className="content-wrapper">
          <div className="border_class2 box_padding">
            <h1 className="main_heading_h1 m-0">CREATE STUDENT</h1>
          </div>

          <div class="formSuccess success_msg">
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="success">
                {/* {error_message} */}
              </Alert>
            </Stack>
          </div>

          <form>
            <div className="create_form">
              <div className="border_class2 box_padding">
                <div class="row">
                  <div class="col-md-8">
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        paddingRight: "0",
                      }}
                    >
                      <div className="row d-flex">
                        {/* first name */}
                        <div className="col-md-6">
                          <div className="d-flex">
                            <label className="all_labels">First Name</label>

                            <p className="all_stars">*</p>
                          </div>

                          <input
                            className="all_inputs"
                            type="name"
                            id="first_name"
                            // value={firstName}
                            pattern="[A-Za-z]"
                            // onChange={(e) => updateFirstName(e.target.value)}
                            placeholder="Enter Your First Name..."
                            autoComplete="true"
                          />

                          <div
                            class="first_name"
                            style={{ margin: "0", display: "none" }}
                          >
                            <h4 class="login-text all_validations_h4">
                              Please Enter First Name
                            </h4>
                          </div>
                        </div>

                        {/* last name */}
                        <div className="col-md-6">
                          <div className="">
                            <div className="d-flex ">
                              <label className="all_labels">Last Name</label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              className="all_inputs"
                              type="name"
                              id="last_name"
                              // value={lastName}
                              // onChange={(e) => updateLastName(e.target.value)}
                              placeholder="Enter Your Last Name..."
                              autoComplete="true"
                            />

                            <div
                              class="last_name"
                              style={{ margin: "0", display: "none" }}
                            >
                              <h4 class="login-text all_validations_h4">
                                Please Enter Last Name
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 row d-flex">
                        {/* preffered name */}
                        <div className="col-md-6">
                          <label className="all_labels">Preffered Name</label>
                          <input
                            className="all_inputs"
                            type="name"
                            id="preferred_name"
                            // value={preferredName}
                            // onChange={(e) =>
                            //   updatePreferredName(e.target.value)
                            // }
                            placeholder="Enter Your Preferred Name..."
                            autoComplete="true"
                          />
                        </div>

                        {/* last name */}
                        <div className="col-md-6">
                          <div className="">
                            <label className="all_labels">Gender</label>

                            <select
                              className="form-select form-select-sm all_inputs"
                              aria-label=".form-select-sm example"
                              id="gender"
                              // onChange={fetchGender}
                            >
                              <option selected
                              //  value={gender}
                               >
                                Select Gender
                              </option>
                              <option value="1">Male</option>
                              <option value="2">Female</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 row d-flex">
                        {/* dob */}
                        <div className="col-md-6">
                          <label className="all_labels">Date Of Birth</label>
                          <input
                            type="date"
                            className="all_inputs"
                            placeholder="dd-mm-yyyy"
                            id="dob"
                            required
                            // value={dateOfBirth}
                            // onChange={(e) => updateDateOfBirth(e.target.value)}
                          />
                        </div>

                        {/* last name */}
                        <div className="col-md-6">
                          <div className="">
                            <label className="all_labels">Parent Name</label>
                            <input
                              className="all_inputs"
                              type="name"
                              id="parent_name"
                              // value={parentName}
                              pattern="[A-Za-z]"
                              // onChange={(e) => updateParentName(e.target.value)}
                              placeholder="Enter parent name..."
                              autoComplete="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">Student's Profile</label>
                      </div>
                      <label for="file-ip-1">
                        <img
                          className="image_std"
                          src="dist/img/event_photo.png"
                          alt="dropdown"
                          style={{ height: "165px" }}
                        />
                        <img
                          id="file-ip-1-preview"
                          style={{
                            display: "none",
                            height: "150px",
                            top: "33px",
                            left: "50px",
                          }}
                        />
                      </label>

                      <input
                        type="file"
                        name="photo"
                        style={{ visibility: "hidden",display:"none" }}
                        accept="image/png, image/gif, image/jpeg"
                        // onChange={getImage}
                        id="file-ip-1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border_class2 box_padding">
                <div class="row">
                  <div class="col-md-4">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div
                        className=""
                        style={{ width: "100%", marginTop: "0px" }}
                      >
                        <div className="d-flex">
                          <label className="all_labels">Department</label>
                          <p className="all_stars">*</p>
                        </div>

                        <select
                          className="form-select form-select-sm all_inputs"
                          aria-label=".form-select-sm example"
                          id="department_neww"
                          // onChange={departmentWiseCourseList}
                        >
                          <option selected="selected" 
                          // value={department}
                          >
                            Select Department
                          </option>

                          {/* {departmentdata &&
                            departmentdata.map((dept, index) => {
                              return (
                                <option
                                  value={dept.department_id}
                                  key={index}
                                  name={dept.department_name}
                                >
                                  {dept.department_name}
                                </option>
                              );
                            })} */}
                        </select>

                        <div
                          class="std_dept"
                          style={{ margin: "0", display: "none" }}
                        >
                          <h4 class="login-text all_validations_h4">
                            Please Select Department
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex ">
                        <label className="all_labels">Course</label>
                        <p className="all_stars">*</p>
                      </div>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="student_course"
                        // onChange={courseWiseSubjectList}
                      >
                        <option selected="selected" 
                        // value={course}
                        >
                          Select Course
                        </option>

                        {/* {courseData.map((item, index) => {
                          return (
                            <option value={item.course_id} key={index}>
                              {item.course_name}
                            </option>
                          );
                        })} */}
                      </select>

                      <div
                        className="std_course"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 className="login-text all_validations_h4">
                          Please Select Course
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">Class</label>
                        <p className="all_stars">*</p>
                      </div>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="student_class"
                        // onChange={fetchClass}
                      >
                        <option selected="selected"
                        //  value={studentClass}
                         >
                          Select Class
                        </option>

                        {/* {classdata && classdata.length > 0 ? (
                          classdata.map((item, index) => {
                            return (
                              <option
                                value={item.class_id}
                                key={index}
                                style={{
                                  color: "#000000",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                }}
                              >
                                {item.class_name}
                              </option>
                            );
                          })
                        ) : (
                          <div>Data Not Found</div>
                        )} */}
                      </select>
                      <div
                        class="std_class"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 class="login-text all_validations_h4">
                          Please Select Class
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border_class2 box_padding">
                <div class="row">
                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">Section</label>
                        <p className="all_stars">*</p>
                      </div>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="student_subject"
                        // onChange={fetchSubjectData}
                      >
                        <option selected="selected" 
                        // value={subject}
                        >
                          Select Section
                        </option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                        <option value="F">F</option>
                      </select>

                      <div
                        class="std_subject"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 className="login-text all_validations_h4">
                          Please Select Subject
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">First Nationality</label>
                      </div>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="first_national"
                        // onChange={fetchFirstNationality}
                      >
                        <option selected="selected" 
                        // value={firstNationality}
                        >
                          Select First Nationality
                        </option>
                        {/* {nationalitydata.map((nationality, index) => {
                          return (
                            <option value={nationality.country_id} key={index}>
                              {nationality.country}
                            </option>
                          );
                        })} */}
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">Second Nationality</label>
                      </div>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="second_national"
                        // onChange={fetchSecondNationality}
                      >
                        <option selected="selected"
                        //  value={secondNationality}
                         >
                          Select Second Nationality
                        </option>
                        {/* {nationalitydata.map((nationality, index) => {
                          return (
                            <option value={nationality.country_id} key={index}>
                              {nationality.country}
                            </option>
                          );
                        })} */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border_class2 box_padding">
                <div class="row">
                  <div class="col-md-4">
                    <div>
                      <div className="d-flex">
                        <label className="all_labels">Blood Type</label>
                      </div>
                      <input
                        type="name"
                        className="all_inputs"
                        id="blood_type"
                        pattern="[A-Za-z]"
                        // value={bloodType}
                        // onChange={(e) => updateBloodType(e.target.value)}
                        placeholder="Enter Blood Type"
                        autoComplete="true"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">Email</label>

                        <p className="all_stars">*</p>
                      </div>

                      <input
                        type="email"
                        className="all_inputs"
                        autoComplete="off"
                        placeholder="Enter Email"
                        id="email"
                        // value={email}
                        // onChange={(e) => updateEmail(e.target.value)}
                      />

                      <div
                        class="std_email"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 class="login-text all_validations_h4">
                          Please Enter Email Name
                        </h4>
                      </div>

                      <div
                        class="validEmail"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 className="login-text all_validations_h4">
                          Please Enter valid Email Address with @
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <div className="d-flex">
                        <label className="all_labels">Mobile Number</label>

                        <p className="all_stars">*</p>
                      </div>

                      <input
                        type="text"
                        className="all_inputs"
                        placeholder="Enter Mobile Number"
                        id="mobile_new"
                        // value={mobile}
                        minLength="10"
                        pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                        maxLength="10"
                        required
                        // onChange={(e) => checkInput(e)}
                      />

                      <div
                        class="std_mobile"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 class="login-text all_validations_h4">
                          Please Enter Mobile
                        </h4>
                      </div>

                      <div
                        class="validMobileNumber"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 class="login-text all_validations_h4">
                          Please Enter 10 digit mobile number
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border_class2 box_padding">
                <div class="row">
                  <div class="col-md-4">
                    <div>
                      <div
                        className=""
                        style={{ width: "100%", marginTop: "0px" }}
                      >
                        <label className="all_labels">First Language</label>
                        <br />

                        <select
                          className="form-select form-select-sm all_inputs"
                          aria-label=".form-select-sm example"
                          id="first_language"
                          // onChange={fetchFirstLanguage}
                        >
                          <option selected="selected"
                          //  value={firstLanguage}
                           >
                            Select First Language
                          </option>
                          {/* {languagedata.map((lang, index) => {
                           
                            return (
                              <option value={lang.language_id} key={index}>
                                {lang.language}
                              </option>
                            );
                          })} */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div className="">
                      <div
                        className=""
                        style={{ width: "100%", marginTop: "0px" }}
                      >
                        <label className="all_labels">Spoken Language</label>
                        <br />

                        <select
                          className="form-select form-select-sm all_inputs"
                          aria-label=".form-select-sm example"
                          id="spoken_language"
                          // onChange={fetchSpokenLanguage}
                        >
                          <option selected="selected"
                          //  value={spokenLanguage}
                           >
                            Select Spoken Language
                          </option>
                          {/* {languagedata.map((lang, index) => {
                            //  console.log("Id", language.country)
                            return (
                              <option value={lang.language_id} key={index}>
                                {lang.language}
                              </option>
                            );
                          })} */}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div className="">
                      <label className="all_labels">Race/Ethnicity</label>
                      <br />

                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="race"
                        // onChange={fetchStdRace}
                      >
                        <option selected="selected"
                        //  value={race}
                         >
                          Select Race/Ethnicity
                        </option>
                        {/* {raceData.map((item, index) => {
                          return (
                            <option value={item.race_id} key={index}>
                              {item.race}
                            </option>
                          );
                        })} */}
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row " style={{ paddingBottom: "10px" }}>
                  <div class="col-md-4">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex mt-2">
                        <label className="all_labels">Password</label>

                        <p className="all_stars">*</p>
                      </div>

                      <input
                        type="password"
                        id="password"
                        className="std_form_placeholder all_inputs"
                        placeholder="Enter Password..."
                        // value={password}
                        // onChange={(e) => updatePassword(e.target.value)}
                      />

                      <div
                        class="std_password"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4
                          class="login-text all_validations_h4"
                         
                        >
                          Please Enter Password
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 d-flex">
                    <div style={{ width: "100%" }}>
                      <div className="d-flex mt-2">
                        <label className="all_labels">Persona</label>
                        <p className="all_stars">*</p>
                      </div>
                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="persona"
                        // onChange={fetchPersona}
                        required
                      >
                        <option selected="selected"
                        //  value={persona}
                         >
                          Select Persona
                        </option>
                        {/* {personadata.map((item, index) => {
                          console.log("Id", item.persona);
                          return (
                            <option value={item.persona_id} key={index}>
                              {item.persona}
                            </option>
                          );
                        })} */}
                      </select>
                    </div>
                  </div>
                  <div
                    class="col-md-4"
                    style={{ height: "100%", marginTop: "35px" }}
                  >
                    <a className="cta" href="#google">
                      
                      <img
                        src={require("../images/add.png")}
                        style={{ height: "30px", width: "30px" }}
                      />
                    </a>
                  </div>

                  <div
                    class="std_persona"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4 class="login-text all_validations_h4">
                      Please Select Persona
                    </h4>
                  </div>


                  <div id="google" className="modaloverlay">
                    <div className="modalContainer">
                      <form role="form">
                        <div className="card-body" style={{ marginTop: "0px" }}>
                          <div style={{ width: "100%" }}>
                            {/* CATEGORY */}
                            <div
                              className="form-group"
                              style={{ marginTop: "0px" }}
                            >
                              <label
                                htmlFor="exampleInputEmail1"
                                style={{ color: "#1F3977" }}
                              >
                                Add Persona
                              </label>
                              <input
                                type="name"
                                className="border"
                                id="exampleInputEmail1"
                                placeholder="Add Persona"
                                // value={personaValue}
                                // onChange={(e) =>
                                //   updatePersonaValue(e.target.value)
                                // }
                                style={{ width: "100%", height: "35px" }}
                              />
                            </div>

                            <div className="d-flex mt-3">
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "blue",
                                  marginTop: "20px",
                                }}
                              >
                                {/* {personaErrorMessage} */}
                              </div>
                              <input
                                type="button"
                                className="create_btn"
                                defaultValue="Sign Up"
                                // value="Cancel"
                                // onClick={() => cancelPersona()}
                                style={{
                                  borderRadius: "5px",
                                  fontWeight: "600",
                                  fontSize: "12px",
                                  marginLeft: "auto",
                                  color: "#1F3977",
                                  background: "transparent",
                                }}
                              />
                              <input
                                type="button"
                                className="create_btn"
                                defaultValue="Sign Up"
                                value="Submit"
                                // onClick={() => createPersona()}
                                style={{
                                  borderRadius: "5px",
                                  fontSize: "12px",
                                  backgroundColor: "#1F3977",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                      <a
                        class="close"
                        href="#"
                        style={{ marginTop: "-171px", marginRight: "8px" }}
                      >
                        &times;
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* buttons */}
              <div className="d-flex border_class2 box_padding buttons_div">
                <div
                  class="ValueMsg"
                  style={{ margin: "8px", width: "71%", display: "none" }}
                >
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert variant="filled" severity="error">
                      Error! You Must Fill In Mandatory Fields
                    </Alert>
                  </Stack>
                </div>

                <img
                  src="dist/img/delete.png"
                  alt="dropdown"
                  className="delete_img"
                  // onClick={() => resetValue()}
                />
                <span className="news_bar">|</span>
                <button className="preview_button"
                //  onClick={() => preview()}
                 >
                  <span className="preview_font">Preview</span>
                  <div className="preview_img_div">
                    <img
                      className="preview_img"
                      src="dist/img/view.png"
                      alt="dropdown"
                    />
                  </div>
                </button>

                <input
                  type="button"
                  className="publish_button"
                  defaultValue="Sign Up"
                  // onClick={() => createStudent()}
                  value="Publish"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
