import React,{ useState } from 'react'
import { Header } from "../Header"
import { Menu } from "../Menu"
import { CreateCampus } from './CreateCampus'
export function CreateCampusDetails() {
    const [loc,setLoc] = useState(window.location.pathname);
    // setLoc(window.location.pathname);
    return (
        <div>
            <Header />
            <div className="d-flex">
            <Menu pageTitle={loc} />
            <CreateCampus />
        </div>
        </div>

    )
}
