import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import DataTable from 'react-data-table-component';
import axios from "axios";
import $ from "jquery";
import {IoCloseCircleSharp} from "react-icons/io5"
import Swal from "sweetalert2";
import {Previous_next_button} from "./Previous_next_button";
const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export function Marketplace() {

  const token = localStorage.getItem('Token');
  const [data, setData] = useState([]);
  const [image, setImage] = useState([]);
  const [marketPlaceId, updateMarketPlaceId] = useState("");
  const [emailAddress, updateEmailAddress] = useState("");
  const [campudId, updateCampudId] = useState("");
  const [deletePassword, updateDeletePassword] = useState("");
  const [deleteErrorCode, updatedeleteErrorCode] = useState("");
  const [deleteErrorMessage, updatedeleteErrorMessage] = useState("");
  const [title, updateTitle] = useState("");
  const [description, updateDescription] = useState("");
  const [photo, updatePhotos] = useState("");
  const [price, updatePrice] = useState("");
  const [sku, updateSku] = useState("");
  const [skuValue, updateSkuValue] = useState("");
  const [info,updateInfo]  =useState("")
  const [optionId, updateOptionId] = useState("");
  const [optionName, updateOptionName] = useState("");
  const [optionValue, updateOptionValue] = useState("");
  const [send_to, updateSend_to] = useState("");
  
  const [inputList, setInputList] = useState([{ title: "", value: "" }]);
  
    // handle input change
    const handleInputChange = (e, index) => {
    
      const { name, value } = e.target;
  
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
  
    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, { title: "", value: "" }]);
    };

    const chnageOption = (e, index) => {
      console.log("get unknown number",e.target.name)
      const { name, value } = e.target;
      console.log("get unknown number",...inputList)
      const list = [...inputList];
      console.log("get unknown number",list)
      list[index][name] = value;
      console.log("get unknown number",value)
      setInputList(list);
      console.log("inputList",list)
    };
  

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };
  
  const [childNewsData, setChildNewsData] = useState([]);
  const passEditData = (marketPlaceId) => {
    setChildNewsData(marketPlaceId);
    edit_category(marketPlaceId);
  };

  const passDeleteData = (marketPlaceId) => {
    setChildNewsData(marketPlaceId);
    delete_category(marketPlaceId);
  };

  function close_delete_modal() {
    $(".delete_popup_password").hide();
  }
  async function edit_category(marketPlaceId)
  {
    $(".edit_container").show();
    const formData = new FormData();

    formData.append("m_id", marketPlaceId);
      const singleMarketplaceResponse = await axios.post(process.env.REACT_APP_API_KEY + "admin_get_marketplace",
      formData, 
      {
          headers:
          {
            "Content-Type": 'multipart/form-data',
            "Authorization": token,
          }
        }
      );

      alert("Get single marketplace", singleMarketplaceResponse.data.data.title);
      
      if(singleMarketplaceResponse.data.error_code == 200)
      {
       
         const item = singleMarketplaceResponse.data.data
         updateMarketPlaceId(item.marketplace_id)
          updateTitle(item.title)
          updateDescription(item.description)
          updateMarketplaceImage(item.image)
 
          updatePrice(item.price)
          updateSku(item.sku)
          updateSend_to(item.send_to)
          console.log("manage sku",item.manage_sku)
          updateSkuValue(item.manage_sku)
          setInputList(item.m_info)
          {
            item.m_info.map((infoItem) =>
            {
              updateOptionId(infoItem.info_id)
              updateOptionName(infoItem.title)
              updateOptionValue(infoItem.value)
            })
          }
      }
  }
  function deleteFile(e) {
    const s = imgDataMarketplace.filter((item, index) => index !== e);
    setImgDataMarketplace(s);
    console.log("setImgDataMarketplace",s);
  }
  async function deleteImage(image_id,evt)
  {
    const r = marketplaceImage.filter((item, index) => index !== evt);
    updateMarketplaceImage(r);
    console.log("setImgDataMarketplace",r);
    console.log("get index of that image",image_id)
    
    const formData = new FormData();

    formData.append("img_id", image_id);
    
      const singleMarketplaceResponse = await axios.post(process.env.REACT_APP_API_KEY + "admin_delete_marketplace_image",
      formData, 
      {
          headers:
          {
            "Content-Type": 'multipart/form-data',

            "Authorization": token,
          }
        }
      );
      //console.log("delet marketplace imgage", singleMarketplaceResponse.data.data);
  }

  function delete_category(m_id) {
    console.log("delete data id", m_id);
    $(".preview_polls").hide();
    $(".delete_preview_polls").show();
    updateMarketPlaceId(m_id)
  }

  function deleteMarketPlace(id) {
    console.log("delete by id",id)
    updateMarketPlaceId(id)
    $(".delete_container").show();
  }

  const [imgData, setImgData] = useState([]);
  const [imgDataMarketplace, setImgDataMarketplace] = useState([]);
  const [marketplaceImage, updateMarketplaceImage] = useState([]);
  const [marketplaceImageId, updateMarketplaceImageId] = useState([]);

var newImage= []
 var imgDataNew = []
 var getMutipleImages = []
  marketplaceImage.map((item) =>
  {
    imgDataNew.push(item.imgs)
  })
  // console.log("imgDataNew",imgDataNew)
  // console.log("imgDataNew",imgData)
  // // imgData.splice(0,5);
  // console.log("imgDataNew",imgData)
  getMutipleImages = imgDataNew.concat(imgData);
  console.log("getMutipleImages",getMutipleImages)
 
  console.log("get photos",photo)
  const getMultipleImage = (e) => {
 
    updatePhotos(e.target.files);

    if (e.target.files.length > 0) {
      for (let i = 0 ; i < e.target.files.length ; i++) {
      
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";
       
        const l = src.length;
        const fruit = src.slice(5,l)
        console.log("imgData",fruit)
        imgData.push(fruit)
       
        imgDataMarketplace.push(src) 
    }
    }
  }

   obj = [{
    imgs : imgData
   }]

   console.log("get images",obj)
  function createNewElement() {
    var txtNewInputBox = document.createElement('div');
    txtNewInputBox.innerHTML = "<input type='text' id='newInputBox' style='fontFamily: Poppins;background: #FFFFFF; width: 100%; height: 28px; font-size: 12px !important; margin-left: 0px; border: 1px solid #c4c4c4; margin-top:5px'>";
    document.getElementById("newElementId").appendChild(txtNewInputBox);
  }

  async function fetchList() {
    console.log("Access Token-", token);
    try {

      const fetchMarketplaceResponse = await axios.post(process.env.REACT_APP_API_KEY + "super_get_marketplace_list",
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',
            "Authorization": token,
          }
        }
      );

      console.log("Get Marketplace List Details", fetchMarketplaceResponse);

      const MArketplaceErrorCode = fetchMarketplaceResponse.data.error_code;
      console.log("Marketplace Error Code ", MArketplaceErrorCode);

      const MArketplaceErrorMsg = fetchMarketplaceResponse.data.message;
      console.log("MArketplace Error msg ", MArketplaceErrorMsg);

      const imagearray = fetchMarketplaceResponse.data.data;
      {
        imagearray.map((item) => {
          console.log("image item", item.image[0])
          const imgs = item.image[0]
        }
        )
      }


      if (MArketplaceErrorCode == 200) {
        const marketplaceListArray = fetchMarketplaceResponse.data.data;
        console.log("Marketplace list Array", marketplaceListArray);
        setData(marketplaceListArray);
      }
      else {
        setData([]);

        alert(fetchMarketplaceResponse.data.message);
        $(".alert-danger").show();
        setTimeout(function () {
          $(".alert-danger").hide();
        }, 3000);
      }

    }
    catch (err) {
      console.log("Log in Fail", err);
    }

  }
  
  async function editNewsRow(m_id) {

    $(".edit_container").show();
    console.log("Access Token-", token);
    try {

      const formData = new FormData();

    formData.append("m_id", m_id);
    
      const singleMarketplaceResponse = await axios.post(process.env.REACT_APP_API_KEY + "admin_get_marketplace",
      formData, 
      {
          headers:
          {
            "Content-Type": 'multipart/form-data',

            "Authorization": token,
          }
        }
      );

      console.log("Get single marketplace", singleMarketplaceResponse.data.data.title);
      
      if(singleMarketplaceResponse.data.error_code == 200)
      {
       
         const item = singleMarketplaceResponse.data.data
          updateMarketPlaceId(item.marketplace_id)
          updateTitle(item.title)
          updateDescription(item.description)
         
          updateMarketplaceImage(item.image)
          item.image.map((item) =>
          {
            updateMarketplaceImageId(item.img_id)
          })
   console.log("image data",item.manage_sku)
          updatePrice(item.price)
          updateSku(item.sku)
          updateSend_to(item.send_to)
          updateSkuValue(item.manage_sku)
          setInputList(item.m_info)
          {
            item.m_info.map((infoItem) =>
            {
              updateOptionId(infoItem.info_id)
              updateOptionName(infoItem.title)
              updateOptionValue(infoItem.value)
            })
          } 
      }
    }
    catch (err) {
      console.log("Log in Fail", err);

    }

  }

  async function getUserDetails() {
    const fetchResponse = await axios.get(
      process.env.REACT_APP_API_KEY + "admin_get_Primary_user_info",

      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },
      }
    );

    console.log("Get campus info", fetchResponse.data.data);
    fetchResponse.data.data.map((fetchItem) => {
      updateEmailAddress(fetchItem.email);
      updateCampudId(fetchItem.campus_id);
    });
  }

  useEffect(() => {
    fetchList();
    getUserDetails();
  }, []);

  async function deleteWithPassword() {
    const formData = new FormData();

    formData.append("username", emailAddress);
    formData.append("password", deletePassword);
    formData.append("campus_id", campudId);

    const deleteNewsResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "admin_check_password",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },
      }
    );

    console.log("check password and verify", deleteNewsResponse);
    updatedeleteErrorCode(deleteNewsResponse.data.error_code);
    updatedeleteErrorMessage(deleteNewsResponse.data.message);

    if (deleteNewsResponse.data.error_code == 200) {
      deleteMarketPlaceApi();
    }
  }

  async function deleteMarketPlaceApi() {
    try {

      const formData = new FormData();

      formData.append("m_id", marketPlaceId);

      const deleteResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "admin_delete_marketplace",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      console.log("Delete Marketplace", deleteResponse);
      if (deleteResponse.data.error_code == 200) {
        $(".delete_popup_password").hide();

        handleButton();
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const handleButton = () => {

    Swal.fire({
      title: "'Yes, Deleted it!'..",
      type: "success",
      text: "Marketplace Deleted Successfully!!",
      icon: "success",
    }).then(function () {
      window.location = "/marketplaceDetails";
    });
  };


  async function editMarketplace() {
    $(".edit_container").show();
  }

  function close_edit_modal() {
    $(".edit_container").hide();
  }

  function viewDescription(m_id) {
    $(".preview_polls").show();
  
  }
  const columns = [
    {
      name: 'Image',
      wrap: true,
      width: "auto",
      cell: (row) => {

        const array = [];
        row.image.map((itemimage) => {
          console.log("image item", itemimage.imgs)
          array.push(itemimage.imgs);
        })

        return (
          <div
            onClick={() =>
              viewDescription(row.marketplace_id)}
            style={{
              backgroundColor: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "black",
              cursor:"pointer",
              margin:"3px"
            }}
          >
            {
              array == "" ?
                (
                  <div>
                    <img src={require('../images/no_image.png')} alt="Default" style={{ width: "50px", height: "50px" ,padding:"5px"}} />
                  </div>
                ) :
                (
                  <div>
                    <img src={array[0]} style={{ width: "50px", height: "50px",padding:"5px" }} />
                  </div>
                )
            }

          </div>
        )
      }
    },
    {
      name: 'Title',
      wrap: true,
      width: "auto",
      cell: (row) => {

        return (
          <div
            onClick={() =>
              viewDescription(row.marketplace_id)}
            style={{
              backgroundColor: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "black",
              cursor:"pointer"
            }}
          >
            {row.title}
          </div>
        )

      }
    },
    {
      name: 'Price',
      wrap: true,
      width: "auto",
      cell: (row) => {

        return (
          <div
            onClick={() =>
              viewDescription(row.marketplace_id)}
            style={{
              backgroundColor: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "black",
              cursor:"pointer"
            }}
          >
            {row.price}

          </div>
        )
      }

    },
    {
      name: 'Option Name',
      // selector: 'description',
      sortable: true,

      width: "auto",
      // height:"auto",
      cell: (row) => {
        console.log("display row",row)
        return (
          <div
            onClick={() =>
           
              viewDescription(row.marketplace_id)}
            style={{
              backgroundColor: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "black",
              cursor:"pointer",
              margin:"5px 0px",
              whiteSpace: "normal"
            }}
          >
            {row.m_info.map((item) =>
            {
              return(
                <div>
                  {item.title}
                </div>
              )
            })}
          </div>)
      }
    },
    {
      name: 'Option Value',
      selector: 'description',
      sortable: true,
      width: "auto",
      // height:"auto",
      cell: (row) => {
        return (
          <div
            onClick={() =>
           
              viewDescription(row.marketplace_id)}
            style={{
              backgroundColor: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "black",
              cursor:"pointer",
              margin:"5px 0px",
              whiteSpace: "normal"
            }}
          >
           {row.m_info.map((item) =>
            {
              return(
                <div>
                  {item.value}
                </div>
              )
            })}
          </div>)
      }
    },
    {
      name: 'Action',
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) => {
        return (
          <div className="d-flex">
          
            <a
              className="cta"
              href="#edit_marketplace"
              onClick={() => editNewsRow(row.marketplace_id)}
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={require("../images/Pencil.png")}
                alt="edit"
                style={{ width: "18px", height: "18px", marginLeft: "5px" }}
              />
            </a>

            <a className="cta" href="#delete" onClick={() => deleteMarketPlace(row.marketplace_id)} style={{ backgroundColor: "transparent" }}>
              <img style={{ width: "20px", height: "20px", marginLeft: "2px" }} src={require('../images/delete.png')} />&nbsp;
            </a>
          </div>
        )
      }
    }

  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );

  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      // <FilterComponentAppointment
      //   onFilter={e => setFilterText(e.target.value)}
      //   onClear={handleClear}
      //   filterText={filterText}
      // />
      <div></div>
    );
  }, [filterText, resetPaginationToggle]);

function closePreviewDescription()
{
  $(".preview_polls").hide()
}
 
  async function editWithPassword() {
    const formData = new FormData();

    formData.append("username", emailAddress);
    formData.append("password", deletePassword);
    formData.append("campus_id", campudId);

    const deleteNewsResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "admin_check_password",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },
      }
    );

    console.log("check password and verify", deleteNewsResponse);
    updatedeleteErrorCode(deleteNewsResponse.data.error_code);
    updatedeleteErrorMessage(deleteNewsResponse.data.message);

    if (deleteNewsResponse.data.error_code == 200) {
      updateForm();
    }
  }

  var obj =[{
    "id":optionId,
    "title":optionName,
    "value":optionValue
  }
   ]


  const [isEditLoading, setIsEditLoading] = useState(false);
  async function updateForm() {
    setIsEditLoading(true);
    const formData = new FormData();
    for (let i = 0 ; i < getMutipleImages.length ; i++) {
    
      console.log("get mutiple imagess in marketplace",getMutipleImages[i])
  }
   
    formData.append("m_id", marketPlaceId);
    formData.append("title", title);
    formData.append("price", price);
    formData.append("send_to", send_to);
    formData.append("charge_tax", 1);
    formData.append("description", description);
    formData.append("sku", sku);
    formData.append("manage_sku", skuValue);
    formData.append("info", JSON.stringify(inputList));
    formData.append("users", "");
    for (let i = 0 ; i < photo.length ; i++) {
      formData.append("image[]", photo[i]);
  }
    // formData.append("image", array);
    const eventResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "admin_edit_marketplace",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }
    );
    console.log("Update Campus Event", eventResponse);
    setIsEditLoading(false);
    if (eventResponse.data.error_code == 200) {
      $(".edit_popup_password").hide();
      handleEditButton();
    }else {
      $(".edit_popup_password").hide();

      setTimeout(() => {
        $(".required_filed").show();
      }, 1000);
    }
   
  }
  const handleEditButton = () => {
   
    Swal.fire({
      title: "'Yes, Edited it!'..",
      type: "success",
      text: "Marketplace Edited Successfully!!",
      icon: "success",
    }).then(function () {
      window.location = "/marketplaceDetails";
    });
  };

  function cancel_delete_poll()
  {
    $(".delete_preview_polls").hide()
  }
  return (

    <div className="content-wrapper mx-auto" >
      {/* edit marketplace */}
      <div
        id="edit_marketplace"
        className="edit_container"
        style={{
          position: "fixed",
          top: "0",
          left: "0px",
          background: "rgba(0,0,0,0.5)",
          padding: "10px",
          width: "100%",
          height: "100%",
          zIndex: "100",
          display: "none",
        }}
      >
        <div
          style={{
            padding: "15px",
            background: "#f5f5f5",
            boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)",
            position: "absolute",
            bottom: "0px",
            top: "0",
            right: "5px",
            width: "420px",
            height: "100%",
         
          }}
        >
          <div
            className="d-flex"
            style={{
              borderBottom: "2px solid #15a312",
              transform: "rotate(0.13deg)",
              paddingBottom: "10px",
             
            }}
          >
            <label
              style={{ color: "black", fontSize: "13px", fontWeight: "700" }}
            >
              Edit Item
            </label>

            <img
              src="dist/img/Cancel.png"
              onClick={() => close_edit_modal()}
              alt="dropdown"
             
              className="close_event ml-auto"
              style={{ cursor: "pointer",width:"20px",height:"20px" }}
            />
          </div>

          <div className="card-body" style={{ margin: "0px", padding: "0" }}>
          <div
             className="preview_form"
              style={{
              
                fontSize: "11PX",
                margin: "5px 0 0 0",
                padding: "0px 5px 60px 0px",
                overflowY:"auto",
                height:"600px",
               
              }}
            >

              {/*title  */}
              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12" style={{ padding: "0" }}>
                    <div style={{ width: "100%", marginTop: "0px", paddingRight: "0" }} >
                      <div className="d-flex">
                        <label style={{ color: "#1F3977", fontSize: "11px", fontWeight: "600" }}>Title</label>

                        <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX" }}>*</p>
                      </div>

                      <input
                        type="name"
                        id="validreason"
                        className="input_fields"
                        autoComplete="true"
                        onChange={(e) => updateTitle(e.target.value)}
                        value={title}
                        style={{ width: "100%", height: "28px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "12px", paddingLeft: "5PX" }}

                      />

                    </div>

                  </div>

                </div>
              </div>

              {/* description */}
              <div className="mt-2 p-0">
                <div class="row">

                  <div class="col-md-12" style={{ padding: "0" }}>
                    <div style={{ width: "100%", marginTop: "0px", paddingRight: "0" }} >
                      <div className="d-flex">
                        <label style={{ color: "#1F3977", fontSize: "11px", fontWeight: "600" }}>Description</label>

                        <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX" }}>*</p>
                      </div>

                      <textarea
                        type="name"
                        rows="4"
                        className="input_fields"
                        id="news_description"
                        value={description}
                        onChange={(e) => updateDescription(e.target.value)}
                        style={{ width: "100%", height: "80px", border: "0.5px solid #c4c4c4", boxSizing: "border-box", fontSize: "10PX", padding: "5px" }}

                      />

                    </div>

                  </div>

                </div>
              </div>

              {/* add photos */}
              <div className="mt-2 p-0">
            <div class="row">
              <div class="col-md-8">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600" }}>Media</label>

                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                  </div>
                    

                  <label
              for="add_imagee"
              style={{
                background: "rgba(71, 121, 240, 0.3)",
                borderRadius: "2px", fontSize: "12PX", padding: "10px",color:"2D5DD0",
                border: "none",fontWeight:"500"
              }}
            >
               
              Add Photos
            </label>
           {/* preview image */}
            {
              imgData == "null" ?
              (
                <div>
                 
                    <img className="d-flex" id="file-ip-1-preview" src={require("../images/no_image.png")} style={{width:"50px",height:"50px"}}/>
                </div>
              ):(
                <div className="d-flex">
                  {
                    marketplaceImage.map((item,index) =>
                    
                    {
                     
                      return(
                        <div id="remove">
                          <IoCloseCircleSharp onClick={(evt) => deleteImage(item.img_id,index,evt)} style={{cursor:"pointer"}}/>
                        

                          <img src={item.imgs}  alt="marketpace" style={{width:"50px",height:"50px"}}/>
                        
                          </div>
                      )
                      
                    })
                  }
                </div>
              )
            }

            {/* add new image */}

            <div className="d-flex" id="file-ip-1-preview" style={{width:"100%"}}>
              
            {
    imgDataMarketplace.map((item,index) =>
    {
      console.log("get new image addeddddddddddddddddddddddddddd",item)
      return(
<div style={{width:"50px",height:"50px"}} id="remove">
<IoCloseCircleSharp onClick={() => deleteFile(index)} style={{cursor:"pointer"}}/>
<img src={item}  alt="new" style={{height:"100%",width:"100%"}}/>
</div>
      )
     
    })
  }
</div>
            
            <input
              type="file"
              name="photo"
              onChange={getMultipleImage}
              // onChange={(e) => updatePhotos(e.target.files)}
              id="add_imagee"
              multiple="multiple"
              accept="image/*"
              style={{ visibility: "hidden", width: "2PX", position: "absolute" }}
            />
            
                  <div
                    class="NewsTitle"
                    style={{ marginTop: "-6px", display: "none" }}>
                    <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                      Please Write News Title
                    </h4>
                  </div>

                </div>
              </div>

            </div>
          </div>

{/* price */}
              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12 mt-2" style={{ padding: "0", paddingLeft: "5px" }}>
                    <div style={{ width: "100%", marginTop: "0px", paddingRight: "0" }} >
                      <div className="d-flex">
                        <label style={{ color: "#1F3977", fontSize: "11px", fontWeight: "600" }}>Price</label>

                        <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX" }}>*</p>
                      </div>
                      <input type="text" id="app_end_time"
                        onChange={(e) => updatePrice(e.target.value)}
                        value={price}
                        className="input_fields" name="birthdaytime"
                        style={{ border: "1px solid #c4c4c4", width: "100%", paddingLeft: "5PX", fontSize: "11px", height: "28px" }} />

                    </div>

                  </div>

                </div>
              </div>

              {/* user type */}
              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12" style={{ padding: "0" }}>
                    <label
                      style={{
                        color: "#1F3977",
                        fontSize: "11px",
                        fontWeight: "600",
                      }}
                    >
                      User Type
                    </label>

                    <div className="d-flex">
                      <input
                        type="radio"
                        id="all students"
                        name="editUserType"
                        value="1"
                        checked={send_to == 1}
                        onChange={(e) => updateSend_to(e.target.value)}
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />
                      <label
                        for="all students"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "10px",
                          marginLeft: "10PX",
                          marginTop: "4px",
                          fontWeight: "600",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      //  onClick={() => all_student()}
                      >
                        <p style={{ marginLeft: "5px" }}>All Students</p>
                      </label>
                      <input
                        type="radio"
                        id="specific class"
                        name="editUserType"
                        value="2"
                        checked={send_to == 2}
                        onChange={(e) => updateSend_to(e.target.value)}
                        style={{
                          marginLeft: "78px",
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />
                      <label
                        for="specific class"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "10px",
                          marginLeft: "10PX",
                          marginTop: "4PX",
                          fontWeight: "600",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      // onClick={() => specific_class()}
                      >
                        <p style={{ marginLeft: "8px" }}>Specific Recipients</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

{/* sku */}
              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12" style={{ padding: "0", paddingLeft: "5px" }}>
                    <div style={{ width: "100%", marginTop: "0px", paddingRight: "0" }} >
                      <div className="d-flex">
                        <label style={{ color: "#1F3977", fontSize: "11px", fontWeight: "600" }}>SKU</label>

                        <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX" }}>*</p>
                      </div>
                      <input type="text" id="app_end_time"
                        onChange={(e) => updateSku(e.target.value)}
                        value={sku}
                        className="input_fields" name="birthdaytime"
                        style={{ border: "1px solid #c4c4c4", width: "100%", paddingLeft: "5PX", fontSize: "11px", height: "28px" }} />

                    </div>

                  </div>

                </div>
              </div>

{/* sku value */}
              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12" style={{ padding: "0" }}>

                    <div className="d-flex">
                    <input
                        type="radio"
                        id="track_quantity"
                        name="trackInfo"
                        value="1"
                        checked={skuValue == 1}
                        onChange={(e) => updateSkuValue(e.target.value)}
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />
                      <label
                        for="track_quantity"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "10px",
                          marginLeft: "10PX",
                          marginTop: "4px",
                          fontWeight: "600",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      //  onClick={() => all_student()}
                      >
                        <p style={{ marginLeft: "5px" }}>Track Quantity</p>
                      </label>
                      <input
                        type="radio"
                        id="continue_selling"
                        name="trackInfo"
                        value="2"
                        checked={skuValue == 2}
                        onChange={(e) => updateSkuValue(e.target.value)}
                        style={{
                          marginLeft: "78px",
                          width: "20px",
                          height: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        }}
                      />
                      <label
                        for="continue_selling"
                        className="d-flex"
                        style={{
                          color: "black",
                          fontSize: "10px",
                          marginLeft: "10PX",
                          marginTop: "4PX",
                          fontWeight: "600",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      // onClick={() => specific_class()}
                      >
                        <p style={{ marginLeft: "8px" }}>Continue selling when out of stock</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* option name and value */}
              {inputList == ""?
            (
              <div>
                data not found
                </div>
            ):(
              <div>
                {
                    inputList.map((item,i) =>
                    {
                      
                      return(
                        <div>
                        <div className="d-flex">
            <label
                                    style={{
                                      color: "#1F3977",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Option Name
                                  </label>
                                  <p
                                    style={{
                                      color: "#EB2424",
                                      fontWeight: "600",
                                      marginLeft: "3PX",
                                      fontSize: "10px",
                                    }}
                                  >
                                    *
                                  </p>
                                  </div>
                                  
                                  <select
                                  className="form-select form-select-sm "
                                  name="title"
                                  id="marketplaceOptionName"
                                  aria-label=".form-select-sm example"
                                  class="input_fields"
                                
                                  // onChange={(e) => updateOptionName(e.target.value)}
                                  value={item.title}
                                  onChange={e => chnageOption(e, i)}
                                  style={{
                                    width: "100%",
                                    height: "35px",
                                    padding: "5px",
                                    fontSize: "10px",
                                    color: "black",
                                    border: "1px solid #c4c4c4",
                                    borderRadius: "0px",
                                    boxSizing: "border-box",
                                    padding: "6px",
                                  }}
                                >
                                  <option selected="selected" style={{ padding: "6px" }}>
                                    {item.title}
                                  </option>
                                  <option style={{ padding: "6px" }}>Size</option>
                                  <option style={{ padding: "6px" }}>Color</option>
                                  <option style={{ padding: "6px" }}>Material</option>
                                </select>
            
                      
                        <br />
            
            {/* option value */}
                        <div className="d-flex">
                                  <label
                                    style={{
                                      color: "#1F3977",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Option Value
                                  </label>
            
                                  <p
                                    style={{
                                      color: "#EB2424",
                                      fontWeight: "600",
                                      marginLeft: "3PX",
                                      fontSize: "10px",
                                    }}
                                  >
                                    *
                                  </p>
                        </div>
            
            <div className="d-flex" >
                                <input
                                  type="text"
                                  name="value"
                                  id="optionValue"
                                  // onChange={(e) => updateOptionValue(e.target.value)}
                                  // value={optionValue}
                                  value={item.value}
                                  onChange={e => chnageOption(e, i)}
                                  className="input_fields ml10"
                                
                                  style={{
                                    width: "100%",
                                    height: "35px",
                                    border: "1px solid #c4c4c4",
                                    boxSizing: "border-box",
                                    fontSize: "10px",
                                    paddingLeft: "5PX",
                                    
                                  }}
                                />
                                  <div className="btn-box d-flex" >
                                  {inputList.length !== 1 && 
                             <button class="delete mr10"  onClick={() => handleRemoveClick(i)} style={{background:"transparent",marginTop:"0",padding:"0",border:"none"}}>
                             <span
                                                 className="d-flex"
                                                 style={{ marginLeft: "5px" }}
                                               
                                               >
                                                 <img
                                                   src={require("../images/Cancel.png")}
                                                   alt="dropdown"
                                                   style={{ width: "18px", height: "18px",marginLeft:"auto" }}
                                                 />
                                               
                                               </span>
                             </button>
                            }
                                    </div>
                                </div>
            
            </div>
                      )
                    })
                }
                </div>
            )
          
            }

            {/* option name */}
            {/* booked with */}
            <div className="mt-2 p-0">
                <div class="row">

                </div>
              </div>

              <div className="d-flex mt-3">
                <a
                  onClick={() => close_edit_modal()}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "transparent",
                      color: "#1F3977",
                      fontWeight: "600",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>

                <a
                  className="cta"
                  href="#edit_with_password"
                  style={{ backgroundColor: "transparent" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_student"
                    value="Update"
                    //onClick={() => update_edited_Event()}
                    style={{
                      borderRadius: "5px",
                      marginRight: "7px",
                      backgroundColor: "#1F3977",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>
              </div>

              <div
                className="required_filed"
                style={{
                  display: "none",
                  fontSize: "12px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Please Fill The Require Field !!!
              </div>

             </div>
{/* display input options */}

</div>
  
            </div>
          </div>
          {/* </form> */}

       {/* edit popuop with password  */}
      <div id="edit_with_password" className="modaloverlay edit_popup_password">

        <div className="modalContainer" style={{ width: "500px", borderRadius: "0", padding: "10PX", background: "#6C7A99" }}>

          <div className="d-flex" style={{ padding: "5px" }}>

            <p style={{ fontWeight: "600", color: "white", fontSize: "13px", marginTop: "5PX" }}>Edit Item</p>
            <a
              //onClick={close_delete_modal}
              href="#" style={{ marginLeft: "auto", marginTop: "0" }}>
              <img src={require("../images/delete_cancel.png")} style={{ height: "26px", width: "26px" }} />

            </a>
          </div>

          <div style={{ background: "white", padding: "15px", fontSize: "13px" }}>
            <div className="d-flex">
              <p style={{ color: "#2D5DD0" }}>Warning:</p>
              <p style={{ marginLeft: "5px" }}>You are editing a screen. This operation cannot be</p>
            </div>

            <p> undone. Please type the password of the screen Admin into the box below to confirm you really want to do this.</p>

            <div className="d-flex mt-4">
              <p style={{ marginTop: "10PX", fontWeight: "600", fontSize: "13PX" }}>Admin Password:</p>
              <input
                type="password"
                value={deletePassword}
                onChange={(e) => updateDeletePassword(e.target.value)}
                style={{ marginLeft: "6px", width: "70%", borderRadius: "5px", background: "white", height: "40px", fontSize: "13PX", padding: "8px 12px", border: "1px solid #2d5dd0" }}
              />

            </div>
            <div className="d-flex mt-4">
              <div style={{ marginTop: "10PX" }}>
                {deleteErrorCode == 200 ?
                  (
                    <div style={{ color: "green" }}>{deleteErrorMessage}</div>
                  )
                  : (
                    <div style={{ color: "red" }}>{deleteErrorMessage}</div>
                  )

                }
              </div>
              <input
                type="button"
                className="create_btn ml-auto"
                id="delete_single_student"
                value="Edit"
                onClick={() => editWithPassword()}

                style={{ borderRadius: "5px", marginRight: "7px", background: "rgba(235, 36, 36, 0.95)", fontSize: "13PX", padding: "8px 25px" }}
              />
            </div>
          </div>

        </div>

      </div>

{/* delete marketplace */}
      <div id="delete" className="modaloverlay delete_container" style={{zIndex:"100"}}>

        <div className="modalContainer">

          <div className="card-body" style={{ marginTop: "0px", }} >
            <div >

              <p style={{ fontWeight: "600", color: "black", fontSize: "13px" }}>Delete message</p>
              <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>Are You Sure That You Want To Delete This Item?</h2>

              <div className="d-flex mt-3">

                <a
                  //onClick={close_delete_modal}
                  href="#" style={{ marginLeft: "auto" }}>
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"

                    style={{ borderRadius: "5px", backgroundColor: "#c4c4c4", fontSize: "13PX", padding: "8px 12px" }}
                  />
                </a>

                <a className="cta" href="#delete_with_protection" style={{ backgroundColor: "transparent" }}>
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_student"
                    value="Delete"

                    style={{ borderRadius: "5px", marginRight: "7px", backgroundColor: "#d21f3c", fontSize: "13PX", padding: "8px 12px" }}
                  />
                </a>

              </div>
            </div>
          </div>
          {/* </form> */}

        </div>

      </div>

      {/* delete popuop with password */}
      <div id="delete_with_protection" className="modaloverlay delete_popup_password">

        <div className="modalContainer" style={{ width: "500px", borderRadius: "0", padding: "10PX", background: "#6C7A99" }}>

          {/* <div className="card-body" style={{ marginTop: "0px", background: "#6C7A99",borderRadius:"0"}} > */}
          <div className="d-flex" style={{ padding: "5px" }}>

            <p style={{ fontWeight: "600", color: "white", fontSize: "13px", marginTop: "5PX" }}>Delete Item</p>
            <a
              //onClick={close_delete_modal} 
              href="#" style={{ marginLeft: "auto", marginTop: "0" }}>
              <img src={require("../images/delete_cancel.png")} style={{ height: "26px", width: "26px" }} />

            </a>
          </div>

          <div style={{ background: "white", padding: "15px", fontSize: "13px" }}>
            <div className="d-flex">
              <p style={{ color: "#2D5DD0" }}>Warning:</p>
              <p style={{ marginLeft: "5px" }}>You are deleting a screen. This operation cannot be</p>
            </div>

            <p> undone. Please type the password of the screen Admin into the box below to confirm you really want to do this.</p>

            <div className="d-flex mt-4">
              <p style={{ marginTop: "10PX", fontWeight: "600", fontSize: "13PX" }}>Admin Password:</p>
              <input
                type="password"
                value={deletePassword}
                onChange={(e) => updateDeletePassword(e.target.value)}
                style={{ marginLeft: "6px", width: "70%", borderRadius: "5px", background: "white", height: "40px", fontSize: "13PX", padding: "8px 12px", border: "1px solid #2d5dd0" }}
              />

            </div>
            <div className="d-flex mt-4">
              <div style={{ marginTop: "10PX" }}>
                {deleteErrorCode == 200 ?
                  (
                    <div style={{ color: "green" }}>{deleteErrorMessage}</div>
                  )
                  : (
                    <div style={{ color: "red" }}>{deleteErrorMessage}</div>
                  )
                }
              </div>
              <input
                type="button"
                className="create_btn ml-auto"
                id="delete_single_student"
                value="Delete"
                onClick={() => deleteWithPassword()}

                style={{ borderRadius: "5px", marginRight: "7px", background: "rgba(235, 36, 36, 0.95)", fontSize: "13PX", padding: "8px 25px" }}
              />
            </div>
          </div>

        </div>

      </div>

      <div className="show_delete_message " style={{ display: "none", marginLeft: "20px" }}>
        <p style={{ fontWeight: "600", fontSize: "14PX", color: "green" }}>Appointment Deleted Successfully!!</p>
      </div>

      <div className="show_edit_message " style={{ display: "none", marginLeft: "20px" }}>
        <p style={{ fontWeight: "600", fontSize: "14PX", color: "green" }}>Appointment Updated Successfully!!</p>
      </div>

      <div className="row mt-2 mb-2" style={{ width: "100%", marginLeft: "0", padding: "0px 10px" }} >

        <div className="col-md-5 d-flex flex-row" style={{ height: "100%", padding: "0px 5px" }}>
          <h4 style={{ color: "black", fontWeight: "bold", marginTop: "7px" }} >MarketPlace</h4>
        </div>

        <div className="col-md-3 d-flex flex-row" style={{ height: "100%", background: "white", padding: "0", border: "1px solid lightgrey" }}>
          <img src={require("../images/Search.png")} style={{ width: "21px", height: "21px", margin: "5px 0px 0px 3px", }} />
          <Input
            id="search"
            type="text"
            placeholder="Search by item name"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            style={{ background: "white", height: "32px", width: "100%", border: "none", fontWeight: "500", borderRadius: "30PX", fontSize: "11px" }}
          />
        </div>

        <div className="col-md-1 d-flex flex-row">
          {/* <img src="dist/img/Sorting.png" onClick={fetchList} style={{ height: "28px", width: "28px", marginTop: "3px" }} /> */}

        </div>
        <div className="col-md-3 d-flex flex-row">
          <div style={{ marginTop: "0px", padding: "0" }}>

            <a href="/sellItem">


              <button
                type="button"
                className="d-flex buttonContainer news-button"
                defaultValue="Sign Up"

                style={{ padding: "8px 12px", marginTop: "0", background: "#1F3977", flexWrap: "wrap", borderRadius: "5px", marginLeft: "auto", height: "auto",alignItems:"center",justifyContent:"center" }}
              >
                <div style={{ marginLeft: "5px", fontSize: "12.25PX", fontWeight: "400", fontFamily: "Poppins" }}>Sell Item</div>
              </button>

            </a>

          </div>
        </div>
      </div>
      <DataTable
        style={{ border: "1px solid green" }}
        columns={columns}
        data={filteredItems}
        striped
        paginationPerPage={10}
 pagination
 paginationRowsPerPageOptions={[10,20,30,40,50,60,70,80,90,100]}
 paginationComponentOptions={paginationComponentOptions}
        subHeader
        subHeaderComponent={subHeaderComponent}
        highlightOnHover
        defaultSortFieldId={1}

      />
      {/* end news table */}

   {/* **************************preview******************************** */}

    
      <div className="preview_polls" style={{position: "fixed",top: "0", left: "0px",background: "rgba(0,0,0,0.5)",padding: "10px",width: "100%",height: "100%",zIndex: "10",display: "none"}}>
        <div style={{padding: "15px",background: "#f5f5f5",boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)",position: "absolute",bottom: "0px",top: "0",right: "5px",width: "420px",height: "100%"}}>
          <div className="d-flex" style={{borderBottom: "2px solid #15a312",marginTop:"28px",transform: "rotate(0.13deg)",paddingBottom: "10px"}}>
            <label style={{ color: "black", fontSize: "11px", fontWeight: "700" }}>Marketplace</label>

            <img src="dist/img/Cancel.png" alt="dropdown"
                 onClick={() => closePreviewDescription()}
                 style={{ cursor: "pointer",width:"20px",height:"20px",marginLeft:"auto" }}
            />
          </div>

          <div className="preview_form" style={{background: "white",marginTop: "10PX",padding: "5px 10PX",border: "1px solid #C4C4C4",height: "530px",overflowY:"AUTO"}}>
            {data.length != "" ? (
              <Previous_next_button
                data={data}
                passEditData={passEditData}
                passDeleteData={passDeleteData}
              />
            ) : null}
          </div>
        </div>
      </div>
      
        {/*preview delete  */}
        <div
        className="delete_preview_polls"
        style={{
          position: "fixed",
          top: "0",
          left: "0px",
          background: "rgba(0,0,0,0.5)",
          padding: "10px",
          width: "100%",
          height: "100%",
          zIndex: "10",
          display: "none",
        }}
      >
        <div
          style={{
            padding: "15px",
            background: "#f2f2f2",
            boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)",
            position: "relative",
            width: "420px",
            height: "auto",
            overflow: "auto",
            margin: "100px auto",
            borderRadius: "10px",
          }}
        >
          <div className="d-flex">
            {/* <label style={{ color: "black", fontSize: "11px", fontWeight: "700" }}>Polls</label> */}

            <img
              src="dist/img/Cancel.png"
              onClick={() => cancel_delete_poll()}
              alt="dropdown"
              width="18px"
              height="14px"
              className="close_event ml-auto"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="mt-3">
            <p style={{ fontWeight: "600", color: "black", fontSize: "13px" }}>
              Delete message
            </p>
            <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>
              Are You Sure That You Want To Delete This Item?
            </h2>

            <div className="d-flex mt-3">
              <input
                type="button"
                className="create_btn"
                value="Cancel"
                onClick={() => cancel_delete_poll()}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "transparent",
                  fontSize: "13PX",
                  padding: "8px 12px",
                  marginLeft: "auto",
                  color: "#d21f3c",
                }}
              />

              <a
                className="cta"
                href="#preview_delete_with_password"
                style={{ backgroundColor: "transparent" }}
              >
                <input
                  type="button"
                  className="create_btn"
                  id="delete_single_student"
                  value="Delete"
                  // onClick={() =>deleteMessage()}
                  style={{
                    borderRadius: "5px",
                    marginRight: "7px",
                    backgroundColor: "#d21f3c",
                    fontSize: "13PX",
                    padding: "8px 12px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
  {/* preview delete  with password */}
  <div
        id="preview_delete_with_password"
        className="modaloverlay delete_popup_password"
      >
        <div
          className="modalContainer"
          style={{
            width: "500px",
            borderRadius: "0",
            padding: "10PX",
            background: "#6C7A99",
          }}
        >
          {/* <div className="card-body" style={{ marginTop: "0px", background: "#6C7A99",borderRadius:"0"}} > */}
          <div className="d-flex" style={{ padding: "5px" }}>
            <p
              style={{
                fontWeight: "600",
                color: "white",
                fontSize: "13px",
                marginTop: "5PX",
              }}
            >
              Delete Item
            </p>
            <a
              onClick={close_delete_modal}
              href="#"
              style={{ marginLeft: "auto", marginTop: "0" }}
            >
              <img
                src={require("../images/delete_cancel.png")}
                style={{ height: "26px", width: "26px" }}
              />
            </a>
          </div>

          <div
            style={{ background: "white", padding: "15px", fontSize: "13px" }}
          >
            <div className="d-flex">
              <p style={{ color: "#2D5DD0" }}>Warning:</p>
              <p style={{ marginLeft: "5px" }}>
                You are deleting a screen. This operation cannot be
              </p>
            </div>

            <p>
              {" "}
              undone. Please type the password of the screen Admin into the box
              below to confirm you really want to do this.
            </p>

            <div className="d-flex mt-4">
              <p
                style={{
                  marginTop: "10PX",
                  fontWeight: "600",
                  fontSize: "13PX",
                }}
              >
                Admin Password:
              </p>
              <input
                type="password"
                // className="create_btn"
                // id="delete_single_student"
                value={deletePassword}
                onChange={(e) => updateDeletePassword(e.target.value)}
                style={{
                  marginLeft: "6px",
                  width: "70%",
                  borderRadius: "5px",
                  background: "white",
                  height: "40px",
                  fontSize: "13PX",
                  padding: "8px 12px",
                  border: "1px solid #2d5dd0",
                }}
              />
            </div>
            <div className="d-flex mt-4">
              <div style={{ marginTop: "10PX" }}>
                {deleteErrorCode == 200 ? (
                  <div style={{ color: "green" }}>{deleteErrorMessage}</div>
                ) : (
                  <div style={{ color: "red" }}>{deleteErrorMessage}</div>
                )}
              </div>
              <input
                type="button"
                className="create_btn ml-auto"
                id="delete_single_student"
                value="Delete"
                // onClick={() => previewDeleteWithPassword()}
                onClick={() => deleteWithPassword()}
                style={{
                  borderRadius: "5px",
                  marginRight: "7px",
                  background: "rgba(235, 36, 36, 0.95)",
                  fontSize: "13PX",
                  padding: "8px 25px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}