import React,{ useState } from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu"
import { StudentList } from './StudentList'
export function Student() {
  const [loc,setLoc] = useState(window.location.pathname);
  return (
    <div>
      <Header />
      <div className='d-flex'>
      <Menu pageTitle={loc} />
       <StudentList />
    </div>
    </div>
  )
}
