import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import $, { event } from "jquery";
import { Header } from "./Header";
import { Menu } from "./Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory, useLocation } from "react-router-dom";
import StateAndCities from "./State and Cities/StateAndCities.json"

export const StudentOffersStep2 = () => {
  const token = localStorage.getItem("Token");
  var todayy = "";
  const history = useHistory();

  const location = useLocation();
  const { offerName } = location.state || { id: "none" };
  const { offerDesc } = location.state || { id: "none" };
  const { offerBrandName } = location.state || { id: "none" };
  const { offerDiscount } = location.state || { id: "none" };
  const { offerLogo } = location.state || { id: "none" };
  const { offerCoverImg } = location.state || { id: "none" };
  const { offerAvailableOn } = location.state || { id: "none" };

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [uniData, setUniData] = useState([]);
  const [stateName, setStateName] = useState("");

  const [offerState, setOfferState] = useState("");
  const [offerCity, setOfferCity] = useState("");
  const [university, setUniversity] = useState("");
  const [offerStartDate, setOfferStartDate] = useState("");
  const [offerEndDate, setOfferEndDate] = useState("");
  const [offerPublishDate, setOfferPublishDate] = useState("");
  const [offerPublishExpiryDate, setOfferPublishExpiryDate] = useState("");
  const [offerLink, setOfferLink] = useState("");
  const [offerVoucherCode, setOfferVoucherCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [queryEmail, setQueryEmail] = useState("");

  const [stepsDescription, setStepsDescription] = useState([]);
  const [stepImages, setStepImages] = useState([]);
  const [stepDesc, setStepDesc] = useState("");

  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [stateNameData, setStateNameData] = useState([]);
  const [cityNameData, setCityNameData] = useState([]);

  const isDisabled = !offerStartDate;
  const publishDateIsDisabled = !offerEndDate;
  const expiryDateIsDisabled = !offerPublishDate;

//   const [elements, setElements] = useState([{ind_no:1, type: 'textarea', value: '' },
//                                 {ind_no:1, type: 'image', value: 'dist/img/event_photo.png' }]);
//  const [test_val,setTest_val] = useState(2);
//  var desc_array=[];

  todayy = new Date().toISOString().slice(0, 10);


  const getCityData = async (e) => {
    stateData.map((item) => {
      if (item.state_id == e) {
        setStateName(item.state_name);
      }
    });
    setOfferState(e);
    try {
      const formData = new FormData();
      formData.append("state_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_state_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const getCityAndUniData = async (e) => {
    setUniData([]);
    setOfferCity(e)
    try {
      const formData = new FormData();
      formData.append("city_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_city_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const stateFun =() =>{
    setStateCityData(StateAndCities)
    stateCityData.map((e) =>{
      setStateNameData(e.states);
    })
}

  const onStateChange = async (e) =>{
    setOfferState(e);
    stateNameData.map((_e) =>{
      if(_e.state == e){
        setCityNameData(_e.districts);
      }
    })
    try {
      const formData = new FormData();
      formData.append("state_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY +
          "super_admin_get_university_list_state_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  useEffect(() => {
    stateFun();
  }, []);



  async function createOffers() {
    try {
      // var total_disc=test_val -1;
      // if(total_disc != 0){
      //   for(let i =1 ; i <= total_disc ;i++){
      //     var desc=$('#offer_desc'+i).val();
      //     if(desc !=""){
      //       desc_array.push(desc);
      //     }
      //   }
      // }
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

               if (offerState == "") {
                 $(".offer_state").show();

                 setTimeout(function () {
                 $(".offer_state").hide();
                 }, 3000);
                 } else if (offerStartDate == "") {
                 $(".start_date").show();

                 setTimeout(function () {
                 $(".start_date").hide();
                 }, 3000);
                 }
                 else if (offerEndDate == "") {
                 $(".end_date").show();

                 setTimeout(function () {
                 $(".end_date").hide();
                 }, 3000);
                 }else if (offerVoucherCode == "") {
                  $(".offer_voucher_code").show();

                  setTimeout(function () {
                  $(".offer_voucher_code").hide();
                  }, 3000);
                  }
                 else if (offerPublishDate == "") {
                 $(".publish_date").show();

                 setTimeout(function () {
                 $(".publish_date").hide();
                 }, 3000);
                 } else if (offerPublishExpiryDate == "") {
                 $(".publish_expiry_date").show();

                 setTimeout(function () {
                 $(".publish_expiry_date").hide();
                 }, 3000);
                 }else if (offerLink == "") {
                 $(".offer_link").show();

                 setTimeout(function () {
                 $(".offer_link").hide();
                 }, 3000);
                 }
                 else if (queryEmail == "") {
                  $(".job_email").show();

                  setTimeout(function () {
                    $(".job_email").hide();
                  }, 3000);
                }else if (!filter.test(queryEmail )) {
                  $(".validEmail").show();

                  setTimeout(function() {
                    $(".validEmail").hide();
                  }, 3000);
                }
                  {

      const formData = new FormData();
      formData.append("offername", offerName);
      formData.append("offerdesc", offerDesc);
      formData.append("brand_name", offerBrandName);
      formData.append("discount", offerDiscount);
      formData.append("prod_available_on", offerAvailableOn);
      formData.append("state", offerState);
      formData.append("city", offerCity);
      formData.append("university", university);
      formData.append("offerlink", offerLink);
      formData.append("v_code", offerVoucherCode);
      formData.append("start_date_time", offerStartDate);
      formData.append("end_date_time", offerEndDate);
      formData.append("publish_start_date_time", offerPublishDate);
      formData.append("publish_end_date_time", offerPublishExpiryDate);
      formData.append("comp_logo", offerLogo);
      formData.append("cover_img", offerCoverImg);
      formData.append("query_email", queryEmail);
      // formData.append("step_descriptions", JSON.stringify(desc_array));

      // for (var i = 0; i < stepImages.length; i++) {
      //   formData.append("step_images[]", stepImages[i]);
      // }
      const creareOfferResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_add_student_offer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      console.log("creareOfferResponse----------",creareOfferResponse);
      setErrorMsg(creareOfferResponse.data.message);
      if (creareOfferResponse.data.error_code == 200) {
        $(".formSuccess").show();

        setTimeout(function () {
          $(".formSuccess").hide();
        }, 3000);

        history.push("/StudentOffersList");
      }
    }
    } catch (err) {
      console.log("create offer error-------", err);
    }
  }

  const resetValues = () =>{
    setOfferState("");
    setOfferCity("");
    setUniversity("");
    setOfferStartDate("");
    setOfferEndDate("");
    setOfferPublishDate("");
    setOfferPublishExpiryDate("");
    setOfferLink("");
    setOfferVoucherCode("");
    $("#state_name").val("disabled")
    $("#city_name").val("disabled")
    $("#university").val("disabled")
    setStepsDescription([]);
  }

  function preview() {
    $(".preview_polls").show();
  }

  function closePreview() {
    $(".preview_polls").hide();
  }

  function edit_category() {
    $(".preview_polls").hide();
    $(".preview_category").show();
  }
  function closeEditForm() {
    $(".preview_category").hide();
  }

  // const handleAddElement = () => {
  //   setStepsDescription([...stepsDescription, setStepDesc]);
  //   if(test_val == 1){
  //     setTest_val(2);
  //   }else{
  //     let aa = test_val + 1;
  //   setTest_val(aa);
  //   }
  //   var t_cnt=elements.length/2;
  //   setStepDesc("")
  //   setElements((prevElements) => [
  //     ...prevElements,
  //     {ind_no:test_val, type: 'textarea', value: '' },
  //     {ind_no:test_val, type: 'image', value: 'dist/img/event_photo.png' },
  //   ]);
  // };

  // const handleRemoveElement = (ind) => {
  //   setElements((prevElements) => {
  //     const newElements = [...prevElements];
  //     var t_cnt=newElements.length/2;
  //     const elementsToRemove = prevElements.filter(
  //       (element) => element.ind_no === t_cnt
  //     );

  //     const indexOfLastElementToRemove = prevElements.lastIndexOf(
  //       elementsToRemove[elementsToRemove.length - 2]
  //     );
  //     if(test_val >1 ){
  //       setTest_val(test_val - 1);
  //     }else{
  //       setTest_val(2);
  //     }
  //     newElements.splice(indexOfLastElementToRemove, 2)
  //     return newElements;
  //   });
  // };


  // const handleChange = (index, event) => {
  //   setStepsDescription([...stepsDescription, event.target.value]);




    // setStepDesc(event.target.value);

    // setStepsDescription((prevElements) => {
    //   const newElements = [...prevElements];
    //   if (event.target && event.target.value !== undefined) {
    //     newElements[index].value = event.target.value;
    //   }
    //   return newElements;
    // });
  // };


  // const handleImageChange = (ind, event) => {
  //   if (event.target && event.target.files && event.target.files[0]) {
  //     setStepImages([...stepImages,event.target.files[0]]);
  //     var src = URL.createObjectURL(event.target.files[0]);
  //     var prev = document.getElementById(`steps_prev_img${ind}`);
  //     prev.src = src;
  //     prev.style.display = "block";
  //   }
  // };

  const handleStartDateChange = (e) => {
    setOfferStartDate('')
    setOfferStartDate(e.target.value);

    setOfferEndDate('');
    setOfferPublishDate('');
    setOfferPublishExpiryDate('')
  };

  const handleEndDateChange = (e) => {
    setOfferEndDate(e.target.value);
    setOfferPublishDate('');
    setOfferPublishExpiryDate('')
  };

  const expiryDateLogic = (e) => {
    setOfferPublishDate(e.target.value);
    setOfferPublishExpiryDate('')
  };

  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu />
          <div className="content-wrapper">
            <div
              class="formSuccess"
              style={{
                marginTop: "5px",
                width: "100%",
                marginRight: "198px",
                display: "none",
              }}
            >
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert variant="filled" severity="success">
                  {errorMsg}
                </Alert>
              </Stack>
            </div>

            <div className="border_class2">
              <div style={{ padding: "6px" }}>
                <div className="polls_heading_h1">CREATE OFFERS</div>
              </div>

              <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 2 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                     Offer Reciplents and Schedule
                  </p>
                </div>
              </div>
            </div>

            <div className=" border_class box_padding">
              <div className="row">
                <div class="col-md-3">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">State</label>

                        <p className="all_stars">*</p>
                      </div>

                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="state_name"
                        onChange={(e) => onStateChange(e.target.value)}
                      >
                         <option value={"disabled"}>Select State</option>
                        {stateNameData.map((e) => {
                          return (
                            <option value={e.state}>{e.state}</option>
                          );
                        })}
                      </select>

                      <div class="offer_state" style={{ display: "none" }}>
                        <h4 className="all_validations_h4">
                          Please Select State
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">City</label>
                      <br />
                      <p className="all_stars"></p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="city_name"
                      onChange={(e) => getCityAndUniData(e.target.value)}
                    >
                      <option value = "disabled">Select City</option>
                        {cityNameData.map((name) => {
                          return (
                            <option value={name}>
                              {name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">University</label>
                      <br />
                      <p className="all_stars"></p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="university"
                      onChange={(e) => setUniversity(e.target.value)}
                    >
                      <option value = "disabled">Select University</option>
                      {uniData.map((e) => {
                        return <option value={e.uni_id}>{e.uni_name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div class="row" style={{ marginTop: "20px" }}>
                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Start Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                    type="datetime-local"
                    className="form_control input_box"
                    min={todayy + "T00:00"}
                    value={offerStartDate}
                    onChange={handleStartDateChange}
                    name="startDateTime"
                    selected={offerStartDate}

                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd HH:mm"
                    />

                    <div
                      class="start_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Select Start Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">End Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                     type="datetime-local"
                     className="form_control input_box end_date_login"
                     min={offerStartDate}
                     value={offerEndDate}
                     onChange={handleEndDateChange}
                     name="endDateTime"
                     disabled={isDisabled}
                     selected={offerEndDate}
                    />
                    <div
                      class="end_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Select End Date
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">Voucher Code</label>
                        <p className="all_stars">*</p>
                      </div>
                      <input
                        className="all_inputs"
                        type="name"
                        id="offer_voucher_code"
                        name="offername"
                        placeholder="Voucher Code.."
                        autoComplete="true"
                        value={offerVoucherCode}
                        required="1"
                        onChange={(e) => setOfferVoucherCode(e.target.value)}
                      />
                      <div
                        class="offer_voucher_code"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4 className="all_validations_h4">
                          Please Enter Voucher Code
                        </h4>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="border_class box_padding">
              <div class="row">
                <div class="col-md-12">
                  <div
                    className=""
                    style={{ width: "100%", marginTop: "0px" }}
                    id="new_delivery_type"
                  >

                    <div className="d-flex">
                      <label className="all_labels">
                        When it should be published?
                      </label>

                      <p className="all_stars"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20px" }}>
                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Publish Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                    type="datetime-local"
                    className="form_control input_box"
                    min={offerStartDate}
                    max={offerEndDate}
                    placeholder="Now"
                    id="publish_date"
                    value={offerPublishDate}
                    disabled={publishDateIsDisabled}
                    onChange={expiryDateLogic}
                    name="birthdaytime"
                    />

                    <div
                      class="publish_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter publish Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Expiry Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                     type="datetime-local"
                     className="form_control input_box"
                     min={offerPublishDate}
                     max={offerEndDate}
                     placeholder="dd-mm-yyyy hh-mm"
                     id="publish_date"
                     value={offerPublishExpiryDate}
                     disabled={expiryDateIsDisabled}
                     onChange={(e) => setOfferPublishExpiryDate(e.target.value)}
                     name="birthdaytime"
                    />

                    <div
                      class="publish_expiry_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Select Publish Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 ">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">Offer Link</label>

                        <p className="all_stars">*</p>
                      </div>
                      <input
                        className="input_box"
                        type="text"
                        autoComplete="true"
                        placeholder="Link goes here.."
                        value={offerLink}
                        onChange={(e) => setOfferLink(e.target.value)}
                      />

                      <div
                        class="offer_link"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4 className="all_validations_h4">
                          Please enter offer link
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                  <div
                    style={{
                      width: "100%",

                      paddingRight: "0",
                    }}
                  >
                    <div className="d-flex">
                      <label className="all_labels">For Queries</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                      type="email"
                      className="input_box"
                      id="email"
                      placeholder="Company email goes here.."
                      value={queryEmail}
                      onChange={(e) => setQueryEmail(e.target.value)}
                    />

                    <div
                      class="job_email"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter Company Email
                      </h4>
                    </div>
                    <div
                    class="validEmail"
                    style={{  marginTop: "-6px", display: "none"}}
                  >
                    <h4 className="all_validations_h4">
                      Please Enter valid Email Address with @
                    </h4>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="border_class ">
              <div class="row">
              <div class="col-md-12">
                  <div
                    className=""
                    style={{ width: "100%", marginTop: "0px" }}
                    id="new_delivery_type"
                  >

                    <div className="d-flex">
                      <label className="all_labels">
                        Offer Redeem Steps
                      </label>

                      <p className="all_stars"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
              {elements.map((element, index) => {
                return(<>

                {element.type === 'textarea' && (
              <div className="col-md-6 mt-2">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">Offer Step: {element.ind_no}</label>

                        <p className="all_stars">*</p>
                      </div>
                      <textarea
                        className="all_inputs"
                        id={`offer_desc${element.ind_no}`}
                        name="offerdesc"
                        placeholder="Step goes here..."
                        rows={4}

                        onChange={(event) => handleChange(element.ind_no, event)}
                        style={{ height: "80px" }}
                      />
                      <div
                        class="offer_desc"
                        style={{ margin: "0", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "12PX",
                            marginLeft: "0",
                          }}
                        >
                          Please Enter Offer Description
                        </h4>
                      </div>
                    </div>
                  </div>
                    )}

                 {element.type === 'image' && (
                  <div class="col-md-2">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label className="all_labels">Image</label>

                  <p className="all_stars"></p>
                </div>
                  <div className="d-flex">
                    <div>


                <label for={`file-ip-1${element.ind_no}`}>
                  <img
                    className="image_std"
                    id={`steps_prev_img${element.ind_no}`}
                    src={element.value}
                    alt={`Image ${index + 1}`}
                    style={{ height: "87px", width: "140px"}}
                  />
                </label>

                <input
                  type="file"
                  name="photo"
                  style={{ visibility: "hidden",display:"none" }}
                  accept="image/png, image/gif, image/jpeg"

                  onChange={(event) => handleImageChange(element.ind_no, event)}
                  id={`file-ip-1${element.ind_no}`}
                />
                 </div>

                    </div>

              </div>
            </div>
                   )}
                     </>)
                  })}

                  <div className="col-md-2 p-0"
                  style={{
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <button
                        onClick={() => handleAddElement()}
                        style={{ border: "none", background: "white" }}
                      >
                        <img
                          src={require("./images/add.png")}
                          style={{
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                    </div>
                    <div style={{display:"flex",alignItems:"end"}}>
                      {elements.length !==2 &&(
                      <button
                        onClick={() => handleRemoveElement(elements.ind_no)}
                        style={{ border: "none", background: "white" }}
                      >
                        <img
                          src={require("./images/wrong.jpg")}
                          style={{
                            height: "30px",
                            width: "30px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                      )}
                    </div>
                  </div>
                </div>

              </div>
            </div> */}

            {/* Buttons */}

            <div className="d-flex   border_class box_padding buttons_div">
              <div
                class="ValueMsg"
                style={{ margin: "8px", width: "57%", display: "none" }}
              >
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert variant="filled" severity="error">
                    Error! You Must Fill In All The Fields
                  </Alert>
                </Stack>
              </div>

              <img
                className="delete_image"
                src="dist/img/delete.png"
                alt="dropdown"
                onClick={() => resetValues()}
              />
              <span className="news_bar">|</span>
              <button className="preview_button " onClick={preview}>
                <span className="preview_font">Preview</span>
                <div className="preview_img_div">
                  <img
                    className="preview_image"
                    src="dist/img/view.png"
                    alt="dropdown"
                  />
                </div>
              </button>

              <input
                type="button"
                className="publish_button"
                defaultValue="Sign Up"
                onClick={() => createOffers()}
                value="Publish"
              />
            </div>

            {/* Preview */}
            <div className="preview_polls">
              <div className="preview_polls_inner_div1">
                <div className="preview_polls_inner_label">
                  <label>Preview</label>

                  <button
                    style={{ border: "none", marginLeft: "auto" }}
                    onClick={() => closePreview()}
                  >
                    <img
                      src="dist/img/Cancel.png"
                      alt="dropdown"
                      className="cancel_img "
                    />
                  </button>
                </div>

                {/* jobCategory == "" ?

               (
                 <div>Please fill the form to preview it</div>
              ) : ( */}
                <div className="preview_polls_inner_div2">
                  <div className="d-flex" style={{ padding: "10px 0px" }}>
                    <h4 className="preview_h4">Offers</h4>
                    <img
                      src="dist/img/Pencil.png"
                      onClick={() => edit_category()}
                      alt="dropdown"
                      className="edit_img"
                    ></img>
                  </div>

                  {
                    <div>
                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Company Logo
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{"  "}
                          {offerLogo == ""?(
                            <div>
                            <img
                              src={require("./images/no_image.png")}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "5px",
                              }}
                            />
                            </div>
                          ):(
                            <div>
                          <img
                            src={offerLogo}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "5px",
                            }}
                          />
                          </div>
                          )}

                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Offer Name</p>
                        <p className="col-md-9 preview_row_inner">:{offerName}</p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Offer Description
                        </p>
                        <p className="col-md-9 d-flex preview_row_inner">:
                        <p dangerouslySetInnerHTML={{ __html: offerDesc }}/></p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Offer Cover Photo
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{"   "}
                          {offerCoverImg == ""?(
                            <div>
                            <img
                              src={require("./images/no_image.png")}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "5px",
                              }}
                            />
                            </div>
                          ):(
                            <div>
                          <img
                            src={offerCoverImg}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "5px",
                            }}
                          />
                          </div>
                          )}

                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">State</p>
                        <p className="col-md-9 preview_row_inner">
                          :{offerState}
                        </p>
                      </div>
                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Start Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {offerStartDate}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          End Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {offerEndDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Publish Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {offerPublishDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Expiry Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {offerPublishExpiryDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Offer Link</p>
                        <p className="col-md-9 preview_row_inner">
                          : {offerLink}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                           Voucher Code
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {offerVoucherCode}{" "}
                        </p>
                      </div>
                    </div>
                  }
                </div>
                {/* ) */}
                {/* } */}

                <div
                  class="EnterValue"
                  style={{ margin: "10px auto", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "12PX", marginLeft: "0" }}
                  >
                    You Must Fill In All The Fields
                  </h4>
                </div>
              </div>
            </div>

            {/* **************************** Edit Offer ***************************** */}
            <div className="preview_category">
              <div className="edit_inner">
                <div className="d-flex edit_inner_div">
                  <label className="main_labels">Offer</label>

                  <img
                    src="dist/img/Cancel.png"
                    alt="dropdown"
                    className="close_event ml-auto cancel_img"
                    onClick={closeEditForm}
                  />
                </div>

                <div className="preview_form">
                  {/* <div className="border_class edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Add Company Logo
                              </label>
                            </div>

                            <div>

                              <div>
                                <label
                                  for="file-ip-1"
                                  style={{ height: "100%" }}
                                >
                                  <img
                                    className="image_std"
                                    src={require("./images/no_image.png")}
                                    alt="no image"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                  <img
                                    id="file-ip-1-preview"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "6PX",
                                      display: "none",
                                    }}
                                  />
                                </label>

                                <input
                                  type="file"
                                  name="photo"
                                  style={{
                                    visibility: "hidden",
                                    display: "none",
                                  }}
                                  accept="image/png, image/gif, image/jpeg"

                                  id="file-ip-1"
                                />
                              </div>

                            </div>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 p-0">
                        <div class="row edit_row_margin">
                          <div class="col-md-12">
                            <div
                              className=""
                              style={{ width: "100%", marginTop: "0px" }}
                            >
                              <div className="d-flex">
                                <label className="edit_all_labels">
                                  Offer Name
                                </label>

                                <p className="all_stars">*</p>
                              </div>
                              <input
                                className="edit_all_inputs"
                                type="name"
                                autoComplete="true"
                                value={offerName}
                                onChange={(e) => setOfferName(e.target.value)}
                              />

                              <div
                                class="newsDescription"
                                style={{ marginTop: "-6px", display: "none" }}
                              >
                                <h4
                                  class="login-text"
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    marginLeft: "0",
                                  }}
                                >
                                  Please enter event name
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="border_class edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Offer Description
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <textarea
                              className=" edit_all_inputs"
                              id="news_description"
                              style={{ height: "80px" }}
                            ></textarea>
                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Enter Event Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="border_class2 edit_row_padding2">
                    <div className="p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Offer Cover Photo
                              </label>
                            </div>

                            <div>

                              <div>
                                <label
                                  for="file-ip-1"
                                  style={{ height: "100%" }}
                                >
                                  <img
                                    className="image_std"
                                    src={require("./images/no_image.png")}
                                    alt="no image"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                  <img
                                    id="file-ip-1-preview"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "6PX",
                                      display: "none",
                                    }}
                                  />
                                </label>

                                <input
                                  type="file"
                                  name="photo"
                                  style={{
                                    visibility: "hidden",
                                    display: "none",
                                  }}
                                  accept="image/png, image/gif, image/jpeg"

                                  id="file-ip-1"
                                />
                              </div>

                            </div>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">State</label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="e_offer_state"
                              onChange={(e) => getCityData(e.target.value)}
                            >
                              <option value={"disabled"}>Select State</option>
                              {stateData.map((e) => {
                                return (
                                  <option value={e.state_id}>
                                    {e.state_name}
                                  </option>
                                );
                              })}
                            </select>
                            <div
                            class="e_offer_state"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please select state
                            </h4>
                          </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">City</label>
                              <p className="all_stars"></p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="student_course"
                                     onChange={setOfferCity}
                            >
                              <option selected="selected">Select City</option>

                              {cityData.map((name) => {
                                return (
                                  <option value={name.city_name}>
                                    {name.city_name}
                                  </option>
                                );
                              })}
                            </select>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                University
                              </label>
                              <p className="all_stars"></p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="edit_offer_univercity"
                              onChange={(e) => setUniversity(e.target.value)}
                            >
                              <option selected="selected">
                                Select University
                              </option>
                              {uniData.map((e) => {
                                 return <option value={e.uni_id}>{e.uni_name}</option>;
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div className="d-flex">
                            <label className="edit_all_labels">
                              Start Date/Time
                            </label>

                            <p className="all_stars">*</p>
                          </div>
                          <input
                            type="datetime-local"
                            className="form_control edit_all_inputs"
                            min={todayy + "T00:00"}
                            placeholder="Now"
                            id="edit_start_date"
                            value={offerStartDate}
                            onChange={(e) => setOfferStartDate(e.target.value)}
                            name="birthdaytime"
                          />
                          <div
                            class="edit_start_date"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please select start date
                            </h4>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div>
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                End Date/Time
                              </label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              type="datetime-local"
                              className="form_control edit_all_inputs"
                              min={todayy + "T00:00"}
                              placeholder="dd-mm-yyyy hh-mm"
                              id="edit_end_date"
                              value={offerEndDate}
                              onChange={(e) => setOfferEndDate(e.target.value)}
                              name="birthdaytime"
                            />
                            <div
                              class="edit_end_date"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please select end date
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                      <div className="pb-2">
                              <label className="edit_all_labels">
                                When it should be published?
                              </label>
                            </div>

                        <div class="col-md-6">
                          <div className="d-flex">
                            <label className="edit_all_labels">
                              Publish Date/Time
                            </label>

                            <p className="all_stars">*</p>
                          </div>
                          <input
                            type="datetime-local"
                            className="form_control edit_all_inputs"
                            min={todayy + "T00:00"}
                            placeholder="Now"
                            id="edit_publish_date"
                            value={offerPublishDate}
                            onChange={(e) => setOfferPublishDate(e.target.value)}
                            name="birthdaytime"
                          />

                          <div
                            class="edit_publish_date"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please select publish date
                            </h4>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Expiry Date/Time
                              </label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              type="datetime-local"
                              className="form_control edit_all_inputs"
                              min={todayy + "T00:00"}
                              placeholder="dd-mm-yyyy hh-mm"
                              id="edit_expiry_date"
                              value={offerPublishExpiryDate}
                              onChange={(e) => setOfferPublishExpiryDate(e.target.value)}
                              name="birthdaytime"
                            />

                            <div
                              class="edit_expiry_date"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please select expiry date
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Offer Link
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="text"
                              id="edit_offer_link"
                              autoComplete="true"
                              placeholder="Link goes here.."
                              value={offerLink}
                              onChange={(e) => setOfferLink(e.target.value)}
                            />

                            <div
                              class="edit_offer_link"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please enter offer Link
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                               <label className="edit_all_labels">
                                   Voucher Code
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <input
                              className="edit_all_inputs"
                              id="edit_voucher_code"
                              type="text"
                              autoComplete="true"
                              placeholder="Voucher Code..."
                              value={offerVoucherCode}
                              onChange={(e) => setOfferVoucherCode(e.target.value)}
                            />

                            <div
                              class="edit_voucher_code"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please enter Voucher Code
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ******************button********************** */}

                  <div
                    className="d-flex mt-3 edit_buttons_div border_class"
                    style={{ justifyContent: "end" }}
                  >
                    <input
                      type="button"
                      className="edit_cancel_button"
                      onClick={() => closeEditForm()}
                      value="Cancel"
                    />

                    <input
                      type="button"
                      className="edit_update_button"
                      id="delete_single_student"
                      value="Update"
                      onClick={() => closeEditForm()}
                    />
                  </div>

                  <div style={{ display: "none" }} className="saveMessage">
                    Data Saved Successfully
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
