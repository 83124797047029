import React,{ useState } from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu"
import { MarketplaceForm } from './MarketplaceForm'
export function SellItem() {
    const [loc,setLoc] = useState("/marketplaceDetails");
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} />
            <MarketplaceForm />
            </div>
        </div>
    )
}
