import React, { useState, useEffect ,Component, useMemo } from "react";
import DataTable from "react-data-table-component";
// import StudentFilterComponent from "./StudentFilterComponent";
import styled from "styled-components";

import axios from "axios";
import $ from "jquery";
import { AiFillEye } from "react-icons/ai";

import { Link,useHistory,useLocation } from 'react-router-dom';
import {   } from "react-icons/ai";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";





// import '../AdminLogin.css'

// import StudentExcel from "../../StudentFormat/uploadStudent.xlsx";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;


// const customStyles = {
//   rows: {
//     style: {
//       background: "rgba(228, 233, 243, 0.6)",
//       marginTop: "6PX",
//       border: "none",

//     },
//   },
//   headCells: {
//     style: {

//       color:"#1F3977",
//      },
//   },
//   cell:{
//     style:{
//       display:"flex",
//       justifyContent:"center"
//     }
//   },

//   head: {
//     style: {

//       boxShadow: "0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)",
//       display:"flex",
//       justifyContent:"center"
//     },
//   },
//   table: {
//     style: {

//       marginTop: "0PX",
//       height: "auto",
//     },
//   },

// };

const customStyles = {
  rows: {
    style: {
      background: "rgba(228, 233, 243, 0.6)",
      marginTop: "3PX",
      border: "none",
      fontSize:"10px",
      fontWeight:"500"
    },
  },
  headCells: {
    style: {
      color: "#1F3977",
      fontWeight: "500",
      fontSize: "10px",
    },
  },

  table: {
    style: {
      padding: "0px 10px 0 10px",
      marginTop: "0PX",
      // height: "180px",
    },
  },
};

export function StudentList() {

  const [data, setData] = useState([]);
  const token = localStorage.getItem("Token");
  const [teacherList, setTeacherList] = useState([]);
  const [teacherId,setTeacherId] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [statusMsg, setStatusMsg] = useState();

  const location = useLocation();
  const history = useHistory();
  const { campusId } = location.state || { id: "none" };


  async function fetchList() {

    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "get_campus_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );


      const campusListArry = fetchResponse.data.data;

      setData(campusListArry);

    } catch (err) {
      console.log("Log in Fail", err);
    }
  }



  const paginationComponentOptions = {
    selectAllRowsItem: true,

    selectAllRowsItemText: "ALL"
  };
  async function fetchStudentData()
  {

    const formData = new FormData();
    formData.append("campus_id", campusId);

    const fetchResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_admin_get_campuswise_student",
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },

      }
    );


    console.log("Campuswise All Students-----------------", fetchResponse);

    setStudentList(fetchResponse.data.data)

  }

  async function activeStudent(r_id)
  {

    const formData = new FormData();
    formData.append("stud_id", r_id);

    const fetchResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_admin_change_student_status",
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },

      }
    );

    setStatusMsg(fetchResponse.data.message);
    $('.actions_modal'+tId).hide();
    fetchStudentData();


    $(".success_msg").show();
    setTimeout(function () {
      $(".success_msg").hide();
    }, 2000);



  }

  async function freezeStudent(r_id)
  {
    const formData = new FormData();
    formData.append("stud_id", r_id);

    const fetchResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_admin_change_student_freez_acc_status",
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },

      }
    );

    // console.log("error_code",fetchResponse.data.error_code)
    console.log("freeze Students-----------------", fetchResponse);

    setStatusMsg(fetchResponse.data.message);
    $('.actions_modal').hide();
    fetchStudentData();

    $('.actions_modal'+tId).hide();
    $(".success_msg").show();
    setTimeout(function () {
      $(".success_msg").hide();
    }, 2000);

  }


  useEffect(() => {
    fetchList();
    fetchStudentData();

  }, []);

  const [tId, setTId] = useState("");
  const openActionsModal = (e) => {
    setTId(e);
    $('.edit_campus_modal').hide();
    $('.actions_modal'+e).toggle();
  }
  const closeActionsModal = (e) => {
    $('.edit_campus_modal').hide();
  }



 const columns = [

  {
    name: "Student Name",
    selector:(row)=> row.first_name,
    sortable: true,
    wrap: true,
    width: "auto",

  },
  {
    name: "Student ID",
    selector:(row)=> row.id,
    sortable: true,
    wrap: true,
    width: "auto",

  },
  {
    name: "Persona",
    selector:(row)=> row.persona,
    wrap: true,
    width: "auto",

  },
  {
    name: "Email Id",
    selector:"email",
    wrap: true,

    cell: (row) => {
      return (
        // <div style={{fontWeight:"700"}}>{row.email}</div>
        <span
          className="overflow_class ten_font_class"
          style={{ width: "130px" }}
        >
          {row.email}
        </span>
      );
    },

  },
  {
    name: "Class",
    selector:(row)=> row.class,
    wrap: true,
    width: "auto",

  },
  {
    name: "Status",
    selector: "status",
    cell: (row) => {
      return (
        <div className="d-flex" style={{marginLeft:"13px"}}>
          {row.status == 0 ? (
            <button
              type="button"
              id="statusChangeButton"
              className=" form-buttons3"
              defaultValue="Sign Up"
                    // onClick={() => adminStatus(row.admin_id)}
              style={{
                borderRadius: "50%",
                backgroundColor: "red",
                height:"13px",
                width:"13px",
                fontSize: "12PX",
                fontWeight: "bold",
                color: "#ffffff",
                border: "none",
              }}
            >

            </button>
          ) : (
            <button
              type="button"
              id="statusChangeButton"
              className=" form-buttons3"
              defaultValue="Sign Up"
              // onClick={() => adminStatus(row.admin_id)}
              style={{
                borderRadius: "50%",
                backgroundColor: "green",
                fontSize: "12PX",
                fontWeight: "bold",
                color: "#ffffff",
                border: "none",
                height:"13px",
                width:"13px",
              }}
            >

            </button>
          )}
        </div>
      );
    },
  },
  {
    name: "Freeze",
    selector: "status",
    cell: (row) => {
      return (
        <div className="d-flex"style={{marginLeft:"13px"}}>
          {row.acc_freeze == 0 ? (
            <button
              type="button"
              id="statusChangeButton"
              className=" form-buttons3"
              defaultValue="Sign Up"
                    // onClick={() => adminStatus(row.admin_id)}
              style={{
                borderRadius: "50%",
                backgroundColor: "green",
                height:"13px",
                width:"13px",
                fontSize: "12PX",
                fontWeight: "bold",
                color: "#ffffff",
                border: "none",
              }}
            >

            </button>
          ) : (
            <button
              type="button"
              id="statusChangeButton"
              className=" form-buttons3"
              defaultValue="Sign Up"
              // onClick={() => adminStatus(row.admin_id)}
              style={{
                borderRadius: "50%",
                backgroundColor: "red",
                height:"13px",
                width:"13px",
                fontSize: "12PX",
                fontWeight: "bold",
                color: "#ffffff",
                border: "none",
              }}
            >

            </button>
          )}
        </div>
      );
    },
  },

  {
    name: "",
    width: "auto",
    cell: (row) => {

      return (
        <div className="action_buttons_end_css">
          <button
                className="all_action_buttons"
                onClick={() => openActionsModal(row.id)}
              >
                Actions
              </button>
                      <div className={`edit_campus_modal actions_modal${row.id}`}
                       style={{display: "none",position:"absolute",top:"5px",right:"0px"}}>
                      <div>
                    <div className="  ">
                      <div className=" d-flex ml-auto">
                        <img
                          className="campus_img ml-auto"
                          src="dist/img/Cancel.png"
                           onClick={()=>closeActionsModal(row.id)}
                        />
                      </div>
                    </div>

                      {row.status==0?(


                    <div className="  hover_class">
                      <div className=" d-flex flex-row" onClick={() => activeStudent(row.id)}>

                        <div className="campus_inner_div">
                          <span>Active</span>
                        </div>
                      </div>
                    </div>
                     ):(
                      <div className="  hover_class">
                      <div className=" d-flex flex-row" onClick={() =>activeStudent(row.id)} >

                        <div className="campus_inner_div">
                          <span>Deactive</span>
                        </div>
                      </div>
                    </div>
                     )}

                     {row.acc_freeze == 0 ?(
                    <div className=" d-flex flex-row hover_class">
                      <div className=" d-flex flex-row"  onClick={() =>freezeStudent(row.id)}>

                        <div className="campus_inner_div">
                          <span>Freeze</span>
                        </div>
                      </div>
                    </div>
                    ):(
                      <div className=" d-flex flex-row hover_class">
                      <div className=" d-flex flex-row"  onClick={() =>freezeStudent(row.id)}>
                        {/* <div>
                          <img
                            className="campus_img"
                            src="dist/img/campusClose.png"
                          />
                        </div> */}
                        <div className="campus_inner_div">
                          <span>Unfreeze</span>
                        </div>
                      </div>
                    </div>
                    )}


                  </div>
                  </div>



        </div>
      );
    },
  },

];

const [filterText, setFilterText] = React.useState("");
const [resetPaginationToggle, setResetPaginationToggle] =
  React.useState(false);

// const filteredItems = teacherList.filter(
//   (item) =>
//     JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
//     -1
// );

const subHeaderComponent = useMemo(() => {
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  return (
    // <FilterComponent
    //   onFilter={(e) => setFilterText(e.target.value)}
    //   onClear={handleClear}
    //   filterText={filterText}
    // />
    <div></div>
  );
}, [filterText, resetPaginationToggle]);

function addStudents() {
  $(".add_std_modal").toggle();
}

function close_student_modal() {
  $(".preview_category").hide();

}
  return (
    <div className="content-wrapper">
       <div
                            class="success_msg"
                            style={{
                              marginTop: "5px",
                              display: "none",
                              width: "100%",
                              marginBottom: "5px",
                            }}
                          >
                            <Stack sx={{ width: "100%" }} spacing={2}>
                              <Alert variant="filled" severity="success">
                                {statusMsg}
                              </Alert>
                            </Stack>
                          </div>

     <div className="search_box_border_class">
        <div className="col-md-4 d-flex">
            <div className="search_box_div">
              <img className="search_box_img"
                src={require("../images/Search.png")}

              />
              <Input className="search_box"
                id="search"
                type="text"
                placeholder="Search by student name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}

              />
            </div>
          </div>

          {/* <div className="col-md-7 d-flex" style={{justifyContent:"end",alignItems:"center"}}>
              <div className="d-flex">
                <a

                >
                  <div
                    style={{
                      marginLeft: "5px",
                      color: "#FFFFFF",
                      fontSize: "11PX",
                      lineHeight: "18px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontStyle: "normal",

                    }}
                  >
                    <img
                      src="dist/img/Download.png"
                      style={{ width: "22px", height: "22px" }}
                    />
                  </div>
                </a>
              </div>

              <div className="d-flex" style={{ alignItems: "center" }}>
                <span
                  className="faq_bar"
                  style={{
                    marginLeft: "13px",
                    marginRight: "13px",
                    color: "#4AA081",
                  }}
                >
                  |
                </span>
              </div>


              <div className="">
                              <button
                                type="button"
                                className="d-flex add_faq_button"
                                defaultValue="Sign Up"
                                style={{
                                  justifyContent: "end",
                                  position: "relative",
                                  height:"33px"
                                }}
                                onClick={addStudents}
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Add students
                                </span>
                                <div
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "12.25PX",
                                    fontWeight: "400",
                                    fontFamily: "Poppins",
                                  }}
                                ></div>
                                <img
                                  src="dist/img/AddNew.png"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginLeft: "13px",
                                  }}
                                />
                              </button>
                              <div
                                class="add_students_modal add_std_modal"
                                id="add_modal1"
                                style={{
                                  display: "none",
                                  position: "absolute",
                                  top:"14px"
                                }}
                              >
                                <div className="  hover_class">
                                  <Link
                                    to="/studentform"
                                    style={{
                                      display: "flex",
                                      padding: "5px 10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className=" d-flex">
                                      <img
                                        src="dist/img/CollaboratorMale.png"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        color: "#000000",
                                        fontSize: "11PX",
                                        lineHeight: "18px",
                                        fontWeight: "500",
                                        fontFamily:
                                          "Poppins",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      Add a student
                                    </div>
                                  </Link>
                                </div>

                                <div className=" d-flex  hover_class">
                                  <a
                                    href="#addStudents"
                                    style={{
                                      display: "flex",
                                      padding: "5px 10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className=" d-flex flex-row">
                                      <img
                                        src="dist/img/UserAccount.png"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        color: "#000000",
                                        fontSize: "11PX",
                                        lineHeight: "18px",
                                        fontWeight: "500",
                                        fontFamily:
                                          "Poppins",
                                        fontStyle: "normal",
                                      }}
                                    >
                                      Add multiple student
                                    </div>
                                  </a>
                                </div>
                              </div>

                            </div>
            </div> */}


        </div>

        <div>

          {/* <div className="border_class">
            <div class="row m-0">
              <div class="col-md-6 p-0">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <label
                    style={{
                      color: "#1F3977",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Campus <span className="text-danger text-lg">*</span>
                  </label>
                  <select
                    className="form-select form-select-sm input_box "
                    aria-label=".form-select-sm example"
                    id="food-select"
                    onChange={(e) => displayTeacherList(e.target.value)}

                    required
                  >
                    <option selected="selected" value={0} >
                      Select Campus
                    </option>
                    {
                    data.map((item, index) => {
                      console.log("Id",item.campus_id)

                      return (

                        <option
                          value={item.campus_id}
                          key={index}
                          onClick={() => displayTeacherList(item.campus_id)}
                        >
                          {item.campus_name}
                        </option>
                      );
                    })}
                  </select>

                </div>
              </div>
            </div>
          </div> */}

          {/* <div
              className="row  mt-3"

            >
              <div className="col-md-12">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "11px",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: "18px",
                    color: "#1F3977",

                    background: "rgba(31, 57, 119, 0.9)",
                    height: "40px",
                    width: "100%",
                    margin:"0px"
                  }}
                >
                  <p
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontSize: "14px",

                      padding: "10px",
                      textAlign: "center",
                    }}

                  >
                    All Students
                  </p>
                </label>
                <br></br>
              </div>
            </div> */}

          <div className="border_class">
          <DataTable
          columns={columns}
          data={studentList}
          striped
          paginationPerPage={10}
          pagination
          paginationRowsPerPageOptions={[10,20,30,40,50,60,70,80,90,100]}
          paginationComponentOptions={paginationComponentOptions}
          // subHeader
          subHeaderComponent={subHeaderComponent}
          highlightOnHover
          defaultSortFieldId={1}
          customStyles={customStyles}

        />

</div>


      </div>

       {/* add student modal */}
       <div id="addStudents" className="student_add_new_modal">
        <div className="student_inner_div border_class" style={{width:"380px"}}>
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Import Students
              </p>
              <br></br>
              <div>
              <p
                style={{ fontWeight: "300", color: "black", fontSize: "13px" }}
              >
                To import students, select a CSV or vCard file.
              </p>
              </div>
              <div class="row">
                <div class="" style={{ padding: "0" }}>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "30px",
                      paddingRight: "0",
                      height:"85px"
                    }}
                  >
                    <label for="file-ip-1">
                      <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-around"}}>
                       <div  style={{background:"#1F3977",color:"#FFFFFF",height:"50px",width:"110px",
                                   display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"6px",
                                   fontWeight:"300",fontSize:"15px"}}>Select file
                       </div>
                      <div  id="file-ip-1-preview" style={{display:"block", width:"200px",overflow:"auto",marginLeft:"13px",paddingTop:"8px"}}>

                      </div>
                      </div>

                    </label>
                    <input
                      type="file"

                      // onChange={(e)=>getImage(e)}
                      id="file-ip-1"

                      accept=".pdf,.xlsx,.xls,application/vnd.ms-excel"
                      className="input_fields"

                      name="birthdaytime"
                      style={{
                       visibility:"hidden",
                        color: "black",
                        fontSize: "11px",
                        width: "100%",
                        marginTop:"20px"


                      }}
                    />
                     </div>
                     <div class="error_modal" style={{ marginTop: "5px", display:"none",  width: "97%", marginBottom: "5px" }}>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                       <Alert variant="filled" severity="error">
                         Please select file
                     </Alert>
                   </Stack>
                  </div>

                </div>
              </div>



              <div className="d-flex mt-3">
                <a
                  onClick={close_student_modal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className=""
                    value="Cancel"
                    style={{
                      background:"#ffffff",
                      border:"none",
                      color:"#4779F0"
                    }}
                  />
                </a>


                  <input
                    type="button"
                    value="Import"
                    // onClick={() => uploadExcel()}
                    style={{
                      background:"#ffffff",
                      border:"none",
                      color:"#4779F0",
                      marginLeft:"35px"
                    }}
                  />


                <div
                  class="formError"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}
                  >
                    Please Select the File
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add student modal end */}

    </div>
  );
}
