import React from 'react'
import {BiPlusMedical, BiSearchAlt2} from "react-icons/bi";
import { CreateCampusForm } from './CreateCampusForm';
export function CreateCampus() {
    return (
      <div className="content-wrapper">

   <div className="border_class2 ">
            <div style={{ padding: "6px" }}>
              <div className="polls_heading_h1">CREATE NEW CAMPUS</div>
            </div>

            <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 1 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                  Basic Details
                  </p>
                </div>
              </div>
          </div>


<CreateCampusForm />

 </div>
    )
}
