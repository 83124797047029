import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StateAndCities from "../State and Cities/StateAndCities.json"
import SummerNote from "../Summer Note/SummerNote";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export function CreateEvent() {
  const [loc, setLoc] = useState(window.location.pathname);


  const customStyles = {
    rows: {
      style: {
        background: "rgba(228, 233, 243, 0.6)",
        marginTop: "3PX",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#1F3977",
        fontSize: "10px",
        fontWeight: "600",
      },
    },

    head: {
      style: {

        boxShadow: "0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)",
        height:"35px"
      },
    },
  };


  const [offerData, setOfferData] = useState([]);
  const token = localStorage.getItem("Token");
  const [getEventID, updateGetEventID] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const history = useHistory();
  var todayy = "";

  const [stateData, setStateData] = useState([]);
  const [ cityData, setCityData] = useState([]);
  const [ uniData, setUniData] = useState([]);

  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("")
  const [entryFee, setEntryFee] = useState("");
  const [capacity, setCapacity] = useState("")
  const [ticketUrl, setTicketUrl] = useState("");
  const [state, setState] = useState("")
  const [city, setCity] = useState("");
  const [university, setUniversity] = useState("")
  const [startDateTime, setStartDateTime] = useState("")
  const [endDateTime, setEndDateTime] = useState("");
  const [publishDateTime, setPublishDateTime] = useState("")
  const [expiryDateTime, setExpiryDateTime] = useState("");
  const [eventPhoto, setEventPhoto] = useState([]);
  const [eventLocation,setEventLocation] = useState("");

  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [stateNameData, setStateNameData] = useState([])
  const [cityNameData, setCityNameData] = useState([]);

  async function getEventData() {
    try {


      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_event_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
        }
      );

      const off_Data = fetchResponse.data.data;
      const error_code = fetchResponse.data.error_code;

      if (error_code == 200) {
        setOfferData(off_Data);
        // console.log("off_Data---------------------------",off_Data);

      }
    } catch (err) {
      console.log("Failed to get offer list ", err);
    }
  }

  const getCityAndUniData = async (e) => {
    setUniData([]);
    setCity(e)
    try {
      const formData = new FormData();
      formData.append("city_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_city_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

        // console.log("uni Response---------------------",fetchResponse);

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };
  const stateFun =() =>{

    setStateCityData(StateAndCities)
    stateCityData.map((e) =>{
      setStateNameData(e.states);
    })
}

const getCityData = async (e) => {
  setState(e)
  stateNameData.map((_e) =>{
    if(_e.state == e){
      setCityNameData(_e.districts);
    }
  })
  try {
    const formData = new FormData();
    formData.append("state_name", e);

    const fetchResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_admin_get_university_list_state_wise",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }
    );

    if (fetchResponse.data.error_code == 200) {
      setUniData(fetchResponse.data.data);
    } else {
      setUniData([]);
    }
  } catch (err) {
    console.log("Log in Fail", err);
  }
};


  function delete_event(event_id) {

    updateGetEventID(event_id);
    $(".delete_container").show();
    $(".delete_container").addClass("showModal");
  }

  useEffect(() => {
    getEventData();
    stateFun();
  }, []);

  const viewEventDetails = (e_id) => {
    history.push("/eventdetails",{e_id})

  }

  const [tId, setTId] = useState("");
  const openActionsModal = (e) => {
    setTId(e);
    $(".edit_campus_modal").hide();
    $('.actions_modal'+e).toggle();

  }
  const closeActionsModal = (e) => {
    $(".edit_campus_modal").hide();
  }

  const columns = [
    {
      name:"Event Name",
      selector: (row) =>{
        const array = [];
        row.image.map((itemimage) => {
        array.push(itemimage.image);

        });
        return (
          <div className="d-flex" style={{alignItems:"center"}}>
            {array.length == 0 ? (
              <div>
                <img
                  src={require("../images/no_image.png")}
                  style={{ padding: "5px", width: "40px", height: "30px" }}
                />
              </div>
            ) : (
              <div>
                <img
                  src={array[0]}
                  style={{ padding: "5px", width: "40px", height: "30px" }}
                />
              </div>
            )}

              <span className="ten_font_class"
                onClick={() => viewEventDetails(row.event_id)}
                style={{
                  color: "black",
                  fontWeight: "600",
                  cursor: "pointer",

                  marginLeft: "10PX",
                  // marginTop: "8PX",
                  width:"115px",
                  overflow:"hidden",
                  whiteSpace:"nowrap",
                  textOverflow:"ellipsis"

                }}
              >
                {row.title}
              </span>

          </div>
        );
      } ,
      sortable: true,
      with:"30%",
    },
    {
      name:"Date",
      width: "auto",
      selector: (row) => {
        var date1= row.start_date_time;
        var s_date = moment(date1).format("D MMM YYYY");
        return(<span className="ten_font_class">{s_date}</span>)
      },
      sortable: true,
    },

    {
      name:"Venue",
      sortable: true,
      width: "20%",
      cell: (row, rowIndex) => {
        return (
          <div
            className="ten_font_class"
            style={{
              cursor: "pointer",
              width: "160px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => viewEventDetails(row.event_id, rowIndex)}
          >
            {row.location}
          </div>
        );
      },
    },
    {
      name:"Entry Fee",
      width: "auto",
      sortable: true,
      cell: (row, rowIndex) => {
        return (
          <div
            className="ten_font_class"
            style={{ cursor: "pointer" }}
            onClick={() => viewEventDetails(row.event_id, rowIndex)}
          >
            {row.fee}
          </div>
        );
      },
    },

    {
      name: "",
      width: "auto",
      cell: (row) => {

        return (
          <div className="action_buttons_end_css">

               <button
                className="all_action_buttons"
                onClick={() => openActionsModal(row.event_id)}
              >
                Actions
              </button>


                        <div className={`p-0 edit_campus_modal actions_modal${row.event_id}`}
                         style={{display: "none",position:"absolute",top:"12px",right:"0px"}}>


                        <div className=" d-flex ml-auto" style={{padding:"2px 2px 0px 0px"}}>
                          <img
                            className="campus_img ml-auto"
                            src="dist/img/Cancel.png"
                             onClick={()=>closeActionsModal(row.event_id)}
                          />
                        </div>

                        <div style={{padding:"2px 10px 10px 10px"}}>
                      <div className="  hover_class">
                        <div className=" d-flex flex-row" onClick={()=>openEditForm(row.event_id)}>
                          <div>
                            <img
                              className="campus_img"
                              src="dist/img/campusEdit.png"
                            />
                          </div>
                          <div className="campus_inner_div">
                            <span>Edit</span>
                          </div>
                        </div>
                      </div>

                      <div className=" d-flex flex-row hover_class">
                        <div className=" d-flex flex-row" onClick={() => delete_event(row.event_id)}>
                          <div>
                            <img
                              className="campus_img"
                              src="dist/img/campusClose.png"
                            />
                          </div>
                          <div className="campus_inner_div">
                            <span>Delete</span>
                          </div>
                        </div>
                      </div>


                    </div>

                      </div>


          </div>
        );
      },
    },

    // {
    //   name:"Action",
    //   selector: (row) => {
    //     return (
    //       <div className="d-flex">
    //         <a
    //           className="cta"
    //           href="#edit"
    //           onClick={() => openEditForm(row.event_id)}
    //           style={{ backgroundColor: "transparent" }}
    //         >
    //           <img
    //             src={require("../images/Pencil.png")}
    //             alt="edit"
    //             style={{ width: "20px", height: "20px", marginLeft: "5px" }}
    //           />
    //         </a>

    //         <a
    //           className="cta"
    //           onClick={() => delete_event(row.event_id)}
    //           style={{ backgroundColor: "transparent" }}
    //         >
    //           <img
    //             style={{ width: "20px", height: "20px", marginLeft: "2px" }}
    //             src={require("../images/delete.png")}
    //           />
    //           &nbsp;
    //         </a>
    //       </div>
    //     );
    //   },

    // },
  ];

  const handelSummenrnote = (e) => {
    setDescription(e);
  };


  const saveEvent = async () => {
    try{

    if (eventName == "") {
      $(".event_name").show();

      setTimeout(function () {
        $(".event_name").hide();
      }, 3000);
    }else if(description == "") {
      $(".event_description").show();

      setTimeout(function () {
        $(".event_description").hide();
      }, 3000);
    }
    else if(eventPhoto == "") {
      $(".event_photo").show();

      setTimeout(function () {
        $(".event_photo").hide();
      }, 3000);
    }
    else if(entryFee == "") {
      $(".entery_fee").show();

      setTimeout(function () {
        $(".entery_fee").hide();
      }, 3000);
    }
    else if(capacity == "") {
      $(".event_capacity").show();

      setTimeout(function () {
        $(".event_capacity").hide();
      }, 3000);
    }
    else if(ticketUrl == "") {
      $(".ticket_url").show();

      setTimeout(function () {
        $(".ticket_url").hide();
      }, 3000);
    }
    else if(state == "") {
      $(".event_state").show();

      setTimeout(function () {
        $(".event_state").hide();
      }, 3000);
    }
    else if(eventLocation == "") {
      $(".event_locatin").show();

      setTimeout(function () {
        $(".event_locatin").hide();
      }, 3000);
    }else if(startDateTime == "") {
      $(".start_date").show();

      setTimeout(function () {
        $(".start_date").hide();
      }, 3000);
    }
    else if(endDateTime == "") {
      $(".end_date").show();

      setTimeout(function () {
        $(".end_date").hide();
      }, 3000);
    }
    else if(publishDateTime == "") {
      $(".publish_date").show();

      setTimeout(function () {
        $(".publish_date").hide();
      }, 3000);
    }
    else if(expiryDateTime == "") {
      $(".expiry_date").show();

      setTimeout(function () {
        $(".expiry_date").hide();
      }, 3000);
    }else{

      const formData = new FormData();
      formData.append("id", eventId);
      formData.append("title", eventName);
      formData.append("description", description);
      formData.append("fee", entryFee);
      formData.append("location", eventLocation);
      formData.append("capacity", capacity);
      formData.append("ticket_url", ticketUrl);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("university", university);
      formData.append("start_date_time", startDateTime);
      formData.append("end_date_time", endDateTime);
      formData.append("publish_start_date_time", publishDateTime);
      formData.append("publish_end_date_time", expiryDateTime);
      // formData.append("File[]", eventPhoto)

      for (var i = 0; i < eventPhoto.length; i++) {
        formData.append("File[]", eventPhoto[i]);
      }


      for(const p of formData.entries()){
        console.log(`---------${p[0]},${p[1]}`);
      }

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_edit_event",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      console.log("edit evemt response-----------",fetchResponse);
      if(fetchResponse.data.error_code == 200){
        $(".preview_category").hide();
        getEventData();
      }
    }
  }catch(err){
      console.log("event edit form error---------",err);
  }
  }


  function update_edited_Event() {
    $(".edit_popup_password").show();
  }

  function closeModal() {
    $(".edit_campus_modal").hide();
    $(".delete_container").removeClass("showModal");

  }

  function openEditForm(e_id) {
    setEventPhoto("")
    setState("");
    setCity("");
    setEventId(e_id)
    const id_event_data = offerData.filter((item) =>item.event_id == e_id);
    id_event_data.map((e) =>{

      setEventName(e.title);
      setDescription(e.description);
      setEntryFee(e.fee);
      setEventLocation(e.location);
      setCapacity(e.capacity);
      setTicketUrl(e.url);
      setState(e.state);
      setCity(e.city);
      setUniversity(e.university);
      setStartDateTime(e.start_date_time);
      setEndDateTime(e.end_date_time);
      setPublishDateTime(e.publish_start_date_time);
      setExpiryDateTime(e.publish_end_date_time);
      setEventLocation(e.location)
      setEventPhoto(e.image);
      getCityData(e.state);
      getCityAndUniData(e.city)
      var preview = document.getElementById("edit_event_imgs");
        preview.style.display = "none";
        $(".image_std").show();
        $(".edit_campus_modal").hide();
    })

    $(".preview_category").show();
  }

  function closeEditForm() {
    $(".preview_category").hide();
    $(".edit_campus_modal").hide();

  }

  async function deleteMessage() {
    try {
      const formData = new FormData();

      formData.append("event_id", getEventID);

      const deleteResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_delete_event",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );
      $(".edit_campus_modal").hide();
      //   updatedeleteErrorMessage(deleteResponse.data.message)
      if (deleteResponse.data.error_code == 200) {
        $(".delete_container").hide();
        $(".delete_container").removeClass("showModal");
        setErrorMsg(deleteResponse.data.message);

        $(".formSuccess").show()
        setTimeout(() => {
          $(".formSuccess").hide()
         }, 3000);

      }
      getEventData();
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }


  var eventImage = [];
  const getImage = (e) => {
    setEventPhoto("")
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setEventPhoto(eventImage);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("edit_event_imgs");
        preview.src = src;
        preview.style.display = "block";
        $(".image_std").hide();
      }
    }
  };

  const [filterText, setFilterText] = React.useState("");


  const filteredItems = offerData.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  return (
    <div>
      <Header />
      <div className="d-flex">
      <Menu pageTitle={loc} />

      <div className="content-wrapper">
      <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
      </div>



        <div className="search_box_border_class">
        <div className="col-md-3 d-flex flex-row">
            <div className="search_box_div">
              <img className="search_box_img"
                src={require("../images/Search.png")}

              />
              <Input className="search_box"
                id="search"
                type="text"
                placeholder="Search by event name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}

              />
            </div>
          </div>

          <div
            className="col-md-9 d-flex flex-row"
            style={{ justifyContent: "end" }}
          >
            <div style={{ marginTop: "0px", padding: "0" }}>
              <Link to="/eventform">
                <button
                  type="button"
                  className="d-flex  create_button"
                  defaultValue="Sign Up"

                >
                  <div className="create_button_inner">
                    Create Event
                  </div>
                  <img className="create_button_img"
                    src="dist/img/Progress.png"

                  />
                </button>
              </Link>
            </div>
          </div>


        </div>


        <div className="border_class">
        <DataTable
          // title="Campus List"
          fixedHeader
          fixedHeaderScrollHeight="400px"
          pagination
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
        />
        </div>
      </div>

      <div id="delete" className="modaloverlay delete_container">
        <div className="modalContainer">
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Delete Event
              </p>
              <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>
              Are You Sure, You Want To Delete this Event?
              </h2>

              <div className="d-flex mt-3">
                <a
                  //   onClick={close_delete_modal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"
                    onClick={()=>closeModal()}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#c4c4c4",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>

                <a
                  href="#delete_with_protection"
                  style={{ color: "grey", fontSize: "15PX" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_student"
                    value="Delete"
                    onClick={() => deleteMessage()}
                    style={{
                      borderRadius: "5px",
                      marginRight: "7px",
                      backgroundColor: "#d21f3c",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>

       {/* **************************** Edit Event ***************************** */}
       <div
        className="preview_category">
        <div className="edit_inner">
          <div className="d-flex edit_inner_div">
            <label className="main_labels">
              Campus Event
            </label>

            <img
              src="dist/img/Cancel.png"
              alt="dropdown"
              className="close_event ml-auto cancel_img"
              onClick={closeEditForm}

            />
          </div>

          <div
            className="preview_form">
              <div className="border_class edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Name
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}

                    />

                    <div
                      class="event_name"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter event name
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Description
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <SummerNote _onChange={handelSummenrnote}
                       value={description}
                    />
                    <div
                      class="event_description"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Event Description
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className="p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Add Event Photo
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>

                    <div>
                      {eventPhoto == null ? (
                        <div>
                          <label for="file-ip-1" style={{ height: "100%" }}>
                            <img
                              className="image_std"
                              src={require("../images/no_image.png")}
                              alt="no image"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="photo"
                            style={{ visibility: "hidden",display:"none" }}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={getImage}
                            id="file-ip-1"
                            multiple
                          />
                        </div>
                       ) : (
                         <div>
                          <label
                            for="file-ip-1"
                            style={{ height: "100%", display: "flex" }}
                          >
                            {eventPhoto.map((item) => {
                              return (
                                <div style={{ margin:"2px" }}>
                                  <img className="image_std"
                                   style={{width: "100px", height: "70px",objectFit:"contain"}}
                                  src={item.image} />
                                </div>
                              );
                            })}

                            <img
                              id="edit_event_imgs"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden" }}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={getImage}
                              id="file-ip-1"
                              multiple
                            />
                          </label>
                        </div>
                      )}
                    </div>

                    <div
                      class="event_photo"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter event photo
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            </div>



            <div className="border_class2 edit_row_padding2">

            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Entry Fee
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input className="edit_all_inputs"
                      type="number"
                      autoComplete="true"
                      value={entryFee}
                      onChange={(e) => setEntryFee(e.target.value)}

                    />
                     <div
                      class="entery_fee"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter entery fee
                      </h4>
                    </div>

                  </div>
                </div>

                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Event Capacity
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input className="edit_all_inputs"
                      type="number"
                      autoComplete="true"
                      value={capacity}
                      onChange={(e) => setCapacity(e.target.value)}

                    />

                    <div
                      class="event_capacity"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter capacity
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="mt-2 p-0">
              <div class="row m-0">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Ticket URL
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={ticketUrl}
                      onChange={(e) => setTicketUrl(e.target.value)}

                    />

                    <div
                      class="ticket_url"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter Ticket Url
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">

            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        State
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <select
                    className="form-select form-select-sm edit_all_inputs"
                    aria-label=".form-select-sm example"
                    id="department_neww"
                    value={state }
                      onChange={(e) => getCityData(e.target.value)}
                  >
                    <option
                      selected="selected"   value=""
                      style={{ padding: "6px" }}
                    >
                      Select State
                    </option>
                    {
                      stateNameData.map((e) => {
                        return(
                          <option value={e.state}>{e.state}</option>
                        )
                      })
                    }
                  </select>
                  <div
                      class="event_state"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter state
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        City
                      </label>
                      <p className="all_stars">

                      </p>
                    </div>
                    <select
                  className="form-select form-select-sm edit_all_inputs"
                  aria-label=".form-select-sm example"
                  id="student_course"
                  value={city}
                  onChange={(e)=>getCityAndUniData(e.target.value)}

                >
                  <option
                      selected="selected"   value=""
                      style={{ padding: "6px" }}
                    >
                      Select City
                    </option>

                  {cityNameData.map((name) => {
                      return (
                        <option value={name}>{name}</option>
                      );
                    })}

                </select>


                  </div>
                </div>
              </div>
            </div>



            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        University
                      </label>
                    </div>
                    <select
                  className="form-select form-select-sm edit_all_inputs"
                  aria-label=".form-select-sm example"
                  id="student_class"
                  value={university}
                  onChange={(e)=>setUniversity(e.target.value)}
                >
                  <option
                      selected="selected"   value=""
                      style={{ padding: "6px" }}
                    >
                      Select University
                    </option>
                  {uniData.map((e)=>{
                      return(
                        <option value={e.uni_id}>{e.uni_name}</option>
                      )
                    })}
                </select>

                    <div
                      class="newsDescription"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Write News Description
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Location
                      </label>
                    </div>
                    <input className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={eventLocation}
                      onChange={(e) => setEventLocation(e.target.value)}

                    />


                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      Start Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="Now"
                  id="publish_date"
                  value={startDateTime}
                  onChange={(e) => setStartDateTime(e.target.value)}
                  name="birthdaytime"

                />
                  <div
                    class="start_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please enter start date time
                    </h4>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      End Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="dd-mm-yyyy hh-mm"
                  id="publish_date"
                  value={endDateTime}
                  onChange={(e) => setEndDateTime(e.target.value)}
                  name="birthdaytime"

                />
                  <div
                    class="end_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please enter end date time
                    </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div className="mb-2">
                  <label className="edit_all_labels">
                  When it should be published?
                </label>
                  </div>
                <div class="col-md-6">

                  <div className="d-flex">
                    <label className="edit_all_labels">
                      Publish Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="Now"
                  id="publish_date"
                  value={publishDateTime}
                  onChange={(e) => setPublishDateTime(e.target.value)}
                  name="birthdaytime"

                />

                  <div
                    class="publish_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please enter publish date
                    </h4>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      Expiry Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="dd-mm-yyyy hh-mm"
                  id="publish_date"
                  value={expiryDateTime}
                  onChange={(e) => setExpiryDateTime(e.target.value)}
                  name="birthdaytime"

                />

                  <div
                    class="expiry_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please enter expiry date
                    </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>


            {/* ******************button********************** */}

            <div className="d-flex mt-3 edit_buttons_div border_class"
             style={{justifyContent:"end"}}>
              <input
                type="button"
                className="edit_cancel_button"
                onClick={() => closeEditForm()}
                value="Cancel"

              />

              <input
                type="button"
                className="edit_update_button"
                id="delete_single_student"
                value="Update"
                onClick={() => saveEvent()}

              />
            </div>

            <div style={{ display: "none" }} className="saveMessage">
              Data Saved Successfully
            </div>
          </div>
        </div>
      </div>

      </div>
    </div>
  );
}
export default CreateEvent;
