import React, { useState, useEffect } from 'react'
import axios from 'axios';
import DataTable from "react-data-table-component"
import { AiOutlineEye, AiFillDelete } from "react-icons/ai";

const Viewtoken = () => {
  const token = localStorage.getItem("Token");
  //alert(typeof token)
  // console.log("token = "+localStorage.getItem("Token"))
  const [data, setData] = useState([]);
  async function fetchList() {
    try {
      //alert("see token is working just fine"+ localStorage.getItem("Token"))
      console.log("token = " + localStorage.getItem("Token")) //token to ja rha 
      const fetchResponse = await axios.get(process.env.REACT_APP_API_KEY + "get_campus_details_list",
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',
            'authorization': token,
          },
        }
      );
      console.log("Get Campus List Details...................", fetchResponse);
      console.log("Error Code", fetchResponse.data.error_code);
      const campusListArry = fetchResponse.data.data;
      const error_code = fetchResponse.data.error_code

      console.log("Campus listArry", campusListArry);
      if (error_code == 200) {
        setData(campusListArry);
      }
      // setData(campusListArry);
    } catch (err) {
      console.log("Failed to get campus list ", data);
    }
  }

  const columns = [
    {
      name: <b>Campus Id</b>,
      selector: (row) => row.campus_id,
      sortable: true
    },
    {
      name: <b>Campus Name</b>,
      selector: (row) => row.campus_name,
      sortable: true
    },
    {
      name: <b>City</b>,
      selector: (row) => row.city,
      sortable: true
    },
    {
      name: <b>Website</b>,
      selector: (row) => row.web,
      sortable: true
    },
    {
      name: <b>Admin Name</b>,
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: <b>Admin number</b>,
      selector: (row) => row.mobile,
      sortable: true}
    // },
    // {
    //   name: <b>Action</b>,
    //   cell: (row) => <>
    //     <button className="btn btn-info" onClick={() => { alert("Campus id  = " + row.campus_id + "\nCampus name " + row.campus_name) }} >{AiOutlineEye}</button>
    //     <button className="btn btn-info" onClick={() => { alert("Campus id  = " + row.campus_id + "\nCampus name " + row.campus_name) }} >{AiFillDelete}</button>
    //   </>
    // },
  ]

  useEffect(() => {
    fetchList();
  }, []);
  return (
    <div className='content-wrapper '>
      <div className="border_class">
        <h4><b>Campus List</b></h4>
      </div>
      <div className='border_class'>
      <DataTable
        // title="Campus List"
        fixedHeader
        fixedHeaderScrollHeight='400px'
        pagination
        columns={columns}
        data={data}
      />
      </div>
    </div>
  )
}

export default Viewtoken