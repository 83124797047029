import React, { useState, useEffect} from "react";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Moment from "moment";
import { format } from "date-fns";
import { Calendar } from "react-calendar";

export const CalendarHomePage = () => {
  const token = localStorage.getItem("Token");
  const [eventData, setEventData] = useState("");
  const [eventDatas, setEventDatas] = useState([]);
  const [eventsCount, setEventsCount] = useState([]);
  const [dateWseEvents, setDateWseEvents] = useState([]);
  const [monthWiseEvents, setMonthWiseEvents] = useState([]);

  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [todaysDate, setTodaysDate] = useState(new Date());

  async function getOfferData() {
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_event_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token,
          },
        }
      );
      const e_Data = fetchResponse.data.data;
      const error_code = fetchResponse.data.error_code;

      if (error_code == 200) {
        setEventData(e_Data);
      }
    } catch (err) {
      console.log("Failed to get offer list ", err);
    }
  }

  const [selectedDate, setSelectedDate] = useState("");
  const [onlyDate, setOnlyDate] = useState("");

  const Date_Click_Fun = (date) => {
    setSelectedDate(date);
    const onlyD = format(date, "dd MMM");
    setOnlyDate(onlyD);

    const dd = format(date, "YYY-MM-dd");
    getDateWiseEvents(dd);
  };

  const closeDayMOdal = () => {
    $(".show_events").hide();
    $(".sidebar-mini").css("overflow", "auto");
  };
  const mark = eventDatas;
  async function getDates() {
    try {
      const fetchResponse = await axios
        .get(process.env.REACT_APP_API_KEY + "super_admin_get_event_dates", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })
        .then((response) => {
          setEventDatas(response.data.data);
        });
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function getEventCount() {
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_events_cnt",

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const e_data = fetchResponse.data.data;

        setEventsCount(e_data);
      } else {
        // setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function getDateWiseEvents(datee) {
    try {
      const formData = new FormData();
      formData.append("event_date", datee);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_datewise_event",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const event_data = fetchResponse.data.data;
        setDateWseEvents(event_data);
        $(".show_events").show();
        $(".sidebar-mini").css("overflow", "hidden");
      } else {
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function getMonthWiseEvents(mon, yr) {
    try {
      setMonthWiseEvents("");
      const formData = new FormData();
      formData.append("month_num", mon);
      formData.append("year_num", yr);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_monthyearwise_events",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const event_data = fetchResponse.data.data;
        setMonthWiseEvents(event_data);
      } else {
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  useEffect(() => {
    getOfferData();
    getDates();
    getEventCount();
    getMonthWiseEvents(currentMonth + 1, currentYear);
  }, [currentMonth]);

  function set_d_nxt() {
    if (currentMonth == 11) {
      setCurrentMonth(0);
      let yy = currentYear + 1;
      setCurrentYear(yy);

      getMonthWiseEvents(currentMonth + 2 + "," + currentYear);
    } else {
      let mm = currentMonth + 1;
      setCurrentMonth(mm);
      getMonthWiseEvents(currentMonth + 2 + "," + currentYear);
    }
  }

  function set_d_prv() {
    if (currentMonth === 0) {
      let yyy = currentYear - 1;
      setCurrentYear(yyy);
      setCurrentMonth(11);
      getMonthWiseEvents(currentMonth - 2, currentYear);
    } else {
      let mmm = currentMonth - 1;
      setCurrentMonth(mmm);
      getMonthWiseEvents(currentMonth - 2, currentYear);
    }
  }

  const monthName = new Date(currentYear, currentMonth).toLocaleString(
    "default",
    { month: "long" }
  );
  return (
    <div>
      <Header />

      <div className="d-flex">
        <Menu />

        <div className="content-wrapper">
          <div>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className=" "
                    style={{
                      padding: "0px 0px 5px 0px",
                      boxShadow:
                        "0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)",
                      borderRadius: "5px",
                      background: "#fff"
                    }}
                  >
                    <div
                      className="inner calendar_inner"
                      style={{
                        lineHeight: "40px",
                        background: "rgb(108, 122, 153)",
                        color: "#fff",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{ flexWrap: "wrap", paddingLeft: "20px" }}
                      >
                        <div>
                          <div
                            style={{
                              fontWeight: "600",

                              marginTop: "0px",
                              fontSize: "13px",
                              padding: "0px",
                            }}
                          >
                            Event Calendar
                          </div>
                          <div
                            style={{
                              padding: "0px",
                              fontWeight: "400",
                              marginLeft: "0px",
                              fontSize: "12px",
                              marginBottom: "0",
                              color: "#fff",
                              marginTop: "0",
                            }}
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    <Calendar
                      value={selectedDate}
                      onClickDay={Date_Click_Fun}
                      tileClassName={({ date, view }) => {
                        if (
                          mark &&
                          mark.find(
                            (x) => x === Moment(date).format("YYYY-MM-DD")
                          )
                        ) {
                          return "highlight";
                        }

                        if (
                          Moment(todaysDate).format("YYYY-MM-DD") ==
                          Moment(date).format("YYYY-MM-DD")
                        ) {
                          return "current_date";
                        }
                      }}
                    />
                    </div>

                    <div
          className="show_events"
          style={{
            position: "absolute",
            top: "0",
            left: "0px",
            background: "rgba(0,0,0,0.5)",
            padding: "10px",
            width: "100%",
            height: "100%",
            zIndex: "2",
            display: "none",
          }}
        >
          <div
            style={{
              borderRadius: "10PX",
              width: "100%",
              height: "100%",
              overflowY: "scroll",
            }}
            className="datewise_events"
          >
            <div
              className="d-flex"
              style={{ background: " #f2f2f2", padding: "5px",alignItems:"center" }}
            >
              <p
                style={{
                 display:"flex",
                 alignItems: "center",
                  background: "#50404d",
                  borderRadius: "5PX",
                  padding: "5px 10PX",
                  color: "white",
                  fontSize:"10px",
                  fontWeight:"500"
                }}
              >
                {onlyDate}
              </p>
              <p
                style={{
                  marginLeft: "7px",
                  fontWeight: "600",
                  fontSize:"12px"
                }}
              >
                EVENTS OF THE DAY
              </p>
              <a  onClick={closeDayMOdal}
                href="#close"
                className="close_event ml-auto"
                style={{
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  background: "darkgrey",
                  borderRadius: "50%",
                  color: "white",
                  fontSize: "15px",
                height:"20px",
                width:"21px"
                }}
              >
                ×
              </a>
            </div>
            <div style={{ overflowY: "auto", background: "#dbd7d2" }}>
              {dateWseEvents == "" ? (
                <div
                  style={{
                    overflowY: "auto",
                    background: "#dbd7d2",
                    padding: "15PX",
                    textAlign: "center",
                  }}
                >
                  NO EVENT TO DISPLAY
                </div>
              ) : (
                dateWseEvents.map((itemDate) => {
                  return (
                    <div style={{ padding: "10px" }} className="d-flex">
                      <div
                        style={{
                          borderRight: "3px solid #339dd8",
                          padding: "10px",
                          fontSize:"11px",
                          fontWeight:"500"
                        }}
                      >
                        {itemDate.start_time}
                      </div>
                      <div style={{ padding: "10px" }}>
                        <div
                          style={{
                            marginLeft: "5px",
                            fontWeight: "600",
                            fontSize: "12PX",
                          }}
                        >
                          {itemDate.title}
                        </div>
                        <div
                          style={{
                            marginLeft: "5px",
                            color: "grey",
                            fontSize: "10PX",
                          }}
                        >
                          {
                            <p className="desc_class"
                              dangerouslySetInnerHTML={{
                                __html: itemDate.description,
                              }}
                            ></p>
                          }
                        </div>
                        <div
                          style={{
                            marginLeft: "5px",
                            fontSize: "11PX",
                            fontStyle: "italic",
                            fontWeight:"600"
                          }}
                        >
                          {itemDate.location}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) }
            </div>
          </div>
        </div>

                    {/* <div
                      className="show_events"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0px",
                        background: "rgba(0,0,0,0.5)",
                        padding: "10px",
                        width: "100%",
                        height: "100%",
                        zIndex: "2",
                        display: "none",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "10PX",
                          width: "100%",
                          height: "100%",
                          overflowY: "scroll",
                        }}
                        className="datewise_events"
                      >
                        <div
                          className="d-flex"
                          style={{ background: " #f2f2f2", padding: "5px" }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              background: "#50404d",
                              borderRadius: "5PX",
                              padding: "5px 10PX",
                              color: "white",
                            }}
                          >
                            {onlyDate}
                          </div>
                          <div
                            style={{
                              marginTop: "2px",
                              marginLeft: "7px",
                              fontWeight: "bold",
                            }}
                          >
                            EVENTS OF THE DAY
                          </div>
                          <a
                            onClick={closeDayMOdal}
                            className="close_event ml-auto"
                            style={{
                              background: "darkgrey",
                              borderRadius: "50%",
                              color: "white",
                              fontSize: "15px",
                              padding: "5px 10px",
                            }}
                          >
                            ×
                          </a>
                        </div>
                        <div
                          style={{ overflowY: "auto", background: "#dbd7d2" }}
                        >
                          {dateWseEvents == "" ? (
                            <div
                              style={{
                                overflowY: "auto",
                                background: "#dbd7d2",
                                padding: "15PX",
                                textAlign: "center",
                              }}
                            >
                              NO EVENT TO DISPLAY
                            </div>
                          ) : (
                            dateWseEvents.map((itemDate) => {
                              return (
                                <div
                                  style={{ padding: "10px" }}
                                  className="d-flex"
                                >
                                  <div
                                    style={{
                                      borderRight: "3px solid #339dd8",
                                      padding: "10px",
                                    }}
                                  >
                                    {itemDate.start_time}
                                  </div>
                                  <div style={{ padding: "10px" }}>
                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        fontWeight: "600",
                                        fontSize: "15PX",
                                      }}
                                    >
                                      {itemDate.title}
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        color: "grey",
                                        fontSize: "13PX",
                                      }}
                                    >
                                      {<p> {itemDate.description}</p>}
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11PX",
                                        fontStyle: "italic",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {itemDate.location}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="col-md-6" style={{ height: "100%" }}>
                  <div
                    className="small-box p-0"
                    style={{
                      width: "100%",

                      borderRadius: "10PX",
                      boxShadow: "none",
                      background: "transparent",
                    }}
                  >
                    <div
                      className="inner"
                      style={{ height: "100%", padding: "0", margin: "0" }}
                    >
                      <div className="row">
                        {/* total events */}
                        <div
                          className="col-md-6"
                          style={{
                            height: "100%",
                          }}
                        >
                          <div
                            className="small-box"
                            style={{
                              height: "80px",
                              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                              borderRadius: "3px",
                              width: "100%",
                              padding:"7px"
                            }}
                          >
                            <div className="inner" style={{ padding: "0" }}>
                              <div
                                className="d-flex"
                                style={{ padding: "0", marginLeft: "15PX" }}
                              >
                                <img
                                  src="dist/img/Tent.png"
                                  className="ml-auto all_icon_imgs"
                                />
                              </div>
                              <div className="sixteen_font_class"
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "-10px"
                                }}
                              >
                                {eventsCount.all_events}
                              </div>

                              <span className="eleven_font_class"
                                style={{
                                  marginLeft: "15px",
                                  color: "black",
                                  marginTop: "0px",
                                }}
                              >
                                Total Events
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* events this month */}
                        <div
                          className="col-md-6"
                          style={{
                            height: "100%",

                            margin: "0",
                          }}
                        >
                          <div
                            className="small-box"
                            style={{
                              height: "80px",
                      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.2)",
                      borderRadius: "3px",
                      padding:"7px"
                            }}
                          >
                            <div className="inner" style={{ padding: "0" }}>
                              <div className="d-flex" style={{ padding: "0" }}>
                                <img
                                  src="dist/img/Today.png"
                                  className="ml-auto all_icon_imgs"
                                />
                              </div>
                              <div className="sixteen_font_class"
                                style={{
                                  marginLeft: "15px",
                          marginTop: "-10px"
                                }}
                              >
                                {eventsCount.this_mnth_evnt}
                              </div>

                              <span className="eleven_font_class"
                                style={{
                                  marginLeft: "15px",
                          color: "black",
                          marginTop: "0px"
                                }}
                              >
                                Events This Month
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* holidays for this month */}
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{
                            height: "100%",
                            marginTop: "10PX",
                          }}
                        >
                          <div
                            className="small-box preview_form"
                            style={{
                              padding: "0px",
                              height: "238px",
                              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                              borderRadius: "0px 0px 5px 5px",
                            }}
                          >
                            <div className="">
                              <div>
                                <div
                                  style={{
                                    background: "rgb(108, 122, 153)",
                                    color: "#fff",
                                    padding: "7px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span>holidays</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    height: "100%",
                    marginTop: "10PX",
                  }}
                >
                  <div
                    className="small-box "

                      style={{
                        padding: "0px",
                        minHeight:"130px",
                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                        borderRadius: "0px 0px 5px 5px",
                      }}

                  >
                    <div className="">
                      <div>
                        <div
                          style={{
                            background: "rgb(108, 122, 153)",
                          color: "#fff",
                          padding: "7px",
                          display: "flex",
                          justifyContent: "center",
                          }}
                        >
                          <button
                            id="prevMonth"
                            onClick={set_d_prv}
                            style={{
                              marginRight: "25px",
                              border: "none",
                              background: "rgb(108, 122, 153)",
                            }}
                          >
                            <img
                              src="dist/img/prev_img.png"
                              style={{ height: "18px", width: "18px" }}
                            />
                          </button>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              fontSize:"10px",
                              fontWeight:"500"
                            }}
                          >
                            <span>Campus Events in</span>
                            <span id="">{monthName}</span>
                            <span>{currentYear}</span>
                          </div>

                          <button
                            onClick={set_d_nxt}
                            id="nextMonth"
                            style={{
                              marginLeft: "25px",
                              border: "none",
                              background: "rgb(108, 122, 153)",
                            }}
                          >
                            <img
                              src="dist/img/next_img.png"
                              style={{ height: "18px", width: "18px" }}
                            />
                          </button>
                        </div>
                      </div>

                      <div className="event_inner_div">
                        <div className="row m-0">
                          {monthWiseEvents == "" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{ fontSize: "10px", fontWeight: "500",marginTop:"10px" }}
                              >
                                No Events Available
                              </p>
                            </div>
                          ) : (
                            <>
                              {monthWiseEvents.map((item) => {
                                let array = [];
                                var date1 = item.start_date_time;
                                var date2 = item.end_date_time;
                                var s_date = Moment(date1).format("D MMM");
                                const dateObject = new Date(date1);
                                const hours = dateObject.getHours();
                                const minutes = dateObject.getMinutes();
                                const formattedTime = `${hours}:${
                                  minutes < 10 ? `0${minutes}` : minutes
                                }`;

                                var e_date = Moment(date2).format("D MMM");
                                const dateObject2 = new Date(date2);
                                const hours2 = dateObject2.getHours();
                                const minutes2 = dateObject2.getMinutes();
                                const formattedTime2 = `${hours2}:${
                                  minutes2 < 10 ? `0${minutes2}` : minutes2
                                }`;

                                item.event_img.map((e) => {
                                  array.push(e.event_img);
                                });

                                return (
                                  <>
                                    <div className="col-md-4 mt-2">
                                      <div
                                        className="small-box"
                                        style={{
                                          width: "100%",
                                        paddingLeft: "10px",
                                        background: "transparent",
                                        minHeight: "75px",
                                        boxShadow:"0px 1px 1px rgba(0, 0, 0, 0.2)",
                                        borderRadius: "3px",
                                        }}
                                      >
                                        <div
                                          className="inner"
                                          style={{
                                            height: "100%",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          <div className="row">
                                            <div style={{display:"flex", gap:"12px"}}>
                                              <div
                                                className=""
                                                style={{
                                                  height: "100%",
                                                }}
                                              >
                                                <div>
                                                  {array.length == 0 ? (
                                                    <div>
                                                      <img
                                                        src={require("../images/no_image.png")}
                                                        alt="Default"
                                                        style={{
                                                          padding: "5px",
                                                        width: "80px",
                                                        height: "60px",
                                                        }}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <img
                                                        src={array[0]}
                                                        style={{
                                                          height: "60px",
                                                        width: "80px",
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>

                                              <div className="">
                                                <div className="mt-1">
                                                  <span
                                                    style={{
                                                      fontSize: "12px",
                                                    fontWeight: "600",
                                                    width:"190px",
                                                    overflow:"hidden",
                                                    whiteSpace:"nowrap",
                                                    textOverflow:"ellipsis",
                                                    display:"block"
                                                    }}
                                                  >
                                                    {item.title}
                                                  </span>
                                                </div>

                                                <div style={{display:"grid"}}>
                                                  <span
                                                    style={{
                                                      fontSize: "9px",
                                                      fontWeight: "500",
                                                    }}
                                                  >

                                                      <span  style={{fontWeight: "600",marginRight:"4px"}}>Start Date :</span>
                                                     {s_date}
                                                    <span> - </span>
                                                    <span>{formattedTime}</span>
                                                  </span>

                                                  <span
                                                    style={{
                                                      fontSize: "9px",
                                                      fontWeight: "500",
                                                    }}>
                                                     <span  style={{fontWeight: "600",marginRight:"4px"}}> End Date :</span>
                                                     {e_date}
                                                    <span> - </span>
                                                    <span>
                                                      {formattedTime2}
                                                    </span>
                                                  </span>
                                                  </div>

                                                <div
                                                  className=""
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    fontSize: "9px",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  <span>{item.location}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
