import React from 'react'
// import {ViewStudent} from "./ViewStudent";
export function AppointmentTable() {
    return (
      <div style={{ overflowX: "hidden", margin: "0px", width:"100%", height:"100%"}}>
      <div className="card-body">
  
  <table className="table table-striped" id="example"
  style={{backgroundColor:"#EFF6FF", color:"black",height:"100px", width:"100%", padding:"10px"}}>
                <thead style={{background:"white"}}>
                  <tr style={{padding:"10px", marginBottom:"10px", borderBottom:"none"}}>
                 
                  <th style={{ width: "20%",borderBottom:"none",fontSize:"15px",textAlign:"center"}} >
                  Appointment Date
                    </th>
                    <th style={{ width: "20%" ,borderBottom:"none",fontSize:"15px",textAlign:"center"}} >
                    Reason
                    </th>
                    <th style={{ width: "15%" , borderBottom:"none",fontSize:"15px",textAlign:"center"}} >
                    Booked By
                    </th>
                    <th style={{ width: "15%" , borderBottom:"none",fontSize:"15px",textAlign:"center"}} >
                    Duration
                    </th>
                    <th style={{ width: "15%", borderBottom:"none" ,fontSize:"15px",textAlign:"center"}} >
                    Venue
                    </th>
                    <th style={{ width: "15%" , borderBottom:"none",fontSize:"15px",textAlign:"center"}} >
                    Status
                    </th>
                   
                  </tr>
                </thead>
  
                <tbody style={{width:"100%" }}>
                  <tr style={{padding:"0"}}><td colspan="6" style={{backgroundColor:"#EFF6FF",border:"none", padding:"0px", margin:"7px"}}></td></tr>
                  <tr className="text-center" style={{background:"white"}}>
               
                <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Sept 30, 2021</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item" >Maths doubt</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Student</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">30 mins</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Online</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Scheduled</li></td>
                </tr>

                <tr className="text-center" style={{background:"white"}}>
               
               <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Oct 15,2021</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item" >Performance</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Teacher</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">15 mins</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Online</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Completed</li></td>
               </tr>

               <tr className="text-center" style={{background:"white"}}>
               
               <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Dec 31,2021</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item" >Violation of rules</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Principal</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">20 mins</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">College Premises</li></td>
                 <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Completed</li></td>
               </tr>
  
                  <tr className="text-center" style={{background:"white"}}>
               
                <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Dec 02,2021</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item" >Exam registration</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Student</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">30 mins</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">College Premises</li></td>
                  <td style={{padding:"10px", borderTop:"0"}}><li className="list-inline-item">Cancelled</li> </td>
                </tr>

                </tbody>
              </table>
              </div>
  
    
    </div>
    )
}
