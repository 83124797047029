import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import axios from "axios";
import $ from "jquery"

import { DayPickerRangeController } from 'react-dates'
import { START_DATE, END_DATE } from 'react-dates/constants'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import sortBy from 'lodash/fp/sortBy'
import last from 'lodash/fp/last'
import { memoize } from 'lodash'
import moment from 'moment';

// import eventsData from './Event'

//css

const Wrapper = styled.div`
  .CalendarDay__highlighted_calendar {
     background: #1F3977 !important;
     border-radius:50%;
margin-top:10px !important;
 text-align:center !important;
    color:white !important;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:hover {
   
    color: inherit;
    background: none;
  }

  .CalendarDay__selected_span {
    border: 2px double #0c6776;
    color: inherit;
    background: none;
  }
`

const DETAILS = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`

const Number = styled.div`
  position: absolute;
  top: 13px;
  left: 17px;
`

const Event = styled.div`
  

  ${({ green }) =>
    green &&
    `
    color: white;
    background: #0c6776;
  `}
`

const EventStart = styled(Event)`
  margin-left: 2px;
  margin-right: -2px;
`

const EventSpan = styled(Event)`
  margin-left: -2px;
  margin-right: -2px;
  padding-left: 8px;
`

const EventEnd = styled(Event)`
  /* margin-right: 2px; */
  margin-left: -2px;
`

//css ended

var array= [];



export function EventCalendar(){
  const token = localStorage.getItem('Token');
  const[data,setData] = useState([]);

  const [eventDate,updateEventDate] = useState("");
  

  async function fetchList() {
   
    try {

      const fetchResponse = await axios.get(process.env.REACT_APP_API_KEY + "super_get_event_date",
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',

            "Authorization": token,
          }
        }
      );

      console.log("Get Calendar event", fetchResponse.data.data);
      
      const events = fetchResponse.data.data;
      console.log("eventssssssssssss",events);
     
      array.push(events);
      
     if(fetchResponse.data.error_code == 404)
     {
       alert("Invalid Token OR Non Authorized User");
      window.location.href = "/";
     }

    }
    catch (err) {
      console.log("Log in Fail", err);

    }

  }

  useEffect(() => {
    isDayHighlighted();
    fetchList();
  }, []);
  
  const [focusedInput, setFocusedInput] = useState(START_DATE)
  // const [{ startDate, endDate }, setDates] = useState({
  //   startDate: null,
  //   endDate: null,
  // })

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFocusChange = focusedInput => {
    setFocusedInput(focusedInput ? END_DATE : START_DATE)
  }

  const handlePrevMonth = () => {
    setStartDate(moment(startDate).subtract(1, 'month'));
    setEndDate(moment(endDate).subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setStartDate(moment(startDate).add(1, 'month'));
    setEndDate(moment(endDate).add(1, 'month'));

    console.log("startDate------------------",startDate);
    console.log("endDate------------------",endDate);
  };

  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e) => {
    setDateState(e);
  };
 

  // const isDayHighlighted = day => {
  //   let isBlocked = false
    
  // array.map((item) =>
  // {
   
  //   item.map(({ start_date, end_date }) =>
  //   {
     
  //     if (day.isBetween(start_date, end_date, 'day', '[]')) {
  //           isBlocked = true
  //        }
  //   })
  // })
  //   return isBlocked
  // }

  const isBetween = (date, start, end) => {
    return moment(date).isBetween(start, end, null, '[]');
  };

  const isDayHighlighted = day => {
    let isBlocked = false
    for (const item of array) {
      for (const { start_date, end_date } of item) {
        if (isBetween(day, start_date, end_date)) {
          isBlocked = true
          return true;
          
        }
      }
    }
  
    return false;
  };
  
  const getEventForDay = memoize(day =>
    flow(
      filter(({ start, end }) => day.isBetween(start, end, 'day', '[]')),
      sortBy('start'),
      last
    )(array)
  )
  const renderEventForDay = day => {
    const event = getEventForDay(day)
    const eventForPreviousDay = getEventForDay(day.clone().subtract(1, 'days'))
    if (!event) return null
    const { start, end, label, color, type } = event
    const isBooking = type === 'booking'
    const isStart = day.isSame(start, 'day')
    const isEnd = day.isSame(end, 'day')
  
    if (isStart && isEnd) {
      return <Event green={isBooking}>{label}</Event>
    } else if (isStart) {
      return <EventStart green={isBooking}>{label}</EventStart>
    } else if (isEnd) {
      return <EventEnd green={isBooking} />
    } else {
      return (
        <EventSpan green={isBooking}>
          {eventForPreviousDay && eventForPreviousDay.color !== color && label}
          
        </EventSpan>
      )
    }
   
  }


 
  async function showDate(day)
{
console.log("SHOW DATE",day.format('YYYY-MM-DD'));
const getFullDate = day.format('YYYY-MM-DD');
const atasi = day.format('DD');
updateEventDate(atasi);
$(".show_events").show();

try {

  const formDataCategory = new FormData();
  formDataCategory.append("date", getFullDate);


  const responseDate = await axios.post(process.env.REACT_APP_API_KEY + "super_get_datewise_event_list",
    formDataCategory,
    {
      headers:
      {
        "Content-Type": 'multipart/form-data',
        "Authorization": token,
      }
    });

  console.log("Get_Datewise_Event_List", responseDate.data.data);
  // setError( responseDate.data.error_code)
  setData(responseDate.data)

  // updateCategoryName("");

}
catch (err) {
  console.log("Log in Fail", err)
}
}
 const Day = (day) => {
    
    return(
      <DETAILS >
     
         <Number onClick={() => showDate(day)}>
            
          {day.format('DD')}
          </Number>
          
                
         {renderEventForDay(day)}
         
         </DETAILS>
    )
  }
  $(".close_event").click(function(){
    $(".show_events").hide();
  });
  return (
    <Wrapper>
      <button  onClick={handlePrevMonth}>Prew</button>
      <button  onClick={handleNextMonth}>Next</button>
      <DayPickerRangeController
   
        focusedInput={focusedInput}
        // onFocusChange={handleFocusChange}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={[setStartDate, setEndDate]}
        // daySize={100}
        hideKeyboardShortcutsPanel
        renderDayContents={Day}
        isDayHighlighted={isDayHighlighted}
        // enableOutsideDays
        // firstDayOfWeek={1}
      />
    
      <div className="show_events" style={{position:"absolute",top:"0",left:"0px",background: "rgba(0,0,0,0.5)",padding:"10px",width:"100%",height:"100%",zIndex:"2",display:"none"}}>
       
     
        <div style={{borderRadius:"10PX",width:"100%",height:"100%",overflowY:"scroll"}} className="datewise_events">
          <div className="d-flex" style={{background:" #f2f2f2",padding:"5px"}}>
            
          <p style={{textAlign:"center",background:"#50404d", borderRadius:"5PX",padding:"5px 10PX",color:"white"}}>
            {eventDate}
            </p>
          <p style={{marginTop:"2px",marginLeft:"7px",fontWeight:"bold"}}>EVENTS OF THE DAY</p>
          <a href="#close" className="close_event ml-auto" 
          style={{background:"darkgrey",borderRadius:"50%",color: "white",fontSize: "15px",padding: "5px 10px"}}>×</a>

      
          </div>
      <div style={{overflowY:"auto",background:"#dbd7d2"}}>
      {data.error_code == 404 ?
  (
      <div style={{overflowY:"auto",background:"#dbd7d2",padding:"15PX",textAlign:"center"}}>
      NO EVENT TO DISPLAY
      </div>  
    ):data.error_code == 200 ?
    (
      data.data.map((itemDate) =>
        {
          return(
            <div style={{padding:"10px" }} className="d-flex">
                    <div style={{borderRight:"3px solid #339dd8",padding:"10px"}}>
                      5:00
                    </div>
                    <div style={{padding:"10px"}}>
                      <div style={{marginLeft:"5px",fontWeight:"400",fontSize:"15PX"}}>{itemDate.title}</div>
                      <div  style={{marginLeft:"5px",color:"grey",fontSize:"13PX"}}>{itemDate.description}</div>
                      <div  style={{marginLeft:"5px",fontSize:"11PX",fontStyle:"italic"}}>{itemDate.campus_name}</div>
                      <div  style={{marginLeft:"5px",fontSize:"11PX",fontStyle:"italic"}}>{itemDate.location}</div>
                    </div>
                
                </div>
          )
        })
    ):
    "null"
    }

  </div>        
          </div>
        
       
      </div>
    </Wrapper>
  )
}

// export default EventCalendar
