import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import SummerNote from "./Summer Note/SummerNote";

const StudentOffer = () => {
  const [offerName, setOfferName] = useState("");
  const [offerDesc, setDesc] = useState("");
  const [offerAvailableOn, setOfferAvailableOn] = useState("");
  const [offerBrandName, setOfferBrandName] = useState("");
  const [offerDiscount, setOfferDiscount] = useState("");
  const [offerCoverImg, setOfferCoverImg] = useState("");
  const [offerLogo, setOfferLogo] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();

  const getImage = (e) => {
    setOfferLogo(e.target.files[0]);
    if (e.target.files.length > 0) {
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
      $(".image_std").hide();
    }
  };

  const getCoverImage = (e) => {
    setOfferCoverImg(e.target.files[0]);
    if (e.target.files.length > 0) {
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("offer_cover_img_preview");
      preview.src = src;
      preview.style.display = "block";
      $(".default_cover_image").hide();
    }
  };

  const handelSummenrnote = (e) => {
    setDesc(e);
  };

  const nextStep = () => {
    if (offerLogo == "") {
      $(".offer_logo").show();

      setTimeout(function () {
        $(".offer_logo").hide();
      }, 3000);
    } else if (offerName == "") {
      $(".offer_name").show();

      setTimeout(function () {
        $(".offer_name").hide();
      }, 3000);
    } else if (offerDesc == "") {
      $(".offer_desc").show();

      setTimeout(function () {
        $(".offer_desc").hide();
      }, 3000);
    } else if (offerCoverImg == "") {
      $(".offer_cover_img").show();

      setTimeout(function () {
        $(".offer_cover_img").hide();
      }, 3000);
    } else if (offerAvailableOn == "") {
      $(".offer_available_on").show();

      setTimeout(function () {
        $(".offer_available_on").hide();
      }, 3000);
    } else if (offerBrandName == "") {
      $(".offer_brand_name").show();

      setTimeout(function () {
        $(".offer_brand_name").hide();
      }, 3000);
    } else if (offerDiscount == "") {
      $(".offer_discount").show();

      setTimeout(function () {
        $(".offer_discount").hide();
      }, 3000);
    } else {
      setTimeout(function () {
        history.push("/studentoffersstep2", {
          offerName,
          offerDesc,
          offerBrandName,
          offerDiscount,
          offerLogo,
          offerCoverImg,
          offerAvailableOn,
        });
      }, 1000);
    }
  };

  const resetValues = () => {
    setOfferName("");
    setDesc("");
    setOfferBrandName("");
    setOfferDiscount("");
    setOfferCoverImg("");
    setOfferLogo("");
    setOfferAvailableOn("");
    var preview1 = document.getElementById("file-ip-1-preview");
    preview1.style.display = "none";
    $(".image_std").show();

    var preview2 = document.getElementById("offer_cover_img_preview");
    preview2.style.display = "none";
    $(".default_cover_image").show();
  };

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/[^0-9]/g, "");
    setOfferDiscount(onlyDigits);
  };

  return (
    <div className="content-wrapper">
      <div
        class="formSuccess"
        style={{
          marginTop: "5px",
          width: "100%",
          marginRight: "198px",
          display: "none",
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
      </div>

      <div className="border_class2">
        <div style={{ padding: "6px" }}>
          <div className="polls_heading_h1">CREATE OFFERS</div>
        </div>

        <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 1 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                     Offer Details
                  </p>
                </div>
              </div>
      </div>

      <div>
        <div className="border_class">
          <div class="row">
            <div class="col-md-12">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label className="all_labels">Add Company Logo</label>

                  <p className="all_stars">*</p>
                </div>

                <label for="file-ip-1">
                  <img
                    className="image_std"
                    src="dist/img/event_photo.png"
                    alt="dropdown"
                    style={{ height: "120px", width: "120px",objectFit:"cover" }}
                  />
                  <img
                    id="file-ip-1-preview"
                    style={{ display: "none", height: "115px", width: "115px" }}
                  />
                </label>

                <input
                  type="file"
                  name="photo"
                  style={{ visibility: "hidden",display:"none" }}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={getImage}
                  id="file-ip-1"
                />
                <div
                  class="offer_logo"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}
                  >
                    Please Select Company Logo
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Offer Name</label>
                      <p className="all_stars">*</p>
                    </div>
                    <input
                      className="all_inputs"
                      type="name"
                      id="offer_name"
                      name="offername"
                      placeholder="Enter Offer Name..."
                      autoComplete="true"
                      value={offerName}
                      required="1"
                      onChange={(e) => setOfferName(e.target.value)}
                    />
                    <div
                      class="offer_name"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Offer Name
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Offer Description</label>

                      <p className="all_stars">*</p>
                    </div>
                    <div>
                      <SummerNote
                      _onChange={handelSummenrnote}
                      value={offerDesc}
                      />
                    </div>

                    <div
                      class="offer_desc"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Offer Description
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Product Available On</label>
                      <p className="all_stars">*</p>
                    </div>
                    <input
                      className="all_inputs"
                      type="name"
                      id="offer_available_on"
                      name="offername"
                      placeholder="Enter Offer Name..."
                      autoComplete="true"
                      value={offerAvailableOn}
                      required="1"
                      onChange={(e) => setOfferAvailableOn(e.target.value)}
                    />
                    <div
                      class="offer_available_on"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Offer Availability
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Brand Name</label>

                      <p className="all_stars">*</p>
                    </div>
                    <input
                      type="name"
                      className="all_inputs"
                      id="offer_brand_name"
                      name="offerdesc"
                      placeholder="Enter Offer Brand Name..."
                      value={offerBrandName}
                      onChange={(e) => setOfferBrandName(e.target.value)}
                    />
                    <div
                      class="offer_brand_name"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Offer Brand Name
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2 ">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Discount</label>

                      <p className="all_stars">*</p>
                    </div>
                    <div className="all_inputs d-flex">
                    <input
                      className="all_inputs_discount"
                      type="text"
                      id="offer_discount"
                      name="offerdesc"
                      minLength="0"
                      maxLength="3"
                      pattern="/^-?\d+\.?\d*$/"
                      placeholder="Like 10"
                      value={offerDiscount}
                      onChange={(e) => checkInput(e)}
                      style={{border:"none",width:"95%"}}
                    />
                    <span style={{fontSize:"13px",fontWeight:"500"}}>%</span>
                    </div>
                    <div
                      class="offer_discount"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Offer Discount
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label className="all_labels">Offer cover Image</label>

                  <p className="all_stars">*</p>
                </div>

                <label for="offer_cover_img">
                  <img
                    className="default_cover_image"
                    src="dist/img/event_photo.png"
                    alt="dropdown"
                    style={{ height: "150px", width: "235px"}}
                  />
                  <img
                    id="offer_cover_img_preview"
                    style={{ display: "none", height: "150px", width: "235px" }}
                  />
                </label>

                <input
                  type="file"
                  name="photo"
                  style={{ visibility: "hidden" }}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={getCoverImage}
                  id="offer_cover_img"
                />
                <div
                  class="offer_cover_img"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}
                  >
                    Please Select Item Images
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex border_class" style={{ alignItems: "center" }}>
          <div className="col-8 text-danger">{errorMsg}</div>

          <img
            className="ml-auto delete_image"
            onClick={() => resetValues()}
            src="dist/img/delete.png"
            alt="dropdown"
          />
          <div>
            <div className="news_bar">|</div>
          </div>

          <button type="button" className="next_step_btn" onClick={nextStep}>
            <div className="next_step_btn_div">Next Step</div>
            <img className="next_step_btn_img" src="dist/img/next.png" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentOffer;
