import React,{ useState } from 'react'
import { Header } from "../Header"
import { Menu } from "../Menu"
import { StudentsProfile } from './StudentsProfile'
import { useLocation } from 'react-router-dom'
export function ViewStudentInfo() {
  // console.log("location", useLocation().state.sid);
  const [loc,setLoc] = useState("/student");
  // alert("at least i am in viewstudentinfo")
  return (

    <div>
      {/* <h1>hey there how are you</h1> */}
      <Header />
      
      <Menu pageTitle={loc} />
      <StudentsProfile />
    </div>
    
  )
}
