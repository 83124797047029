import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
// import DonutChart from "react-donut-chart";
import "@patternfly/react-core/dist/styles/base.css";
import { Header } from "../Header";
import { Menu } from "../Menu";
import {  Link } from "react-router-dom";

const reactDonutChartdata = [

  {
    label: "Present",
    value: 96,
    color: "#FF4560",
  },

  {
    label: "Absent",
    value: 4,
    color: "#FEB019",
  },
  {
    label: "Leave",
    value: 2,
    color: "#00E396",
  },
];
const reactDonutChartBackgroundColor = ["green", "red", "purple"];
const reactDonutChartInnerRadius = 0.7;
const reactDonutChartSelectedOffset = 0.04;
const reactDonutChartHandleClick = (item, toggled) => {
  if (toggled) {
    console.log("item", item);
  }
};
let reactDonutChartStrokeColor = "#FFFFFF";
const reactDonutChartOnMouseEnter = (item) => {
  let color = reactDonutChartdata.find((q) => q.label === item.label).color;
  reactDonutChartStrokeColor = color;
};

export function TeachersProfile() {
  const tid = useLocation().state.tid;
  // alert(tid)
  const token = localStorage.getItem("Token");
  const teacher_id = tid;
  const [teacherProfile, updateteacherProfile] = useState([]);

  console.log("TEACHER'S ID", teacher_id)

  async function viewTeacherProfile() {
    const formData = new FormData();
    formData.append("teacher_id", teacher_id);
    const teacherProfileResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_get_teacher_profile",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }
    );
    const profile = teacherProfileResponse.data.data;
    updateteacherProfile(profile);

  }
  useEffect(() => {
    viewTeacherProfile();

  }, []);
  //   {console.log("atasi",teacherProfile)}
  return (
    <div className="d-flex">
      {/* <Header /> */}
      <Menu />
      <div className="content-wrapper p-0">
        <Link to="/teacher">
          <div className="d-flex mt-3" style={{ padding: "0", margin: "0" }}>
            <i
              class="fas fa-arrow-alt-circle-left"
              style={{
                color: "black",
                margin: "0px 0px 0px 10px",
                fontSize: "24px",
                padding: "0",
                fontWeight: "bold",
              }}
            />
          </div>
        </Link>

        <div className="border_class2">
            <div style={{ height: "80px", background: "#1F3977" }}>
              <div className=""></div>
            </div>

            {teacherProfile.map((teacherItem) => {
              console.log("profile.........................",teacherItem);
             
              return (
                <>
                  <div
                    style={{
                      height: "60px",
                      fontWeight: "500",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div></div>
                      <div>
                        <div>
                          <b>{teacherItem.personal_info.teacher_name}</b>
                        </div>
                        <div>
                          <span>{teacherItem.personal_info.department}</span>
                        </div>
                      </div>

                      <div>
                        <div>
                          <b>Academy of Art</b>
                        </div>
                        <div>
                          <span>NUC Mumbai</span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div
                          style={{
                            border: "1px solid #ced4da",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{ alignContent: "center" }}
                          >
                            <div>
                              <img
                                src="dist/img/IdentificationImg.png"
                                style={{ height: "20px", width: "20px" }}
                              />
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                alignContent: "center",
                                marginLeft: "5px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  color: "black",
                                  marginLeft: "2px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Enrolled
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "grey",
                                  marginLeft: "2px",
                                  fontWeight: "500",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                - {teacherItem.personal_info.created_at}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ marginLeft: "10px" }}>
                          <div>
                            <button
                              type="button"
                              className="d-flex  create_button"
                              defaultValue="Sign Up"
                              style={{
                                marginRight: "0px",
                                padding: "7px 10px",
                              }}
                            >
                              <div className="create_button_inner">
                                Send Message
                              </div>
                              <img
                                className="create_button_img"
                                src="dist/img/Progress.png"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {teacherItem.personal_info.teacher_profile == "" ? (
                      <div>
                        <img
                          src="dist/img/boy.jpg"
                          className="text-center"
                          alt="User Profile"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "10%",
                            top: "-60px",
                            left: "55px",
                            position: "absolute",
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={teacherItem.personal_info.teacher_profile}
                          className="text-center"
                          alt="User Profile"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "10%",
                            top: "-60px",
                            left: "55px",
                            position: "absolute",
                            color:"#ffffff"
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>

        {teacherProfile.map((teacherItem) => {
        
          return (
            <div className="d-flex mt-2"  style={{ padding: "5px 25px 80px 25px" }}>
              {/* left part */}

              <div style={{ width: "100%"}}>
               
                {/* <div
                  style={{
                    background: "#1F3977",
                    margin: "0px",
                    paddingBottom: "4px",
                  }}
                >
                 
                  <div
                    style={{ background: "#1F3977", width: "100%" }}
                    className="d-flex"
                  >
                    <div style={{ width: "10%" }}>
                      <img
                        src="dist/img/boy.jpg"
                        className="text-center"
                        alt="User Image"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginLeft: "20px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <div style={{ width: "65%", marginLeft: "7px" }}>
                      <div style={{ color: "white", marginTop: "20px" }}>
                        <p>
                          <b style={{ fontSize: "15px" }}>{teacherItem.personal_info.teacher_name}</b>
                          <br />
                          <p style={{ color: "#a8afba", fontSize: "12px" }}>
                            {teacherItem.personal_info.department}
                          </p>
                        </p>
                      </div>
                    </div>

                    <div style={{ width: "25%", marginLeft: "7px" }}>
                      <div style={{ color: "white", marginTop: "10px" }}>
                        <div style={{ fontWeight: "bold", fontSize: "10px", color: "white", textAlign: "right" }}>
                          <div style={{ marginRight: "10PX" }}>STATUS</div>
                          <div
                            className="d-flex pr-3"
                            style={{ background: "white", margin: "5px 7px", paddingTop: "5px" }}
                          >

                            <i
                              class="far fa-check-circle fa-2x"
                              style={{ color: "#46d262", padding: "0", margin: "0px 5px" }}
                            ></i>

                            <p
                              style={{ fontSize: "12px", color: "black", marginLeft: "2px" }}
                            >
                              Enrolled
                            </p>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "grey",
                                marginLeft: "2px",
                                fontWeight: "500",
                                padding: 0
                              }}
                            >
                              - {teacherItem.personal_info.created_at}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div> */}

                <div className="border_class">
                <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        style={{
                          color: "#1F3977",
                          fontSize: "13px",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span> Personal Details</span>
                      </div>

                      <div className="ml-auto">
                        <span
                          style={{
                            background: "#4779F0",
                            color: "white",
                            padding: "5px 30px",
                            borderRadius: "5px",
                          }}
                        >
                          Actions
                        </span>
                      </div>
                      <div></div>
                 </div>

                 <hr style={{ marginTop: "9px" }} />

                  <div  style={{ paddingBottom: "70px" }}>
                    <table style={{ width: "80%", fontFamily: "Poppins" }}>
                      <tr style={{ width: "100%" }}>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>First Name</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.first_name}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Gender</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.gender}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Subject</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.subject}</div>
                        </td>

                      </tr>

                      <tr style={{ width: "100%" }}>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Last Name</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.last_name}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Department</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.department}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Email Address</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.email}</div>
                        </td>
                      </tr >

                      <tr style={{ width: "100%" }}>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Preffered Name</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.preffered_name}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Teacher's ID</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.teacher_id}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Mobile Number</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.mobile}</div>
                        </td>
                      </tr>

                      <tr style={{ width: "100%" }}>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Date of Birth</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.dob}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Class</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.class}</div>
                        </td>
                        <td style={{ padding: "8px", lineHeight: "14px" }}>
                          <div style={{ color: "grey", fontWeight: "400", fontSize: "11px" }}>Race/Ethnicity</div>
                          <div style={{ fontWeight: "600", fontSize: "13px" }}>{teacherItem.personal_info.race_ethnicity}</div>

                        </td>
                      </tr>

                    </table>
                  </div>
                 
                </div>
               
              </div>
              
            </div>
          )
        })}
      </div>
    </div>
  );
}