import React,{useEffect, useState} from "react"
import axios from "axios"

export default function ViewProfile(){

  const token = localStorage.getItem("Token");
  const [name,setName] = useState("");
  const [mail,setMail] = useState("");
  const [cell,setCell] = useState("");


    async function get_superadmin_profile()
    {
        const data = await axios.get("https://testingsites.in/Unicircle_Api_copy/SuperAdminController/get_superadmin_data",
        {
            headers: {
              "Content-Type": "multipart/form-data",
    
              Authorization: token,
            },
    
          })
        // console.log("adminwa ka data = ",data.data.data[0])
        if(data)
        {
            console.log("data = ",data)
            setName(data.data.data[0].name)
            setMail(data.data.data[0].email)
            setCell(data.data.data[0].mobile)
            console.log("name = "+name+" email = "+mail+" mobile = "+cell)
        }
    }

    async function editSuperAdmin(){
        const formData = new FormData();
        formData.append("adminname",name)
        formData.append("adminemail",mail)
        formData.append("adminmob",cell)
        const updateSuperAdmin = await axios.post("https://testingsites.in/Unicircle_Api_copy/SuperAdminController/edit_superadmin_data",
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
      
                Authorization: token,
              },
        })
        console.log("updateSuperAdmin",updateSuperAdmin)
        if(updateSuperAdmin.data.error_code == 200)
        {
          window.location.href="/homepage"
        }
    }

    useEffect(()=>{
        get_superadmin_profile();
    },[])

    return (
        <>
           <div className="content-wrapper">
      <div className="" style={{ marginTop: "20px", padding: " 0PX 10px 0px 20px", width: "80%" }}>
        <div style={{ color: "black", fontWeight: "700", marginTop: "0" }}>Superadmin Profile</div>
        {/* <div style={{fontSize:"11PX", color:"darkgrey",fontWeight:"500"}}>Campus News keep up to date with what's happening on campus & in your city.</div> */}
      </div>

      <div
        style={{ background: "white", margin: "5px 30px 0px 15px", padding: "10px 10px 10px 20px", width: "98%", borderRadius: "10PX" }}
      >
        <div className="mt-2 p-0">
          <div class="row">
            <div class="col-md-12">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "600" }}>Superadmin Name</label>
                  <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                </div>
                <input
                  type="name"
                  id="offer_name"
                  name="offername"
                  placeholder="Enter Superadmin Name..."
                  //autoComplete="true"
                  value={name}
                  required="1"
                  onChange={(e) => setName(e.target.value)}
                  style={{ width: "80%", height: "35px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "15px", paddingLeft: "5PX" }}
                />
                <div
                  class="Campus_name"
                  style={{ margin: "0", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{
                      color: "red",
                      fontSize: "12PX",
                      marginLeft: "0",
                    }}
                  >
                    Please Enter Superadmin Name
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 p-0">
          <div class="row">
            <div class="col-md-12">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "600" }}>Superadmin email</label>

                  <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                </div>
                <input
                  type="text"
                  id="offer_link"
                  name="offerlink"
                  placeholder="Enter Superadmin email"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  style={{ background: "white", width: "80%", height: "35px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "15px", paddingLeft: "5PX" }}
                />
                <div
                  class="Campus_name"
                  style={{ margin: "0", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{
                      color: "red",
                      fontSize: "12PX",
                      marginLeft: "0",
                    }}
                  >
                    Please Enter Superadmin Email
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 p-0">
          <div class="row">
            <div class="col-md-12">
              <div className="" style={{ width: "100%", marginTop: "0px" }}>
                <div className="d-flex">
                  <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "600" }}>Superadmin Mobile No</label>

                  <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                </div>
                <input
                  type="text"
                  id="offer_desc"
                  name="offerdesc"
                  placeholder="Enter Superadmin Mobile number"
                  value={cell}
                  onChange={(e) => setCell(e.target.value)}

                  style={{ background: "white", width: "80%", height: "35px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "15px", paddingLeft: "5PX" }}
                />
                <div
                  class="Campus_name"
                  style={{ margin: "0", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{
                      color: "red",
                      fontSize: "12PX",
                      marginLeft: "0",
                    }}
                  >
                    Please Enter Superadmin mobile number
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>


        <br />
        <div className="row" style={{ width: "80%" }}>
          <div className="col-10 text-danger">{}</div>
          <div className="col-5 mx-r" >
            {/* <input
              type="button"
              className="create_btn bg-white text-primary shadow form-buttons3 "
              value="Preview"
            //   onClick={() => previewOffer()}
              style={{
                fontWeight: "bold",
                borderRadius: "5px",
                marginLeft: "auto",
                backgroundColor: "#1F3977",
                padding: "10px 30px",
                fontSize: "12PX",
                alignItems: "flex-end",
              }}
            /> */}
            <input
              type="button"
              className="create_btn shadow form-buttons3 "
              value="Update"
              onClick={() => editSuperAdmin()}
              style={{
                fontWeight: "bold",
                borderRadius: "5px",
                marginLeft: "auto",
                backgroundColor: "#1F3977",
                padding: "10px 30px",
                fontSize: "12PX",
                alignItems: "flex-end",
              }}
            />
          </div>
        </div>

      </div>

    </div>
        </>
    )
}