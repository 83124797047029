import React,{ useState } from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu"
import { Tickets } from './Tickets'
export function TicketsDashboard() {
    const [loc,setLoc] = useState("/helpDeskDetails");
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} />
            <Tickets />
            </div>
        </div>
    )
}
