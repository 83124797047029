import React, { useState, useEffect } from "react";
import { Header } from "../components/Header";
import { Menu } from "../components/Menu";
import { Dashboard } from "./Dashboard";
// import {Home} from "./Home";
export function Homepage() {
  const [loc, setLoc] = useState(window.location.pathname);
  // alert(window.location.pathname)
  return (
    <div id="identity">
      <Header />
      <div className="d-flex">
        <Menu pageTitle={loc} />
        <Dashboard />
      </div>

    </div>
  );
}
