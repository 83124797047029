import React from 'react'
import {AddAppoinment} from "./CreateAppoinment";
import { AppointmentTable } from "./AppointmentTable";
import {BiPlusMedical,BiSearchAlt2} from "react-icons/bi"
export function Appointment() {
    return (
        <div className="content-wrapper">
      <div className="d-flex" style={{marginLeft:"10px", marginTop:"20px", width:"100%", paddingRight:"30px"}}>
      <div>
            <h4 style={{color:"black",fontWeight:"bold", marginTop:"0"}}>APPOINTMENT</h4>
            <div style={{fontSize:"12PX", color:"darkgrey"}}>Campus News keep up to date with what's happening on campus & in your city.</div>
            </div>

{/* all student */}
        {/* <div className="mt-1"><h5 style={{color:"black", fontWeight:"500", marginLeft:"200px"}}>All Appointments</h5></div> */}

{/* search */}
<div style={{marginLeft:"auto"}}>
<div className="d-flex" style={{width:"250px",height:"30px",marginTop:"0px",marginLeft:"120px", borderRadius:"20px", backgroundColor:"white"}}>
<div  style={{padding:"5px", borderRadius:"20px",backgroundColor:"white"}}><BiSearchAlt2 style={{color:"#293043", fontSize:"18px", marginTop:"1px"}}/></div>
<div style={{padding:"0px", width:"100%", height:"30px", backgroundColor:"white", borderRadius:"20px"}}>
    <input type="text" placeholder="Search by Appointment" style={{background:"white", borderRadius:"20px",height:"27px", width:"90%", fontSize:"12px"}}/>
</div>
</div>
</div>
{/* end search */}

 {/* CREATE New student*/}
 <div style={{marginLeft:"10px", marginTop:"0px",padding:"0" }}>
 <a href="/createAppoinment">


 <button
                  type="button"
                  className="d-flex buttonContainer"
                  defaultValue="Sign Up"
                 
                  style={{ padding:"8px", marginTop:"0",background:"#1F3977", flexWrap: "wrap", borderRadius:"5px", marginLeft:"auto"}}
                >
                   <BiPlusMedical style={{marginTop:"0px",fontSize:"12px"}}/>
                  <div style={{marginLeft:"5px",fontSize:"12PX"}}>Create Appointment</div>
</button>

</a>
    
</div>
{/* end CREATE new student */}

      </div>
      

      <AppointmentTable />

    </div>
    )
}
