import React, { useState, useEffect, useMemo, Component } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Modal from "react-modal";
import { format } from 'date-fns';

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import styled from "styled-components";
import moment from "moment";

export const EventCalendarPage = () => {
  const token = localStorage.getItem("Token");
  const localizer = momentLocalizer(moment);


  const events = [
    {
      // title: "My Event",
      start: new Date(),
      end: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
  ];
    //  console.log("title------------------------",events.title);
     console.log("start------------------------",events.start);
     console.log("end------------------------",events.end);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    console.log("event-------------------", format(event.start, 'dd/MM/yyyy'));
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
};
  

  

 

 
  return (
    <div>
      <Header />
      <div className="d-flex">
        <Menu />
        <div className="content-wrapper">
          <div className="row">
            {/* <div className="col-md-6">
            <div>
      <button onClick={handlePrevMonth}>Previous Month</button>
      <button onClick={handleNextMonth}>Next Month</button>
      <DayPickerRangeController
        
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleDatesChange}
        
      />
    </div>
            </div> */}




            <div className="col-md-6 mt-2">
            <div className="border_class p-0">
            <div
                    className="inner calendar_inner"
                    style={{ lineHeight: "40px",background:"rgb(108, 122, 153)",color:"#fff",marginBottom:"5px" }}
                  >
                    <div className="d-flex" style={{ flexWrap: "wrap",paddingLeft:"20px" }}>
                      <div>
                        <div
                          style={{
                            fontWeight: "600",
                            
                            marginTop: "0px",
                            fontSize: "13px",
                            padding: "0px",
                          }}
                        >
                          Event Calendar
                        </div>
                        <div
                          style={{
                            padding: "0px",
                            fontWeight: "400",
                            marginLeft: "0px",
                            fontSize: "12px",
                            marginBottom: "0",
                            color: "#fff",
                            marginTop: "0",
                          }}
                        >
                          {/* You have 3 Events in {moment(dateState).format("MMMM")} */}
                        </div>
                      </div>
                     
                    </div>
                  </div>

                  <div style={{display:"flex",justifyContent:"center"}}>              
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  views={["month"]}
                  onSelectEvent={handleEventClick}
                  style={{ height: 250, width: 430 }}
                />


                {/* <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={handleModalClose}
                  contentLabel="Event Details"
                >
                  <h2>heloo</h2>
                  <p>{events.start}</p>
                  <p>{events.end}</p>
                </Modal> */}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
