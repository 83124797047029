import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link,useLocation } from "react-router-dom";
import styled from "styled-components";

export const EventDetails = () => {

  const location = useLocation();
  const token = localStorage.getItem("Token");
  const { e_id } = location.state || { id: "none" };
  const [eventData, setEventData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  async function getEventData(eId) {
    try {
      const formData = new FormData();
      formData.append("event_id", eId);

      const eventResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_event_data",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = eventResponse.data.error_code;
      if (ErrorCode == 200) {
        const e_data = eventResponse.data.data;

        setEventData(e_data);
      } else {

      }
    } catch (err) {
      console.log("get event data error-----------", err);
    }
  }

  useEffect(() => {
    getEventData(e_id);
  }, []);

  return (
    <>
      <div>
        <Header />

        <div className="d-flex">
          <Menu />

          <div className="content-wrapper">
            {/* <Link to="/CreateEvent">
              <i
                className="fas fa-arrow-alt-circle-left"
                style={{ fontSize: "21px", color: "black" }}
              ></i>
            </Link> */}
             {eventData.map((e_data) =>{
              var date1= e_data.start_date_time;
              var date2= e_data.end_date_time;
              var s_date = moment(date1).format("D MMM").toUpperCase();
              const dateObject = new Date(date1);
              const hours = dateObject.getHours();
              const minutes = dateObject.getMinutes();
              const formattedTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

              var e_date = moment(date2).format("D MMM").toUpperCase();
              const dateObject2 = new Date(date2);
              const hours2 = dateObject2.getHours();
              const minutes2 = dateObject2.getMinutes();
              const formattedTime2 = `${hours2}:${minutes2 < 10 ? `0${minutes2}` : minutes2}`;
              return(<>


            <div className="search_box_border_class">

              <div className="main_heading_h1">{e_data.title}</div>
            </div>

            <div className="card_div mt-2" style={{ width: "100%" }}>
              <div className="card-header bg-white p-0">
                <div className="card_inner_div">
                  <div className="row">
                    <div className="col-md-12 d-flex">
                      <div
                        className="d-flex"

                      >
                        {e_data.event_img.map((e)=>{
                          return(
                        <div style={{margin:"2px"}}>
                          <img className="img_hover_class"
                            src={e.event_img}
                          />
                        </div>
                         )
                        })}

                      </div>

                    </div>
                  </div>

                  <div className="row">
                    <div style={{ marginTop: "15px" }}>
                      <div class="col-md-12 p-0">
                        <div className="ten_font_class"
                          style={{
                            color: "#1F3977",
                            gap: "5px",
                            display: "flex"
                          }}
                        >
                          <span>{s_date}</span>
                          <span>AT</span>
                          <span>{formattedTime}</span>
                          <span> {" "}- {" "}</span>
                          <span>{e_date}</span>
                          <span>AT</span>
                          <span>{formattedTime2}</span>

                        </div>

                        <div
                          className="mt-2 twelve_font_class">
                          <p>{e_data.title}</p>
                        </div>

                        <div className="d-flex">
                          <div className="ten_font_class"
                            style={{
                              color: "#1F3977"
                            }}
                          >
                            <span>Venue :</span>
                          </div>
                          <div className="ten_font_class"
                            style={{
                              marginLeft: "5px"
                            }}
                          >
                            {e_data.location}
                          </div>
                        </div>

                        <div className="d-flex ten_font_class">
                          <div
                            style={{
                              color: "#1F3977"
                            }}
                          >
                            <span>Entry Fee :</span>
                          </div>
                          <div
                            style={{

                              marginLeft: "5px",
                            }}
                          >
                            {e_data.entry_fee}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <hr className="card_inner_hr_css" />
                  </div>

                  <div className="row">
                    <div className="">
                      <div className="">
                        <label
                          className="all_labels ten_font_class"
                        >
                          About Event
                        </label>
                      </div>
                      <div className="">
                        <p>
                        <p className="desc_class" dangerouslySetInnerHTML={{ __html:e_data.description }}/>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <hr className="card_inner_hr_css"/>
                  </div>

                  <div className="row">
                    <div>
                      <span className = "ten_font_class"
                        style={{
                          color: "#4AA081"
                        }}
                      >
                        Engagement Analytics
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5 p-0">
                      <section style={{ margin: "10px 5px" }}>
                        <div className="container-fluid table-cards">
                          <div className="table-cards">
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ height: "100%", padding: "0px 5px" }}
                              >
                                <div
                                  className="small-box"
                                  style={{
                                    height: "65px",

                                    borderRadius: "2.5PX",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="inner"
                                    // onClick={UniDetails}
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                        <span className="eleven_font_class"
                                          style={{
                                            color: "#1F3977"
                                          }}
                                        >
                                          Link Opened
                                        </span>


                                      <div
                                        className="d-flex sixteen_font_class"
                                        style={{
                                          flexWrap: "wrap",
                                          marginTop: "5px"
                                        }}
                                      >
                                        <div>{e_data.total_links_open}</div>
                                      </div>
                                    </div>

                                    <div className ="all_icon_imgs_div"
                                      style={{background: "#FBE1FF"}}
                                    >
                                      <img className="all_icon_imgs"
                                        src="dist/img/ComboChart.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div className="col-md-5 p-0">
                      <section style={{ margin: "10px 5px" }}>
                        <div className="container-fluid table-cards">
                          <div className="table-cards">
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ height: "100%", padding: "0px 5px" }}
                              >
                                <div
                                  className="small-box"
                                  style={{
                                    height: "65px",
                                    borderRadius: "2.5PX",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="inner"
                                    // onClick={UniDetails}
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>

                                        <span className ="eleven_font_class"
                                          style={{
                                            color: "#1F3977"
                                          }}
                                        >
                                          Interested
                                        </span>


                                      <div
                                        className="d-flex sixteen_font_class"
                                        style={{
                                          flexWrap: "wrap",
                                          marginTop: "5px"
                                        }}
                                      >
                                        <div> {e_data.total_intrested}</div>
                                      </div>
                                    </div>

                                    <div className="all_icon_imgs_div"
                                      style={{background: "#BEF5C3"}}
                                    >
                                      <img className="all_icon_imgs"
                                        src="dist/img/CircleChart.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>)
             })}

          </div>
        </div>
      </div>
    </>
  );
};
