import React, { useEffect, useState } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import styled from "styled-components";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import axios from "axios";
import $ from "jquery";
import { useHistory, useLocation,Link } from "react-router-dom";
import StateAndCities from "../State and Cities/StateAndCities.json"


export const AddUniversity = (props) => {
   const [childData, setChildData] = useState({});
  const eventformdata = (data) => {
    setChildData(data);
  }

  

  const [loc, setLoc] = useState(window.location.pathname);
  const token = localStorage.getItem("Token");
  const history = useHistory();

  const [uniName, setUniName] = useState("");
  const [uniAddress, setUniAddress] = useState("");
  const [uniLogo, setUniLogo] = useState(null);
  const [uniCoverPhoto, setUniCoverPhoto] = useState(null);
  const [error_message, setError_message] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");

  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [stateNameData, setStateNameData] = useState([])
  const [cityNameData, setCityNameData] = useState([])
 
  const stateFun =() =>{   
    setStateCityData(StateAndCities)
    stateCityData.map((e) =>{
      setStateNameData(e.states); 
    })
}

  useEffect(() =>{
    stateFun()
  },[])

  const onStateChange = (e) =>{
    stateNameData.map((_e) =>{
      if(_e.state == e){
        setStateName(e);
        setCityNameData(_e.districts);
      }
    })
  }

  async function createUniversity() {
    
    try {
      const u_name = document.getElementById("u_name");
      const u_address = document.getElementById("u_address");

      if (
        u_name.value == "" &&
        u_address.value == "" 
        
      ) {
        $(".ValueMsg").show();

        setTimeout(function () {
          $(".ValueMsg").hide();
        }, 3000);
        return;
      } else if (u_name.value == "") {
        $(".u_name").show();

        setTimeout(function () {
          $(".u_name").hide();
        }, 3000);

      } else if (u_address.value == "") {
        $(".u_address").show();

        setTimeout(function () {
          $(".u_address").hide();
        }, 3000);
      } 
      
       else if (uniLogo == null) {
        $(".u_logo").show();

        setTimeout(function () {
          $(".u_logo").hide();
        }, 3000);
      }
      else if (stateName == "") {
        $(".state_name").show();

        setTimeout(function () {
          $(".state_name").hide();
        }, 3000);
      }
      
      else {
        const formData = new FormData();

        formData.append("uni_name", uniName);
        formData.append("uni_add", uniAddress);
        formData.append("state_name", stateName);
        formData.append("city_name", cityName);

        for (var i = 0; i < uniLogo.length; i++) {            
            formData.append("uni_photo", uniLogo[i]);
          }
           if(uniCoverPhoto == null){
            formData.append("uni_cover_photo", "");
           }else{

          for (var i = 0; i < uniCoverPhoto.length; i++) {            
            formData.append("uni_cover_photo", uniCoverPhoto[i]);
          }
        }
        
          
        const eventResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_create_university",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
        // setIsLoading(false);
        setError_message(eventResponse.data.message);
        if (eventResponse.data.error_code == 200) {       

           setError_message(eventResponse.data.message);
          $(".formSuccess").show();
           
          setTimeout(function () {
            $(".formSuccess").hide();
          }, 5000);

          setUniName("");
          setUniAddress("");

          var preview = document.getElementById("file-ip-1-preview");
        
           preview.style.display = "none";
          setUniAddress("");
          
        }
        history.push("/universities")
        

      }
    } catch (err) {
      console.log("Create university error---------", err);
     
      // setIsLoading(false);
    }
  }

  var eventImage = [];
  const getImage = (e) => {
    $(".event_image").hide();

    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setUniLogo(eventImage);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";
      }
    }
  };
  var c_image = [];
  const getCoverImg = (e) => {
    $(".uni_cover_defalt_img").hide();
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        c_image.push(e.target.files[i]);

        setUniCoverPhoto(c_image);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("uni_cover_img_preview");
        preview.src = src;
        preview.style.display = "block";
      }
    }
  };

  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu pageTitle={loc} />

          <div className="content-wrapper">

          <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {error_message}
          </Alert>
        </Stack>
        </div>

            <div className="border_class">
              <div className="main_heading_h1">Add University</div>
             
            </div>

            <div className="border_class">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="col-md-12">
                <div
                  style={{ width: "100%"}}
                >
                  <div className="d-flex">
                    <label className="all_labels">
                      University Name
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input className="input_box"
                    type="name"
                    id="u_name"
                    value={uniName}
                    onChange={(e) => setUniName(e.target.value)}
                    placeholder="Event title goes here..."
                    autoComplete="true"
                    
                  />
                  <div
                    class="u_name"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Universities Name
                    </h4>
                  </div>

                  

                  
                </div>
                </div>

              <div class="col-md-6 mt-2">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">State</label>

                        <p className="all_stars">*</p>
                      </div>

                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="department_neww"
                        onChange={(e) => onStateChange(e.target.value)}
                      >
                        <option value={"disabled"}>Select State</option>
                        {stateNameData.map((e) => {
                          return (
                            <option value={e.state}>{e.state}</option>
                          );
                        })}
                       
                      </select>

                      <div class="state_name" style={{ display: "none" }}>
                        <h4 className="all_validations_h4">
                          Please Select State
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mt-2">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">City</label>
                  
                      <p className="all_stars">
                    
                  </p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="student_course"
                      onChange={(e) => setCityName(e.target.value)}
                    >
                      <option selected="selected">Select City</option>

                      {cityNameData.map((name) => {
                        return (
                          <option value={name}>
                            {name}
                          </option>
                        );
                      })}
                     
                    </select>

                    <div class="std_course" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Select City
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-2">
                <div className="d-flex">
                    <label className="all_labels">
                      University Address
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <textarea className="input_box"
                    id="u_address"
                    value={uniAddress}
                    onChange={(e) => setUniAddress(e.target.value)}
                    placeholder="Your message goes here..."
                    autoComplete="true"
                    style={{height:"70px"}}
                  />
                  <div
                    class="u_address"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Address
                    </h4>
                  </div>
                </div>

                </div>

              <div class="col-md-3">
                <div  className="">
                  <div className="d-flex">
                    <label className="all_labels">
                      Add University Logo
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <label for="file-ip-1">
                    <img
                      src="dist/img/event_photo.png"
                      className="event_image"
                      alt="dropdown"
                      style={{ height: "145px" }}
                    />
                    <img
                      className=" multipleImages"
                      id="file-ip-1-preview"
                      style={{display:"none", width: "100%", height: "140px"  }}
                    >
                    </img>
                  </label>

                  <div
                    class="u_logo"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Select University Logo
                    </h4>
                  </div>

                  <input
                    type="file"
                    name="photo"
                    style={{ visibility: "hidden",display:"none" }}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={getImage}
                    multiple
                    id="file-ip-1"
                    
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div  className="">
                  <div className="d-flex">
                    <label className="all_labels">
                      Add University Cover Photo
                    </label>

                    <p className="all_stars">
                      
                    </p>
                  </div>

                  <label for="uni_cover_img">
                    <img
                      src="dist/img/event_photo.png"
                      className="uni_cover_defalt_img"
                      alt="dropdown"
                      style={{ height: "145px" }}
                    />
                    <img
                      className=" multipleImages"
                      id="uni_cover_img_preview"
                      style={{display:"none", width: "100%", height: "140px"  }}
                    >
                    </img>
                  </label>
                  <input
                    type="file"
                    name="photo"
                    style={{ visibility: "hidden",display:"none" }}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={getCoverImg}
                    multiple
                    id="uni_cover_img"
                    
                  />
                </div>
              </div>

              

                

            </div>
          </div>

            {/* buttons */}
            <div className="d-flex   border_class box_padding buttons_div">           
          
          <div
            class="ValueMsg"
            style={{ margin: "8px", width: "57%", display: "none" }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="filled" severity="error">
                Error! You Must Fill In All The Fields
              </Alert>
            </Stack>
          </div>

          <input
            type="button"
            className="publish_button"
            defaultValue="Sign Up"
            onClick={() => createUniversity()}
            value="Publish"
           
          />
        </div>

          </div>
        </div>
      </div>
    </>
  );
};
