import React from 'react'
import { Header } from '../Header';
import { Menu } from '../Menu';
import { Appointment } from './Appointment';
import { AppointmentDatatable } from './AppointmentDatatable';

export function AppointmentDetails() {
    return (
        <div>
            <Header />
     
     <Menu />
  <Appointment />
  {/* <AppointmentDatatable /> */}
   
        </div>
    )
}
