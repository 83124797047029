import React from 'react'
import ImageUploader from "react-images-upload";
import { useState } from "react";
import $ from "jquery";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { NewRecipient } from "./NewRecipient"
import { NewPersonaRecipient } from "./NewPersonaRecipient"
import { NewClassRecipient } from "./NewClassRecipient"
import { ExportToExcel } from "./ExportToExcel"
export function MarketplaceForm() {
  const token = localStorage.getItem('Token');
  const [data, setData] = useState([])
  const [state, setState] = React.useState({
    pictures: []
  });

  
  const [childData, setChildData] = useState([]);
  const [childId, setChildId] = useState({});
  const passData = (id, data) => {

    setChildId(id)

    setChildData(data)


  };

  const passPersonaData = (Pid, Pdata) => {

    setChildId(Pid)

    setChildData(Pdata)


  };
  console.log("childId",childId)
  const student_name = childData.join(", ")
  const [addPersona, updatePersona] = useState([])
  const [errorMessagePersona, updateErrorMessagePersona] = useState("")
  const [errorCodePersona, updateErrorCodePersona] = useState("")
  async function createPersona() {
   
    const formData = new FormData();
    formData.append("persona", addPersona)
    const personaResponse = await axios.post(process.env.REACT_APP_API_KEY + "add_persona",
      formData,
      {
        headers:
        {
          "Content-Type": 'multipart/form-data',
          "Authorization": token,
        }
      });

    console.log("Create Persona", personaResponse);
    if(personaResponse.data.error_code == 200)
    {
      updatePersona("")
    }
    updateErrorCodePersona(personaResponse.data.error_code)
    
      updateErrorMessagePersona(personaResponse.data.message)
   
    
      
    
  
  
   
    $(".personaMsg").show();

    setTimeout(function () {
      $(".personaMsg").hide();
    }, 3000);
  }

  const fileName = "uploadStudent";
  // const[studentList,updateStudentList] = useState([])
  // async function fetchStdList() {
  //   try {
  //     const fetchClassResponse = await axios.get(
  //       process.env.REACT_APP_API_KEY + "get_students_list",
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",

  //           Authorization: token,
  //         },
  //       }
  //     );

  //     console.log("Student Details", fetchClassResponse.data.data);
  //     updateStudentList(fetchClassResponse.data.data);
  //   } catch (err) {
  //     console.log("Log in Fail", err);
  //   }
  // }

  var studentList = [
    {
        "First Name": "",
        "Last Name": "",
        "Preferred Name": "",
        "Father Name":"",
        "dob":"",
        "Mother Name":"",
        "Gender":"",
        "Country":"",
        "Mobile":"",
        "password":"",
        "First Language":"",
        "Class":"",
        "Department":"",
        "First Nationality":"",
        "Second Nationality":"",
        "Email":"",
        "Spoken Language":"",
        "Race":"",
        "persona":""
    
       
    }
]

  const [excel, setExcel] = useState([]);
  const [excelError_code, updateExcelError_code] = useState("")
  const [excelError_message, updateExcelError_message] = useState("")
  async function uploadExcel() {
    try {


      const formData = new FormData();

      formData.append("uploadFile", excel);

      const excelResponse = await axios.post(process.env.REACT_APP_API_KEY + "admin_upload_excel_file_student",
        formData,
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',
            "Authorization": token,
          }
        });

      console.log("Upload ExcelSheet", excelResponse);
      // if(excelResponse.data.error_code == 200)
      // {
      // setData(excelResponse.data.data)
      // setTimeout(() => {
      //   $(".user_type").hide();
      // }, 3000);
      // }
      updateExcelError_code(excelResponse.data.error_code);
      updateExcelError_message(excelResponse.data.message);

$(".excel_message").show()
setTimeout(() => {
  $(".excel_message").hide ()
}, 3000);

    }
    catch (err) {
      console.log("Log in Fail", err);

    }
  }
  const onDrop = (picture, e) => {
    setState({
      pictures: state.pictures.concat(picture)
    });
    // updatePhotos(e.target.value)
  };

  const [images, setImages] = useState([{
    data: [],
    url: ""
  }])

  const [title, updateTitle] = useState("")
  const [description, updateDescription] = useState("")
  const [photos, updatePhotos] = useState([])
  const [price, updatePrice] = useState("")
  const [send_to, updateSend_to] = useState("")
  const [imgData, setImgData] = useState([]);
  const [imgDataMarketplace, setImgDataMarketplace] = useState([]);
  const history = useHistory();


 
  const getMultipleImage = (e) => {
 
    updatePhotos(e.target.files);

    if (e.target.files.length > 0) {
      for (let i = 0 ; i < e.target.files.length ; i++) {
    
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";
       
     
        imgData.push(src)
        // const l = src.length;
        // const fruit = src.slice(5,l)
    
        // imgData.push(fruit)
       
      
    }
      
  
    }
    
    
 
 
  }
 
 
  console.log("photos imgData", imgData);
 
  const [userType, updateUserType] = useState([]);
  function all_student() {
    $(".user_type").hide();
  }
  async function specific_class() {
    $(".user_type").show();
    try {

      const fetchClassResponse = await axios.get(process.env.REACT_APP_API_KEY + "get_classes_list",
        {
          headers:
          {
            "Content-Type": 'multipart/form-data',
            "Authorization": token,
          }
        }
      );

      console.log("Get class List Details", fetchClassResponse.data.data);
      updateUserType(fetchClassResponse.data.data);

    }
    catch (err) {
      console.log("Log in Fail", err);

    }
  }
  const checkRadioForUserType = () => {
    var user = document.getElementById("tblFruitsUserType");
    var radioInput = user.getElementsByTagName("INPUT");
    var isValid = false;

    for (var i = 0; i < radioInput.length; i++) {
      if (radioInput[i].checked) {
        isValid = true;
        break;
      }
    }
    document.getElementById("spnErrorUserType").style.display = isValid ? "none" : "block";
    return isValid;
  }

  async function createMarketplace() {
    try {
      const m_title = document.getElementById("title");
      const m_description = document.getElementById("description");
      const m_photos = document.getElementById("add_imagee");
      const m_price = document.getElementById("price");
      const newsSendTo = document.getElementById("sendNotification");


      if (m_title.value == "" &&
        m_description.value == "" &&
        m_photos.value == "" &&
        m_price.value == "" 
        // m_send_to.value == ""
      ) 
      {
        $(".ValueMsg").show();

        setTimeout(function () {
          $(".ValueMsg").hide();
        }, 3000);
        return;
      }

      else if (m_title.value == "") {

        $(".Title").show();

        setTimeout(function () {
          $(".Title").hide();
        }, 3000);

      }

      else if (m_description.value == "") {

        $(".Description").show();

        setTimeout(function () {
          $(".Description").hide();
        }, 3000);

      }

      else if (m_photos.value == "") {

        $(".Photos").show();

        setTimeout(function () {
          $(".Photos").hide();
        }, 3000);

      }

      else if (m_price.value == "") {

        $(".Price").show();

        setTimeout(function () {
          $(".Price").hide();
        }, 3000);

      }

      else if (send_to == "") {
        checkRadioForUserType();
        $(".SendToAll").show();

        setTimeout(function () {
          $(".SendToAll").hide();
        }, 3000);

      }

else{
  $(".formSuccess").show();

  setTimeout(function () {
    $(".formSuccess").hide();
    history.push("/marketplaceStep2", { title, description, imgData, photos,price, send_to ,childId})
  }, 3000);




}
  


    }
    catch (err) {
      console.log("Log in Fail", err);

    }
  }

  function closeSpecificPopUp()
  {
    $(".user_type").hide()
  }

  return (
    <div className="content-wrapper mx-auto">
      <div style={{ padding: "10px", margin: "0", }}>

<h1 style={{ color: "black", fontWeight: "600", fontFamily: "Poppins", fontSize: "13PX", lineHeight: "24PX", marginLeft: "42PX" }}>ADD PRODUCT</h1>
</div>

      <div class="formSuccess" style={{ marginTop: "5px", marginLeft: "18px", width: "97%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            Data Saved Successfully
          </Alert>
        </Stack>
      </div>

      <div class="ValueMsg" style={{ margin: "8px", width: "57%", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="error">
            Error! You Must Fill In All The Fields
          </Alert>
        </Stack>
      </div>

      <div style={{ background: "#FFFFFF",  width: "100%", height: "520px",overflowY:"auto",overflowX:"hidden", boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)", borderRadius: "10px" ,paddingRight:"10px"}}>

        
{/* TITLE */}
<div className="mt-2 p-0 pl-3">
            <div class="row">
              <div class="col-md-8">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600" }}>Title</label>

                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                  </div>
                  <input
                    type="name"
                    id="title"
                    className="input_fields"
                    value={title}
              onChange={(e) => updateTitle(e.target.value)}
                    placeholder="Your title goes here..."
                    autoComplete="true"
                    style={{ width: "80%", height: "35px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "10px", paddingLeft: "5PX" }}

                  />
                  <div
                    class="Title"
                    style={{ marginTop: "-6px", display: "none" }}>
                    <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                      Please Write Item Name
                    </h4>
                  </div>


                </div>
              </div>



            </div>
          </div>

{/* PRODUCT DESCRIPTION */}
<div className="mt-2 p-0 pl-3">
            <div class="row">
              <div class="col-md-12">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600" }}>Product Description</label>

                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                  </div>
                  <textarea
                    type="name"
                    rows="4"
                    class="input_fields"
                    id="description"
                    value={description}
              onChange={(e) => updateDescription(e.target.value)}
                    placeholder="Your message goes here..."
                    style={{ width: "88%", height: "80px", border: "1px solid #c4c4c4", boxSizing: "border-box", padding: "6px", fontSize: "10px" }}

                  />
                  <div
                    class="Description"
                    style={{ marginTop: "-6px", display: "none" }}>
                    <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                      Please Write Item Description
                    </h4>
                  </div>
                  

                </div>
              </div>

            </div>

          </div>
       
{/* MEDIA */}
<div className="mt-2 p-0 pl-3">
            <div class="row">
              <div class="col-md-8">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600" }}>Media</label>

                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                  </div>
                     

                  <label
                  id="photos"
              for="add_imagee"
              style={{
                background: "rgba(71, 121, 240, 0.3)",
                borderRadius: "2px", fontSize: "12PX", padding: "10px",color:"2D5DD0",
                border: "none",fontWeight:"500"
              }}
            >
               
              Add Photos
            </label>
            <input
              type="file"
              name="photo"
              onChange={getMultipleImage}
              id="add_imagee"
              accept="image/png, image/gif, image/jpeg"
              style={{ visibility: "hidden", width: "2PX", position: "absolute" }}
              multiple
            />
            <div className="d-flex" id="file-ip-1-preview" style={{width:"100%"}}>
            {
    imgData.map((item) =>
   
    {
      console.log("get new image added",item)
      return(
<div style={{width:"50px",height:"50px"}}>
<img src={item} style={{width:"100%",height:"100%"}}/>
</div>
      )
     
    })
  }
</div>
            
           
   
            
                  <div
                    class="Photos"
                    style={{ marginTop: "-6px", display: "none" }}>
                    <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                      Please Select Item Images
                    </h4>
                  </div>


                </div>
              </div>



            </div>
          </div>
{/* PRICE */}
<div className="mt-2 p-0 pl-3">
            <div class="row">
              <div class="col-md-6">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600" }}>Price</label>

                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", fontSize: "10px" }}>*</p>
                  </div>
                  <input
                    type="number"
                    id="price"
                    className="input_fields"
                    value={price}
                onChange={(e) => updatePrice(e.target.value)}
                placeholder="&#x20B9;"
                    autoComplete="true"
                    style={{ width: "80%", height: "35px", border: "1px solid #c4c4c4", boxSizing: "border-box", fontSize: "10px", paddingLeft: "5PX" }}

                  />
                  <div
                    class="Price"
                    style={{ marginTop: "-6px", display: "none" }}>
                    <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                      Please Write Price of Item
                    </h4>
                  </div>


                </div>
              </div>

              <div class="col-md-6">
                <div className="" style={{ width: "100%", marginTop: "20px" }}>
                  <div className="d-flex">
                  <input type="radio" name="tax" value="" checked="checked" style={{ width: "20px", height: "20px", border: "1px solid rgba(0, 0, 0, 0.5)" }} />
                    {/* <label style={{ color: "black", fontSize: "10px", fontWeight: "600" }}>Charge tax on this product?</label> */}
                    <label for="now" className="d-flex" style={{ color: "black", fontSize: "12px", marginLeft: "10PX", marginTop: "4px", fontWeight: "600", alignItems: "center", justifyContent: "center" }}>
                <p style={{ marginLeft: "5px" }}>Charge tax on this product?</p>
              </label>
                  
                  </div>
                 
                  <div
                    class="NewsTitle"
                    style={{ marginTop: "-6px", display: "none" }}>
                    <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                      Please Write News Title
                    </h4>
                  </div>


                </div>
              </div>

            </div>
          </div>
{/* user type */}

<div className="mt-3 p-0 pl-3">
            <div class="row">
              <div class="col-md-12">
                <div className="" style={{ width: "100%", marginTop: "0px" }}
                id="news_sendto"
        value={send_to}
        onChange={(e) => updateSend_to(e.target.value)}>
                  {/* <label style={{ color: "black", fontSize: "12px", fontWeight: "bold" }}>Who are you sending this notification to??</label><br /> */}
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600", margin: "10PX 0px 0px 0px" }}>Who are you sending this notification to?</label>

                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX", marginTop: "5px", fontSize: "10px" }}>*</p>
                  </div>
                  <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "600" }}>User Type</label><br />



                  <div className="d-flex"  id="tblFruitsUserType">
                    <input type="radio" id="all students" name="userType" value="1" style={{ width: "20px", height: "20px", border: "1px solid rgba(0, 0, 0, 0.5)" }} />
                    <label for="all students" className="d-flex" style={{ color: "black", fontSize: "10px", marginLeft: "10PX", marginTop: "4px", fontWeight: "600", alignItems: "center", justifyContent: "center" }} onClick={() => all_student()} >
                      <p style={{ marginLeft: "5PX" }}>All Students</p>
                    </label>
                    <input type="radio" id="specific class" name="userType" value="2" style={{ marginLeft: "78px", width: "20px", height: "20px", border: "1px solid rgba(0, 0, 0, 0.5)" }}
                    />
                    <label for="specific class" className="d-flex" style={{ color: "black", fontSize: "10px", marginLeft: "15PX", marginTop: "4PX", fontWeight: "600", alignItems: "center", justifyContent: "center" }} onClick={() => specific_class()}>
                      <p style={{ marginLeft: "5PX" }}>Specific Recipients</p>
                    </label>
                  </div>

                </div>

                <div
                  class="SendToAll" id="spnErrorUserType"
                  style={{ marginTop: "-6px", display:"none"}}>
                  <h4 class="login-text" style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}>
                    Please Select User Type
                  </h4>
                </div>
              </div>




            </div>
          </div>


          <div className="mt-3 p-0">
            <div class="row">
              <div class="col-md-12" style={{ fontSize: "12px", margin: "8px 20px" }}>
                {student_name}
              </div></div></div>

        <div>
          <div className="d-flex ml-auto">

            <input
              type="button"
              className=" ml-auto"
              onClick={() => createMarketplace()}
              value="Next Step"
              style={{ fontWeight: "500", border: "none", color: "white", borderRadius: "6px", marginLeft: "8px", backgroundColor: "#1F3977", padding: "10px 40px", fontSize: "10px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", marginBottom: "5px" }}
            />
            {/* </a> */}
          </div>
        </div>

      </div>

      {/* specific student pop up */}
      <div className="user_type pl-3" style={{ position: "fixed", top: "0", left: "0px", right: "0", bottom: "0", background: "rgba(0,0,0,0.5)", padding: "10px", width: "100%", height: "100%", zIndex: "10", display: "none" }}>
            <div style={{ padding: "15px", background: "#f5f5f5", boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)", position: "absolute", bottom: "0px", top: "0", right: "5px", width: "500px", height: "100%", overflow: "auto" }}>
              <div className="d-flex" style={{ borderBottom: "2px solid #15a312", transform: "rotate(0.13deg)" }}>
                <label style={{ color: "black", fontSize: "10px", fontWeight: "600" }}>Recipients</label>


                <img src="dist/img/Cancel.png" alt="dropdown" width="21px" height="21px" className="close_event ml-auto" onClick={() => closeSpecificPopUp()}/>

              </div>


              <div id="exTab2" class="container" style={{ marginTop: "10PX", height: "100%" }}>
                <ul class="nav nav-tabs" >
                  <li class="active">
                    <a href="#1" data-toggle="tab" style={{ padding: "10px 20px" }}>Persona</a>
                  </li>
                  <li style={{ marginLeft: "10px" }}><a href="#2" data-toggle="tab">Class</a>
                  </li>
                  <li style={{ marginLeft: "10px" }}><a href="#3" data-toggle="tab">Individual</a>
                  </li>
                </ul>

                <div class="tab-content " style={{ height: "100%" }}>
                  <div class="tab-pane active" id="1" style={{ height: "100%" }}>
                    {/* persona */}
                    <div id="exTab4" class="container" style={{ marginTop: "0PX", height: "100%" }}>
                      <ul class="nav nav_tabs">
                        <li class="active">
                          <a href="#4" data-toggle="tab">Recipient</a>
                        </li>
                        <li style={{ marginLeft: "5px" }}><a href="#5" data-toggle="tab">Add Persona</a>
                        </li>

                      </ul>

                      {/* persona tab content */}
                      <div class="tab-content " style={{ padding: "0px", height: "auto" }}>
                        <div class="tab-pane active" id="4" style={{ height: "100%" }}>

                          {/* Datatable */}
                          <NewPersonaRecipient style={{ height: "100%" }} passPersonaData={passPersonaData} />
                        </div>
                        <div class="tab-pane" id="5" style={{ paddingTop: "20px" }}>
                          <h3 style={{ fontWeight: "600", }}>ADD PERSONA</h3>
                          <input type="text"
                            value={addPersona}
                            onChange={(e) => updatePersona(e.target.value)}
                            style={{ border: "1px solid #c4c4c4", width: "96%", height: "35px", fontSize: "11PX", margin: "0 10px", background: "transparent" }}
                          />
                          <div className="d-flex mt-4">
                          <input
                              type="button"
                              className="close_event ml-auto"
                              defaultValue="Sign Up"

                              value="Cancel"
                              style={{ fontWeight: "600", border: "none", color: "#1F3977",  marginLeft: "auto", backgroundColor: "white",  fontSize: "12PX" }}
                            />

                            <input
                              type="button"
                              className=" form-buttons3"
                              defaultValue="Sign Up"
                              onClick={() => createPersona()}
                              value="Submit"
                              style={{ fontWeight: "500", border: "none", color: "white", borderRadius: "6px", marginLeft: "8px", backgroundColor: "#1F3977", padding: "10px 40px", fontSize: "10PX", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", marginRight: "10px" }}
                            />
                          </div>
                          {/* <div style={{ color: "green", marginLeft: "50px", fontSize: "11px" }}>
                            {errorMessagePersona}
                          </div> */}

                          <div style={{ fontWeight: "500", fontFamily: "Poppins", fontSize: "11px", marginTop: "10px" }} >
                            {
                              errorCodePersona == 200 ?
                                (
                                  <div class="personaMsg" style={{ marginLeft: "8px", width: "96%" }}>
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                      <Alert variant="filled" severity="success">
                                        {errorMessagePersona}
                                      </Alert>
                                    </Stack>
                                  </div>
                                ) : errorCodePersona == 406 ?
                                  (
                                    <div className="personaMsg" style={{ marginLeft: "8px", width: "96%" }}>
                                      <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert variant="filled" severity="error">
                                          Please Enter the Field
                                        </Alert>
                                      </Stack>
                                    </div>
                                  ) : errorCodePersona == 409 ?
                                    (
                                      <div className="personaMsg" style={{ marginLeft: "8px", width: "96%" }}>
                                        <Stack sx={{ width: '100%' }} spacing={2}>
                                          <Alert variant="filled" severity="error">
                                            {errorMessagePersona}
                                          </Alert>
                                        </Stack>
                                      </div>
                                    ) :
                                    ""

                            }


                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  {/* group tab content] */}
                  <div class="tab-pane" id="2">
                  
                    <NewClassRecipient style={{ height: "100%" }} passData={passData} />
                  </div>

                  <div class="tab-pane" id="3">

                    {/* individual content */}

                    <div id="exTab3" class="container" style={{ marginTop: "0PX", height: "100%" }}>
                      <ul class="nav nav_tabs">
                        <li class="active">
                          <a href="#6" data-toggle="tab">Recipient</a>
                        </li>
                        <li style={{ marginLeft: "5px" }}><a href="#7" data-toggle="tab">Upload Recipient</a>
                        </li>

                      </ul>

                      <div class="tab-content " style={{ padding: "0px", height: "auto" }}>
                        <div class="tab-pane active" id="6" style={{ height: "100%" }}>

                          {/* Datatable */}
                          <NewRecipient style={{ height: "100%" }} passData={passData} />

                        </div>
                        <div class="tab-pane" id="7">
                          <h3 style={{ fontWeight: "600", }}>UPLOAD RECIPIENT</h3>

                          <div className="mt-0 p-0">
                            <div class="row">
                              <div class="col-md-12">
                                <div style={{ width: "100%", marginTop: "0px", paddingRight: "0" }} >
                                <ExportToExcel apiData={studentList} fileName={fileName} />
                                <br />
                                  <div className="d-flex">
                                    <label style={{ color: "#1F3977", fontSize: "12px", fontWeight: "bold" }}>Upload ExcelSheet</label>

                                    <p style={{ color: "#EB2424", fontWeight: "600", marginLeft: "3PX" }}>*</p>
                                  </div>

                                  <input
                                    type="file"
                                    id="excelSheet"
                                    //value={excel}
                                    onChange={(e) => setExcel(e.target.files[0])}
                                    accept=".xlsx,.xls"
                                    placeholder="Your Title goes here..."
                                    autoComplete="true"
                                    style={{ boxSizing: "border-box", fontSize: "12px", paddingLeft: "5PX" }}

                                  />

                                  <div className="d-flex mt-3">
                                    <input
                                      type="button"
                                      className=" form-buttons3"
                                      defaultValue="Sign Up"
                                      onClick={() => uploadExcel()}
                                      value="Publish"
                                      style={{ marginLeft: "auto", fontWeight: "500", border: "none", color: "white", borderRadius: "6px", backgroundColor: "#1F3977", padding: "6px 20px", fontSize: "12PX", }}
                                    />
                                  </div>

                                  <div>
                                  <p style={{color:"blue",fontSize:"12px",marginTop:"7PX"}}>{excelError_message}</p>
                                    {/* {excelError_code == 200 ?
                                      (
                                        <div style={{ color: "green", fontSize: "11px", marginLeft: "20px" }}>{excelError_message}</div>
                                      ) :
                                      (
                                        <div style={{ color: "red", fontSize: "11px", marginLeft: "20px", display: "none" }}>
                                          Students are not beed added
                                        </div>)

                                    } */}
                                  </div>
                                </div>

                              </div>



                            </div>


                          </div>
                          <div style={{ color: "green", marginLeft: "50px" }}>{errorMessagePersona}</div>
                        </div>

                      </div>
                    </div>
                    {/* end' */}

                  </div>
                </div>
              </div>


            </div>
          </div>
    </div>
  )
}
