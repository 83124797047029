import React, { useState, useEffect, useMemo } from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";
import axios from "axios";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link,useLocation } from "react-router-dom";


export const StudentOfferDetails = () => {

  const [loc, setLoc] = useState(window.location.pathname);
  const location = useLocation();
  const token = localStorage.getItem("Token");
  const { o_id } = location.state || { id: "none" };

  const [offerData, setOfferData] = useState([]);

  async function getOfferData(offer_id) {
    try {
      const formData = new FormData();
      formData.append("offer_id", offer_id);

      const offerResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_offer_data",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = offerResponse.data.error_code;
      if (ErrorCode == 200) {
        const e_data = offerResponse.data.data;
        setOfferData(e_data);
      } else {

      }
    } catch (err) {
      console.log("get offer data error-----------", err);
    }
  }

  useEffect(() => {
    getOfferData(o_id);
  }, []);


  return (
    <>
    <div>
      <Header />

      <div className="d-flex">
        <Menu />

        <div className="content-wrapper">
          {/* <Link to="/StudentOffersList">
            <i
              className="fas fa-arrow-alt-circle-left"
              style={{ fontSize: "21px", color: "black" }}
            ></i>
          </Link> */}

           {offerData.map((o_data) =>{
            var date1= o_data.start_date_time;
            var date2= o_data.end_date_time;
            var s_date = moment(date1).format("D MMM").toUpperCase();
            const dateObject = new Date(date1);
            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            const formattedTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

            var e_date = moment(date2).format("D MMM").toUpperCase();
            const dateObject2 = new Date(date2);
            const hours2 = dateObject2.getHours();
            const minutes2 = dateObject2.getMinutes();
            const formattedTime2 = `${hours2}:${minutes2 < 10 ? `0${minutes2}` : minutes2}`;
            return(<>

          <div className="search_box_border_class">
            <div className="main_heading_h1">{o_data.offername}</div>
          </div>

          <div className="card_div mt-2" style={{ width: "100%" }}>
            <div className="card-header bg-white p-0">
              <div className="card_inner_div">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{position:"relative"}}>
                        <img
                          src={o_data.cover_img}
                          style={{
                            height: "170px",
                            width: "400px",
                            cursor: "pointer",
                            // objectFit:"contain",

                          }}
                        />
                        </div>
                        <img
                          src={o_data.comp_logo}
                          style={{
                            height: "80px",
                            width: "80px",
                            cursor: "pointer",
                            position:"absolute",
                            top:"120px",
                            left:"150px",
                            borderRadius:"50%",
                            background:"white"
                          }}
                        />

                      {/* <div
                        style={{ position: "relative", cursor: "pointer" }}
                      >
                        <img
                          src="dist/img/menu2-512.jpg"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>

                      <div className="twelve_font_class" style={{marginTop:"40px"}}>
                        <p>{o_data.offername}</p>
                      </div>

                <div className="row">
                  <div style={{ marginTop: "5px" }}>
                    <div class="col-md-12 p-0">
                      <div>
                        {/* <div className="d-flex" style={{gap: "5px"}}>
                        <span className="eleven_font_class" style={{color: "#1F3977"}}>Offer Details:</span>
                        <span>
                        <p className="desc_class" dangerouslySetInnerHTML={{ __html: o_data.offerdesc }}/>
                         </span>
                        </div> */}

                        <div className="d-flex mt-1" style={{gap: "5px"}}>
                        <span className="eleven_font_class" style={{color: "#1F3977"}}>Offer:</span>
                        <span className="ten_font_class">{o_data.discount}%</span>
                        <span className="ten_font_class">Off</span>
                        </div>

                        <div className="d-flex mt-1" style={{gap: "5px"}}>
                        <span className="eleven_font_class" style={{color: "#1F3977"}}>Offer Period:</span>
                        <span className="ten_font_class">{s_date}</span>
                        <span className="ten_font_class">At</span>
                        <span className="ten_font_class">{formattedTime}</span>
                        <span> -</span>
                        <span className="ten_font_class">{e_date}</span>
                        <span className="ten_font_class">At</span>
                        <span className="ten_font_class">{formattedTime2}</span>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div className="mt-2">
                    <hr className="card_inner_hr_css" />
                  </div>

                  <div className="row">
                    <div className="">
                      <div className="">
                        <label
                          className="all_labels"
                          style={{ fontSize: "11px" }}
                        >
                         Offer Details
                        </label>
                      </div>
                      <div className="">
                        <p  className="desc_class">
                        <p className="desc_class" dangerouslySetInnerHTML={{ __html:o_data.offerdesc}}/>
                        </p>
                      </div>
                    </div>
                  </div>

                <div className="mt-2">
                  <hr className="card_inner_hr_css"/>
                </div>

                <div className="row">
                  <div>
                    <span className="ten_font_class"
                      style={{color: "#4AA081"}}>
                      Engagement Analytics
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 p-0">
                    <section style={{ margin: "10px 5px" }}>
                      <div className="container-fluid table-cards">
                        <div className="table-cards">
                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ height: "100%", padding: "0px 5px" }}
                            >
                              <div
                                className="small-box"
                                style={{
                                  height: "65px",
                                  borderRadius: "2.5PX",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="inner"
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <div>
                                      <span className="eleven_font_class"
                                        style={{color: "#1F3977"}}
                                      >
                                        Link Opened
                                      </span>
                                    </div>

                                    <div
                                      className="d-flex sixteen_font_class"
                                      style={{
                                        flexWrap: "wrap",
                                        marginTop: "5px"
                                      }}
                                    >
                                      <div>{o_data.total_links_open}</div>
                                    </div>
                                  </div>

                                  <div className="all_icon_imgs_div"
                                    style={{background: "#FBE1FF"}}
                                  >
                                    <img className="all_icon_imgs"
                                      src="dist/img/ComboChart.png"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>)
           })}
        </div>
      </div>
    </div>
  </>
  )
}

