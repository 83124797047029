import React from 'react'
import {Header} from "../Header";
import { Menu } from '../Menu';
import { JobForm } from './JobForm';
export function CreateJob() {
    const loc = '/jobDetails'
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} />
            <JobForm />
        </div>
        </div>
    )
}
