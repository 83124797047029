import React,{ useState } from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu"
import {TeachersProfile} from "./TeachersProfile"

export function ViewInfo() {
 
  const [loc,setLoc] = useState("/teacher")
  console.log("location",loc)
  return (
    <div>
        <Header/>

        <Menu pageTitle={loc} />
        <TeachersProfile />
        </div>
     
  )
}
