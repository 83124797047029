import React, {useState} from "react";
// import "../Components/AdminLogin.css";
import {AiFillLock} from "react-icons/ai";
import $ from "jquery";
import axios from 'axios';
import { Link } from "react-router-dom";
export function Forgotpassword() 
{
  const token = localStorage.getItem("Token");
  const [email, updateEmail] = useState([]);
  const [data, setData] = useState([]);
  const [errorMsg, seterrorMsg] =useState();
  async function forgotPwd()
  {
   try{
    const formData = new FormData();
console.log("enter email",email)
    formData.append("email", email);
    const response = await axios.post(
      process.env.REACT_APP_API_KEY + "super_forgot_password",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
         
          Authorization: token,
        },
      }
    );
  
      console.log("Response",response);
      console.log("forgot password", response.data.error_code);
      
      console.log("Message", response.data.message);
setData( response.data.message)
      updateEmail("");
     
     
        $(".forgot_pass_text").show();
        setTimeout(() => {
         $(".forgot_pass_text").hide();
        }, 5000);
      
    if(response.data.error_code == 200)
    {

      window.location.href="/"
    }
    else
    {
      //seterrorMsg(response.data.message);
       seterrorMsg("Enter Email Id First")
    }
}
catch(err)
{
  
  console.log("Log in Fail",err);
 
}

}

function backToLogin(){
  window.location.href="/";
 }
  
 
  return (
    <div  className="mt-3 mx-5" >

  
    
 <div style={{background:"#293043",margin:"30PX auto",paddingTop:"40px",paddingBottom:"100px",textAlign:"center", height:"100%",width:"35%"}}>
   {/* <div style={{background:"#293043"}}> */}
   <div style={{textAlign:"center"}}>
   <AiFillLock style={{color:"white",width:"60px", height:"60px"}}/>
   </div>
  
   <h1 style={{fontSize:"bold",textAlign:"center",color:"white",marginTop:"10PX",fontSize:"20px"}}>FORGOT PASSWORD</h1>
   <p style={{color:"white",fontSize:"11PX",marginTop:"20PX"}}>Please Enter The Email Address you'd like your password reset information sent to</p>
   <div style={{margin:"10px 20px"}}>
   <input
                  type="text"
                  name=""
                  placeholder="Enter Your Email Id"
                  autoComplete="off"
                  id="email"
                  value={email}
                  onChange={(e) => updateEmail(e.target.value)}
                  style={{border:"none",width:"100%",margin:"40PX AUTO 10px",
                    borderRadius:'5px',paddingLeft:"10px",height:"32px"}}
               
                />
                <div className="d-flex">
                <input
                      type="button"
                      style={{
                        border: "none",
                        background: "white",
                        fontWeight: "bold",
                        borderRadius:'5px',
                        color: "#293043",
                        textAlign:"center",
                        padding:"10px 15px",
                        marginLeft:"auto",
                        width:"100%"
                
                      }}
                      id="go"
                      defaultValue="Sign In"
                      onClick={() => forgotPwd()}
                      value="Submit"
                      
                    />
                </div>
                <div className="forgot_pass_text" style={{display:"none",color:"white",fontSize:'12PX',marginTop:"50PX",fontSize:"10PX"}}> {errorMsg}</div>
                {/* <Link to ="/">
                <p style={{color:"white",marginTop:"50px",fontSize:"10PX"}}>Back to login</p>
               
                </Link> */}
  
              <div  style={{color:"white",fontSize:'12PX',marginTop:"50PX",fontSize:"10PX" }}>
               <button onClick={backToLogin} style={{ width:"30%", borderRadius:'5px'}}>
               <p style={{ color: "#293043",fontSize:"12PX", textAlign:"center", fontWeight: "bold", marginTop:"10px"}}>Back to login</p>
               
                </button>
                </div>

                    
   </div>
   
   
               
               
              
   </div>
    </div>
    
  );
}
