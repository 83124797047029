import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import FilterComponent from "../ViewCampusList/FilterComponent";
import axios from "axios";
import $ from "jquery";



const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const customStyles = {
  rows: {
    style: {
      padding: "5px 0 10px 15px",
      border: "none",
    },
  },

  head: {
    style: {
      padding: "10px 0 10px 15px",
      fontWeight: "600",
      color: "black",
    },
  },
  table: {
    style: {
      padding: "0",
    },
  },
};

export function ViewCampusList(props) {const token  = localStorage.getItem("Token");
  const [data, setData] = useState([]);
  //alert("Access Token-"+ token);
  async function fetchList() {
    alert( process.env.REACT_APP_API_KEY)
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "get_campus_details_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'authorization': token,
          },
        }
      );

      console.log("Get Campus List Details", fetchResponse);
      const campusListArry = fetchResponse.data.data;
      
      console.log("Campus listArry", campusListArry);

      if(data.error_code == 200){
        setData(campusListArry);
      }
     
    } catch (err) {
      console.log("Failed to get campus list ", err);
    }
  }

  useEffect(() => {
    fetchList();
  }, []);

  // EDIT CAMPUS
  // async function editCampus(id) {
  //   console.log("CAMPUS ID", id);
  // }

  // DELETE CAMPUS
  const paginationComponentOptions = {
    selectAllRowsItem: true,

    selectAllRowsItemText: "ALL"
  };
  // async function deleteCampus(id) {
  //   try {
  //     const formData = new FormData();
  //     formData.append("a_id", id);
  //     console.log("Campus_ID", id);

  //     const response = axios.post(
  //       process.env.REACT_APP_API_KEY + "super_delete_campus",
  //       {
  //         headers: {
  //           "Content-Type": "text/plain",
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     console.log("Delete response", response);

  //     $(".DeleteAlert").show();
  //     setTimeout(function () {
  //       $(".DeleteAlert").hide();
  //     }, 3000);
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // }
  const columns = [
    //  {
    //   name: "Serial ID",
    //   // selector: "campus_id",
    //   sortable: true,
    //   wrap: true,
    //   width: "auto",
    //   {1}
    // },
    {
      name: "Campus ID",
      // selector: "campus_id",
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) => {
        return (
          <div
            style={{
              fontWeight: "500",
              fontSize: "11PX",
              color: "rgba(0, 0, 0, 0.6)",
              cursor:"pointer"
            }}
          >
            { data.campus_id }
          </div>
        );
      },
    },
    {
      name: "Campus Name",
      // selector: "campus_name",
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) => {
        return (
          <div
            style={{
              fontWeight: "500",
              fontSize: "11PX",
              color: "rgba(0, 0, 0, 0.6)",
              cursor:"pointer"
            }}
          >
            {data.campus_name}
          </div>
        );
      },
    },
    {
      name: " Address",
      // selector: "email",
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) => {
        return (
          <div
        
            style={{
              fontWeight: "500",
              fontSize: "11PX",
              color: "rgba(0, 0, 0, 0.6)",
              cursor:"pointer"
            }}
          >
           {data.address}
          </div>
        );
      },
    },
    {
      name: "City",
      // selector: "mobile",
      sortable: true,
      width: "auto",
      wrap: true,
      cell: (row) => {
        return (
          <div
            style={{
              fontWeight: "500",
              fontSize: "11PX",
              color: "rgba(0, 0, 0, 0.6)",
              cursor:"pointer"
            }}
          >
            {data.city}
          </div>
        );
      },
    }
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      // <FilterComponent
      //   onFilter={(e) => setFilterText(e.target.value)}
      //   onClear={handleClear}
      //   filterText={filterText}
      // />
      <div></div>
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div className="content-wrapper">
       <div
        className="row mt-2 mb-2"
        style={{
          width: "100%",
          marginLeft: "0",
          padding: "0px 10px",
          background: "transparent",
        }}
      >
        <div
          className="col-md-8 d-flex flex-row"
          style={{ height: "100%", padding: "0px 5px" }}
        >
          <h4 style={{ color: "black", fontWeight: "600", marginTop: "7px" }}>
            All Campus
          </h4>
        </div>

        <div
          className="col-md-3 d-flex flex-row"
          style={{
            height: "100%",
            background: "white",
            padding: "0",
            border: "1px solid lightgrey",
          }}
        >
          {/* <img
            src={require("../images/Search.png")}
            style={{ width: "21px", height: "21px", margin: "5px 0px 0px 3px" }}
          /> */}
         
          <Input
            id="search"
            type="text"
            placeholder="Search by campus"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{
              border: "none",
              background: "white",
              height: "32px",
              width: "100%",
              fontWeight: "500",
              fontSize: "12PX",
              paddingLeft: "5px",
            }}
          />
        </div>

        <div className="col-md-1 d-flex flex-row">
          {/* <img
            src="dist/img/Sorting.png"
            alt="view"
            style={{ width: "28px", height: "28px" }}
            className="sort_table"
            onClick={fetchList}
          /> */}
        </div>
      </div>
<DataTable
          columns={columns}
          data={filteredItems}
          striped
          paginationPerPage={10}
          pagination
          paginationRowsPerPageOptions={[10,20,30,40,50,60,70,80,90,100]}
          paginationComponentOptions={paginationComponentOptions}
          subHeader
          subHeaderComponent={subHeaderComponent}
          highlightOnHover
          defaultSortFieldId={1}
          customStyles={customStyles}
        />
      {/* <CampusListTable /> */}
      <div id="editCampus" className="modaloverlay">
        <div className="modalContainer" style={{ overflowX: "hidden" }}>
          <div className="birthday" style={{ fontSize: "20px" }}>
            Edit Campus
          </div>
          <a href="#close" className="closeContainer">
            ×
          </a>
          <div
            style={{
              background: "white",
              borderRadius: "0px 0px 10px 10px",
              // marginBottom: "50px",
              padding: "10px",
              borderTop: "1px solid #E4E6EB",
              borderBottom: "1px solid #E4E6EB",
            }}
          >
            {/* write data */}
            <div>
              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Campus Name*
                      </label>
                      <input
                        type="name"
                        id="exampleInputEmail1"
                        placeholder="Enter Campus Name..."
                        autoComplete="true"
                        //                             value={campusName}
                        //  onChange={(e) => updateCampusName(e.target.value)}
                        style={{
                          width: "100%",
                          height: "30px",
                          border: "1px solid grey",
                          fontSize: "12px",
                          paddingLeft: "5PX",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Address*
                      </label>
                      <input
                        type="name"
                        className="stud-prefered-name"
                        id="exampleInputEmail1"
                        placeholder="Enter Address..."
                        autoComplete="true"
                        // value={address}
                        // onChange={(e) => updateAddress(e.target.value)}
                        style={{
                          width: "100%",
                          height: "30px",
                          border: "1px solid grey",
                          fontSize: "12px",
                          paddingLeft: "5PX",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        State*
                      </label>
                      <input
                        type="name"
                        className="stud-prefered-name"
                        id="exampleInputEmail1"
                        placeholder="Enter State..."
                        autoComplete="true"
                        // value={state}
                        // onChange={(e) => updateState(e.target.value)}
                        style={{
                          width: "100%",
                          height: "30px",
                          border: "1px solid grey",
                          fontSize: "12px",
                          paddingLeft: "5PX",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        City*
                      </label>
                      <input
                        type="name"
                        className="stud-prefered-name"
                        id="exampleInputEmail1"
                        placeholder="Enter City..."
                        autoComplete="true"
                        // value={city}
                        // onChange={(e) => updateCity(e.target.value)}
                        style={{
                          width: "100%",
                          height: "30px",
                          border: "1px solid grey",
                          fontSize: "12px",
                          paddingLeft: "5PX",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 p-0">
                <div class="row">
                  <div class="col-md-6">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Start Date*
                      </label>
                      <input
                        type="date"
                        className="stud-prefered-name"
                        id="exampleInputEmail1"
                        placeholder="Enter State..."
                        autoComplete="true"
                        // value={startDate}
                        // onChange={(e) => updateStartDate(e.target.value)}
                        style={{
                          width: "100%",
                          height: "30px",
                          border: "1px solid grey",
                          fontSize: "12px",
                          paddingLeft: "5PX",
                        }}
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <label
                        style={{
                          color: "#1F3977",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        End Date*
                      </label>
                      <input
                        type="date"
                        className="stud-prefered-name"
                        id="exampleInputEmail1"
                        placeholder="Enter State..."
                        autoComplete="true"
                        // value={endDate}
                        // onChange={(e) => updateEndDate(e.target.value)}
                        style={{
                          width: "100%",
                          height: "30px",
                          border: "1px solid grey",
                          fontSize: "12px",
                          paddingLeft: "5PX",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className=""
            style={{ margin: "0px", padding: "10px", width: "100%" }}
          >
            {/* {/ publish button /} */}
            <div className="d-flex form-buttons">
              {/* <input
        type="button"
        className="create_btn form-buttons1"
        defaultValue="Sign Up"
        value="Save"
        style={{ borderRadius:"5px", backgroundColor:"#293043" ,padding:"10px 30px",fontSize:"12PX",fontWeight:"bold"}}
      /> */}
              {/* <input
        type="button"
        className="create_btn form-buttons2"
        defaultValue="Sign Up"
        value="Save"
        style={{ fontWeight:"bold",borderRadius:"5px", color:"#1F3977",marginLeft:"auto", backgroundColor:"white",padding:"10px 30px",fontSize:"12PX", border:"1px solid #1F3977", color:"#1F3977" }}
      /> */}
              {/* <a href="/student"> */}
              <input
                type="button"
                className="create_btn form-buttons3 ml-auto"
                defaultValue="Sign Up"
                value="Update"
                // onClick={() => createCampus()}
                style={{
                  fontWeight: "bold",
                  borderRadius: "5px",
                  marginLeft: "5px",
                  backgroundColor: "#1F3977",
                  padding: "10px 30px",
                  fontSize: "12PX",
                }}
              />
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}