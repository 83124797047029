import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import $ from "jquery";
import axios from "axios";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link } from "@mui/material";


const StateManagement = () => {
  const [loc, setLoc] = useState(window.location.pathname);

  const [offerData, setOfferData] = useState([]);
  const [stateName, setStateName] =useState();
  const [stateId, setStateId ] = useState(false);
  const [errorMsg, seterrorMsg] =useState();
  // const [state, setStateName] =useState();

  //async function to fetc market data
  async function getOfferData() {
    try {
      const token = localStorage.getItem("Token");
      //console.log("token-------------------------------------", token);
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_state_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: token,
            
          },
        }
        
      );
      console.log("Response-----------------------------------", fetchResponse);
       
      const off_Data = fetchResponse.data.data;
      const error_code = fetchResponse.data.error_code;

      if (error_code == 200) {
        setOfferData(off_Data);
        seterrorMsg(fetchResponse.data.message);
      }
    } catch (err) {
      console.log("Failed to get offer list ", err);
    }
  }
  

  async function AddSateName() {
    try {
      const token = localStorage.getItem("Token");
      console.log("State name.....................", stateName);
      const formData = new FormData();

      formData.append("state_name", stateName);

      const AddResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "create_state",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );
    
       
      
      if (AddResponse.data.error_code == 200) {
        
        seterrorMsg(AddResponse.data.message);
       $(".add_state_modal").removeClass("showModal");

      $(".formSuccess").show()
      setTimeout(() => {
        $(".formSuccess").hide()

       
      }, 5000);
          window.location.reload();
         
     
      }
      if (AddResponse.data.error_code == 409) {
        seterrorMsg(AddResponse.data.message);
        $(".error_msg").show();
        setTimeout(() => {
          $(".error_msg").hide();
        }, 5000);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function changeStatus(state_id)
  {
   try{
    const token = localStorage.getItem("Token");
      const formData = new FormData();
      formData.append("state_id", state_id);
    
      
      const StatusResponse =  await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_change_status_state",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            
            Authorization: token,
          },
        }
   
      
      );
    

      if (StatusResponse.data.error_code == 200) {
        seterrorMsg(StatusResponse.data.message);
        $(".delete_container").removeClass("showModal");
 
       $(".formSuccess").show()
       setTimeout(() => {
         $(".formSuccess").hide()
 
        
       }, 5000);
           window.location.reload();
          
      
       }
     } catch (err) {
       console.log("Log in Fail", err);
     }
   }

     
  function edit_State(state_id,state_name) {
    
    setStateId(state_id);
    setStateName(state_name);
    $(".edit_modal").addClass("showModal");
  }

  async function edit_State_data() {
    try{
      const token = localStorage.getItem("Token");
        const formData = new FormData();
        formData.append("state_id", stateId);
        formData.append("state_name", stateName);
      
        
        const StatusResponse =  await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_edit_state",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              
              Authorization: token,
            },
          }
     
        
        );
      
  
        if (StatusResponse.data.error_code == 200) {
          seterrorMsg(StatusResponse.data.message);
          $(".edit_modal").removeClass("showModal");
   
         $(".formSuccess").show()
         setTimeout(() => {
           $(".formSuccess").hide()
   
          
         }, 5000);
             window.location.reload();
            
        
         }
         if (StatusResponse.data.error_code == 409) {
          seterrorMsg(StatusResponse.data.message);
          $(".error_msg").show();
          setTimeout(() => {
            $(".error_msg").hide();
          }, 5000);
        }
       } catch (err) {
         console.log("Log in Fail", err);
       }
   
  }


  useEffect(() => {
     getOfferData();
    
  }, []);

  const columns = [
    {
      name: <b>State ID </b>,
      selector: (row) => row.state_id,
      sortable: true,
    },
    {
      name: <b>State Name</b>,
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: <b>Status</b>,
      sortable: true,
      selector: (row) => {
        return(
          <div className="d-flex ">
      
             {row.status == 0 ?
             (
               <button
               type="button"
               id="statusChangeButton"
               className="create_btn form-buttons3"
              
             
               onClick={() => changeStatus(row.state_id)}
               style={{
                 borderRadius: "5px",
                 backgroundColor: "red",
                 padding: "10px",
                 marginBottom:"9px",
                 fontSize: "12PX",
                 fontWeight: "bold",
               }}
              >
                Deactive
                </button>
             ):
             (
               <button
               type="button"
               id="statusChangeButton"
               className="create_btn form-buttons3"
               
             
               onClick={() => changeStatus(row.state_id)}
               style={{
                 borderRadius: "5px",
                 backgroundColor: "green",
                 padding: "10px 17px",
                 fontSize: "12PX",
                 fontWeight: "bold",
                 marginBottom:"9px",
               }}
              >
                Active
                </button>
             )
             
      }
      
      </div>
        )
    }
    
    },
    {
     
      name: <b>Action</b>,
      selector: (row) => {
        return (
          <div className="d-flex">
              
              <a
              className="cta"
              onClick={() => edit_State(row.state_id,row.state_name)}
              style={{ backgroundColor: "transparent" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginLeft: "2px" }}
                src={require("../images/Pencil.png")}
              />
              &nbsp;
            </a>

            <a
              className="cta"
              onClick={() => delete_state(row.state_id)}
              style={{ backgroundColor: "transparent" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginLeft: "2px" }}
                src={require("../images/delete.png")}
              />
              &nbsp;
            </a>
            
          </div>
        );
      },
      sortable: true,

    
      
    }
  ];

  function addState() {
    //console.log("button clicked................");
    
   
   $(".add_state_modal").addClass("showModal");
   
  }
  function closeModal() {
    
    $(".delete_container").removeClass("showModal");
   // console.log("button clicked................", stateName)
   
  }
  
    function delete_state(state_id){
      setStateId(state_id);
      $(".delete_state_modal").addClass("showModal");
    }
    async function deleteMessage() {
      try{
        const token = localStorage.getItem("Token");
          const formData = new FormData();
          formData.append("state_id", stateId);
          const StatusResponse =  await axios.post(
            process.env.REACT_APP_API_KEY + "super_admin_delete_state",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                
                Authorization: token,
              },
            }
       
          
          );
        
    
          if (StatusResponse.data.error_code == 200) {
           
            $(".delete_state_modal").removeClass("showModal");
             seterrorMsg(StatusResponse.data.message);
           $(".formSuccess").show()
           setTimeout(() => {
             $(".formSuccess").hide()
     
            
           }, 5000);
               window.location.reload();
              
          
           }
         } catch (err) {
           console.log("Log in Fail", err);
         }
    }

  return (
    <div>
      <Header />
      <div className="d-flex">
      <Menu pageTitle={loc} />

      <div className="content-wrapper">
         
      <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
        </div>

        <div className="d-flex border_class">
          <div>
            {" "}
            <h5><b> Sates List</b></h5>
          </div>
          <button className="create_button" style={{marginLeft:"auto"}}
             onClick={addState}>Add State</button>
        </div>

        <div className="border_class">
        <DataTable
          // title="Campus List"
          fixedHeader
          fixedHeaderScrollHeight="400px"
          pagination
          columns={columns}
          data={offerData}
        />
        </div>
      </div>
      <div id="delete" className="modaloverlay delete_container add_state_modal">
      
        <div className="modalContainer" style={{ width:"30%" }}>
           <div  className="close_modal">
           <button style={{color:"red" ,border:"none",position:"right"}} onClick={closeModal}>
           <img
                style={{ width: "30px", height: "30px", marginLeft: "2px" }}
                src={require("../images/wrong.jpg")}
              />
           </button>
           </div>
          <div className="card-body" style={{ marginTop: "0px",  }}>
            
            <div>
              <p style={{ fontWeight: "600", color: "black", fontSize: "13px" }}>
                Enter State:
              </p>
              <div className="d-flex mt-2">
              
              <input
                type="text"
                value={stateName}
                onChange={(e) => setStateName(e.target.value)}
                style={{ marginLeft: "2px", marginRight: "6px", width: "100%", borderRadius: "5px", background: "white", height: "40px", fontSize: "13PX", padding: "8px 12px", border: "1px solid #2d5dd0" }}
              />
             
               </div>
               {/* <p className="error_msg" style={{color:"red"}}> {errorMsg} </p> */}
              <div className="d-flex mt-3 " >
                <a
                 
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Add"
                    onClick={()=>AddSateName()}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: " rgb(41, 48, 67)",
                      fontSize: "13PX",
                      padding: "8px 12px",
                      fontWeight:"700"
                    }}
                  />
                </a>


              </div>
            </div>
          </div>
        </div>
        </div> 
        {/* Modal end */}

        <div id="delete" className="modaloverlay delete_container edit_modal ">
      
      <div className="modalContainer" style={{ width:"30%" }}>
         <div className="close_modal">
         <button className="close_modal" style={{color:"red" ,border:"none",position:"right", }} onClick={closeModal}>
         <img
                style={{ width: "30px", height: "30px", marginLeft: "2px" }}
                src={require("../images/wrong.jpg")}
              />
         </button>
         </div>
        <div className="card-body" style={{ marginTop: "0px",  }}>
          
          <div>
            <p
              style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
            >
               State Name:
            </p>
            <div className="d-flex mt-4">
            
            <input
              type="text"
              value={stateName}
              onChange={(e) => setStateName(e.target.value)}
              style={{ marginLeft: "6px", marginRight: "6px", width: "100%", borderRadius: "5px", background: "white", height: "40px", fontSize: "13PX", padding: "8px 12px", border: "1px solid #2d5dd0" }}
            />
             </div>
             {/* <p className="error_msg" style={{color:"red"}}> {errorMsg} </p> */}

            <div className="d-flex mt-3 " >
              <a
               
                href="#"
                style={{ marginLeft: "auto" }}
              >
                <input
                  type="button"
                  className="create_btn"
                  value="Submit"
                  onClick={()=>edit_State_data()}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: " rgb(41, 48, 67)",
                    fontSize: "13PX",
                    padding: "8px 12px",
                    fontWeight:"700"
                  }}
                />
              </a>


            </div>
          </div>
        </div>
        {/* </form> */}
      </div>
      </div>
      {/* Modal end */}

      <div id="delete" className="modaloverlay delete_container delete_state_modal">
        <div className="modalContainer">
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Delete State
              </p>
              <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>
                Are You Sure, You Want To Delete this State?
              </h2>

              <div className="d-flex mt-3">
                <a
                  //   onClick={close_delete_modal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"
                    onClick={()=>closeModal()}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#c4c4c4",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>

                <a
                  href="#delete_with_protection"
                  style={{ color: "grey", fontSize: "15PX" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_student"
                    value="Delete"
                    onClick={() => deleteMessage()}
                    style={{
                      borderRadius: "5px",
                      marginRight: "7px",
                      backgroundColor: "#d21f3c",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      {/* modal end */}

    </div>
    </div>
  );
                              
};


export default StateManagement;
