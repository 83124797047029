import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {Login} from './components/Login';
import {TicketsDashboard} from './components/Tickets/TicketsDashboard'
import {Homepage} from './components/Homepage';
import {Student} from "./components/Student/Student";
import {StudentsProfile} from "./components/Student/StudentsProfile"
import {Teacher} from "./components/Teacher/Teacher";
import {ViewStudentInfo} from "./components/Student/ViewStudentInfo"
import {TeachersProfile} from "./components/Teacher/TeachersProfile"
import { FacultyForm } from './components/Teacher/FacultyForm';
import {HelpDeskDetails} from './components/HelpDesk/HelpDeskDetails';
import {JobDetails} from './components/Job/JobDetails';
import {CreateJob} from './components/Job/CreateJob';
import {ViewJob} from './components/Job/ViewJob';
import { JobPreview } from './components/Job/JobPreview';
import { JobFormStep2 } from './components/Job/JobFormStep2';

import {MarketplaceDetails} from './components/Marketplace/MarketplaceDetails';
import {SellItem} from './components/Marketplace/SellItem';
import {ViewMarketDetails} from './components/Marketplace/ViewMarketDetails';
import {ViewItem} from './components/Marketplace/ViewItem';
import {Forgotpassword} from './components/Forgotpassword';
import {AppointmentDetails} from './components/Appointment/AppointmentDetails';
import {CreateAppoinment} from './components/Appointment/CreateAppoinment';
import {CreateCampusDetails} from './components/CreateCampus/CreateCampusDetails';
import {ViewCampusListDetails} from './components/ViewCampusList/ViewCampusListDetails';
import {CampusDirectoryDetails} from './components/Campus Directory/CampusDirectoryDetails';
import {Profile} from './components/Profile/Profile';
import {CreateAdminDetails} from './components/Create Admin/CreateAdminDetails';
import {ChangePassword} from './components/ChangePassword';
import {DisplayHelp} from './components/Help&Support/DisplayHelp';
import StudentOffers from './components/StudentOffers';
import { ViewInfo } from './components/Teacher/ViewInfo';
import { MarketPlaceStep2 } from './components/Marketplace/MarketPlaceStep2';
import  StudentOffersList from './components/StudentOffersList';
import { StudentOffersStep2 } from './components/StudentOffersStep2';
import { StudentOfferDetails } from './components/StudentOfferDetails';
import { StudentForm } from './components/Student/StudentForm';

import StateManagement from './components/Settings/StateManagement';
import citymanagement from './components/Settings/CityManagment';

import CampusEvents from './components/CampusEvents';
import { CreateEvent } from './components/Events/CreateEvent';
import EventForm from './components/Events/EventForm';
import EventFormStep2 from './components/Events/EventFormStep2';
import { EventDetails } from './components/Events/EventDetails';

import { Universities } from './components/Universities/Universities';
import { UniversityDetails } from './components/Universities/UniversityDetails';
import { AddUniversity } from './components/Universities/AddUniversity';
import { AdminDetails } from './components/Universities/AdminDetails';

import { CalendarHomePage } from './components/Calendar/CalendarHomePage';
import { EventCalendarPage } from './components/Calendar/EventCalendarPage';

import { flaggedContents } from './components/Flagged Contents/flaggedContents';



ReactDOM.render(
 
  <Router>
  <Switch>
     <Route exact path="/"                       component={Login}/>
     <Route exact path="/homepage"               component={Homepage}/>
     <Route exact path="/marketplaceStep2"       component={MarketPlaceStep2}/>
     <Route exact path="/student"                component={Student}/>
     <Route exact path="/studentsProfile"        component={StudentsProfile}/>
     <Route exact path="/teacher"                component={Teacher}/>
     <Route exact path="/ViewStudentInfo"        component={ViewStudentInfo}/>
     <Route exact path="/ViewInfo"               component={ViewInfo}/>
     <Route exact path="/teachersProfile"        component={TeachersProfile}/>
     <Route exact path="/facultyform"            component={FacultyForm}/>
     <Route exact path="/helpDeskDetails"        component={HelpDeskDetails}/>
     <Route exact path="/jobDetails"             component={JobDetails}/>
     <Route exact path="/createJob"              component={CreateJob}/>
     <Route exact path="/jobpreview"             component={JobPreview}/>
     <Route exact path="/jobformstep2"           component={JobFormStep2}/>
     <Route exact path="/viewJob"                component={ViewJob}/>
     <Route exact path="/marketplaceDetails"     component={MarketplaceDetails}/>
     <Route exact path="/sellItem"               component={SellItem}/>
     <Route exact path="/viewMarketDetails"      component={ViewMarketDetails}/>
     <Route exact path="/viewItem"               component={ViewItem}/>
     <Route exact path="/forgotpassword"         component={Forgotpassword}/>
     <Route exact path="/appointmentDetails"     component={AppointmentDetails}/>
     <Route exact path="/createAppoinment"       component={CreateAppoinment}/>
     <Route exact path="/createCampusDetails"    component={CreateCampusDetails}/>
     <Route exact path="/viewCampusListDetails"  component={ViewCampusListDetails}/>
     <Route exact path="/campusDirectoryDetails" component={CampusDirectoryDetails}/>
     <Route exact path="/profile"                component={Profile}/>
     <Route exact path="/createAdminDetails"     component={CreateAdminDetails}/>
     <Route exact path="/changePassword"         component={ChangePassword}/>
     <Route exact path="/displayHelp"            component={DisplayHelp}/>
     <Route exact path="/offers"                 component={StudentOffers}/>l
     <Route exact path="/ticketsDashboard"       component={TicketsDashboard}/>
     <Route exact path="/StudentOffersList"      component={StudentOffersList}/>
     <Route exact path="/studentoffersstep2"     component={StudentOffersStep2}/>
     <Route exact path="/studentofferdetails"    component={StudentOfferDetails}/>
     <Route exact path="/studentform"            component={StudentForm}/>
     
     <Route exact path="/CampusEvents"           component={CampusEvents}/>
     <Route exact path="/CreateEvent"            component={CreateEvent}/>
     <Route exact path="/eventform"              component={EventForm}/>
     <Route exact path="/eventformstep2"         component={EventFormStep2}/>
     <Route exact path="/eventdetails"           component={EventDetails}/>
     
     <Route exact path="/statemanagement"        component={StateManagement}/>
     <Route exact path="/citymanagement"         component={citymanagement}/>

     <Route exact path="/universities"           component={Universities}/>
     <Route exact path="/universitydetails"      component={UniversityDetails}/>
     <Route exact path="/adduniversity"          component={AddUniversity}/>
     <Route exact path="/admindetails"           component={AdminDetails}/>

     <Route exact path="/calendarhomepage"       component={CalendarHomePage}/>    
     <Route exact path="/eventcalendarpage"      component={EventCalendarPage}/>

     <Route exact path="/flaggedcontents"          component={flaggedContents}/>
     
     
     
     
     
 </Switch>
</Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
