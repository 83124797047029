import React, { useState } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import { Job } from "./Job";

export function JobDetails() {
  const [loc, setLoc] = useState(window.location.pathname);
  return (
    <div >
      <Header /> 
      
      <div style={{ display: "flex" }}>
      <Menu pageTitle={loc} />
      <Job />
    </div>
    </div>
  );
}
