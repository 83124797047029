import React, { useState } from 'react'
import { Header } from "../Header"
import { Menu } from "../Menu"
import { ViewCampusList } from './ViewCampusList'
import Viewtoken from './Viewtoken';
export function ViewCampusListDetails() {
    const [loc, setLoc] = useState(window.location.pathname);
    return (
        <div>
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} /><Viewtoken/>
            {/* <ViewCampusList /> */}
            <camps/>
        </div>
        </div>
    )
}
