import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory,useLocation } from "react-router-dom";
import StateAndCities from "../State and Cities/StateAndCities.json";
import SummerNote from "../Summer Note/SummerNote";

const EventFormStep2 = (props) => {
  const location = useLocation();
  const { eventName } = location.state || { id: "none" };
  const { description } = location.state || { id: "none" };
  const { entryFee } = location.state || { id: "none" };
  const { ticketCapacity } = location.state || { id: "none" };
  const { ticketUrl } = location.state || { id: "none" };
  const { picture } = location.state || { id: "none" };

  const token = localStorage.getItem("Token");
  var todayy = "";
  todayy = new Date().toISOString().slice(0, 10);

  const history = useHistory();

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [uniData, setUniData] = useState([]);

  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [university, setUniversity] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [publishStartDateTime, setPublishStartDateTime] = useState("");
  const [publishEndDateTime, setPublishEndDateTime] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [stateNameData, setStateNameData] = useState([]);
  const [cityNameData, setCityNameData] = useState([]);

  const isDisabled = !startDateTime;
  const publishDateIsDisabled = !endDateTime;
  const expiryDateIsDisabled = !publishStartDateTime;


  const stateFun = () => {
    setStateCityData(StateAndCities);
    stateCityData.map((e) => {
      setStateNameData(e.states);
    });
  };

  const getCityData = async (e) => {
    setStateName(e);
    stateNameData.map((_e) => {
      if (_e.state == e) {
        setCityNameData(_e.districts);
      }
    });
    try {
      const formData = new FormData();
      formData.append("state_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY +
          "super_admin_get_university_list_state_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const getCityAndUniData = async (e) => {
    setUniData([]);
    setCityName(e);
    try {
      const formData = new FormData();
      formData.append("city_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY +
          "super_admin_get_university_list_city_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      // console.log("uni Response---------------------",fetchResponse);

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const handelSummenrnote = (e) => {
    // updateDescription(e);
  };

  async function createEvent() {
    try {
      if (
        stateName == "" &&
        startDateTime == "" &&
        endDateTime == "" &&
        eventLocation == "" &&
        publishStartDateTime == "" &&
        publishEndDateTime == ""
      ) {
        $(".ValueMsg").show();

        setTimeout(function () {
          $(".ValueMsg").hide();
        }, 3000);
        return;
      } else if (stateName == "") {
        $(".state_name").show();

        setTimeout(function () {
          $(".state_name").hide();
        }, 3000);
      } else if (startDateTime == "") {
        $(".start_date").show();

        setTimeout(function () {
          $(".start_date").hide();
        }, 3000);
      } else if (endDateTime == "") {
        $(".end_date").show();

        setTimeout(function () {
          $(".end_date").hide();
        }, 3000);
      } else if (eventLocation == "") {
        $(".event_location").show();

        setTimeout(function () {
          $(".event_location").hide();
        }, 3000);
      } else if (publishStartDateTime == "") {
        $(".publish_start_date").show();

        setTimeout(function () {
          $(".publish_start_date").hide();
        }, 3000);
      } else if (publishEndDateTime == "") {
        $(".publish_end_date").show();

        setTimeout(function () {
          $(".publish_end_date").hide();
        }, 3000);
      } else {
        const formData = new FormData();

        formData.append("title", eventName);
        formData.append("description", description);
        formData.append("fee", entryFee);
        formData.append("location", eventLocation);
        formData.append("capacity", ticketCapacity);
        formData.append("ticket_url", ticketUrl);
        formData.append("state", stateName);
        formData.append("city", cityName);
        formData.append("university", university);
        formData.append("start_date_time", startDateTime);
        formData.append("end_date_time", endDateTime);
        formData.append("publish_start_date_time", publishStartDateTime);
        formData.append("publish_end_date_time", publishEndDateTime);

        for (var i = 0; i < picture.length; i++) {
          formData.append("File[]", picture[i]);
        }

        // for (const pair of formData.entries()) {
        //   console.log(`formData-----------${pair[0]},${pair[1]}`);
        // }

        const eventResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_add_event",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
        // console.log("eventResponse-----------------",eventResponse);
        // setIsLoading(false);
        setErrorMsg(eventResponse.data.message);

        if (eventResponse.data.error_code == 200) {
          setErrorMsg(eventResponse.data.message);
          $(".formSuccess").show();

          setTimeout(function () {
            $(".formSuccess").hide();
          }, 3000);
        }
        history.push("/CreateEvent");
      }
    } catch (err) {
      console.log("create event error--------------", err);
      // setIsLoading(false);
    }
  }

  const resetValues = () => {
    setStateName("");
    setCityName("");
    setUniversity("");
    setStartDateTime("");
    setEndDateTime("");
    setPublishStartDateTime("");
    setPublishEndDateTime("");
    setEventLocation("");
    $("#_state").val("disabled");
    $("#_city").val("disabled");
    $("#_university").val("disabled");
  };

  function preview() {
    $(".preview_polls").show();
  }
  function closePreview() {
    $(".preview_polls").hide();
  }

  function edit_category() {
    $(".preview_polls").hide();
    $(".preview_category").show();
  }
  function closeEditForm() {
    $(".preview_category").hide();
  }

  useEffect(() => {
    stateFun();
  }, []);


  const handleStartDateChange = (e) => {
    setStartDateTime(e.target.value);

    setEndDateTime('');
    setPublishStartDateTime('');
    setPublishEndDateTime('')
  };

  const handleEndDateChange = (e) => {
    setEndDateTime(e.target.value);
    setPublishStartDateTime('');
    setPublishEndDateTime('')
  };

  const expiryDateLogic = (e) => {
    setPublishStartDateTime(e.target.value);
    setPublishEndDateTime('')

  };

  const handleMonthChange = () => {
    setStartDateTime('');
    setEndDateTime('');
  };

  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu />
          <div className="content-wrapper">
            <div
              class="formSuccess"
              style={{
                marginTop: "5px",
                width: "100%",
                marginRight: "198px",
                display: "none",
              }}
            >
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert variant="filled" severity="success">
                  {errorMsg}
                </Alert>
              </Stack>
            </div>

            <div className="border_class2">
              <div style={{ padding: "6px" }}>
                <div className="polls_heading_h1">CREATE EVENT</div>
              </div>

              <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 2 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                    Event Reciplents and Schedule
                  </p>
                </div>
              </div>
            </div>

            <div className=" border_class box_padding">
              <div className="row">
                <div class="col-md-3">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">State</label>

                        <p className="all_stars">*</p>
                      </div>

                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="_state"
                        onChange={(e) => getCityData(e.target.value)}
                      >
                        <option selected="selected" value="disabled">
                          Select State
                        </option>
                        {stateNameData.map((e) => {
                          return <option value={e.state}>{e.state}</option>;
                        })}
                      </select>

                      <div class="state_name" style={{ display: "none" }}>
                        <h4 className="all_validations_h4">
                          Please Select State
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">City</label>
                      <p className="all_stars"></p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="_city"
                      onChange={(e) => getCityAndUniData(e.target.value)}
                    >
                      <option selected="selected" value="disabled">
                        Select City
                      </option>

                      {cityNameData.map((name) => {
                        return <option value={name}>{name}</option>;
                      })}
                    </select>

                    <div class="std_course" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Select City
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">University</label>

                      <p className="all_stars"></p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="_university"
                      onChange={(e) => setUniversity(e.target.value)}
                    >
                      <option selected="selected" value="disabled">
                        Select Univercity
                      </option>
                      {uniData.map((e) => {
                        return <option value={e.uni_id}>{e.uni_name}</option>;
                      })}
                    </select>
                    <div class="std_class" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Select Univercity
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style={{ marginTop: "20px" }}>
                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Start Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                        type="datetime-local"
                        className="form_control input_box"
                        min={todayy + "T00:00"}
                        value={startDateTime}
                        onChange={handleStartDateChange}
                        name="startDateTime"
                    />

                    <div
                      class="start_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Event Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">End Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                     type="datetime-local"
                     className="form_control input_box end_date_login"
                     min={startDateTime}
                     value={endDateTime}
                     onChange={handleEndDateChange}
                     name="endDateTime"
                     disabled={isDisabled}
                     onMonthChange={handleMonthChange}
                    />

                    <div
                      class="end_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Event Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Location</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                      type="text"
                      className=" input_box"
                      id="event_location"
                      placeholder="Location"
                      value={eventLocation}
                      onChange={(e) => setEventLocation(e.target.value)}
                      name="birthdaytime"
                    />
                    <div
                      class="event_location"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Location
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class box_padding">
              <div class="row">
                <div class="col-md-12">
                  <div
                    className=""
                    style={{ width: "100%", marginTop: "0px" }}
                    id="new_delivery_type"
                  >
                    <div className="d-flex">
                      <label className="all_labels">
                        When it should be published?
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20px" }}>
                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Publish Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                      type="datetime-local"
                      className="form_control input_box"
                      min={startDateTime}
                      max={endDateTime}
                      placeholder="Now"
                      id="publish_date"
                      value={publishStartDateTime}
                      disabled={publishDateIsDisabled}
                      onChange={expiryDateLogic}
                      name="birthdaytime"
                    />
                    <div
                      class="publish_start_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter publish Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Expiry Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                      type="datetime-local"
                      className="form_control input_box"
                      min={publishStartDateTime}
                      max={endDateTime}
                      placeholder="dd-mm-yyyy hh-mm"
                      id="publish_date"
                      value={publishEndDateTime}
                      disabled={expiryDateIsDisabled}
                      onChange={(e) => setPublishEndDateTime(e.target.value)}
                      name="birthdaytime"
                    />
                    <div
                      class="publish_end_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Event Date
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}

            <div className="d-flex   border_class box_padding buttons_div">
              <div
                class="ValueMsg"
                style={{ margin: "8px", width: "57%", display: "none" }}
              >
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert variant="filled" severity="error">
                    Error! You Must Fill In All The Fields
                  </Alert>
                </Stack>
              </div>

              <img
                className="delete_image"
                src="dist/img/delete.png"
                alt="dropdown"
                onClick={() => resetValues()}
              />
              <span className="news_bar">|</span>
              <button className="preview_button" onClick={preview}>
                <span className="preview_font">Preview</span>
                <div className="preview_img_div">
                  <img
                    className="preview_image"
                    src="dist/img/view.png"
                    alt="dropdown"
                  />
                </div>
              </button>

              <input
                type="button"
                className="publish_button"
                defaultValue="Sign Up"
                onClick={() => createEvent()}
                value="Publish"
              />
            </div>

            {/* Preview */}
            <div className="preview_polls">
              <div className="preview_polls_inner_div1">
                <div className="preview_polls_inner_label">
                  <label>Preview</label>

                  <img
                    src="dist/img/Cancel.png"
                    alt="dropdown"
                    className="cancel_img ml-auto"
                    onClick={closePreview}
                  />
                </div>

                {/* {
            eventName == "" || description == "" || venue == "" || eventDate == "" || startTime == "" || endTime == "" || ticketCapacity == "" || sendNotificationTo == "" ?

              (
                <div>Please fill the form to preview it</div>
              ) : ( */}
                <div className="preview_polls_inner_div2">
                  <div className="d-flex" style={{ padding: "10px 0px" }}>
                    <h4 className="preview_h4">Campus Event</h4>
                    <img
                      src="dist/img/Pencil.png"
                      alt="dropdown"
                      style={{ cursor: "pointer" }}
                      className="edit_img"
                      onClick={() => edit_category()}
                    />
                  </div>

                  {
                    <div>
                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Event Name</p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {eventName}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          About event
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                          <p dangerouslySetInnerHTML={{ __html: description }}/>

                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Event Photo
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :
                          <span style={{ marginLeft: "10px", display: "flex" }}>
                            {picture && picture.map((e) => {
                              var arr = [];
                              arr.push(e);
                              return (
                                <>
                                  <div>
                                    <img
                                      src={e[0]}
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            })}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">State</p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {stateName}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">City</p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>{cityName}</span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">University</p>
                        <p className="col-md-9 preview_row_inner">
                          : :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {university}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Start Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {startDateTime}{" "}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          End Date/time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {endDateTime}
                          </span>
                        </p>
                      </div>
                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Location</p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {eventLocation}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Publish Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {publishStartDateTime}
                          </span>
                        </p>
                        <p className="col-md-3 preview_row_inner">
                          Expiry Date/Time
                        </p>
                        <p className="col-md-3 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {publishEndDateTime}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Entry Fee</p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>{entryFee}</span>{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Event Capacity
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          :{" "}
                          <span style={{ marginLeft: "10px" }}>
                            {ticketCapacity}
                          </span>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Ticket URL</p>
                        <p className="col-md-9 preview_row_inner">
                          {ticketUrl}
                        </p>
                      </div>
                    </div>
                  }
                </div>
                {/* )
          } */}
              </div>
            </div>

            {/* **************************** Edit Event ***************************** */}
            <div className="preview_category">
              <div className="edit_inner">
                <div className="d-flex edit_inner_div">
                  <label className="main_labels">Campus Event</label>

                  <img
                    src="dist/img/Cancel.png"
                    alt="dropdown"
                    className="close_event ml-auto cancel_img"
                    onClick={closeEditForm}
                  />
                </div>

                <div className="preview_form">
                  <div className="border_class edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">Name</label>

                              <p className="all_stars">*</p>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="name"
                              autoComplete="true"
                              // value={eventName}
                              // onChange={(e) => updateEventName(e.target.value)}
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please enter event name
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Description
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <SummerNote
                                   _onChange={handelSummenrnote}
                                  value={description}
                                  style={{height:"80px"}}
                            />

                            {/* <textarea
                              className=" edit_all_inputs"
                              id="news_description"
                              onChange={(e) => updateDescription(e.target.value)}
                              style={{ height: "80px" }}
                            ></textarea> */}



                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Enter Event Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className="p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Add Event Photo
                              </label>
                            </div>

                            <div>
                              {/* {picture == null ? ( */}
                              <div>
                                <label
                                  for="file-ip-1"
                                  style={{ height: "100%" }}
                                >
                                  <img
                                    className="image_std"
                                    src={require("../images/no_image.png")}
                                    alt="no image"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                  <img
                                    id="file-ip-1-preview"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "6PX",
                                      display: "none",
                                    }}
                                  />
                                </label>

                                <input
                                  type="file"
                                  name="photo"
                                  style={{
                                    visibility: "hidden",
                                    display: "none",
                                  }}
                                  accept="image/png, image/gif, image/jpeg"
                                  // onChange={getImage}
                                  id="file-ip-1"
                                />
                              </div>
                              {/* ) : ( */}
                              {/* <div>
                          <label
                            for="file-ip-1"
                            style={{ height: "100%", display: "flex" }}
                          >
                            {imgData.map((item) => {
                              return (
                                <div style={{ width: "70px", height: "70px" }}>
                                  <img className="image_std" src={item} />
                                </div>
                              );
                            })}

                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden" }}
                              accept="image/png, image/gif, image/jpeg"
                              // onChange={getImage}
                              id="file-ip-1"
                            />
                          </label>
                        </div>
                      )} */}
                            </div>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Entry Fee
                              </label>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="number"
                              autoComplete="true"
                              // value={entryFee}
                              // onChange={(e) => updateEntryFee(e.target.value)}
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Event Capacity
                              </label>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="number"
                              autoComplete="true"
                              // value={ticketCapacity}
                              // onChange={(e) => updateTicketCapacity(e.target.value)}
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-0">
                      <div class="row m-0">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Ticket URL
                              </label>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="name"
                              autoComplete="true"
                              // value={ticketUrl}
                              // onChange={(e) => updateTicketUrl(e.target.value)}
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">State</label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="department_neww"
                              onChange={(e) => getCityData(e.target.value)}
                            >
                              <option value={"disabled"}>Select State</option>
                              {stateData.map((e) => {
                                return (
                                  <option value={e.state_id}>
                                    {e.state_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">City</label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="student_course"
                              //        onChange={courseWiseSubjectList}
                            >
                              <option selected="selected">Select City</option>

                              {cityData.map((name) => {
                                return (
                                  <option value={name.city_name}>
                                    {name.city_name}
                                  </option>
                                );
                              })}
                            </select>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                University
                              </label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="student_class"
                              //        onChange={fetchClass}
                            >
                              <option selected="selected">
                                Select University
                              </option>
                            </select>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Location
                              </label>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="name"
                              autoComplete="true"
                              // value={entryFee}
                              // onChange={(e) => updateEntryFee(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div className="d-flex">
                            <label className="edit_all_labels">
                              Start Date/Time
                            </label>

                            <p className="all_stars">*</p>
                          </div>
                          <input
                            type="datetime-local"
                            className="form_control edit_all_inputs"
                            min={todayy + "T00:00"}
                            placeholder="Now"
                            id="publish_date"
                            //        value={jobDate}
                            //        onChange={(e) => updateEventDate(e.target.value)}
                            name="birthdaytime"
                          />
                          <div
                            class="newsDescription"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please Write News Description
                            </h4>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                End Date/Time
                              </label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              type="datetime-local"
                              className="form_control edit_all_inputs"
                              min={todayy + "T00:00"}
                              placeholder="dd-mm-yyyy hh-mm"
                              id="publish_date"
                              //        value={jobDate}
                              //        onChange={(e) => updateEventDate(e.target.value)}
                              name="birthdaytime"
                            />
                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div className="mb-2">
                          <label className="edit_all_labels">
                            When it should be published?
                          </label>
                        </div>
                        <div class="col-md-6">
                          <div className="d-flex">
                            <label className="edit_all_labels">
                              Publish Date/Time
                            </label>

                            <p className="all_stars">*</p>
                          </div>
                          <input
                            type="datetime-local"
                            className="form_control edit_all_inputs"
                            min={todayy + "T00:00"}
                            placeholder="Now"
                            id="publish_date"
                            //        value={jobDate}
                            //        onChange={(e) => updateEventDate(e.target.value)}
                            name="birthdaytime"
                          />

                          <div
                            class="newsDescription"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please Write News Description
                            </h4>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Expiry Date/Time
                              </label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              type="datetime-local"
                              className="form_control edit_all_inputs"
                              min={todayy + "T00:00"}
                              placeholder="dd-mm-yyyy hh-mm"
                              id="publish_date"
                              //        value={jobDate}
                              //        onChange={(e) => updateEventDate(e.target.value)}
                              name="birthdaytime"
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ******************button********************** */}

                  <div
                    className="d-flex mt-3 edit_buttons_div border_class"
                    style={{ justifyContent: "end" }}
                  >
                    <input
                      type="button"
                      className="edit_cancel_button"
                      // onClick={() => cancelEdit()}
                      value="Cancel"
                    />

                    <input
                      type="button"
                      className="edit_update_button"
                      id="delete_single_student"
                      value="Update"
                      // onClick={() => saveEvent()}
                    />
                  </div>

                  <div style={{ display: "none" }} className="saveMessage">
                    Data Saved Successfully
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventFormStep2;
