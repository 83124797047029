import { BorderAll } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
export function Menu(props) {
  const [opt, setOpt] = useState(props.pageTitle);
  {
    // alert("path = "+opt)  //test statement to check if opt holds a value or is empty
  }
  const handleItemClick = (index) => {
    localStorage.setItem('active_index', JSON.stringify(index));
  };


  return (
    <aside class="main-sidebar sidebar-dark-primary elevation-4 position-fixed"
      style={{ overflowY: "auto", fontFamily: "Poppins", overflowX: "hidden", height: "100%", zIndex: "1", position: "relative"}}>
      <nav class="navigation">
        <ul class="mainmenu">
        <li style={{marginTop:"25px"}}>
            <div style={{paddingLeft: "0.0rem",fontFamily:'poppins' ,fontStyle: 'normal',fontWeight: '500',fontSize: '9px',lineHeight: '16px',color: 'rgba(255, 255, 255, 0.9)'}}>Dashboard </div>
              <ul style={{fontSize: "14px",color:'lightblue'}}>

                <li onClick={()=>handleItemClick(1)} className={localStorage.getItem('active_index') == 1 ? "active": ''}><Link to="/homepage">
                    <i class="fa fa-check-square" aria-hidden="true"></i>Home</Link></li>
                 <li onClick={()=>handleItemClick(2)} className={localStorage.getItem('active_index') == 2 ? "active": ''}><Link to="/calendarhomepage"><i class="fa fa-check-square" aria-hidden="true"></i>Calendar</Link></li>
                 <li onClick={()=>handleItemClick(3)} className={localStorage.getItem('active_index') == 3 ? "active": ''}><Link to="/universities"><i class="fa fa-check-square" aria-hidden="true"></i>Universities</Link></li>

              </ul>
          </li>

          <li style={{marginTop:"25px"}}>
            <div style={{paddingLeft: "0.0rem",fontFamily:'poppins' ,fontStyle: 'normal',fontWeight: '500',fontSize: '9px',lineHeight: '16px',color: 'rgba(255, 255, 255, 0.9)'}}>Engage </div>
              <ul style={{fontSize: "14px",color:'lightblue'}}>

              <li onClick={()=>handleItemClick(4)} className={localStorage.getItem('active_index') == 4 ? "active": ''}><Link to="/CreateEvent"><i class="fa fa-check-square" aria-hidden="true"></i>Events</Link></li>
              <li onClick={()=>handleItemClick(5)} className={localStorage.getItem('active_index') == 5 ? "active": ''}><Link to="/jobDetails"><i class="fa fa-check-square" aria-hidden="true"></i>Jobs</Link></li>
              <li onClick={()=>handleItemClick(6)} className={localStorage.getItem('active_index') == 6 ? "active": ''}><Link to="/StudentOffersList" ><i class="fa fa-check-square" aria-hidden="true"></i>Offers</Link></li>
              {/* <li onClick={()=>handleItemClick(7)} className={localStorage.getItem('active_index') == 7 ? "active": ''}><Link  style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>QUIZ</Link></li> */}

              </ul>
          </li>

          <li style={{marginTop:"25px"}}>
            <div style={{paddingLeft: "0.0rem",fontFamily:'poppins' ,fontStyle: 'normal',fontWeight: '500',fontSize: '9px',lineHeight: '16px',color: 'rgba(255, 255, 255, 0.9)'}}>Service Desk </div>
              <ul style={{fontSize: "14px",color:'lightblue'}}>

              <li onClick={()=>handleItemClick(7)} className={localStorage.getItem('active_index') == 7 ? "active": ''}><Link to="/flaggedcontents"><i class="fa fa-check-square" aria-hidden="true"></i>Flagged Content</Link></li>

              </ul>
          </li>

               {/* <li onClick={()=>handleItemClick(2)} className={localStorage.getItem('active_index') == 2 ? "active": ''}><Link to="/createCampusDetails" style={{padding:'5px'}}>
                <i class="fa fa-check-square" aria-hidden="true"></i>CREATE CAMPUS</Link></li>
                <li onClick={()=>handleItemClick(3)} className={localStorage.getItem('active_index') == 3 ? "active": ''}><Link to="/createAdminDetails" style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>CREATE ADMIN</Link></li>
                <li onClick={()=>handleItemClick(4)} className={localStorage.getItem('active_index') == 4 ? "active": ''}><Link to="/viewCampusListDetails" style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>VIEW CAMPUS LIST</Link></li>
                <li onClick={()=>handleItemClick(5)} className={localStorage.getItem('active_index') == 5 ? "active": ''}><Link to="/campusDirectoryDetails" style={{padding:'5px'}}><i class="fa fa-check-square" aria-hidden="true"></i>CAMPUS DIRECTORY</Link></li>

                <li onClick={()=>handleItemClick(7)} className={localStorage.getItem('active_index') == 7 ? "active": ''}><Link to="/marketplaceDetails" style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>MARKET PLACE</Link></li>
                <li onClick={()=>handleItemClick(10)} className={localStorage.getItem('active_index') == 10 ? "active": ''}><Link to="/student" style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>STUDENT</Link></li>
                <li onClick={()=>handleItemClick(11)} className={localStorage.getItem('active_index') == 11 ? "active": ''}><Link to="/teacher"  style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>TEATURE</Link></li>
                <li onClick={()=>handleItemClick(13)} className={localStorage.getItem('active_index') == 13 ? "active": ''}><Link to="/statemanagement"  style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>STATES</Link></li>
                <li onClick={()=>handleItemClick(14)} className={localStorage.getItem('active_index') == 14 ? "active": ''}><Link to="/citymanagement" style={{padding:'5px'}} ><i class="fa fa-check-square" aria-hidden="true"></i>CITIES</Link></li>  */}


        </ul>
      </nav>
    </aside>
  );
}
