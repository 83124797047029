import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory, useLocation } from "react-router-dom";
import StateAndCities from "../State and Cities/StateAndCities.json"

export const JobFormStep2 = () => {
  const token = localStorage.getItem("Token");
  var todayy = "";
  const history = useHistory();

  const location = useLocation();
  const { jobCategory } = location.state || { id: "none" };
  const { companyName } = location.state || { id: "none" };
  const { jobTitle } = location.state || { id: "none" };
  const { picture } = location.state || { id: "none" };
  const { jobDiscription } = location.state || { id: "none" };
  const { jobLocation } = location.state || { id: "none" };
  const { jobBudget } = location.state || { id: "none" };

  const [categoryName, setCategoryName] = useState("");
  const [universityName,setUniversityName] = useState("");

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [uniData, setUniData] = useState([]);

  const [jobState, setJobState] = useState("");
  const [jobCity, setJobCity] = useState("");
  const [university, setUniversity] = useState("");
  const [jobStartDate, setJobStartDate] = useState("");
  const [jobEndDate, setJobEndDate] = useState("");
  const [jobPublishDate, setJobPublishDate] = useState("");
  const [jobPublishExpiryDate, setJobPublishExpiryDate] = useState("");
  const [jobEmail, setJobEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [editJobCategory, setEditJobCategory] = useState(jobCategory);
  const [editCompanyName, setEditCompanyName] = useState(companyName);
  const [editJobTitle, setEditJobTitle] = useState(jobTitle);
  const [editJobDiscription, setEditJobDiscription] = useState(jobDiscription);
  const [editJobState, setEditJobState] = useState(jobState);
  const [editJobCity, setEditJobCity] = useState(jobCity);
  const [editUniversity, setEditUniversity] = useState(university);
  const [editJobStartDate, setEditJobStartDate] = useState(jobStartDate);
  const [editJobEndDate, setEditJobEndDate] = useState(jobEndDate);
  const [editJobPublishDate, setEditJobPublishDate] = useState(jobPublishDate);
  const [editJobPublishExpiryDate, setEditJobPublishExpiryDate] = useState(jobPublishExpiryDate);
  const [editEmail,setEditEmail] = useState(jobEmail);
  console.log("editEmail------------",editEmail);

  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [stateNameData, setStateNameData] = useState([]);
  const [cityNameData, setCityNameData] = useState([]);

  const isDisabled = !jobStartDate;
  const publishDateIsDisabled = !jobEndDate;
  const expiryDateIsDisabled = !jobPublishDate;

  todayy = new Date().toISOString().slice(0, 10);

  async function fetchStateData(name) {
    try {
      const formData = new FormData();
      formData.append("state_name", name);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "state_list",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const stateData1 = fetchResponse.data.data;

        setStateData(stateData1);
      } else {
        setStateData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const getCityData = async (e) => {
    stateData.map((item) => {
      if (item.state_id == e) {
        setJobState(item.state_name);
      }
    });
    try {
      const formData = new FormData();
      formData.append("state_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_state_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const getCityAndUniData = async (e) => {
    setUniData([]);
    setJobCity(e)
    try {
      const formData = new FormData();
      formData.append("city_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_city_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const stateFun =() =>{
    setStateCityData(StateAndCities)
    stateCityData.map((e) =>{
      setStateNameData(e.states);
    })
}

  const onStateChange = async (e) =>{
    stateNameData.map((_e) =>{
      if(_e.state == e){
        setJobState(e);
        setCityNameData(_e.districts);
      }
    })

    try {
      const formData = new FormData();
      formData.append("state_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_state_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const categories = () => {
     if(jobCategory ==""){
      setCategoryName("");
     }else if(jobCategory == 1){
      setCategoryName("Part Time");
    }else if(jobCategory == 2){
      setCategoryName("Full Time");
    }else if(jobCategory == 3){
      setCategoryName("Remote Friendly");
    }
    else if(jobCategory == 4){
      setCategoryName("Freelance");
    }
    else if(jobCategory == 5){
      setCategoryName("Volunteer");
    }
  }

  const getUniversityName = (e) =>{
     setUniversity(e);
    uniData.map((_item)=>{
      if(_item.uni_id == e){
        setUniversityName(_item.uni_name);
      }
    })
  }

  useEffect(() => {
    fetchStateData();
    stateFun();
    categories()

  }, []);

  async function createJob() {
    try {
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (
        jobState == "" &&
        jobCity == "" &&
        university == "" &&
        jobStartDate == "" &&
        jobEndDate == "" &&
        jobPublishDate == "" &&
        jobPublishExpiryDate == "" &&
        jobEmail == ""
      ) {
        $(".job_state").show();

        setTimeout(function () {
          $(".job_state").hide();
        }, 3000);
      } else if (jobState == "") {
        $(".job_state").show();

        setTimeout(function () {
          $(".job_state").hide();
        }, 3000);
      } else if (jobStartDate == "") {
        $(".start_date").show();

        setTimeout(function () {
          $(".start_date").hide();
        }, 3000);
      } else if (jobEndDate == "") {
        $(".end_date").show();

        setTimeout(function () {
          $(".end_date").hide();
        }, 3000);
      } else if (jobPublishDate == "") {
        $(".publish_date").show();

        setTimeout(function () {
          $(".publish_date").hide();
        }, 3000);
      } else if (jobPublishExpiryDate == "") {
        $(".publish_expiry_date").show();

        setTimeout(function () {
          $(".publish_expiry_date").hide();
        }, 3000);
      } else if (jobEmail == "") {
        $(".job_email").show();

        setTimeout(function () {
          $(".job_email").hide();
        }, 3000);
      }else if (!filter.test(jobEmail )) {
        $(".validEmail").show();

        setTimeout(function() {
          $(".validEmail").hide();
        }, 3000);
      }
      else {
        const formData = new FormData();
        formData.append("category", jobCategory);
        formData.append("company_name", companyName);
        formData.append("job_title", jobTitle);
        formData.append("description", jobDiscription);
        formData.append("budget", jobBudget);
        formData.append("state", jobState);
        formData.append("city", jobCity);
        formData.append("university", university);
        formData.append("start_date_time", jobStartDate);
        formData.append("end_date_time", jobEndDate);
        formData.append("publish_start_date_time", jobPublishDate);
        formData.append("publish_end_date_time", jobPublishExpiryDate);
        formData.append("comp_email", jobEmail);

        formData.append("job_location", jobLocation);
        for (var i = 0; i < picture.length; i++) {
          formData.append("comp_logo", picture[i]);
        }

        const createJobResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_add_job",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
        setErrorMsg(createJobResponse.data.message);
        if (createJobResponse.data.error_code == 200) {
          $(".formSuccess").show();
          setTimeout(function () {
            $(".formSuccess").hide();
          }, 3000);

          history.push("/jobDetails");
        }
      }
    } catch (err) {
      console.log("create job error-----------", err);
    }
  }

  const resetValues = () => {
    setJobState("");
    setJobCity("");
    setUniversity("");
    setJobStartDate("");
    setJobEndDate("");
    setJobPublishDate("");
    setJobPublishExpiryDate("");
    setJobEmail("");
    $("#state_name").val("disabled")
    $("#city_name").val("disabled")
    $("#university").val("disabled")
 };

  function preview() {
    $(".preview_polls").show();
  }

  function closePreview() {
    $(".preview_polls").hide();
  }

  function edit_category() {
    $(".preview_polls").hide();
    $(".preview_category").show();
  }
  function closeEditForm() {
    $(".preview_category").hide();
  }

  const handleStartDateChange = (e) => {
    setJobStartDate('')
    setJobStartDate(e.target.value);

    setJobEndDate('');
    setJobPublishDate('');
    setJobPublishExpiryDate('')
  };

  const handleEndDateChange = (e) => {
    setJobEndDate(e.target.value);
    setJobPublishDate('');
    setJobPublishExpiryDate('')
  };

  const expiryDateLogic = (e) => {
    setJobPublishDate(e.target.value);
    setJobPublishExpiryDate('')

  };

  const handleMonthChange = () => {
    setJobStartDate('');
    setJobEndDate('');
  };

  const updateEditData = () =>{

      setJobEmail(editEmail);
      setJobStartDate(editJobStartDate);
      setJobEndDate(editJobEndDate);
      setJobPublishDate(editJobPublishDate);
      setJobPublishExpiryDate(editJobPublishExpiryDate);
  }

  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu />
          <div className="content-wrapper">
            <div
              class="formSuccess"
              style={{
                marginTop: "5px",
                width: "100%",
                marginRight: "198px",
                display: "none",
              }}
            >
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert variant="filled" severity="success">
                  {errorMsg}
                </Alert>
              </Stack>
            </div>

            <div className="border_class2">
              <div style={{ padding: "6px" }}>
                <div className="polls_heading_h1">CREATE JOB</div>
              </div>

              <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 2 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                  Job Reciplents and Schedule
                  </p>
                </div>
              </div>
            </div>

            <div className=" border_class box_padding">
              <div className="row">
                <div class="col-md-3">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div
                      className=""
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      <div className="d-flex">
                        <label className="all_labels">State</label>

                        <p className="all_stars">*</p>
                      </div>

                      <select
                        className="form-select form-select-sm all_inputs"
                        aria-label=".form-select-sm example"
                        id="state_name"
                        onChange={(e) => onStateChange(e.target.value)}
                      >
                         <option value={"disabled"}>Select State</option>
                        {stateNameData.map((e) => {
                          return (
                            <option value={e.state}>{e.state}</option>
                          );
                        })}
                      </select>

                      <div class="job_state" style={{ display: "none" }}>
                        <h4 className="all_validations_h4">
                          Please Select State
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">City</label>
                      <br />
                      <p className="all_stars"></p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="city_name"
                      onChange={(e) => getCityAndUniData(e.target.value)}
                    >
                      <option value = "disabled">Select City</option>

                      {cityNameData.map((name) => {
                        return (
                          <option value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>

                    <div class="job_city" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Select City
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div className="">
                    <div className="d-flex">
                      <label className="all_labels">University</label>
                      <br />
                      <p className="all_stars"></p>
                    </div>
                    <select
                      className="form-select form-select-sm all_inputs"
                      aria-label=".form-select-sm example"
                      id="university"
                      onChange={(e) => getUniversityName(e.target.value)}
                    >
                      <option value ="disabled">Select University</option>
                      {uniData.map((e) => {
                        return <option value={e.uni_id}>{e.uni_name}</option>;
                      })}
                    </select>
                    <div class="university" style={{ display: "none" }}>
                      <h4 class="login-text all_validations_h4">
                        Please Select University
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style={{ marginTop: "20px" }}>
                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Start Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                    type="datetime-local"
                    className="form_control input_box"
                    min={todayy + "T00:00"}
                    value={jobStartDate}
                    onChange={handleStartDateChange}
                    name="startDateTime"
                    />

                    <div
                      class="start_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter Event Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">End Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                    type="datetime-local"
                    className="form_control input_box end_date_login"
                    min={jobStartDate}
                    value={jobEndDate}
                    onChange={handleEndDateChange}
                    name="endDateTime"
                    disabled={isDisabled}
                    />

                    <div
                      class="end_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter Event Date
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class box_padding">
              <div class="row">
                <div class="col-md-12">
                  <div
                    className=""
                    style={{ width: "100%", marginTop: "0px" }}
                    id="new_delivery_type"
                  >
                    <div className="d-flex">
                      <label className="all_labels">
                        When it should be published?
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20px" }}>
                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Publish Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                     type="datetime-local"
                     className="form_control input_box"
                     min={jobStartDate}
                     max={jobEndDate}
                     placeholder="Now"
                     id="publish_date"
                     value={jobPublishDate}
                     disabled={publishDateIsDisabled}
                     onChange={expiryDateLogic}
                     name="birthdaytime"
                    />

                    <div
                      class="publish_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter publish Date
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div>
                    <div className="d-flex">
                      <label className="all_labels">Expiry Date/Time</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                    type="datetime-local"
                    className="form_control input_box"
                    min={jobPublishDate}
                    max={jobEndDate}
                    placeholder="dd-mm-yyyy hh-mm"
                    id="publish_date"
                    value={jobPublishExpiryDate}
                    disabled={expiryDateIsDisabled}
                    onChange={(e) => setJobPublishExpiryDate(e.target.value)}
                    name="birthdaytime"
                    />

                    <div
                      class="publish_expiry_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter Job Date
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class ">
              <div class="row">
                <div class="col-md-6">
                  <div
                    style={{
                      width: "100%",

                      paddingRight: "0",
                    }}
                  >
                    <div className="d-flex">
                      <label className="all_labels">Company Email</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                      type="email"
                      className="input_box"
                      id="email"
                      placeholder="company email goes here.."
                      value={jobEmail}
                      onChange={(e) => setJobEmail(e.target.value)}
                    />

                    <div
                      class="job_email"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4 className="all_validations_h4">
                        Please Enter Company Email
                      </h4>
                    </div>
                    <div
                    class="validEmail"
                    style={{  marginTop: "-6px", display: "none"}}
                  >
                    <h4 className="all_validations_h4">
                      Please Enter valid Email Address with @
                    </h4>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}

            <div className="d-flex   border_class box_padding buttons_div">
              <div
                class="ValueMsg"
                style={{ margin: "8px", width: "57%", display: "none" }}
              >
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert variant="filled" severity="error">
                    Error! You Must Fill In All The Fields
                  </Alert>
                </Stack>
              </div>

              <img
                className="delete_image"
                src="dist/img/delete.png"
                alt="dropdown"
                onClick={() => resetValues()}
              />
              <span className="news_bar">|</span>
              <button className="preview_button " onClick={preview}>
                <span className="preview_font">Preview</span>
                <div className="preview_img_div">
                  <img
                    className="preview_image"
                    src="dist/img/view.png"
                    alt="dropdown"
                  />
                </div>
              </button>

              <input
                type="button"
                className="publish_button"
                defaultValue="Sign Up"
                onClick={() => createJob()}
                value="Publish"
              />
            </div>

            {/* Preview */}
            <div className="preview_polls">
              <div className="preview_polls_inner_div1">
                <div className="preview_polls_inner_label">
                  <label>Preview</label>

                  <button
                    style={{ border: "none", marginLeft: "auto" }}
                    onClick={() => closePreview()}
                  >
                    <img
                      src="dist/img/Cancel.png"
                      alt="dropdown"
                      className="cancel_img "
                    />
                  </button>
                </div>

                {/* jobCategory == "" ?

               (
                 <div>Please fill the form to preview it</div>
              ) : ( */}
                <div className="preview_polls_inner_div2">
                  <div className="d-flex" style={{ padding: "10px 0px" }}>
                    <h4 className="preview_h4">Job</h4>
                    <img
                      src="dist/img/Pencil.png"
                      onClick={() => edit_category()}
                      alt="dropdown"
                      className="edit_img"
                    ></img>
                  </div>

                  {
                    <div>
                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Job Category
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {categoryName}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">Job Title</p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobTitle}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Company Name
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {companyName}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Description
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : <p dangerouslySetInnerHTML={{ __html: jobDiscription }}/>
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Company Logo
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {" "}
                           <img src={picture} style={{ width: "70px" }} />
                        </p>
                      </div>
                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">State</p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobState}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">City</p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobCity}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">University</p>
                        <p className="col-md-9 preview_row_inner">
                          : {universityName}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Start Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobStartDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          End Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobEndDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Publish Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobPublishDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Expiry Date/Time
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobPublishExpiryDate}{" "}
                        </p>
                      </div>

                      <div className="row preview_row">
                        <p className="col-md-3 preview_row_inner">
                          Company Email
                        </p>
                        <p className="col-md-9 preview_row_inner">
                          : {jobEmail}{" "}
                        </p>
                      </div>
                    </div>
                  }
                </div>
                {/* ) */}
                {/* } */}

                <div
                  class="EnterValue"
                  style={{ margin: "10px auto", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "12PX", marginLeft: "0" }}
                  >
                    You Must Fill In All The Fields
                  </h4>
                </div>
              </div>
            </div>

            {/* **************************** Edit Event ***************************** */}
            <div className="preview_category">
              <div className="edit_inner">
                <div className="d-flex edit_inner_div">
                  <label className="main_labels">Job</label>

                  <img
                    src="dist/img/Cancel.png"
                    alt="dropdown"
                    className="close_event ml-auto cancel_img"
                    onClick={closeEditForm}
                  />
                </div>

                <div className="preview_form">
                  <div className="border_class edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Job Category
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              id="job_category"
                              aria-label=".form-select-sm example"

                              onChange={(e) => setEditJobCategory(e.target.value)}
                            >
                              <option
                                selected="selected"
                                value={editJobCategory}
                                style={{ padding: "6px" }}
                              >
                                Select Category
                              </option>
                              <option value="1">Part Time</option>
                              <option value="2">Full Time</option>
                              <option value="3">Remote Friendly</option>
                              <option value="4">Freelance</option>
                              <option value="5">Volunteer</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Job Title
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="name"
                              autoComplete="true"
                              value={editJobTitle}
                              onChange={(e) => setEditJobTitle(e.target.value)}
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please enter event name
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 p-0">
                        <div class="row edit_row_margin">
                          <div class="col-md-12">
                            <div
                              className=""
                              style={{ width: "100%", marginTop: "0px" }}
                            >
                              <div className="d-flex">
                                <label className="edit_all_labels">
                                  Company Name
                                </label>

                                <p className="all_stars">*</p>
                              </div>
                              <input
                                className="edit_all_inputs"
                                type="name"
                                autoComplete="true"
                                value={editCompanyName}
                                onChange={(e) => editCompanyName(e.target.value)}
                              />

                              <div
                                class="newsDescription"
                                style={{ marginTop: "-6px", display: "none" }}
                              >
                                <h4
                                  class="login-text"
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    marginLeft: "0",
                                  }}
                                >
                                  Please enter event name
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Description
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <textarea
                              className=" edit_all_inputs"
                              id="news_description"
                              value={editJobDiscription}
                              onChange={(e) => setEditJobDiscription(e.target.value)}
                              style={{ height: "80px" }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className="p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Add Company Logo
                              </label>
                            </div>

                            <div>
                              {/* {picture == null ? ( */}
                              <div>
                                <label
                                  for="file-ip-1"
                                  style={{ height: "100%" }}
                                >
                                  <img
                                    className="image_std"
                                    src={require("../images/no_image.png")}
                                    alt="no image"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                  <img
                                    id="file-ip-1-preview"
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "6PX",
                                      display: "none",
                                    }}
                                  />
                                </label>

                                <input
                                  type="file"
                                  name="photo"
                                  style={{
                                    visibility: "hidden",
                                    display: "none",
                                  }}
                                  accept="image/png, image/gif, image/jpeg"
                                  // onChange={getImage}
                                  id="file-ip-1"
                                />
                              </div>
                              {/* ) : ( */}
                              {/* <div>
                          <label
                            for="file-ip-1"
                            style={{ height: "100%", display: "flex" }}
                          >
                            {imgData.map((item) => {
                              return (
                                <div style={{ width: "70px", height: "70px" }}>
                                  <img className="image_std" src={item} />
                                </div>
                              );
                            })}

                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden" }}
                              accept="image/png, image/gif, image/jpeg"
                              // onChange={getImage}
                              id="file-ip-1"
                            />
                          </label>
                        </div>
                      )} */}
                            </div>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">State</label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="department_neww"
                              onChange={(e) => getCityData(e.target.value)}
                            >
                              <option value={"disabled"}>Select State</option>
                              {stateData.map((e) => {
                                return (
                                  <option value={e.state_id}>
                                    {e.state_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">City</label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="student_course"
                              //        onChange={courseWiseSubjectList}
                            >
                              <option selected="selected">Select City</option>

                              {cityData.map((name) => {
                                return (
                                  <option value={name.city_name}>
                                    {name.city_name}
                                  </option>
                                );
                              })}
                            </select>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                University
                              </label>
                              <p className="all_stars">*</p>
                            </div>
                            <select
                              className="form-select form-select-sm edit_all_inputs"
                              aria-label=".form-select-sm example"
                              id="student_class"
                              //        onChange={fetchClass}
                            >
                              <option selected="selected">
                                Select University
                              </option>
                            </select>

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-6">
                          <div className="d-flex">
                            <label className="edit_all_labels">
                              Start Date/Time
                            </label>

                            <p className="all_stars">*</p>
                          </div>
                          <input
                            type="datetime-local"
                            className="form_control edit_all_inputs"
                            min={todayy + "T00:00"}
                            placeholder="Now"
                            id="publish_date"
                            value={editJobStartDate}
                            onChange={(e) => setEditJobStartDate(e.target.value)}
                            name="birthdaytime"
                          />
                          <div
                            class="newsDescription"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please Write News Description
                            </h4>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                End Date/Time
                              </label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              type="datetime-local"
                              className="form_control edit_all_inputs"
                              min={todayy + "T00:00"}
                              placeholder="dd-mm-yyyy hh-mm"
                              id="publish_date"
                              value={editJobEndDate}
                              onChange={(e) => setEditJobEndDate(e.target.value)}
                              name="birthdaytime"
                            />
                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div className="col-md-12">
                        <div className="d-flex">
                              <label className="edit_all_labels">
                                When it should be published?
                              </label>

                              <p className="all_stars"></p>
                            </div>
                        </div>
                        <div class="col-md-6">
                          <div className="d-flex">
                            <label className="edit_all_labels">
                              Publish Date/Time
                            </label>

                            <p className="all_stars">*</p>
                          </div>
                          <input
                            type="datetime-local"
                            className="form_control edit_all_inputs"
                            min={todayy + "T00:00"}
                            placeholder="Now"
                            id="edit_publish_date"
                            value={editJobPublishDate}
                            onChange={(e) => setEditJobPublishDate(e.target.value)}
                            name="birthdaytime"
                          />

                          <div
                            class="newsDescription"
                            style={{ marginTop: "-6px", display: "none" }}
                          >
                            <h4
                              class="login-text"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "0",
                              }}
                            >
                              Please Write News Description
                            </h4>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Expiry Date/Time
                              </label>

                              <p className="all_stars">*</p>
                            </div>

                            <input
                              type="datetime-local"
                              className="form_control edit_all_inputs"
                              min={todayy + "T00:00"}
                              placeholder="dd-mm-yyyy hh-mm"
                              id="edit_expiry_date"
                              value={editJobPublishExpiryDate}
                              onChange={(e) => setJobPublishExpiryDate(e.target.value)}
                              name="birthdaytime"
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please Write News Description
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="border_class2 edit_row_padding2">
                    <div className=" p-0">
                      <div class="row edit_row_margin">
                        <div class="col-md-12">
                          <div
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <div className="d-flex">
                              <label className="edit_all_labels">
                                Company Email
                              </label>

                              <p className="all_stars">*</p>
                            </div>
                            <input
                              className="edit_all_inputs"
                              type="email"
                              autoComplete="true"
                              id="edit_job_email"
                              value={editEmail}
                              onChange={(e) => setEditEmail(e.target.value)}
                              placeholder="Company email goes here.."
                            />

                            <div
                              class="newsDescription"
                              style={{ marginTop: "-6px", display: "none" }}
                            >
                              <h4
                                class="login-text"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "0",
                                }}
                              >
                                Please enter event name
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ******************button********************** */}

                  <div
                    className="d-flex mt-3 edit_buttons_div border_class"
                    style={{ justifyContent: "end" }}
                  >
                    <input
                      type="button"
                      className="edit_cancel_button"
                      // onClick={() => cancelEdit()}
                      value="Cancel"
                    />

                    <input
                      type="button"
                      className="edit_update_button"
                      id="delete_single_student"
                      value="Update"
                      onClick={() => updateEditData()}
                    />
                  </div>

                  <div style={{ display: "none" }} className="saveMessage">
                    Data Saved Successfully
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
