import React, { useState, useEffect, useMemo } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link,useLocation } from "react-router-dom";
import moment from "moment";

export const JobPreview = () => {

  const [loc, setLoc] = useState(window.location.pathname);

  const location = useLocation();
  const token = localStorage.getItem("Token");
  const { j_id } = location.state || { id: "none" };
  const [jobDetails, setJobDetails] = useState([]);
  const [jobType, setJobType] = useState("");



  var new_date = jobDetails.Deadline;
  const d_date = moment(new_date).format("D MMM YYYY");



  async function getJobData(jId) {
    try {
      const formData = new FormData();
      formData.append("j_id", jId);

      const jobResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_job_details",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      console.log("jobResponse...............", jobResponse);
      const ErrorCode = jobResponse.data.error_code;
      if (ErrorCode == 200) {
        const j_data = jobResponse.data.data;
        setJobDetails(j_data);
        var cat = j_data.category;

        if(cat == 1){
          setJobType("Part Time")
        }else if(cat == 2){
          setJobType("Full Time")
        }else if(cat == 3){
          setJobType("Remote Friendly")
        }else if (cat == 4) {
          setJobType("Freelance")
        }else if (cat == 5) {
          setJobType("Volunteer")
        }else{
          setJobType("");
        }

      } else {

      }
    } catch (err) {
      console.log("get event data error-----------", err);
    }
  }

  useEffect(() => {
    getJobData(j_id);

  }, []);


  return (
    <>
      <div>
        <Header />

        <div className="d-flex">
          <Menu />

          <div className="content-wrapper">
            {/* <Link to="/jobDetails">
              <i
                className="fas fa-arrow-alt-circle-left"
                style={{ fontSize: "21px", color: "black" }}
              ></i>
            </Link> */}



            <div className="search_box_border_class">
              <div className="main_heading_h1">{jobDetails.job_title}</div>
            </div>

            <div className="card_div mt-2" style={{ width: "100%" }}>
              <div className="card-header bg-white  p-0">
                <div className="card_inner_div">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div>
                          <img
                            src={jobDetails.comp_logo}
                            style={{
                              height: "130px",
                              width: "130px",
                              objectFit:"contain"
                            }}
                          />
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <span
                      style={{
                        background: "#4AA081",
                        color: "white",
                        padding: "5px 30px",
                        borderRadius: "5px",
                        fontSize:"10px"
                      }}
                    >
                      {jobType}
                    </span>
                    </div>

                    <div className="mt-3 twelve_font_class">
                          <p>{jobDetails.company_name}</p>
                        </div>

                        <div
                          className="mt-3"
                          style={{
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          <p>{jobDetails.job_title}</p>
                        </div>

                  <div className="row">
                    <div style={{ marginTop: "5px" }}>
                      <div class="col-md-12 p-0">
                        <div
                          style={{
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          <div className="d-flex" style={{gap: "5px"}}>
                          <span className="eleven_font_class" style={{color: "#1F3977"}}>Place:</span>
                          <span className="ten_font_class">{jobDetails.location}</span>
                          </div>

                          <div className="d-flex mt-1" style={{gap: "5px"}}>
                          <span className="eleven_font_class" style={{color: "#1F3977"}}>Budget:</span>
                          <span className="ten_font_class">{jobDetails.budget}</span>

                          </div>

                          <div className="d-flex mt-1" style={{gap: "5px"}}>
                          <span className="eleven_font_class" style={{color: "#1F3977"}}>Deadline:</span>
                          <span className="ten_font_class">{d_date}</span>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <hr className="card_inner_hr_css" />
                  </div>

                  <div className="row">
                    <div className="">
                      <div className="">
                        <label
                          className="all_labels"
                          style={{ fontSize: "11px" }}
                        >
                          About The Job
                        </label>
                      </div>
                      <div className="">
                        <p>
                        <p className="desc_class" dangerouslySetInnerHTML={{ __html: jobDetails.about }}/>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <hr className="card_inner_hr_css"/>
                  </div>

                  <div className="row">
                    <div>
                      <span className="ten_font_class"
                        style={{
                          color: "#4AA081"
                        }}
                      >
                        Engagement Analytics
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5 p-0">
                      <section style={{ margin: "10px 5px" }}>
                        <div className="container-fluid table-cards">
                          <div className="table-cards">
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ height: "100%", padding: "0px 5px" }}
                              >
                                <div
                                  className="small-box"
                                  style={{
                                    height: "65px",
                                    borderRadius: "2.5PX",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="inner"
                                    // onClick={UniDetails}
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div>
                                        <span className="eleven_font_class"
                                          style={{
                                            color: "#1F3977"
                                          }}
                                        >
                                          Job Applications
                                        </span>
                                      </div>

                                      <div
                                        className="d-flex sixteen_font_class"
                                        style={{
                                          flexWrap: "wrap",
                                          marginTop: "5px"
                                        }}
                                      >
                                        <div>{jobDetails.job_applications}</div>
                                      </div>
                                    </div>

                                    <div className="all_icon_imgs_div"
                                      style={{background: "#FBE1FF"}}
                                    >
                                      <img className="all_icon_imgs"
                                        src="dist/img/ComboChart.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <div className="col-md-5 p-0">
                      <section style={{ margin: "10px 5px" }}>
                        <div className="container-fluid table-cards">
                          <div className="table-cards">
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ height: "100%", padding: "0px 5px" }}
                              >
                                <div
                                  className="small-box"
                                  style={{
                                    height: "65px",
                                    borderRadius: "2.5PX",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="inner"
                                    // onClick={UniDetails}
                                    style={{
                                      cursor: "pointer",
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div>
                                        <span className="eleven_font_class"
                                          style={{color: "#1F3977"}}
                                        >
                                          Saved
                                        </span>
                                      </div>

                                      <div
                                        className="d-flex sixteen_font_class"
                                        style={{
                                          flexWrap: "wrap",
                                          marginTop: "5px"
                                        }}
                                      >
                                        <div>{jobDetails.saved}</div>
                                      </div>
                                    </div>

                                    <div className="all_icon_imgs_div"
                                      style={{background: "#BEF5C3"}}
                                    >
                                      <img className="all_icon_imgs"
                                        src="dist/img/CircleChart.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

