import React, { useState,useEffect } from 'react'
import { Header } from './Header'
import { Menu } from './Menu'
import StudentOffer from './StudentOffer';

const StudentOffers = () => {
    const [loc,setLoc] = useState(window.location.pathname);
    useEffect(() => {
        // This will run when the page first loads and will display the title accordingly
        document.title = "Student Offers";
    }, []);
    return (
        <div >
            <Header />
            <div className='d-flex'>
            <Menu pageTitle={loc} />
            <StudentOffer />
        </div>
        </div>
    )
}

export default StudentOffers
