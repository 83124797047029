import React from 'react'
import {Header} from "../Header";
import {Menu} from "../Menu";
import {Help} from "./Help"
export function DisplayHelp() {
    return (
        <div style={{display:"flex"}}>
            <Header />
            <Menu />
            <Help />
        </div>
    )
}
