import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import $ from "jquery";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import SummerNote from "../Summer Note/SummerNote";


export function EventForm() {
  var todayy = "";

  $(".close_event").click(function () {
    $(".user_type").hide();
  });
  function preview() {
    $(".preview_polls").show();
  }

  $(".close_event").click(function () {
    $(".preview_polls").hide();
  });

  $(".close_event").click(function () {
    $(".preview_category").hide();
  });

  function event_image() {
    $(".event_image").hide();
  }
  function cancelEdit() {
    $(".preview_category").hide();
  }

  const [editEventName, updateEditEventName] = useState("");
  const [editEventDescription, updateEditEventDescription] = useState("");
  const [editEventVenue, updateEditEventVenue] = useState("");
  const [editEventDate, updateEditEventDate] = useState("");
  const [editEndDate, updateEditEndDate] = useState("");
  const [editEventStartTime, updateEditEventStartTime] = useState("");
  const [editEventEndTime, updateEditEventEndTime] = useState("");
  const [editEventEntryFee, updateEditEventEntryFee] = useState("");
  const [editTicketCapacity, updateEditTicketCapacity] = useState("");
  const [editTicketURL, updateEditTicketURL] = useState("");
  const [EditEventPhoto, updateEditEventPhoto] = useState("");
  const [editEventUserType, updateEditEventUserType] = useState("");
  const [errorMsg, setErrorMsg] =useState();
  const history = useHistory();

  function edit_category() {
    $(".preview_polls").hide();
    $(".preview_category").show();
    updateEditEventName(eventName);
    updateEditEventDescription(description);
    updateEditEventVenue(venue);
    updateEditEventDate(eventDate);
    updateEditEndDate(endDate);
    updateEditEventStartTime(startTime);

    updateEditEventEndTime(endTime);
    updateEditEventEntryFee(entryFee);
    updateEditTicketCapacity(ticketCapacity);
    updateEditTicketURL(ticketUrl);
    updateEditEventPhoto(eventPhoto);
    updateEditEventUserType(sendNotificationTo);
  }

  const resetValues = () => {
    var ele = document.getElementsByName("eventUserType");
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;

    updateEventName("");
    updateDescription("");
    updateEventPhoto("");
    updateVenue("");
    updateEventDate("");
    // updateEndDate("");
    updateStartTime("");

    updateEndTime("");
    updateEntryFee("");
    // setPicture("")
    updateTicketCapacity("");
    updateTicketUrl("");

    $(".event_form_image").hide();
    $(".event_image").show();
    $("#file-ip-1-preview").hide();

  };

  todayy = new Date().toISOString().slice(0, 10);

  // const token = localStorage.getItem("Token");
  const [eventName, updateEventName] = useState("");
  const [description, updateDescription] = useState("");
  const [eventPhoto, updateEventPhoto] = useState("");
  const [venue, updateVenue] = useState("");
  const [eventDate, updateEventDate] = useState("");
  const [endDate, updateEndtDate] = useState("");
  const [startTime, updateStartTime] = useState("");
  const [endTime, updateEndTime] = useState("");
  const [entryFee, updateEntryFee] = useState("");
  const [ticketCapacity, updateTicketCapacity] = useState("");
  const [ticketUrl, updateTicketUrl] = useState("");
  const [sendNotificationTo, updatesendNotificationTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [picture, setPicture] = useState(null);

  useEffect(() => {

  }, []);

  // function nextStep(){
  //   window.location.href="/eventformstep2";
  // }

  const handelSummenrnote = (e) => {
    updateDescription(e);
  };

  async function nextStep() {
    console.log("get events image", picture);
    try {
      const event_name = document.getElementById("event_name");
      const event_fee = document.getElementById("entery_fee");
      const event_capacity = document.getElementById("event_capacity");
      const ticket_url = document.getElementById("ticket_url");

      if (
        event_name.value == "" &&
        description == "" &&
        picture == null &&
        event_fee.value == "" &&
        event_capacity.value == "" &&
        ticket_url.value == ""
      ) {
        $(".EventName").show();

        setTimeout(function () {
          $(".EventName").hide();
        }, 3000);

      } else if (event_name.value == "") {
        $(".EventName").show();

        setTimeout(function () {
          $(".EventName").hide();
        }, 3000);
      } else if (description == "") {
        $(".Description").show();

        setTimeout(function () {
          $(".Description").hide();
        }, 3000);
      }
      else if (picture == null) {
        $(".EventImage").show();

        setTimeout(function () {
          $(".EventImage").hide();
        }, 3000);
      }
       else if (event_fee.value == "") {
        $(".entery_fee").show();

        setTimeout(function () {
          $(".entery_fee").hide();
        }, 3000);
      } else if (event_capacity.value == "") {
        $(".event_capacity").show();

        setTimeout(function () {
          $(".event_capacity").hide();
        }, 3000);
      } else if (ticket_url.value == "") {
        $(".ticket_url").show();

        setTimeout(function () {
          $(".ticket_url").hide();
        }, 3000);
      }
       else {
          history.push("/eventformstep2",
          { eventName,description,entryFee,ticketCapacity,ticketUrl,
             picture
          });
      }
    } catch (err) {
      console.log("next step error--------", err);
      setIsLoading(false);
    }
  }


  const [imgData, setImgData] = useState([]);

  var eventImage = [];

  const getImage = (e) => {
    $(".event_image").hide();

    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setPicture(eventImage);

        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";
        //     const atasi = src.substring(5)
        //  console.log("Atasi",atasi)
        imgData.push(src);
      }
    }
  };

  $("#time_end").change(function () {
    var startDate = document.getElementById("time_start").value;
    var endDate = document.getElementById("time_end").value;

    if (startDate >= endDate) {
      $(".time_alert").show();

      const endTime = document.getElementById("time_end");
      endTime.value = "";
      setTimeout(() => {
        $(".time_alert").hide();
      }, 2000);
    } else {
      $(".time_alert").hide();
    }
  });

  return (
    <div>
      <Header />
      <div className="d-flex">
      <Menu />

      <div className="content-wrapper">

      <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
        </div>

        <div
          className="search_box_border_class"

        >
          <div className="main_heading_h1">
            CREATE  CAMPUS  EVENT
          </div>
          {/* <div style={{fontSize:"11PX", color:"darkgrey",fontWeight:"500"}}>Campus News keep up to date with what's happening on campus & in your city.</div> */}
        </div>

        <div
          className=""

        >
          <div className="border_class">
            <div className="row">
              <div className="col-md-6">
                <div
                  style={{ width: "100%"}}
                >
                  <div className="d-flex">
                    <label className="all_labels">
                      Event Name
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input className="input_box"
                    type="name"
                    id="event_name"
                    value={eventName}
                    onChange={(e) => updateEventName(e.target.value)}
                    placeholder="Event title goes here..."
                    autoComplete="true"

                  />
                  <div
                    class="EventName"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Event Name
                    </h4>
                  </div>

                  <div className="d-flex mt-3">
                    <label className="all_labels">
                      About Event
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <div>
                      <SummerNote
                          value={description}
                          _onChange={handelSummenrnote}
                      />
                 </div>

                  {/* <textarea className="input_box"
                    id="desc"
                    value={description}
                    onChange={(e) => updateDescription(e.target.value)}
                    placeholder="Your message goes here..."
                    autoComplete="true"
                    style={{height:"70px"}}
                  /> */}
                  <div
                    class="Description"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Description
                    </h4>
                  </div>


                </div>
              </div>

              <div class="col-md-6">
                <div  className="">
                  <div className="d-flex">
                    <label className="all_labels">
                      Add Event Photo
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <label for="file-ip-1" className="mb-0">
                    <img
                      src="dist/img/event_photo.png"
                      className="event_image"
                      alt="dropdown"
                      style={{ height: "173px" }}
                    />
                    <img
                      className=" multipleImages"
                      id="file-ip-1-preview"
                      style={{display:"none", width: "100%", height: "177px"  }}
                    >
                      {/* {imgData.map((item) => {
                        return (
                          <div style={{ width: "70px", height: "50px" }}>
                            <img src={item} className="event_form_image" />
                          </div>
                        );
                      })} */}
                    </img>
                  </label>

                  <div
                    class="EventImage"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Select Event Image
                    </h4>
                  </div>

                  <input
                    type="file"
                    name="photo"
                    style={{ visibility: "hidden",display:"none" }}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={getImage}
                    multiple
                    id="file-ip-1"

                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            </div>

          <div className="border_class">
            <div class="row">
              <div class="col-md-3">
                <div>
                <div className="d-flex">
                  <label className="all_labels">
                    Entry Fee
                  </label>
                  <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input className="input_box"
                    type="number"
                    id="entery_fee"
                    value={entryFee}
                    onChange={(e) => updateEntryFee(e.target.value)}
                    placeholder="&#x20B9;"

                     style={{width:"100%"}}
                  />
                   <div
                    class="entery_fee"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please enter entry fee
                    </h4>
                  </div>

                </div>
              </div>
              <div class="col-md-3">
                <div >
                  <div className="d-flex">
                    <label className="all_labels">
                      Event Capacity
                    </label>
                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <input className="input_box"
                    type="number"
                    min="0"
                    id="event_capacity"
                    value={ticketCapacity}
                    onChange={(e) => updateTicketCapacity(e.target.value)}
                    placeholder="event capacity"
                    autoComplete="true"

                  />
                  <div
                    class="event_capacity"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Event Capacity
                    </h4>
                  </div>
                </div>
              </div>




            </div>
            <div className="row" style={{marginTop:"10px"}}>
            <div class="col-md-6">
                <div className="">
                 <div className="d-flex">
                  <label className="all_labels">
                    Ticket URL
                  </label>
                  <p className="all_stars">
                      *
                    </p>
                    </div>

                  <input className="input_box"
                    type="name"
                    id="ticket_url"
                    value={ticketUrl}
                    onChange={(e) => updateTicketUrl(e.target.value)}
                    placeholder="yourticket.com"
                    autoComplete="true"

                  />
                  <div
                    class="ticket_url"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter ticket url
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* buttons */}
          <div className="d-flex border_class" style={{alignItems:"center"}}>
            <div
              class="ValueMsg"
              style={{ width: "57%",display: "none" }}
            >
              <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="error">
              Error! You Must Fill In All The Fields
              </Alert>
              </Stack>
            </div>


           <img className="ml-auto delete_image" onClick={() => resetValues()}
             src="dist/img/delete.png" alt="dropdown" />
              <div>
                 <div className="news_bar" >|</div>
              </div>
{/*
             <button className="preview_button" >
              <p className="preview_button_p_tag">Preview</p>
             <img className="preview_image" onClick={() => preview()}
              src="dist/img/view.png" alt="dropdown"  />
             </button> */}

            <button
              type="button"
              className="next_step_btn"

              // onClick={() => createEvent()}
              onClick={nextStep}


            >
             <div className="next_step_btn_div">
              Next Step
            </div>
            <img className="next_step_btn_img"
              src="dist/img/next.png"

            />
            </button>
          </div>


           {/* Preview Polls */}
          <div
            className="preview_polls"
            style={{
              position: "fixed",
              top: "0",
              left: "0px",
              background: "rgba(0,0,0,0.5)",
              padding: "10px",
              width: "100%",
              height: "100%",
              zIndex: "10",
              display: "none",
            }}
          >
            <div
              style={{
                padding: "15px",
                background: "#f5f5f5",
                boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.35)",
                position: "absolute",
                bottom: "0px",
                top: "0",
                right: "5px",
                width: "420px",
                height: "100%",
                overflow: "auto",
              }}
            >
              <div
                className="d-flex"
                style={{
                  borderBottom: "2px solid #15a312",
                  transform: "rotate(0.13deg)",
                }}
              >
                <label style={{ fontSize: "13px", fontWeight: "600" }}>
                  Preview
                </label>

                <img
                  src="dist/img/Cancel.png"
                  alt="dropdown"
                  className="close_event ml-auto"
                  style={{ cursor: "pointer", width: "20px", height: "20px" }}
                />
              </div>

              {/* {
            eventName == "" || description == "" || venue == "" || eventDate == "" || startTime == "" || endTime == "" || ticketCapacity == "" || sendNotificationTo == "" ?

              (
                <div>Please fill the form to preview it</div>
              ) : ( */}
              <div
                style={{
                  background: "white",
                  marginTop: "10PX",
                  padding: "5px 10PX",
                  border: "0.4px solid #C4C4C4",
                }}
              >
                <div className="d-flex" style={{ padding: "10px 0px" }}>
                  <h4
                    style={{
                      color: "rgba(0, 0, 0, 0.7)",
                      fontSize: "12PX",
                      fontWeight: "600",
                    }}
                  >
                    Campus Event
                  </h4>
                  <img
                    src="dist/img/Pencil.png"
                    alt="dropdown"
                    width="18px"
                    height="18px"
                    className=" ml-auto"
                    onClick={() => edit_category()}
                  />
                </div>

                {
                  <div>
                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Event Name
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>{eventName}</span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Description
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>
                        <p dangerouslySetInnerHTML={{ __html:description }}/>

                        </span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Event Photo
                      </p>
                      <p
                        className="col-md-9 d-flex"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        :
                        <span style={{ marginLeft: "10px" }}>
                          {picture == null ? (
                            <div>
                              <img
                                src={require("../images/no_image.png")}
                                alt="no image"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={imgData}
                                alt="preview"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          )}
                        </span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Venue
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        : <span style={{ marginLeft: "10px" }}>{venue}</span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Event Date
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>{eventDate} </span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Event Date
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        : <span style={{ marginLeft: "10px" }}>{endDate} </span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Start Time
                      </p>
                      <p
                        className="col-md-3"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>{startTime}</span>
                      </p>
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        End Time
                      </p>
                      <p
                        className="col-md-3"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        : <span style={{ marginLeft: "10px" }}>{endTime}</span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Entry Fee
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        : <span style={{ marginLeft: "10px" }}>{entryFee}</span>{" "}
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Event Capacity
                      </p>
                      <p
                        className="col-md-9"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        :{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {ticketCapacity}
                        </span>
                      </p>
                    </div>

                    <div
                      className="row"
                      style={{
                        background: "#e4e9f3",
                        padding: "7px",
                        margin: "7px 3px 0px 3px",
                      }}
                    >
                      <p
                        className="col-md-3"
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontWeight: "600",
                          fontSize: "12PX",
                        }}
                      >
                        Ticket URL
                      </p>
                      <p
                        className="col-md-9 d-flex"
                        style={{
                          color: "#1f3977",
                          fontWeight: "600",
                          fontSize: "10PX",
                        }}
                      >
                        {ticketUrl}
                      </p>
                    </div>
                  </div>
                }
              </div>
              {/* )
          } */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default EventForm;
