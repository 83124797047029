import React,{useState} from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu";
import {CreateAdmin} from "./CreateAdmin"
export function CreateAdminDetails() {
    const [loc,setLoc] = useState(window.location.pathname);
    return (
        <div>
            <Header />
            <div className="d-flex">
            <Menu pageTitle={loc} />
            <CreateAdmin />
        </div>
        </div>
    )
}
