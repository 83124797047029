import React from 'react'
import {IoMdAddCircle} from "react-icons/io"
import {BsImageFill} from "react-icons/bs"
export function AppointmentForm() {
    return (
        <div className="content-wrapper">
           <a href="/appointmentDetails" >
           <div className="d-flex" style={{padding:"0", margin:"0"}}>
             <i class="fa fa-angle-double-left" style={{color:"black", margin:"0px 0px 0px 10px",fontSize:"24px", padding:"0"}} /> 
             <p style={{fontSize:"12px", color:"black", margin:"0px",  padding:"5px"}}>Back</p> 
            </div>
            </a>
            {/* Campus News form */}
            <div className="" style={{background:"white", margin:"10px 20px 0px 20px", padding:"10px"}}>
            
             {/* Enter RESON OF APPOINTMENT */}
             <div className="" style={{marginLeft:"20px", width:"97%"}}>
           <label style={{color:"#1F3977", fontSize:"12px", fontWeight:"bold"}}>Enter Your Reason*</label>
           <input
             type="name"
             id="exampleInputEmail1"
             placeholder="Your Title goes here..."
             style={{width: "100%", height:"30px", border:"1px solid grey",fontSize:"12px", paddingLeft:"5PX"}}
            
           />
         </div>

         {/* Enter date */}
         <div className="" style={{marginLeft:"20px", width:"97%",marginTop:"10PX"}}>
           <label style={{color:"#1F3977", fontSize:"12px", fontWeight:"bold"}}>Enter Date Of Appointment*</label>
           <input type="datetime-local" id="birthdaytime" name="birthdaytime" style={{border:"1px solid grey", width:"100%"}}/>
         </div>

 {/* duration */}
 <div className="" style={{marginLeft:"20px", width:"97%",marginTop:"10PX"}}>
           <label style={{color:"#1F3977", fontSize:"12px", fontWeight:"bold"}}>Duration*</label>
           <input
             type="name"
             id="exampleInputEmail1"
             placeholder="Duration Of Appointment..."
             style={{width: "100%", height:"30px", border:"1px solid grey",fontSize:"12px", paddingLeft:"5PX"}}
            
           />
         </div>
             
             {/* venue */}
 <div className="" style={{marginLeft:"20px", width:"97%",marginTop:"10PX"}}>
           <label style={{color:"#1F3977", fontSize:"12px", fontWeight:"bold"}}>Venue*</label>
           <input
             type="name"
             id="exampleInputEmail1"
             placeholder="Venue Of Appointment..."
             style={{width: "100%", height:"30px", border:"1px solid grey",fontSize:"12px", paddingLeft:"5PX"}}
            
           />
         </div>

</div>
          

     
            <div className="" style={{margin:"10px 20px 0px 20px",background:"white", padding:"10px"}}>
         {/* publish button */}
                <div className="d-flex">  
                <input
        type="button"
        className="create_btn"
        defaultValue="Sign Up"
        value="Preview"
        style={{ borderRadius:"5px", backgroundColor:"#293043" ,padding:"10px 30px",fontSize:"12PX",fontWeight:"bold"}}
      />
     <input
        type="button"
        className="create_btn"
        defaultValue="Sign Up"
        value="Save"
        style={{ fontWeight:"bold",borderRadius:"5px", color:"#1F3977",marginLeft:"auto", backgroundColor:"white",padding:"10px 30px",fontSize:"12PX", border:"1px solid #1F3977", color:"#1F3977" }}
      />
      <a href="/campusNews">
       <input
        type="button"
        className="create_btn"
        defaultValue="Sign Up"
        value="Publish"
        style={{ fontWeight:"bold",borderRadius:"5px", marginLeft:"5px", backgroundColor:"#1F3977",padding:"10px 30px",fontSize:"12PX" }}
      />
      </a>
      </div>
      </div>
        </div>
    )
}
