import React, { useState, useEffect, useMemo } from "react";
import TeacherFilterComponent from "./TeacherFilterComponent";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import axios from "axios";
import $ from "jquery";
import { AiFillEye } from "react-icons/ai";
import MaterialTable from "material-table";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link, useHistory } from "react-router-dom";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;
const customStyles = {
  rows: {
    style: {
      padding: "5px 0 10px 15px",
      border: "none",
    },
  },

  head: {
    style: {
      padding: "10px 0 10px 15px",
      fontWeight: "600",
      color: "black",
    },
  },
  table: {
    style: {
      padding: "0",
    },
  },
};

export function TeacherList() {
  const [id, setId] = useState(684);
  const [data, setData] = useState([]);
  const token = localStorage.getItem("Token");
  const [teacherList, setTeacherList] = useState([]);
  const [teacherId, setTeacherId] = useState("");

  async function fetchList() {
    console.log("Access Token-", token);
    try {
      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "get_campus_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      console.log("Get Campus List", fetchResponse);
      const campusListArry = fetchResponse.data.data;
      console.log("Campus listArry", campusListArry);

      setData(campusListArry);
      console.log("Data list", data);
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,

    selectAllRowsItemText: "ALL",
  };
  async function displayTeacherList(id) {
    console.log("ABCDEFGHIJKLMNOPQRSTUVWXYZ", id);

    const formData = new FormData();
    formData.append("campus_id", id);
    const teacherResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_get_campuswise_teacher",
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },
      }
    );

    console.log("error_code", teacherResponse.data.error_code);

    console.log("Campus Wise Teacher's List", teacherResponse.data.data);

    setTeacherList(teacherResponse.data.data);
    console.log("atasi", teacherList);
  }

  async function fetchAllCampusData() {
    const formData = new FormData();
    formData.append("campus_id", 0);
    const teacherResponse = await axios.post(
      process.env.REACT_APP_API_KEY + "super_get_campuswise_teacher",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: token,
        },
      }
    );
    console.log("error_code", teacherResponse.data.error_code);

    console.log("Campuswise All Teacher", teacherResponse.data.data);

    setTeacherList(teacherResponse.data.data);
    console.log("atasi", teacherList);
  }
  useEffect(() => {
    fetchList();
    fetchAllCampusData();
  }, []);

  const columns = [
    {
      name: "Teacher ID",
      // selector: "campus_id",
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) => {
        setId(row.id);
        return (
          <div
            style={{
              fontWeight: "500",
              fontSize: "11PX",
              color: "rgba(0, 0, 0, 0.6)",
              cursor: "pointer",
            }}
          >
            {row.id}
          </div>
        );
      },
    },
    {
      name: "Teacher Name",
      // selector: "campus_name",
      sortable: true,
      wrap: true,
      width: "auto",
      cell: (row) => {
        return (
          <div
            style={{
              fontWeight: "500",
              fontSize: "11PX",
              color: "rgba(0, 0, 0, 0.6)",
              cursor: "pointer",
            }}
          >
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },

    {
      name: "Action",
      // selector: 'campus_info.state',
      sortable: true,
      width: "auto",
      cell: (row) => {
        console.log("ROW", row);
        return (
          <div className="d-flex">
            <Link
              className="cta"
              to={{
                pathname: "/ViewInfo",
                state: { tid: row.id },
              }}
            >
              <AiFillEye style={{ fontSize: "18px" }} />
            </Link>
          </div>
        );
      },
    },
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = teacherList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      // <FilterComponent
      //   onFilter={(e) => setFilterText(e.target.value)}
      //   onClear={handleClear}
      //   filterText={filterText}
      // />
      <div></div>
    );
  }, [filterText, resetPaginationToggle]);

  function addFaculties() {
    $(".add_faculty_modal").toggle();
  }

  function closeFacultyModal() {
    $(".preview_category").hide();
  }

  return (
    <div className="content-wrapper">
      <div
        className="border_class"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="col-md-5 d-flex">
          <div className="search_box_div">
            <img
              className="search_box_img"
              src={require("../images/Search.png")}
            />
            <Input
              className="search_box"
              id="search"
              type="text"
              placeholder="Search by student name/Id"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>

        <div
          className="col-md-7 d-flex"
          style={{ justifyContent: "end", alignItems: "center" }}
        >
          <div className="d-flex">
            <a
            // href={StudentExcel} download="addStudents.xlsx"
            >
              <div
                style={{
                  marginLeft: "5px",
                  color: "#FFFFFF",
                  fontSize: "11PX",
                  lineHeight: "18px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                }}
              >
                <img
                  src="dist/img/Download.png"
                  style={{ width: "22px", height: "22px" }}
                />
              </div>
            </a>
          </div>

          <div className="d-flex" style={{ alignItems: "center" }}>
            <span
              className="faq_bar"
              style={{
                marginLeft: "13px",
                marginRight: "13px",
                color: "#4AA081",
              }}
            >
              |
            </span>
          </div>

          <div className="">
            <button
              type="button"
              className="d-flex add_faq_button"
              defaultValue="Sign Up"
              style={{
                justifyContent: "end",
                position: "relative",
                height: "33px",
              }}
              onClick={addFaculties}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Add Faculty
              </span>
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "12.25PX",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                }}
              ></div>
              <img
                src="dist/img/AddNew.png"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "13px",
                }}
              />
            </button>
            <div
              class="add_students_modal add_faculty_modal"
              id="add_modal1"
              style={{
                display: "none",
                position: "absolute",
                top: "14px",
              }}
            >
              <div className="  hover_class">
                <Link
                  to="/facultyform"
                  style={{
                    display: "flex",
                    padding: "5px 10px",
                    alignItems: "center",
                  }}
                >
                  <div className=" d-flex">
                    <img
                      src="dist/img/CollaboratorMale.png"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "5px",
                      color: "#000000",
                      fontSize: "11PX",
                      lineHeight: "18px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                    }}
                  >
                    Add a Faculty
                  </div>
                </Link>
              </div>

              <div className=" d-flex  hover_class">
                <a
                  href="#addFaculties"
                  style={{
                    display: "flex",
                    padding: "5px 10px",
                    alignItems: "center",
                  }}
                >
                  <div className=" d-flex flex-row">
                    <img
                      src="dist/img/UserAccount.png"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "5px",
                      color: "#000000",
                      fontSize: "11PX",
                      lineHeight: "18px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                    }}
                  >
                    Add Multiple Faculties
                  </div>
                </a>
              </div>
            </div>
            {/* modal end */}
          </div>
        </div>
      </div>

      {/* <div className="border_class">
        <div class="row m-0">
          <div class="col-md-6 p-0">
            <div className="" style={{ width: "100%", marginTop: "0px" }}>
              <label
                style={{
                  color: "#1F3977",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Campus Name <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-select form-select-sm input_box"
                aria-label=".form-select-sm example"
                id="food-select"
                onChange={(e) => displayTeacherList(e.target.value)}
                required
              >
                <option selected="selected" value={0}>
                  Select Campus
                </option>
                {data.map((item, index) => {
                  // console.log("Id",item.campus_id)

                  return (
                    <option
                      value={item.campus_id}
                      key={index}
                      // onClick={() => displayTeacherList(item.campus_id)}
                    >
                      {item.campus_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div> */}

          <div
              className="row  mt-3"
              
            >
              <div className="col-md-12">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "11px",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: "18px",
                    color: "#1F3977",
                   
                    background: "rgba(31, 57, 119, 0.9)",
                    height: "40px",
                    width: "100%",
                    margin:"0px"
                  }}
                >
                  <p
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontSize: "14px",

                      padding: "10px",
                      textAlign: "center",
                    }}
                    // onChange={filterTeachers}
                  >
                    All Faculties
                  </p>
                </label>
                <br></br>
              </div>
            </div>

      <div className="border_class mt-0">
        <DataTable
          columns={columns}
          data={filteredItems}
          striped
          paginationPerPage={10}
          pagination
          paginationRowsPerPageOptions={[
            10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
          ]}
          paginationComponentOptions={paginationComponentOptions}
          // subHeader
          subHeaderComponent={subHeaderComponent}
          highlightOnHover
          defaultSortFieldId={1}
          customStyles={customStyles}
        />
      </div>

      {/* add Faculty modal */}
      <div id="addFaculties" className="student_add_new_modal add_multiple_faculies">
        <div className="student_inner_div border_class" style={{width:"380px"}}>
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Import Faculties
              </p>
              <br></br>
              <div>
              <p
                style={{ fontWeight: "300", color: "black", fontSize: "13px" }}
              >
                To import students, select a CSV or vCard file.
              </p>
              </div>
              <div class="row">
                <div class="" style={{ padding: "0" }}>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "30px",
                      paddingRight: "0",
                      height:"85px"
                    }}
                  >
                    <label for="file-ip-1">
                      <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-around"}}>
                       <div  style={{background:"#1F3977",color:"#FFFFFF",height:"50px",width:"110px",
                                   display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"6px",
                                   fontWeight:"300",fontSize:"15px"}}>Select file
                       </div> 
                      <div  id="file-ip-1-preview" style={{display:"block", width:"200px",overflow:"auto",marginLeft:"13px",paddingTop:"8px"}}>
                           
                      </div>
                      </div>
                
                    </label>
                    <input
                      type="file"
                      
                      // onChange={(e)=>getImage(e)}
                      id="file-ip-1"
                     
                      accept=".pdf,.xlsx,.xls,application/vnd.ms-excel"
                      className="input_fields"
                     
                      name="birthdaytime"
                      style={{
                       visibility:"hidden",
                        color: "black",
                        fontSize: "11px",
                        width: "100%",
                        marginTop:"20px"
                        
                       
                      }}
                    />
                     </div>
                     <div class="error_modal" style={{ marginTop: "5px", display:"none",  width: "97%", marginBottom: "5px" }}>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                       <Alert variant="filled" severity="error">
                         Please select file
                     </Alert>
                   </Stack>
                  </div>
                 
                </div>
              </div>

             

              <div className="d-flex mt-3">
                <a
                  onClick={closeFacultyModal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className=""
                    value="Cancel"
                    style={{
                      background:"#ffffff",
                      border:"none",
                      color:"#4779F0"
                    }}
                  />
                </a>

               
                  <input
                    type="button"
                    value="Import"
                    // onClick={() => uploadExcel()}
                    style={{
                      background:"#ffffff",
                      border:"none",
                      color:"#4779F0",
                      marginLeft:"35px"
                    }}
                  />
               

                <div
                  class="formError"
                  style={{ marginTop: "-6px", display: "none" }}
                >
                  <h4
                    class="login-text"
                    style={{ color: "red", fontSize: "10PX", marginLeft: "0" }}
                  >
                    Please Select the File
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add Faculty modal end */}


    </div>
  );
}
