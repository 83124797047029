import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from "axios";
import { Header } from './Header';
import { Menu } from './Menu';
import { Height } from '@material-ui/icons';

import StudentOffers from './StudentOffers';
import { render } from '@testing-library/react';

const CampusEvents = () => {
    const [loc,setLoc] = useState(window.location.pathname);
    
    const [offerData, setOfferData] = useState([])

    //async function to fetc market data
    async function getOfferData() {
       try {
        const token = localStorage.getItem('Token');
        console.log("token-------------------------------------",token);
            const fetchResponse = await axios.get( process.env.REACT_APP_API_KEY + "get_offer_list",
                {
                    headers:
                    {
                        "Content-Type": 'multipart/form-data',
                        'authorization': token,
                    },
                }
            );
            console.log("Listing------------", fetchResponse);
           
            const off_Data = fetchResponse.data.data;
            const error_code = fetchResponse.data.error_code;

            if (error_code == 200) {
                setOfferData(off_Data);
            }

        } catch (err) {
            console.log("Failed to get offer list ", err);
        }
    }

    useEffect(() => {
        getOfferData();
    }, [])

    const columns = [
        {
            name: <b>Offer Name</b>,
            selector: (row) => row.offername,
            sortable: true
        },
        {
            name: <b>Link</b>,
            selector: (row) => row.offerlink,
            sortable: true
        },
        {
            name: <b>Discription</b>,
            selector: (row) => row.offerdesc,
            sortable: true
        },
        {
            name: <b>Offer Image</b>,
            selector: (row) => <img className='image_class' src={row.offer_img}/>,
            sortable: true
        }

    ];
          
       function addOffersClick(){
        window.location.href="/offers";
       }


    return (
        <div style={{ display: "flex" }}>
        <Header />
        <Menu pageTitle={loc} />

      
       
        <div className='content-wrapper mx-auto h-full'>
        <div className='add_offer_button'>
            <div> <h5> Offer List</h5></div>
            <button onClick={addOffersClick}>Add Offers
                
            </button>
        </div>
           
            <DataTable
                // title="Campus List"
                fixedHeader
                fixedHeaderScrollHeight='400px'
                pagination
                columns={columns}
                data={offerData}
            />
        </div>
    </div>
    )
}

export default CampusEvents
