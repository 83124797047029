import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import { useHistory,Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StateAndCities from "../State and Cities/StateAndCities.json"

export function CreateCampusForm() {
  const [error_message, updateError_message] = useState("");
  const token = localStorage.getItem("Token");

  const [collegeName, setCollegeName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contact, setContact] = useState("");
  const [website, setWebsite] = useState("")
  const [paidStatus, setPaidStatus] = useState(1);

  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [cityNameData, setCityNameData] = useState([])

  const location = useLocation();
   const history = useHistory();
   const { uni_id } = location.state;
   const { uniName } = location.state;
   const { uniState } = location.state;

  async function createCampus() {
    try {
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (
        collegeName == "" &&
        address == "" &&
        city == "" &&
        emailId == "" &&
        contact == "" &&
        website == ""
      ) {
        $(".college_name").show();
        setTimeout(function () {
          $(".college_name").hide();
        }, 3000);
      }
      else if (collegeName == "") {
        $(".college_name").show();
        setTimeout(function () {

          $(".college_name").hide();
        }, 3000);
      }else if (picture == null) {
        $(".college_logo").show();
        setTimeout(function () {

          $(".college_logo").hide();
        }, 3000);
      }
      else if (address == "") {
        $(".address").show();
        setTimeout(function () {

          $(".address").hide();
        }, 3000);
      }
      else if (city == "") {
        $(".city").show();
        setTimeout(function () {

          $(".city").hide();
        }, 3000);
      }

      else if (emailId == "") {
        $(".email_id").show();
        setTimeout(function () {

          $(".email_id").hide();
        }, 3000);
      } else if (!filter.test(emailId )) {
        $(".validEmail").show();

        setTimeout(function() {
          $(".validEmail").hide();
        }, 3000);
        return;
      }
       else if ( contact == "") {
        $(".contact_no").show();
        setTimeout(function () {

          $(".contact_no").hide();
        }, 3000);
      }else if (contact.length != 10) {
        $(".validMobileNumber").show();

        setTimeout(function() {
          $(".validMobileNumber").hide();
        }, 3000);
      }
      else if (website =="") {
        $(".website").show();
        setTimeout(function () {

          $(".website").hide();
        }, 3000);
      }

      else {
        const formData = new FormData();
        formData.append("uni_id", uni_id);
        formData.append("campus_name", collegeName);

        formData.append("address", address);
        formData.append("city", city);
        formData.append("state", uniState);
        formData.append("email_id", emailId);
        formData.append("mobile_no", contact);
        formData.append("website", website);
        formData.append("lat", "");
        formData.append("long", "");
        formData.append("paid_status", paidStatus);

         for (var i = 0; i < picture.length; i++) {
          formData.append("camp_logo", picture[i]);
        }

        for (const p of formData.entries()){
          console.log(`data----------${p[0]},${p[1]}`);
        }
        const response = await axios.post(
          process.env.REACT_APP_API_KEY + "create_campus",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );

        // console.log("response---------------------", response.data);
        const error_code1 = response.data.error_code;

        const camp_data =response.data.camp_id;
        updateError_message(response.data.message);
        if (error_code1 == 200) {
          $(".formSuccess").show();

          setTimeout(function () {
            $(".formSuccess").hide();
            history.push("/createAdminDetails",{camp_data});
          }, 3000);


        } else {

        }


        // updateCampusName("");
        // updateAddress("");
        // updateCity("");
        // updateState("");
        // updateStartDate("");
        // updateEndDate("");
        // updateWebsite("");

        $(".updatePassword").show();

        setTimeout(function () {
          $(".updatePassword").hide();
        }, 3000);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState([]);

  var eventImage = [];

    const getImage = (e) => {
    $(".event_image").hide();

    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setPicture(eventImage);
        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";

        imgData.push(src);
      }
    }
  };

  const stateFun =(uniState) => {
    setStateCityData(StateAndCities)
    stateCityData.map((e) =>{
      e.states.map((_item)=>{
         if (_item.state == uniState) {
          setCityNameData(_item.districts);
         }
      })

    })
}

  useEffect(() => {
    stateFun(uniState);
  }, []);

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/[^0-9]/g, "");
    setContact(onlyDigits);
  };

  const resetValues = () => {
    setCollegeName("");
    setAddress("");
    setCity("");
    setEmailId("");
    setContact("");
    setWebsite("");
    setPaidStatus(1);
    $("#city_input").val("disabled")
    $("#paid_status").val(1);


    $(".event_form_image").hide();
    $(".event_image").show();
    $("#file-ip-1-preview").hide();

    // history.push("")

  };

  return (
    <div>
      <div>
      <div
              class="ValueMsg"
              style={{ width: "57%",display: "none" }}
            >
              <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert variant="filled" severity="error">
              Error! You Must Fill In All The Fields
              </Alert>
              </Stack>
            </div>

            <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {error_message}
          </Alert>
        </Stack>
        </div>

        <div>
          <div className="border_class">
            <div class="row">
              <div className="col-md-6">
                <div class="">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Organization Name</label>

                      <p className="all_stars">*</p>
                    </div>

                    <input
                        type="name"
                      className="stud-prefered-name input_box"

                      autoComplete="true"
                      value={uniName}
                      readOnly
                    />

                  </div>
                </div>

                <div className="mt-2">
                  <div class="">
                    <div class="">
                      <div className="" style={{ width: "100%" }}>
                        <div className="d-flex">
                          <label className="all_labels">College Name</label>

                          <p className="all_stars">*</p>
                        </div>

                        <input
                        type="name"
                      className="stud-prefered-name input_box"
                      id="college_name"
                      placeholder="College name goes here..."
                      autoComplete="true"
                      value={collegeName}
                      onChange={(e) => setCollegeName(e.target.value)}

                    />

                        <div
                          class="college_name"
                          style={{ margin: "0", display: "none" }}
                        >
                          <h4
                            class="login-text"
                            style={{
                              color: "red",
                              fontSize: "12PX",
                              marginLeft: "0",
                            }}
                          >
                            Please Enter College Name
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <div className="d-flex">
                    <label className="all_labels">Add College Logo</label>

                    <p className="all_stars">*</p>
                  </div>

                  <label for="file-ip-1" className="mb-0">
                    <img
                      src="dist/img/event_photo.png"
                      className="event_image"
                      alt="dropdown"
                      style={{ height: "82px" }}
                    />
                    <img
                      className=" multipleImages"
                      id="file-ip-1-preview"
                      style={{
                        display: "none",
                        width: "100%",
                        height: "82px",
                      }}
                    >

                    </img>
                  </label>

                  <div
                    class="college_logo"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Select College Logo
                    </h4>
                  </div>

                  <input
                    type="file"
                    name="photo"
                    style={{ visibility: "hidden", display: "none" }}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={getImage}
                    multiple
                    id="file-ip-1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border_class">


            <div class="row">
              <div class="col-md-3">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>

                  <div className="d-flex">
                    <label className="all_labels">
                      State
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                        type="name"
                      className="stud-prefered-name input_box"

                      autoComplete="true"
                      value={uniState}
                      readOnly
                    />

                  {/* <select
                    className="input_box"
                    onChange={(e) => getStateName(e.target.value)}
                  >
                    <option value={"disabled"}>Select State</option>

                    {stateData.map((name) => {
                      return (
                        <option value={name.state_id}>{name.state_name}</option>
                      );
                    })}
                  </select> */}

                  <div
                    class="state"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter State
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>

                  <div className="d-flex">
                    <label className="all_labels">
                      City
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <select
                    id="city_input"
                    className="input_box"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option value={"disabled"}>Select City</option>

                    {cityNameData.map((name) => {
                      return (
                        <option value={name}>{name}</option>
                      );
                    })}
                  </select>

                  <div
                    class="city"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter City
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div class="row">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Address</label>

                      <p className="all_stars">*</p>
                    </div>
                    <textarea
                      className="stud-prefered-name input_box"
                      id="address"
                      placeholder="Address goes here..."
                      autoComplete="true"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{ height: "50px" }}
                    />
                    <div
                      class="address"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Address
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>

                  <div className="d-flex">
                    <label className="all_labels">
                      Email Id
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                    className="input_box"
                    type="email"
                    id="email_id"
                    placeholder="Email Id goes here..."
                    autoComplete="true"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                  />

                  <div
                    class="email_id"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Email Id
                    </h4>
                  </div>
                  <div
                    class="validEmail"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4 class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}>
                      Please Enter valid Email Address with @
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>

                  <div className="d-flex">
                    <label className="all_labels">
                      Phone Number
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                    className="input_box"
                    type="text"
                    id="contact_no"
                    placeholder="Phone number goes here..."
                    autoComplete="true"
                    value={contact}
                    minLength="10"
                    pattern="\d{3}[\-]\d{3}[\-]\d{4}"
                    maxLength="10"
                    onChange={(e) => checkInput(e)}
                  />

                  <div
                    class="contact_no"
                    style={{ margin: "0", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}
                    >
                      Please Enter Phone Number
                    </h4>
                  </div>
                  <div class="validMobileNumber"
                     style={{ margin: "0", display: "none" }}
                  >
                    <h4 class="login-text"
                      style={{
                        color: "red",
                        fontSize: "12PX",
                        marginLeft: "0",
                      }}>
                      Please Enter 10 digit mobile number
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div class="row ">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>

                    <div className="d-flex">
                      <label className="all_labels">
                        Website
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="name"
                      className="stud-prefered-name input_box"
                      id="website"
                      placeholder="Enter Website..."
                      autoComplete="true"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                    <div
                      class="website"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Website
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="all_labels">Paid Status</label>

                      <p className="all_stars">*</p>
                    </div>

                    <select
                    id="paid_status"
                      className="input_box"
                      onChange={(e) => setPaidStatus(e.target.value)}
                    >
                      <option selected value="1">Paid</option>
                      <option value="2">Unpaid</option>
                    </select>

                    <div
                      class="paid_status"
                      style={{ margin: "0", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "12PX",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Organization Name
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* button */}

          <div className="d-flex border_class" style={{alignItems:"center"}}>



           <img className="ml-auto delete_image"
            onClick={() => resetValues()}
             src="dist/img/delete.png" alt="dropdown" />
              <div>
                 <div className="news_bar" >|</div>
              </div>

            <button
              type="button"
              className="next_step_btn"

              onClick={createCampus}


            >
             <div className="next_step_btn_div">
              Next Step
            </div>
            <img className="next_step_btn_img"
              src="dist/img/next.png"

            />
            </button>
          </div>


          {/* <div className="d-flex border_class ">
            <input
              type="button"
              className="publish_button"
              value="Preview"
              onClick={checkandhandleshow}
            />
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Campus Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <b>Campus: &nbsp;</b>
                {campusName}
                <br />
                <b>Address: &nbsp;</b>
                {address}
                <br />
                <b>State: &nbsp;</b>
                {state}
                <br />
                <b>City: &nbsp;</b>
                {city}
                <br />
                <b>Website: &nbsp;</b>
                {website}
                <br />
                <b>Start Date: &nbsp;</b>
                {startDate}
                <br />
                <b>End Date: &nbsp;</b>
                {endDate}
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>

              </Modal.Footer>
            </Modal>

            <div
              class="EnterAllFileds"
              style={{ margin: "10px auto", display: "none" }}
            >
              <h4
                class="login-text"
                style={{ color: "red", fontSize: "12PX", marginLeft: "0" }}
              >
                You Must Fill In All The Fields
              </h4>
            </div>
            <input
              type="button"
              className="save_button"

              value="Save"
              onClick={() => createCampus()}
              style={{ marginLeft: "auto" }}
            />

          </div> */}
        </div>
        <div class="end_date" style={{ margin: "0", display: "none" }}>
          <h4
            class="login-text"
            style={{ color: "red", fontSize: "12PX", marginLeft: "0" }}
          >
            End Date should be greater than start date
          </h4>
        </div>
      </div>

      <div
        class="updatePassword"
        style={{ margin: "10px auto", display: "none" }}
      >
        <div className="success_img ms-3"></div>
        <h4 class="login-text" style={{ color: "#339dd8" }}>

          {error_message}
        </h4>
      </div>
    </div>
  );
}
