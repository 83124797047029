import React from 'react';
import { Header } from "../Header";
import { Menu } from '../Menu';
import { UpdateProfile } from './UpdateProfile';
import ViewProfile from "./ViewProfile"
export function Profile() {
       return (
              <div>
                     <Header />
                     <div className='d-flex'>
                     <Menu />
                     {/* <UpdateProfile /> */}
                     <ViewProfile/>
              </div>
              </div>
       )
}
