import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from "axios";
import { Header } from './Header';
import { Menu } from './Menu';
import moment from "moment";
import $ from "jquery";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import { useHistory,Link } from "react-router-dom";
import styled from "styled-components";
import StateAndCities from "./State and Cities/StateAndCities.json"
import SummerNote from './Summer Note/SummerNote';

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

const StudentOffersList = () => {
    const [loc,setLoc] = useState(window.location.pathname);
    const [offerData, setOfferData] = useState([])
    const [errorMsg, setErrorMsg] =useState();
    const [offerId, setOfferId] = useState();
    const [descId, setDescId] = useState();
    const [offer_desc,setOffer_desc] = useState();
    const history = useHistory();

    var todayy = "";

    const [uniData, setUniData] = useState([]);

    // edit form states
    const [editOfferId, setEditOfferId] = useState("")
    const [offerName,setOfferName] = useState("");
    const [offerDiscription, setOfferDiscription] = useState("")
    const [productAvailableOn,setProductAvailableOn] = useState("");
    const [offerDescount, setOfferDescount] = useState("")
    const [brandName,setBrandName] = useState("");
    const [state, setState] = useState("")
    const [city,setCity] = useState("");
    const [university, setUniversity] = useState("")
    const [startDate,setStartDate] = useState("");
    const [endDate, setEndDate] = useState("")
    const [publishDate,setPublishDate] = useState("");
    const [expiryDate, setExpiryDate] = useState("")
    const [offerLink,setOfferLink] = useState("");
    const [voucherCode , setVoucherCode] = useState("")
    const [companyLogo,setCompanyLogo] = useState("");
    const [coverPhoto, setCoverPhoto] = useState("")

    const [stateCityData, setStateCityData] = useState([StateAndCities]);
    const [stateNameData, setStateNameData] = useState([])
    const [cityNameData, setCityNameData] = useState([]);



    const customStyles = {
      rows: {
        style: {
          background: "rgba(228, 233, 243, 0.6)",
          marginTop: "3PX",
          border: "none",
          fontSize:"10px",
          fontWeight:"500"
        },
      },
      headCells: {
        style: {
          color: "#1F3977",
          fontSize: "10px",
          fontWeight: "500",
        },
      },

      head: {
        style: {

          boxShadow: "0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)",
          height:"35px"
        },
      },
    };


    const token = localStorage.getItem('Token');
    async function getOfferData() {
       try {
            const fetchResponse = await axios.get( process.env.REACT_APP_API_KEY + "get_offer_list",
                {
                    headers:
                    {
                        "Content-Type": 'multipart/form-data',
                        'authorization': token,
                    },
                }
            );
            const off_Data = fetchResponse.data.data;
            const error_code = fetchResponse.data.error_code;
            if (error_code == 200) {
                setOfferData(off_Data);
            }

        } catch (err) {
            console.log("Failed to get offer list ", err);
        }
    }

    function closeOfferDescModal(){
      $(".offer_description_modal").hide();
      setOffer_desc("");
    }


    function delete_offer(offer_id){
        setOfferId(offer_id);
        $(".delete_offer_modal").addClass("showModal");
      }

      function closeModal() {
            $(".delete_offer_modal").removeClass("showModal");
            $('.actions_modal'+tId).hide();
      }

      async function deleteOffer() {
        try{

            const formData = new FormData();
            formData.append("offer_id", offerId);
            const StatusResponse =  await axios.post(
              process.env.REACT_APP_API_KEY + "super_admin_delete_std_offer",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",

                  Authorization: token,
                },
              }
            );
            if (StatusResponse.data.error_code == 200) {

              $(".delete_offer_modal").removeClass("showModal");
              setErrorMsg(StatusResponse.data.message);
             $(".formSuccess").show()
             setTimeout(() => {
               $(".formSuccess").hide()

             }, 3000);
                getOfferData();
             }
           } catch (err) {
             console.log("Log in Fail", err);
           }
      }

      const getCityData = async (e) => {
        setState(e)
        stateNameData.map((_e) =>{
          if(_e.state == e){
            setCityNameData(_e.districts);
          }
        })
        try {
          const formData = new FormData();
          formData.append("state_name", e);

          const fetchResponse = await axios.post(
            process.env.REACT_APP_API_KEY + "super_admin_get_university_list_state_wise",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
              },
            }
          );

          if (fetchResponse.data.error_code == 200) {
            setUniData(fetchResponse.data.data);
          } else {
            setUniData([]);
          }
        } catch (err) {
          console.log("Log in Fail", err);
        }
      };


      const getCityAndUniData = async (e) => {
        setUniData([]);
        setCity(e)
        try {
          const formData = new FormData();
          formData.append("city_name", e);

          const fetchResponse = await axios.post(
            process.env.REACT_APP_API_KEY + "super_admin_get_university_list_city_wise",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
              },
            }
          );

          if (fetchResponse.data.error_code == 200) {
            setUniData(fetchResponse.data.data);
          } else {
            setUniData([]);
          }
        } catch (err) {
          console.log("Log in Fail", err);
        }
      };

        const stateFun =() =>{

        setStateCityData(StateAndCities)
        stateCityData.map((e) =>{
          setStateNameData(e.states);
        })
    }

    useEffect(() => {
        getOfferData();
        stateFun();
    }, [])

    const ShowOfferDetails = (o_id) => {
        history.push("/studentofferdetails",{o_id})
    }


    const columns = [
      {
        name:"Offer Name",
        selector: (row) =>{
          const array = [];
          array.push(row.comp_logo);

          return (
            <div className="d-flex" style={{alignItems:"center"}}>
              {array.length == 0 ? (
                <div>
                  <img
                    src={require("./images/no_image.png")}
                    style={{ padding: "5px", width: "40px", height: "30px" }}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={array[0]}
                    style={{ padding: "5px", width: "40px", height: "30px" }}
                  />
                </div>
              )}

                <span className="ten_font_class"
                  onClick={() => ShowOfferDetails(row.id)}
                  style={{
                    color: "black",
                    fontWeight: "600",
                    cursor: "pointer",

                    marginLeft: "10PX",
                    // marginTop: "8PX",
                    width:"215px",
                    overflow:"hidden",
                    whiteSpace:"nowrap",
                    textOverflow:"ellipsis"

                  }}
                >
                  {row.offername}
                </span>

            </div>
          );
        } ,
        sortable: true,
        width:"30%",
      },

        {
            name: "Status",
            selector: (row) =>{if(row.status==1){
                return(<span>Active</span>)
            }else{
              return(<p>Inactive</p>)
            }},

        },
        // {
        //     name:"Offer Claims",
        //     selector: (row) => {},
        //     sortable: true
        // },
        {
            name:"Offer Timeline",
            selector: (row) =>{
              var date1= row.start_date_time;
              var date2= row.end_date_time;
              var s_date1 = moment(date1).format("D MMM");
              var s_date2 = moment(date2).format("D MMM");
              return(<>{s_date1} - {s_date2}</>)
            }
        },
        {
          name:"Available On",
          selector: (row) => row.prod_available_on,
        },

        {
          name: "",

          width: "auto",
          cell: (row) => {

            return (
              <div className="action_buttons_end_css">

                   <button
                    className="all_action_buttons"
                    onClick={()=> openActionsModal(row.id)}>
                    Actions
                  </button>


                            <div className={`p-0 edit_campus_modal actions_modal${row.id}`}
                             style={{display: "none",position:"absolute",top:"12px",right:"0px"}}>


                            <div className=" d-flex ml-auto" style={{padding:"2px 2px 0px 0px"}}>
                              <img
                                className="campus_img ml-auto"
                                src="dist/img/Cancel.png"
                                onClick={()=>closeActionsModal(row.id)}
                              />
                            </div>

                            <div style={{padding:"2px 10px 10px 10px"}}>
                          <div className="  hover_class">
                            <div className=" d-flex flex-row"
                             onClick={()=>openEditForm(row.id)}>
                              <div>
                                <img
                                  className="campus_img"
                                  src="dist/img/campusEdit.png"
                                />
                              </div>
                              <div className="campus_inner_div">
                                <span>Edit</span>
                              </div>
                            </div>
                          </div>

                          <div className=" d-flex flex-row hover_class">
                            <div className=" d-flex flex-row" onClick={()=> delete_offer(row.id)}>
                              <div>
                                <img
                                  className="campus_img"
                                  src="dist/img/campusClose.png"
                                />
                              </div>
                              <div className="campus_inner_div">
                                <span>Delete</span>
                              </div>
                            </div>
                          </div>


                        </div>

                          </div>


              </div>
            );


          },
        },




    ];

    const [tId, setTId] = useState("");
    const openActionsModal = (e) => {
      setTId(e);
      $(".edit_campus_modal").hide();
      $('.actions_modal'+e).toggle();

    }
    const closeActionsModal = (e) => {
      $(".edit_campus_modal").hide();
    }

       const handelSummenrnote = (e) => {
        setOfferDiscription(e);
      };

       const saveOffer = async () => {
        try{

        if (companyLogo == "") {
          $(".comp_logo").show();

          setTimeout(function () {
            $(".comp_logo").hide();
          }, 3000);
        }else if(offerName == "") {
          $(".offer_name").show();

          setTimeout(function () {
            $(".offer_name").hide();
          }, 3000);
        }
        else if(offerDiscription == "") {
          $(".offer_description").show();

          setTimeout(function () {
            $(".offer_description").hide();
          }, 3000);
        }
        else if(coverPhoto == "") {
          $(".cover_photo").show();

          setTimeout(function () {
            $(".cover_photo").hide();
          }, 3000);
        }
        else if(productAvailableOn == "") {
          $(".product_on").show();

          setTimeout(function () {
            $(".product_on").hide();
          }, 3000);
        }
        else if(offerDescount == "") {
          $(".offer_discount").show();

          setTimeout(function () {
            $(".offer_discount").hide();
          }, 3000);
        }else if(brandName == "") {
          $(".brand_name").show();

          setTimeout(function () {
            $(".brand_name").hide();
          }, 3000);
        }
        else if(state == "") {
          $(".offer_state").show();

          setTimeout(function () {
            $(".offer_state").hide();
          }, 3000);
        }
        else if(startDate == "") {
          $(".start_date").show();

          setTimeout(function () {
            $(".start_date").hide();
          }, 3000);
        }else if(endDate == "") {
          $(".end_date").show();

          setTimeout(function () {
            $(".end_date").hide();
          }, 3000);
        }
        else if(publishDate == "") {
          $(".publish_date").show();

          setTimeout(function () {
            $(".publish_date").hide();
          }, 3000);
        }
        else if(expiryDate == "") {
          $(".expiry_date").show();

          setTimeout(function () {
            $(".expiry_date").hide();
          }, 3000);
        }
        else if(offerLink == "") {
          $(".offer_link").show();

          setTimeout(function () {
            $(".offer_link").hide();
          }, 3000);
        }else if(voucherCode == "") {
          $(".voucher_code").show();

          setTimeout(function () {
            $(".voucher_code").hide();
          }, 3000);
        }
        else{
          const formData = new FormData();

          formData.append("id", editOfferId);
          formData.append("brand_name", brandName);
          formData.append("discount", offerDescount);
          formData.append("prod_available_on", productAvailableOn);
          formData.append("offername", offerName);
          formData.append("offerdesc", offerDiscription);
          formData.append("state", state);
          formData.append("city", city);
          formData.append("university", university);
          formData.append("offerlink", offerLink);
          formData.append("v_code", voucherCode);
          formData.append("start_date_time", startDate);
          formData.append("end_date_time", endDate);
          formData.append("publish_start_date_time", publishDate);
          formData.append("publish_end_date_time", expiryDate);

          for (var i = 0; i < companyLogo.length; i++) {
            formData.append("comp_logo", companyLogo[i]);
          }
          for (var i = 0; i < coverPhoto.length; i++) {
            formData.append("cover_img", coverPhoto[i]);
          }

          const fetchResponse = await axios.post(
            process.env.REACT_APP_API_KEY + "super_edit_student_offer",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
              },
            }
          );

          if(fetchResponse.data.error_code == 200){
            $(".preview_category").hide();
            getOfferData();
          }
        }
      }catch(err){
          console.log("event edit form error---------",err);
      }
      }

       function openEditForm(r_id) {
        const id_offer_date = offerData.filter((item) =>item.id == r_id);
        id_offer_date.map((e)=>{
          setEditOfferId(r_id);
          setOfferName(e.offername);
          setOfferDiscription(e.offerdesc);
          setBrandName(e.brand_name);
          setProductAvailableOn(e.prod_available_on);
          setOfferLink(e.offerlink);
          setStartDate(e.start_date_time);
          setEndDate(e.end_date_time);
          setVoucherCode(e.v_code);
          setOfferDescount(e.discount);
          setState(e.state);
          setCity(e.city);
          setUniversity(e.university);
          setPublishDate(e.publish_start_date_time);
          setExpiryDate(e.publish_end_date_time)
          setCompanyLogo(e.comp_logo);
          setCoverPhoto(e.cover_img);
          getCityData(e.state);
          getCityAndUniData(e.city)

          var preview = document.getElementById("file-ip-1-preview");
              preview.style.display = "none";
              $(".image_std").show();
         var preview1 = document.getElementById("cover_image_preview");
              preview1.style.display = "none";
              $(".cover_image").show();
        })

        $(".edit_campus_modal").hide();
        $(".preview_category").show();
      }

       function closeEditForm() {
        $(".preview_category").hide();
      }

      var eventImage = [];
      const getCompLogo = (e) => {
        setCompanyLogo("")
        // $(".image_std").hide();
        if (e.target.files.length > 0) {
          for (let i = 0; i < e.target.files.length; i++) {
            eventImage.push(e.target.files[i]);

            setCompanyLogo(eventImage);

            var src = URL.createObjectURL(e.target.files[i]);
            var preview = document.getElementById("file-ip-1-preview");
            preview.src = src;
            preview.style.display = "block";
            $(".image_std").hide();

          }
        }
      };

      var c_image = [];
      const getCoverImage = (e) => {
        setCoverPhoto("")
        // $(".image_std").hide();
        if (e.target.files.length > 0) {
          for (let i = 0; i < e.target.files.length; i++) {
            c_image.push(e.target.files[i]);

            setCoverPhoto(c_image);
            var src = URL.createObjectURL(e.target.files[i]);
            var preview = document.getElementById("cover_image_preview");
            preview.src = src;
            preview.style.display = "block";
            $(".cover_image").hide();

          }
        }
      };

      const [filterText, setFilterText] = React.useState("");
      const filteredItems = offerData.filter(
        (item) =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      );


    return (
        <div>
        <Header />
        <div className='d-flex'>
        <Menu pageTitle={loc} />
        <div className='content-wrapper'>

        <div class="formSuccess" style={{ marginTop: "5px",  width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
        </div>

        <div className="search_box_border_class">
        <div className="col-md-3 d-flex flex-row">
            <div className="search_box_div">
              <img className="search_box_img"
                src={require("./images/Search.png")}

              />
              <Input className="search_box"
                id="search"
                type="text"
                placeholder="Search by offer name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}

              />
            </div>
          </div>

          <div
            className="col-md-9 d-flex flex-row"
            style={{ justifyContent: "end" }}
          >
            <div style={{ marginTop: "0px", padding: "0" }}>
              <Link to="/offers">
                <button
                  type="button"
                  className="d-flex  create_button"
                  defaultValue="Sign Up"

                >
                  <div className="create_button_inner">
                    Create Offer
                  </div>
                  <img className="create_button_img"
                    src="dist/img/Progress.png"

                  />
                </button>
              </Link>
            </div>
          </div>


        </div>
        {/* <div className='d-flex border_class'>
            <div> <h5  style={{fontWeight:"bold", fontSize:"20px"}}> Offer List</h5></div>
            <button className='create_button' style={{marginLeft:"auto"}}
              onClick={addOffersClick}>
                Add Offers

            </button>
        </div> */}

        <div className='border_class'>
            <DataTable
                // title="Campus List"
                fixedHeader
                fixedHeaderScrollHeight='600px'
                pagination
                columns={columns}
                data={filteredItems}
                customStyles={customStyles}

            />
            </div>
        </div>
        <div id="delete" className="modaloverlay delete_container delete_offer_modal">
        <div className="modalContainer">
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Delete Offer
              </p>
              <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>
                Are You Sure, You Want To Delete this Offer?
              </h2>

              <div className="d-flex mt-3">
                <a
                  //   onClick={close_delete_modal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"
                    onClick={()=>closeModal()}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#c4c4c4",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>

                <a
                  href="#delete_with_protection"
                  style={{ color: "grey", fontSize: "15PX" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_student"
                    value="Delete"
                    onClick={() => deleteOffer()}
                    style={{
                      borderRadius: "5px",
                      marginRight: "7px",
                      backgroundColor: "#d21f3c",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      {/* modal end */}

      {/* Add award modal */}
      <div id="offer_desc" className="offer_description_modal" style={{display:"none"}}>
        <div className="student_inner_div" style={{marginLeft:"210px",background:"#ffffff",width:"640px",borderRadius:"7px"}}>
          <div className="card-body border_class2" style={{padding:"10px 0px 0px 0px" ,boxShadow:"2px 2px 2px 0px #C4C4C4"}}>
            <div className='d-flex bottom_border' style={{padding:"0px 10px 0px 0px"}}>

               <div style={{marginLeft:"auto"}}>
               <img className='' onClick={closeOfferDescModal}
                  src='dist/img/Cancel.png'
                  style={{height:"20px",width:"20px"}}
               />
               </div>

               </div>
               <div className='' style={{paddingBottom:"30px"}}>


                 <div style={{padding:"20px 30px"}}>



                 <div>
                  <div className="d-flex">
                    <label style={{ color: "#1F3977", fontSize: "10px", fontWeight: "bold" }}>Offer Description</label>


                  </div>
                   <div>
                   <textarea className='border_class'
                     name='description'
                    id="award_description"
                    value={offer_desc}

                    autoComplete="true"
                    style={{ width: "100%", boxSizing: "border-box", fontSize: "14px", padding: "8PX",height:"120px", }}

                  />


                   </div>
                 </div>


                </div>
               </div>

          </div>
        </div>
      </div>
      {/* Add award modal end */}

         {/* **************************** Edit Offer ***************************** */}
         <div
        className="preview_category">
        <div className="edit_inner">
          <div className="d-flex edit_inner_div">
            <label className="main_labels">
              Offer
            </label>

            <img
              src="dist/img/Cancel.png"
              alt="dropdown"
              className="close_event ml-auto cancel_img"
              onClick={closeEditForm}

            />
          </div>

          <div
            className="preview_form">


            <div className="border_class edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
              <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Add Company Logo
                      </label>
                    </div>

                    <div>
                      {/* {picture == null ? (
                        <div>
                          <label for="file-ip-1" style={{ height: "100%" }}>
                            <img
                              className="image_std"
                              src={require("./images/no_image.png")}
                              alt="no image"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="photo"
                            style={{ visibility: "hidden",display:"none" }}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={getCompLogo}
                            id="file-ip-1"
                          />
                        </div>
                       ) : (  */}
                         <div>
                          <label
                            for="file-ip-1">
                                <div>
                                  <img className="image_std" src={companyLogo}
                                   style={{ width: "70px", height: "70px" }}
                                    />
                                </div>

                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden",display:"none" }}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={getCompLogo}
                              id="file-ip-1"
                            />
                          </label>
                        </div>
                      {/* )}  */}
                    </div>

                    <div
                      class="comp_logo"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please select company logo
                      </h4>
                    </div>
                  </div>
                </div>


              </div>

              <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Offer Name
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={offerName}
                      onChange={(e) => setOfferName(e.target.value)}

                    />

                    <div
                      class="offer_name"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter offer name
                      </h4>
                    </div>
                  </div>
                </div>


              </div>
              </div>
            </div>


            </div>

            <div className="border_class edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Offer Description
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <SummerNote _onChange={handelSummenrnote}
                       value={offerDiscription}
                    />
                    <div
                      class="offer_description"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Enter Offer Description
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>


            <div className="border_class2 edit_row_padding2">
            <div className="p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Offer Cover Photo
                      </label>
                    </div>

                    <div>
                      {/* {picture == null ? ( */}
                        {/* <div>
                          <label for="file-ip-1" style={{ height: "100%" }}>
                            <img
                              className="image_std"
                              src={require("./images/no_image.png")}
                              alt="no image"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="photo"
                            style={{ visibility: "hidden",display:"none" }}
                            accept="image/png, image/gif, image/jpeg"
                            // onChange={getImage}
                            id="file-ip-1"
                          />
                        </div> */}
                      {/* ) : ( */}
                         <div>
                          <label
                            for="cover_image">
                                <div>
                                  <img className="cover_image" src={coverPhoto}
                                  style={{ width: "70px", height: "70px" }}
                                 />
                                </div>
                            <img
                              id="cover_image_preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden",display:"none" }}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={getCoverImage}
                              id="cover_image"
                            />
                          </label>
                        </div>

                    </div>

                    <div
                      class="cover_photo"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please select cover photo
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">

            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                      Product Available On
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={productAvailableOn}
                      onChange={(e) => setProductAvailableOn(e.target.value)}

                    />
                     <div
                      class="product_on"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter store name
                      </h4>
                    </div>


                  </div>
                </div>

                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                      Discount
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={offerDescount}
                      onChange={(e) => setOfferDescount(e.target.value)}

                    />

                    <div
                      class="offer_discount"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter offer discount
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Brand Name
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={brandName}
                      onChange={(e) => setBrandName(e.target.value)}

                    />

                    <div
                      class="brand_name"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter brand name
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">

            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        State
                      </label>
                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <select
                    className="form-select form-select-sm edit_all_inputs"
                    aria-label=".form-select-sm example"
                    id="department_neww"
                      value={state}
                      onChange={(e) => getCityData(e.target.value)}

                  >
                    <option  selected = "selected" value="" >
                      Select State
                    </option>
                    {
                      stateNameData.map((e) => {
                        return(
                          <option value={e.state}>{e.state}</option>
                        )
                      })
                    }

                  </select>

                  <div
                      class="offer_state"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please select state
                      </h4>
                    </div>

                  </div>
                </div>

                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        City
                      </label>
                      <p className="all_stars">

                      </p>

                    </div>
                    <select
                  className="form-select form-select-sm edit_all_inputs"
                  aria-label=".form-select-sm example"
                  id="student_course"
                  value={city}
                  onChange={(e)=>getCityAndUniData(e.target.value)}
                >
                  <option selected="selected" value="">
                    Select City
                  </option>

                  {cityNameData.map((name) => {
                      return (
                        <option value={name}>{name}</option>
                      );
                    })}

                </select>
                  </div>
                </div>
              </div>
            </div>



            <div className="mt-2 p-0">
              <div class="row edit_row_margin">
                <div class="col-md-12">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        University
                      </label>
                      <p className="all_stars">

                      </p>

                    </div>
                    <select
                  className="form-select form-select-sm edit_all_inputs"
                  aria-label=".form-select-sm example"
                  id="student_class"
                  value={university}
                  onChange={(e)=>setUniversity(e.target.value)}

                >
                  <option selected="selected" value="">
                    Select University
                  </option>
                  {uniData.map((e)=>{
                      return(
                        <option value={e.uni_id}>{e.uni_name}</option>
                      )
                    })}

                </select>

                    <div
                      class="newsDescription"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Write News Description
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      Start Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="Now"
                  id="publish_date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  name="birthdaytime"

                />
                  <div
                    class="start_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please select start date
                    </h4>
                  </div>
                </div>

                <div class="col-md-6">
                  <div>
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      End Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="dd-mm-yyyy hh-mm"
                  id="publish_date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  name="birthdaytime"

                />
                  <div
                    className="end_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      className="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please select end date
                    </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className=" p-0">
              <div className="mb-2" style={{paddingLeft:"15px"}}>
              <label className="edit_all_labels">
                  When it should be published?
                </label>
              </div>
              <div class="row edit_row_margin">
                <div class="col-md-6">
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      Publish Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>
                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="Now"
                  id="publish_date"
                  value={publishDate}
                  onChange={(e) => setPublishDate(e.target.value)}
                  name="birthdaytime"

                />

                  <div
                    class="publish_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please select publish date
                    </h4>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                  <div className="d-flex">
                    <label className="edit_all_labels">
                      Expiry Date/Time
                    </label>

                    <p className="all_stars">
                      *
                    </p>
                  </div>

                  <input
                  type="datetime-local"
                  className="form_control edit_all_inputs"
                  min={todayy + "T00:00"}
                  placeholder="dd-mm-yyyy hh-mm"
                  id="publish_date"
                  value={expiryDate}
                  onChange={(e) => setExpiryDate(e.target.value)}
                  name="birthdaytime"

                />

                  <div
                    class="expiry_date"
                    style={{ marginTop: "-6px", display: "none" }}
                  >
                    <h4
                      class="login-text"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        marginLeft: "0",
                      }}
                    >
                      Please select expiry date
                    </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div className="border_class2 edit_row_padding2">
            <div className=" p-0">
              <div class="row edit_row_margin">
              <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Offer Link
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="text"
                      autoComplete="true"
                      placeholder="Link goes here.."
                      value={offerLink}
                      onChange={(e) => setOfferLink(e.target.value)}

                    />

                    <div
                      class="offer_link"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter offer link
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="" style={{ width: "100%", marginTop: "0px" }}>
                    <div className="d-flex">
                      <label className="edit_all_labels">
                      Voucher Code
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input  className="edit_all_inputs"
                      type="name"
                      autoComplete="true"
                      value={voucherCode}
                      onChange={(e) => setVoucherCode (e.target.value)}

                    />

                    <div
                      class="voucher_code"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please enter voucher code
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            {/* ******************button********************** */}

            <div className="d-flex mt-3 edit_buttons_div border_class"
             style={{justifyContent:"end"}}>
              <input
                type="button"
                className="edit_cancel_button"
                // onClick={() => cancelEdit()}
                value="Cancel"

              />

              <input
                type="button"
                className="edit_update_button"
                id="delete_single_student"
                value="Update"
                onClick={() => saveOffer()}

              />
            </div>

            <div style={{ display: "none" }} className="saveMessage">
              Data Saved Successfully
            </div>
          </div>
        </div>
      </div>


    </div>
    </div>
    )


}

export default StudentOffersList
