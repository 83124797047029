import React, { useEffect, useState } from 'react'
import axios from 'axios'
import DataTable from "react-data-table-component"

const Marketdummy = () => {
    const token = localStorage.getItem('Token');
    const [marketdata, setMarketData] = useState([])

    //async function to fetc market data
    async function getMarketData() {
        try {
            console.log("tokenmarket = " + localStorage.getItem("Token")) //token to ja rha 
            // alert("hey tere we are her\n");
            console.log(process.env.REACT_APP_API_KEY, "basepathurl h be")
            const fetchResponse = await axios.get("https://testingsites.in/Unicircle_Api_copy/SuperAdminController/" + "super_get_marketplace_list",
                {
                    headers:
                    {
                        "Content-Type": 'multipart/form-data',
                        'authorization': token,
                    },
                }
            );
            console.log("Get market List Details", fetchResponse);
            console.log("Error Code", fetchResponse.data.error_code);
            const marketData = fetchResponse.data.data;
            const error_code = fetchResponse.data.error_code;

            if (error_code == 200) {
                setMarketData(marketData);
            }

        } catch (err) {
            console.log("Failed to get market data benstokes ", err);
        }
    }

    useEffect(() => {
        getMarketData();
    }, [])

    const columns = [
        {
            name: <b>Title</b>,
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: <b>Email</b>,
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: <b>Price</b>,
            selector: (row) => row.price,
            sortable: true
        },
        {
            name: <b>Condition</b>,
            selector: (row) => row.condition,
            sortable: true
        },
        {
            name: <b>Description</b>,
            selector: (row) => row.description,
            sortable: true
        }
    ];

    return (
        <div className='content-wrapper'>
            <div className='border_class'>
               <h5><b> Market Place</b></h5>
            </div>
            <div className='border_class'>
            <DataTable
                // title="Campus List"
                fixedHeader
                fixedHeaderScrollHeight='400px'
                pagination
                columns={columns}
                data={marketdata}
            />
            </div>
        </div>
    )
}

export default Marketdummy