import React, { useState,useEffect } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import axios from "axios";
import $ from "jquery";
import DataTable from "react-data-table-component";
import { useHistory, Link,useLocation } from "react-router-dom";


export const AdminDetails = () => {
  const token = localStorage.getItem("Token");
  const [loc, setLoc] = useState(window.location.pathname);

  const customStyles = {
    rows: {
      style: {
        background: "rgba(228, 233, 243, 0.6)",
        marginTop: "6PX",
        border: "none",
      },
    },
    headCells: {
      style: {
        // color: "#15a312",
        color: "#1F3977",
      },
    },
    cell: {
      display: "flex",
      text: "center",
    },

    head: {
      style: {
        // border: "0.5px solid #C4C4C4",
        boxShadow: "0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)",
      },
    },
    table: {
      style: {
        padding: "0px 10px 0 10px",
        marginTop: "0PX",
        height: "auto",
      },
    },
  };

  const [adminAllData, setAdminAllData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [adminId,setAdminId] = useState("");

  const [campId, setCampId] = useState("");
  const [adminStatusMsg, setAdminStatusMsg] = useState("");

  const location = useLocation();
  const history = useHistory();
  const { campusId } = location.state || { id: "none" };


  async function getAdminData() {
    try {
      const formData = new FormData();
      formData.append("camp_id", campusId);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_admins_list",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const a_data = fetchResponse.data.data;
        setAdminAllData(a_data);
        setAdminData(a_data[0].admin_data);


      } else {
        // setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function adminStatus(adminId) {
    try {
      const formData = new FormData();
      formData.append("admin_id", adminId);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_change_campus_admin_status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      if (ErrorCode == 200) {
        const a_status = fetchResponse.data.message;
        setAdminStatusMsg(a_status);
        getAdminData();
        $(".success_msg").show();
        setTimeout(function () {
          $(".success_msg").hide();
        }, 2000);
      } else {
        // setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  async function deleteAdmin1() {
    try {
      const formData = new FormData();
      formData.append("admin_id", adminId);
      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_delete_campus_admin",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );
      const ErrorCode = fetchResponse.data.error_code;
      setAdminStatusMsg(fetchResponse.data.message);
      if (ErrorCode == 200) {
        getAdminData();
        $(".delete_container").removeClass("showModal");

        $(".success_msg").show();
        setTimeout(function () {
          $(".success_msg").hide();
        }, 2000);

      } else {

      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  function deleteAdmin(admin_id) {
    setAdminId(admin_id);
    $(".delete_container").show();
    $(".delete_container").addClass("showModal");
  }


  function closeModal() {
    $(".delete_container").removeClass("showModal");
  }

  useEffect(() => {
    getAdminData();
  }, []);


  const columns = [
    {
      name: "Admin Name",
      selector: "name",
            selector: (row) => {
              const array = [];

                array.push(row.profile_img);


              return (
                <div className="d-flex">
                  {array == 0 ? (
                    <div>
                      <img
                        src={require("../images/no_image.png")}
                        alt="Default"
                        style={{ padding: "5px", width: "50px", height: "50px" }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        src={array}

                        style={{ padding: "5px", width: "50px", height: "50px" }}
                      />
                    </div>
                  )}
                  <div>
                    <p
                      //  onClick={() => viewEventDetails(row.event_id)}
                      style={{
                        color: "black",
                        fontWeight: "600",
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "10PX",
                        marginTop: "15PX",
                      }}
                    >
                      {row.name}
                    </p>
                  </div>
                </div>
              );
            },
      sortable: true,
      width: "30%",
    },
    {
      name: "Email",
      selector: "email",

      sortable: true,
    },
    {
      name: "Phone",
      selector: "mobile",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",

      sortable: true,
      cell: (row) => {
        return (
          <div className="d-flex">
            {row.status == 0 ? (
              <button
                type="button"
                id="statusChangeButton"
                className=" form-buttons3"
                defaultValue="Sign Up"
                      onClick={() => adminStatus(row.admin_id)}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "red",
                  padding: "10px",

                  fontSize: "12PX",
                  fontWeight: "bold",
                  color: "#ffffff",
                  border: "none",
                }}
              >
                Deactive
              </button>
            ) : (
              <button
                type="button"
                id="statusChangeButton"
                className=" form-buttons3"
                defaultValue="Sign Up"
                onClick={() => adminStatus(row.admin_id)}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "green",
                  padding: "10px 17px",
                  fontSize: "12PX",
                  fontWeight: "bold",
                  color: "#ffffff",
                  border: "none",
                }}
              >
                Active
              </button>
            )}
          </div>
        );
      },
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="d-flex">
            <a
              className="cta"
              onClick={() => deleteAdmin(row.admin_id)}
              style={{ backgroundColor: "transparent" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginLeft: "2px" }}
                src={require("../images/delete.png")}
              />
              &nbsp;
            </a>
          </div>
        );
      },
    },
  ];



  return (
    <>
      <div>
        <Header />
        <div className="d-flex">
          <Menu pageTitle={loc} />

          <div className="content-wrapper">
            {/* <Link to="/universitydetails">
              <i
                className="fas fa-arrow-alt-circle-left"
                style={{ fontSize: "21px", color: "black" }}
              ></i>
            </Link> */}
            <div className="card_div mt-2" style={{ width: "100%" }}>
              <div className="card-header p-0">
                <div
                  className="bg-white"
                  style={{ borderRadius: "5px", paddingBottom: "50px" }}
                >
                  <div className="">


                    <div>


                          <div style={{ width: "100%" }}>
                            {adminAllData.map((e)=>{
                             return(

                        <img
                          className="cover_image"
                          src={e.cover_photo}

                          style={{background:"#b3b3b3",width:"100%",height:"260px"}}
                        />
                             )
                      })}
                      </div>



                      <div>
                        <div>
                        {adminAllData.map((e)=>{
                          return(
                          <img
                            style={{
                              width: "110px",
                              height: "120px",
                              marginTop: "-55px",
                              marginLeft: "40px",
                              background: "#F1F1F1",
                              borderRadius: "5px",
                            }}
                            src={e.profile_photo}
                          />
                          )
                        })}
                          <div></div>
                        </div>

                        <div style={{ margin: "15px 25px 0px 40px" }}>
                          <div className="row">
                          {adminAllData.map((e)=>{
                            return(<>
                            <div className="col-md-9">
                              <div
                                style={{ fontSize: "15px", fontWeight: "600" }}
                              >
                                <p>{e.campus_name}</p>
                              </div>
                              <div
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "600",
                                  display: "inline-flex",
                                }}
                              >
                                <p style={{ marginRight: "5px" }}>{e.total_user}</p>
                                <p>Users</p>
                              </div>
                            </div>
                            </>)
                            })}


                            <div
                              className="col-md-3 d-flex"
                              style={{
                                alignItems: "end",
                                justifyContent: "end",
                              }}
                            >
                              <Link to="/createAdminDetails">
                                <button
                                  type="button"
                                  className="d-flex  create_button"
                                  defaultValue="Sign Up"
                                >
                                  <div className="create_button_inner">
                                    Create Admin
                                  </div>
                                  <img
                                    className="create_button_img"
                                    src="dist/img/Progress.png"
                                  />
                                </button>
                              </Link>
                            </div>
                          </div>

                          <div
                            class="success_msg"
                            style={{
                              marginTop: "5px",
                              display: "none",
                              width: "100%",
                              marginBottom: "5px",
                            }}
                          >
                            <Stack sx={{ width: "100%" }} spacing={2}>
                              <Alert variant="filled" severity="success">
                                {adminStatusMsg}
                              </Alert>
                            </Stack>
                          </div>

                          <div className="mt-2">
                            <hr />
                          </div>

                          <div className="mt-3">
                            <div className="border_class">
                              <DataTable
                                fixedHeader
                                fixedHeaderScrollHeight="400px"
                                columns={columns}
                                data={adminData}
                                customStyles={customStyles}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          id="delete"
                          className="modaloverlay delete_container"
                        >
                          <div className="modalContainer">
                            <div
                              className="card-body"
                              style={{ marginTop: "0px" }}
                            >
                              <div>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    color: "black",
                                    fontSize: "13px",
                                  }}
                                >
                                  Delete Admin
                                </p>
                                <h2
                                  style={{
                                    marginTop: "20PX",
                                    fontSize: "13PX",
                                  }}
                                >
                                  Are You Sure, You Want To Delete this Admin?
                                </h2>

                                <div className="d-flex mt-3">
                                  <a
                                    //   onClick={close_delete_modal}
                                    href="#"
                                    style={{ marginLeft: "auto" }}
                                  >
                                    <input
                                      type="button"
                                      className="create_btn"
                                      value="Cancel"
                                      onClick={() => closeModal()}
                                      style={{
                                        borderRadius: "5px",
                                        backgroundColor: "#c4c4c4",
                                        fontSize: "13PX",
                                        padding: "8px 12px",
                                      }}
                                    />
                                  </a>

                                  <a
                                    href="#delete_with_protection"
                                    style={{ color: "grey", fontSize: "15PX" }}
                                  >
                                    <input
                                      type="button"
                                      className="create_btn"
                                      id="delete_single_student"
                                      value="Delete"
                                      onClick={() => deleteAdmin1()}
                                      style={{
                                        borderRadius: "5px",
                                        marginRight: "7px",
                                        backgroundColor: "#d21f3c",
                                        fontSize: "13PX",
                                        padding: "8px 12px",
                                      }}
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
